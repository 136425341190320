<template>
  <TableLayout :permissions="['business:cmm:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      
      <el-form-item label="企业名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入企业名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="成熟度等级" prop="level" @keypress.enter.native="search">
        <el-select v-model="searchForm.level" placeholder="请选择成熟度等级">
          <el-option
            v-for="item in levels"
            :key="item.id"
            :value="item.label"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      
      <!-- <el-form-item label="服务机构" prop="serveOrg">
        <el-input v-model="searchForm.serveOrg" placeholder="请输入服务机构" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="所属行业" prop="industry">
        <el-input v-model="searchForm.industry" placeholder="请输入所属行业" @keypress.enter.native="search"></el-input>
      </el-form-item>
      
      <el-form-item label="县区名称" prop="areaname">
        <el-input v-model="searchForm.areaname" placeholder="请输入县区名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="上年度营业收入（万元）" prop="revenue">
        <el-input v-model="searchForm.revenue" placeholder="请输入上年度营业收入（万元）" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="诊断类别" prop="diagnoseType">
        <el-input v-model="searchForm.diagnoseType" placeholder="请输入诊断类别" @keypress.enter.native="search"></el-input>
      </el-form-item> -->
      
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['business:cmm:create']">
        <li><el-button type="primary" @click="$refs.operaCmmWindow.open('新建企业能力成熟度数据信息')" icon="el-icon-plus" v-permissions="['business:cmm:create']">新建</el-button></li>
        <li><el-button type="primary" :loading="isWorking.export" v-permissions="['business:cmm:exportExcel']" @click="exportExcel">导出</el-button></li>
        <!-- <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['business:cmm:delete']">删除</el-button></li> -->
        <li>
          <ImportButton
            text="导入"
            template-name="maturity_import_modle.xlsx"
            template-path="/template/maturity_import_modle.xlsx"
            action="/business/cmm/importExcel"
            @success="search"
          />
        </li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="name" label="企业名称" min-width="120px" align="center" show-overflow-tooltip>
          <template slot-scope="{row}">
            <span style="lang-title-style">{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="serveOrg" label="服务机构" min-width="80px" align="center"></el-table-column>
        <el-table-column prop="industry" label="所属行业" min-width="100px" align="center"></el-table-column>
        <!-- <el-table-column prop="areaId" label="县区编码(关联areas)" min-width="100px"></el-table-column> -->
        <el-table-column prop="areaname" label="县区名称" min-width="80px" align="center"></el-table-column>
        <el-table-column prop="revenue" label="上年度营业收入(万元)" min-width="140px" align="center"></el-table-column>
        <el-table-column prop="diagnoseType" label="诊断类别" min-width="120px" align="center" show-overflow-tooltip>
          <template slot-scope="{row}">
            <span style="lang-title-style">{{ row.diagnoseType }}</span>
          </template></el-table-column>
        <el-table-column prop="level" label="成熟度等级" min-width="90px" align="center"></el-table-column>
        <el-table-column label="8个能力域"  align="center">
          <el-table-column prop="data0" label="人员" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data1" label="技术" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data2" label="资源" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data3" label="设计" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data4" label="生产" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data5" label="物流" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data6" label="销售" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data7" label="服务" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data8" label="总得分" min-width="80px" align="center"></el-table-column>
        </el-table-column>
        <el-table-column label="20个能力子域"  align="center">
          <el-table-column prop="data9" label="组织战略" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data10" label="人员技能" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data11" label="数据" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data12" label="集成" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data13" label="信息安全" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data14" label="装备" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data15" label="网络" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data16" label="产品设计" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data17" label="工艺设计" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data18" label="采购" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data19" label="计划与调度" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data20" label="生产作业" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data21" label="设备管理" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data22" label="安全环保" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data23" label="仓储配送" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data24" label="能源管理" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data25" label="物流(子域)" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data26" label="销售(子域)" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data27" label="客户服务" min-width="80px" align="center"></el-table-column>
          <el-table-column prop="data28" label="产品服务" min-width="80px" align="center"></el-table-column>
        </el-table-column>
        <el-table-column
          v-if="containPermissions(['business:cmm:update', 'business:cmm:delete'])"
          label="操作"
          width="120"
          align="center"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCmmWindow.open('编辑企业能力成熟度数据信息', row)" v-permissions="['business:cmm:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" v-permissions="['business:cmm:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCmmWindow ref="operaCmmWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCmmWindow from '@/components/business/OperaCmmWindow'
import ImportButton from '@/components/common/ImportButton'
export default {
  name: 'Cmm',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCmmWindow, ImportButton },
  data () {
    return {
      levels: [
        { label: '未达标', id: 1 },
        { label: '一级', id: 2 },
        { label: '二级', id: 3 },
        { label: '三级', id: 4 },
        { label: '四级', id: 5 },
      ],
      // 搜索
      searchForm: {
        id: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        remark: '',
        name: '',
        serveOrg: '',
        industry: '',
        areaId: '',
        areaname: '',
        revenue: '',
        diagnoseType: '',
        level: ''
      }
    }
  },
  created () {
    this.config({
      module: '企业能力成熟度数据信息',
      api: '/business/cmm',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
