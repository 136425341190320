<template>
  <div class="light" :class="{normal: !warn && !danger, warn: !danger && warn, danger, mini: mini}">
    <em><i></i></em>
  </div>
</template>

<script>
export default {
  name: 'Light',
  props: {
    warn: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
$cycle-size01: 16px;
$cycle-size02: 6px;
$normal-color: #00CC99;
$shadow-color: #00CCa9;
$warn-color: #FFCC33;
$warn-shadow-color: #FFCbe3;
$danger-color: #FF3300;
$dange-shadowr-color: #FF3310;
@mixin light-status ($cycle-bg) {
  em {
    background: $cycle-bg;
    i {
      background:  mix($cycle-bg,  #000030, 0.5);
    }
  }
}
.light {
  display: inline-block;
  border-radius: 50%;
  em {
    width: $cycle-size01;
    height: $cycle-size01;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      display: block;
      width: $cycle-size02;
      height: $cycle-size02;
      border-radius: 50%;
    }
  }
  &.mini {
    em {
      width: 12px;
      height: 12px;
    }
  }
  // 正常
  &.normal {
    @include light-status($normal-color);
    animation: shine-normal infinite 1s;
  }
  // 警告
  &.warn {
    @include light-status($warn-color);
    animation: shine-warn infinite .8s;
  }
  // 危险
  &.danger {
    @include light-status($danger-color);
    animation: shine-danger infinite .5s;
  }
}
@keyframes shine-normal {
  0% {
    box-shadow: 0 0 5px $shadow-color;
  }
  25% {
    box-shadow: 0 0 10px $shadow-color;
  }
  50% {
    box-shadow: 0 0 15px $shadow-color;
  }
  100% {
    box-shadow: 0 0 20px $shadow-color;
  }
}
@keyframes shine-warn {
  0% {
    box-shadow: 0 0 5px $warn-shadow-color;
  }
  25% {
    box-shadow: 0 0 10px $warn-shadow-color;
  }
  50% {
    box-shadow: 0 0 15px $warn-shadow-color;
  }
  100% {
    box-shadow: 0 0 20px $warn-shadow-color;
  }
}
@keyframes shine-danger {
  0% {
    box-shadow: 0 0 5px $dange-shadowr-color;
  }
  25% {
    box-shadow: 0 0 10px $dange-shadowr-color;
  }
  50% {
    box-shadow: 0 0 15px $dange-shadowr-color;
  }
  100% {
    box-shadow: 0 0 20px $dange-shadowr-color;
  }
}
</style>
