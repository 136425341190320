import request from '@/utils/request'

// 已报名项目
export function fetchList (data) {
  return request.post('/business/projectPermisson/page', data, {
    trim: true
  })
}

// 删除子账号关联项目
export function deleteById (id) {
  return request.post(`/business/projectPermisson/delete/${id}`)
}
// 市局子账号关联项目
export function distributeSjChild(data) {
  return request.post('/business/projectPermisson/create', data)
}
// 市局关联项目
export function findSJProjectList() {
  return request.post('/business/project/findSJProjectList')
}
