<template>
  <TableLayout :permissions="['business:declareanswer:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="主键" prop="id">
        <el-input v-model="searchForm.id" placeholder="请输入主键" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="是否已删除 0未删除 1已删除" prop="deleted">
        <el-input v-model="searchForm.deleted" placeholder="请输入是否已删除 0未删除 1已删除" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建人编码" prop="creator">
        <el-input v-model="searchForm.creator" placeholder="请输入创建人编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="searchForm.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建时间" @change="search"/>
      </el-form-item>
      <el-form-item label="更新人编码" prop="editor">
        <el-input v-model="searchForm.editor" placeholder="请输入更新人编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="editDate">
        <el-date-picker v-model="searchForm.editDate" value-format="yyyy-MM-dd" placeholder="请输入更新时间" @change="search"/>
      </el-form-item>
      <el-form-item label="是否删除0否 1是" prop="isdeleted">
        <el-input v-model="searchForm.isdeleted" placeholder="请输入是否删除0否 1是" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="searchForm.remark" placeholder="请输入备注" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="企业编码（关联company表)" prop="companyId">
        <el-input v-model="searchForm.companyId" placeholder="请输入企业编码（关联company表)" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="项目编码（关联project表)" prop="projectId">
        <el-input v-model="searchForm.projectId" placeholder="请输入项目编码（关联project表)" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="问卷答题记录编码（关联questionnaire表)" prop="questionnaireId">
        <el-input v-model="searchForm.questionnaireId" placeholder="请输入问卷答题记录编码（关联questionnaire表)" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="申报信息编码（关联declare表）" prop="declareId">
        <el-input v-model="searchForm.declareId" placeholder="请输入申报信息编码（关联declare表）" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="总得分" prop="score">
        <el-input v-model="searchForm.score" placeholder="请输入总得分" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="答题详情，json数组，属性为 opt,score,select" prop="content">
        <el-input v-model="searchForm.content" placeholder="请输入答题详情，json数组，属性为 opt,score,select" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="primary" :loading="isWorking.export" v-permissions="['business:declareanswer:exportExcel']" @click="exportExcel">导出</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['business:declareanswer:create', 'business:declareanswer:delete']">
        <li><el-button type="primary" @click="$refs.operaDeclareAnswerWindow.open('新建项目申报问卷答题记录')" icon="el-icon-plus" v-permissions="['business:declareanswer:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['business:declareanswer:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="deleted" label="是否已删除 0未删除 1已删除" min-width="100px"></el-table-column>
        <el-table-column prop="creator" label="创建人编码" min-width="100px"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" min-width="100px"></el-table-column>
        <el-table-column prop="editor" label="更新人编码" min-width="100px"></el-table-column>
        <el-table-column prop="editDate" label="更新时间" min-width="100px"></el-table-column>
        <el-table-column prop="isdeleted" label="是否删除0否 1是" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column>
        <el-table-column prop="companyId" label="企业编码（关联company表)" min-width="100px"></el-table-column>
        <el-table-column prop="projectId" label="项目编码（关联project表)" min-width="100px"></el-table-column>
        <el-table-column prop="questionnaireId" label="问卷答题记录编码（关联questionnaire表)" min-width="100px"></el-table-column>
        <el-table-column prop="declareId" label="申报信息编码（关联declare表）" min-width="100px"></el-table-column>
        <el-table-column prop="score" label="总得分" min-width="100px"></el-table-column>
        <el-table-column prop="content" label="答题详情，json数组，属性为 opt,score,select" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['business:declareanswer:update', 'business:declareanswer:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaDeclareAnswerWindow.open('编辑项目申报问卷答题记录', row)" icon="el-icon-edit" v-permissions="['business:declareanswer:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['business:declareanswer:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaDeclareAnswerWindow ref="operaDeclareAnswerWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaDeclareAnswerWindow from '@/components/business/OperaDeclareAnswerWindow'
export default {
  name: 'DeclareAnswer',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaDeclareAnswerWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        deleted: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        remark: '',
        companyId: '',
        projectId: '',
        questionnaireId: '',
        declareId: '',
        score: '',
        content: ''
      }
    }
  },
  created () {
    this.config({
      module: '项目申报问卷答题记录',
      api: '/business/declareAnswer',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
