<template>
  <GlobalWindow
    :title="title"
    :confirm-working="false"
    :visible.sync="visible"
    @confirm="confirm"
  >
    <TableLayout :permissions="['business:declares:query']">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="120px" label-suffix="：" inline>
        <el-form-item label="企业名称" prop="companyName">
          <el-input v-model="searchForm.companyName" placeholder="请输入企业名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="是否评分" prop="status">
          <el-select v-model="searchForm.status" placeholder="请选择评分状态">
            <el-option
              v-for="item in expertStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['business:declares:exportExcel']">
          <el-button :loading="isWorking.export" v-permissions="['business:declares:exportExcel']" @click="exportExcel">导出</el-button>
        </ul>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="companyName" label="企业名称" fixed="left" min-width="140px" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <el-button type="text" @click="showCompany(row,2)">{{ row.companyName }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="projectName" label="项目名称" min-width="140px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="areaName" label="所属区域" min-width="100px"></el-table-column>
          <el-table-column prop="dascore" label="问卷分数" min-width="100px">
            <template slot-scope="{ row }">
                {{ row.dascore || '0' }}
              </template>
          </el-table-column>
          <el-table-column prop="expertScore" label="诊断得分" min-width="100px">
            <template slot-scope="{ row }">
              {{ (row.expertScore&&row.expertScore.toFixed(2)) || '0' }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="servicePrice2" label="诊断费用" min-width="100px">
            <template slot-scope="{ row }">
              {{ row.servicePrice2 || '0' }}
            </template>
          </el-table-column> -->
          <el-table-column prop="status" label="状态 " min-width="100px">
            <template slot-scope="{ row }">
              {{ statusToStr(row.status) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="160px">
            <template slot-scope="{ row }">
              <el-button v-if="row.status==10 " type="text" @click="$refs.giveMarkWindow.open('评分', { declareId: row.id })">评分</el-button>
              <div v-else>-</div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        >
        </pagination>
      </template>
    </TableLayout>
    <AreaProjectApplyDetail ref="projectApplyDetail" @success="handlePageChange" />
    <GiveMarkWindow ref="giveMarkWindow" @success="success" />
    <div slot="footer">
      <el-button @click="back">返回</el-button>
    </div>
  </GlobalWindow>
</template>

<script>
import { mapState } from 'vuex'
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import AreaProjectApplyDetail from './AreaProjectApplyDetail'
import GiveMarkWindow from './GiveMarkWindow'
import { getPageByProjectId as fetchList, exportExcel, confirmService, declaresDetail } from '@/api/business/declares'
import { projectDetail } from '@/api/business/project'
import { treeList } from '@/api/business/areas'
export default {
  name: 'exportProjectApply',
  extends: BaseOpera,
  components: {
    GlobalWindow,
    TableLayout,
    Pagination,
    AreaProjectApplyDetail,
    GiveMarkWindow
  },
  data () {
    return {
      
      // 表单数据
      searchForm: {
        projectId: null,
        companyName: '',
        status: ''
      },
      // 是否正在执行
      isWorking: {
        // 搜索中
        search: false,
        // 删除中
        delete: false,
        // 导出中
        export: false
      },
      declareStatus: [
        // 0已保存、1待审核、2审核通过、3退回修改、4审核驳回、5待服务机构确认、
        // 6服务机构拒绝、7已分配服务机构、8诊断中（成功上传第一份服务资料）、
        // 9服务完成、10已分配评分专家、11已完成
        { label: '已保存', value: 0 },
        { label: '待审核', value: 1 },
        { label: '审核通过', value: 2 },
        { label: '退回修改', value: 3 },
        { label: '审核驳回', value: 4 },
        { label: '待服务机构确认', value: 5 },
        { label: '服务机构拒绝', value: 6 },
        { label: '已分配服务机构', value: 7 },
        { label: '诊断中', value: 8 },
        { label: '服务完成', value: 9 },
        { label: '已分配评分专家', value: 10 },
        { label: '已完成', value: 11 }
      ],
      expertStatus: [
        { label: '未评分', value: 10 },
        { label: '已评分', value: 11 },
      ],
      // 表格数据
      tableData: {
        // 已选中的数据
        selectedRows: [],
        // 排序的字段
        sorts: [],
        // 当前页数据
        list: [],
        // 分页
        pagination: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        }
      },
      project: {}
    }
  },
  provide() {
    return {
      project: () => this.project
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      // debugger
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        this.searchForm.projectId = target.projectId
        this.reset()
        projectDetail(target.projectId)
          .then(res => {
            // this.project = res
            this.project = {
              ...res,
              startDate: res.startDate.substring(0,res.startDate.length-3),
              endDate: res.endDate.substring(0,res.endDate.length-3),
            }
          })
      })
    },
    statusToStr (status) {
      const temp = this.declareStatus.find(item => item.value === status)
      if (temp) {
        return temp.label
      }
      return '-'
    },
    showCompany (row, flag) {
      // debugger
      declaresDetail(row.id)
        .then(res => {
          console.log(res);
          this.$refs.projectApplyDetail.open('报名详情', res, flag)
        })
        .catch(e => {
          this.$message.error(e)
        })
     
    },
    // 选择省份
    selectProvince(val) {
      this.cities = []
      this.areas = []
      this.form.cityId = ''
      this.form.areaId = ''
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
          
        })
      // const tempProvince = this.province.find(item => item.id === val)
      // if (tempProvince) {
      //   this.cities = tempProvince.childList
      // }
    },
    selectCity(val) {
      this.areas = []
      this.form.areaId = ''
      treeList({ type: 2, parentId: val })
        .then(res => {
          this.areas = res
          
        })
      // const tempCity = this.cities.find(item => item.id === val)
      // if (tempCity) {
      //   this.areas = tempCity.childList
      // }
    },
    // 搜索
    search () {
      this.handlePageChange(1)
    },
    // 导出Excel
    exportExcel () {
      this.$dialog.exportConfirm('确认导出吗？')
        .then(() => {
          this.isWorking.export = true
          exportExcel({
            page: this.tableData.pagination.pageIndex,
            capacity: 1000000,
            model: this.searchForm,
            sorts: this.tableData.sorts
          })
            .then(response => {
              this.download(response)
            })
            .catch(e => {
              this.$message.error(e)
            })
            .finally(() => {
              this.isWorking.export = false
            })
        })
        .catch(() => {})
    },
    success() {
      this.$emit('success')
      this.handlePageChange()
    },
    // 搜索框重置
    reset () {
      this.$refs.searchForm.resetFields()
      this.search()
    },
    // 每页显示数量变更处理
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.search()
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm,
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
          
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    // 行选中处理
    handleSelectionChange (selectedRows) {
      this.tableData.selectedRows = selectedRows
    },
    back () {
      this.visible = false
      this.$emit('success')
    },
    confirmServe(id) {
      // 6服务机构拒绝、7已分配服务机构
      let status = 6
      this.$dialog.confirmServe('确认是否服务该企业')
        .then(() => {
          status = 7
        })
        .catch(() => {
          status = 6
        })
        .finally(() => {
          confirmService({ id, status})
            .finally(() => {
              this.handlePageChange()
            })
        })
        
    }
    
  }
}
</script>
