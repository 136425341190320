import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/company/page', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/business/company/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/business/company/updateById', data)
}

// 企业注册
export function register (data) {
  return request.post('/business/company/register', data)
}
// 服务机构注册
export function registerServiceCompany (data) {
  return request.post('/business/company/registerServiceCompany', data)
}
// 综合服务单位注册
export function registerZFServiceCompany (data) {
  return request.post('/business/company/registerZFServiceCompany', data)
}

// 查询全部企业
export function list (data) {
  return request.post('/business/company/list', data)
}

// 企业注册
export function isFinishData (data) {
  return request.post('/business/company/isFinishData', data)
}
// 判断服务机构是否完善资料
export function judgeCompanyServiceIsFinish (data) {
  return request.post('/business/company/judgeCompanyServiceIsFinish', data)
}

// 企业详情
export function queryCompanyDetail (id) {
  return request.get(`/business/company/queryCompanyDetail?id=${id}`)
}

// 删除
export function deleteById (id) {
  return request.get(`/business/company/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/business/company/delete/batch', {
    params: {
      ids
    }
  })
}


