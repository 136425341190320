<template>
  <TableLayout :permissions="['business:declares:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="企业名称" prop="companyName">
        <el-input v-model="searchForm.companyName" placeholder="请输入企业名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="项目名称" prop="projectName">
        <el-input v-model="searchForm.projectName" placeholder="请输入项目名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="流程状态" prop="status">
        <el-select v-model="searchForm.status" placeholder="请选择状态">
          <el-option
            v-for="item in declareStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="服务机构" prop="soId">
        <el-select v-model="searchForm.soId" filterable placeholder="请选择服务机构">
          <el-option
              v-for="item in this.serviceList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
        <el-button :loading="isWorking.export" v-permissions="['business:declares:exportExcel']" @click="exportExcel">导出</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        border
        :header-row-class-name="'table-header'"
        class="doumee-element-table"
      >
        <el-table-column prop="companyName" label="企业名称" align="center" min-width="100px"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="center" min-width="130px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="diagnoseType" label="诊断类型" align="center" min-width="130px" show-overflow-tooltip></el-table-column>
        <template v-if="userInfo.type==8">
          <el-table-column prop="cityName" label="所属区域" align="center" min-width="100px"></el-table-column>
          <el-table-column prop="createDate" label="报名时间" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="dascore" label="问卷分数" align="center" min-width="100px">
            <template slot-scope="{ row }">
              {{ row.dascore || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="100px" align="center" fixed="right">
            <template slot-scope="{ row }">
              {{ statusToStr(row.status) }}
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column prop="cityName" label="所属区域" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="dascore" label="问卷分数" align="center" min-width="100px" show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{ row.dascore || '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="serviceName" label="服务机构" align="center" min-width="100px" show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{ row.serviceName || '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="servicePrice" label="服务单价" align="center" min-width="100px">
            <template slot-scope="{ row }">
              {{ row.servicePrice || '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="realZJName" label="专家名称" align="center" min-width="100px">
            <template slot-scope="{ row }">
              {{ row.realZJName || '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="expertScore" label="专家评分" align="center" min-width="100px">
            <template slot-scope="{ row }">
              {{ row.expertScore || '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="servicePrice" label="服务价格" align="center" min-width="100px">
            <template slot-scope="{ row }">
              {{ row.servicePrice || '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="报名时间" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column label="状态" min-width="120px" align="center">
            <template slot-scope="{ row }">
              {{ statusToStr(row.status) }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="containPermissions(['business:declares:update'])"
            label="操作"
            min-width="100px"
            align="center"
            fixed="right"
          >
            <template slot-scope="{ row }">
              <el-button type="text" @click="$refs.operaDeclareWindow.open('编辑项目申报诊断类型', row)">编辑</el-button>
            </template>
          </el-table-column>
        </template>

      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
      <OperaDeclareWindow ref="operaDeclareWindow" @success="handlePageChange" />
    </template>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaDeclareWindow from '@/components/business/comprehensiveServe/OperaDeclareWindow.vue'
import { fetchList as projectSo } from '@/api/business/projectSo'
import { list } from '@/api/business/company'
export default {
  name: 'Declares',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaDeclareWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        companyName: '',
        projectName: '',
        status: '',
        soId: ''
      },
      serviceList: [],
      declareStatus: [
        // 0已保存、1待审核、2审核通过、3退回修改、4审核驳回、5待服务机构确认、
        // 6服务机构拒绝、7已分配服务机构、8诊断中（成功上传第一份服务资料）、
        // 9服务完成、10已分配评分专家、11已完成
        { label: '已保存', value: 0 },
        { label: '待审核', value: 1 },
        { label: '审核通过', value: 2 },
        { label: '退回修改', value: 3 },
        { label: '审核驳回', value: 4 },
        { label: '待服务机构确认', value: 5 },
        { label: '服务机构拒绝', value: 6 },
        { label: '已分配服务机构', value: 7 },
        { label: '诊断中', value: 8 },
        { label: '服务完成', value: 9 },
        { label: '已分配评分专家', value: 10 },
        { label: '已完成', value: 11 }
      ]
    }
  },
  created () {
    this.config({
      module: '企业项目报名信息表',
      api: '/business/declares',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.getServiceList()
    this.search()
  },
  methods: {
    statusToStr (status) {
      const temp = this.declareStatus.find(item => item.value == status)
      return temp ? temp.label : '-'
    },
    getServiceList () {
      // debugger
      list({ type: 1 })
        .then(res => {
          this.serviceList = res
        })
        .catch(e => {
          // this.$message.error(e)
        })
    },
    edit(row) {
      this.$refs.operaDeclareWindow.open('编辑项目申报', row)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-select--small{
  width: 140px;
}
</style>
