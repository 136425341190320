<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    v-loading="isUploading"
    element-loading-text="文件上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="150px" label-suffix="：" inline>
      <div class="short-input">
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入项目名称" v-trim/>
        </el-form-item>
      </div>
      <div class="select">
        <el-form-item label="所属城市" prop="cityId">
          <div style="display:flex">
            <el-select v-model="form.provinceId" :disabled="!!form.id" placeholder="请选择省份" @change="selectProvince">
              <el-option
                v-for="item in province()"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line"></div>
            <el-select v-model="form.cityId" :disabled="!!form.id" placeholder="请选择城市">
              <el-option
                v-for="item in cities"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
      </div>
      <div class="select">
        <el-form-item label="关联市局" prop="sjId">
          <div style="display:flex">
            <el-select v-model="form.sjId" placeholder="请选择市局">
              <el-option
                v-for="item in sjList"
                :key="item.id"
                :value="item.id"
                :label="item.username"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="项目图标" prop="imgurl">
          <div class="select-image" >
            <div
              v-for="(item, index) in imageList"
              :key="index"
              class="image-contant"
              :class="index==selectIndex&&'image-contant-select'"
              @click="selectImage(item, index)"
            >
              <img :src="item.imgurlfull" alt="" srcset="">
            </div>
            <div
              class="image-contant"
              :class="3==selectIndex&&'image-contant-select'"
              @click="selectImage(coustomImage, 3)"
            >
              <el-upload
                class="avatar-uploader"
                :action="uploadImgUrl"
                :data="uploadData"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                :on-error="uploadError"
                accept=".jpg,.png"
              > 
                <el-image v-if="coustomImage.imgurlfull" :src="coustomImage.imgurlfull" class="avatar"></el-image>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="el-upload__tip" style="display: flex; flex-direction: column-reverse;">建议尺寸（166px*137px）</div>
          </div>
        </el-form-item>
      </div>
      <div class="select">
        <el-form-item label="报名时间" prop="date">
          <el-date-picker
            v-model="form.date"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="selectDate"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="select">
        <el-form-item label="服务机构分配方式" prop="sodType">
          <div style="display:flex">
            <el-select v-model="form.sodType" placeholder="请选择服务机构分配方式">
              <el-option
                v-for="item in distributionType"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
      </div>
      <div class="select">
        <el-form-item label="专家分配角色" prop="edType">
          <div style="display:flex">
            <el-select v-model="form.edType" placeholder="请选择专家分配角色">
              <el-option
                v-for="item in exportRole"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="县区审核" prop="areaCheck">
          <el-switch
            v-model="form.areaCheck"
            :active-value="0"
            :inactive-value="1">
          </el-switch>
        </el-form-item>
      </div>
      <div class="select">
        <el-form-item label="专家评分方式" prop="scoreType">
          <div style="display:flex">
            <el-select v-model="form.scoreType" placeholder="请选择专家评分方式">
              <el-option
                v-for="item in exportType"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
      </div>
      <div class="short-input">
        <el-form-item label="最少服务次数" prop="serviceLimt">
          <el-input v-model="form.serviceLimt" placeholder="请输入最少服务次数" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="基础问卷">
          <div class="width200">
            <el-button type="primary" @click="$refs.upFile.click()">导入</el-button>
            <el-button type="text" @click="downloadTemplate()">下载模板</el-button>
            <el-upload
              action="1"
              :on-remove="remmoveFile"
              :on-preview="clickQusetionier"
              :file-list="fileList">
            </el-upload>
          </div>
        </el-form-item>
      </div>
      <div class="short-input"> 
        <el-form-item label="项目介绍" prop="content">
          <el-input
            v-model="form.content"
            placeholder="请输入项目介绍(500字以内)"
            type="textarea"
            :autosize="{ minRows: 10, maxRows: 14}"
            :maxlength="500"
            show-word-limit
          />
        </el-form-item>
      </div>
      <div class="short-input">
        <el-form-item label="附件上传要求" prop="fileReq">
          <el-input
            v-model="form.fileReq"
            placeholder="请输入附件上传要求"
            type="textarea"
            :autosize="{ minRows: 10, maxRows: 14}"
          />
        </el-form-item>
      </div>
      <div class="short-input">
        <el-form-item label="服务要求" prop="serviceReq">
          <el-input
            v-model="form.serviceReq"
            placeholder="请输入服务要求"
            type="textarea"
            :autosize="{ minRows: 10, maxRows: 14}"
          />
        </el-form-item>
      </div>
      <div class="short-input">
        <el-form-item label="核查要求" prop="checkReq">
          <el-input
            v-model="form.checkReq"
            placeholder="请输入核查要求"
            type="textarea"
            :autosize="{ minRows: 10, maxRows: 14}"
          />
        </el-form-item>
      </div>
      <div class="short-input">
        <el-form-item label="排序码" prop="sortnum">
          <el-input v-model="form.sortnum" placeholder="请输入排序码" />
        </el-form-item>
      </div>
    </el-form>
    <input type="file" @change="upFiles" ref="upFile" accept=".xls,.xlsx" style="display: none;" />
    <Question ref="question"/>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import Question from './Question.vue'
import { importExcel } from '@/api/business/question'
import { treeList } from '@/api/business/areas'
import { fetchList as userList } from '@/api/business/account'
export default {
  name: 'OperaProjectWindow',
  extends: BaseOpera,
  components: { GlobalWindow, Question },
  data () {
    return {
      uploadImgUrl: process.env.VUE_APP_API_PREFIX + '/public/upload',
      imageUrl: '',
      isUploading: false,
      // 表单数据
      form: {
        id: null,
        date:[],
        name: '',
        provinceId: '',
        cityId: '',
        startDate: '',
        endDate: '',
        sodType: '',
        edType: '',
        sjId: '',
        scoreType: '',
        areaCheck: 0,
        checkReq: '',
        fileReq: '',
        serviceReq: '',
        questionnaireId: '',
        content: '',
        status: '',
        serviceLimt: '1',
        imgurlfull: 'https://znzzzdxt.oss-cn-shanghai.aliyuncs.com/znzz/project_file/default/project_img_a.png',
        imgurl: 'default/project_img_a.png',
        sortnum: '0'
      },
      selectIndex: 0,
      uploadData: {
        folder: 'znzz/project_file',
        type: 'image'
      },
      coustomImage: {
        imgurl: '',
        imgurlfull: '',
      },
      cities: [],
      distributionType: [
        { name: '双选', id: 0 },
        { name: '市局分配', id: 1 },
        { name: '综合服务单位分配', id: 2 },
      ],
      exportRole: [
        { name: '市局分配', id: 0 },
        { name: '综合服务单位分配', id: 1 },
      ],
      exportType: [
        // { name: '线上', id: 0 },
        { name: '线下', id: 1 },
      ],
      imageList: [
        {
          imgurl: 'default/project_img_a.png',
          imgurlfull: 'https://znzzzdxt.oss-cn-shanghai.aliyuncs.com/znzz/project_file/default/project_img_a.png'
        },
        {
          imgurl: 'default/project_img_b.png',
          imgurlfull: 'https://znzzzdxt.oss-cn-shanghai.aliyuncs.com/znzz/project_file/default/project_img_b.png'
        },
        {
          imgurl: 'default/project_img_c.png',
          imgurlfull: 'https://znzzzdxt.oss-cn-shanghai.aliyuncs.com/znzz/project_file/default/project_img_c.png'
        }
      ],
      fileList: [],
      sjList: [],
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
        ],
        cityId: [
          { required: true, message: '请选择城市', trigger: 'change' },
        ],
        sjId: [
          { required: true, message: '请选择项市局', trigger: 'change' },
        ],
        imgurl: [
          { required: true, message: '请选择项目图片', trigger: 'change' },
        ],
        date: [
          { required: true, message: '请选择项目时间', trigger: 'change' },
        ],
        areaCheck: [
          { required: true, message: '请选择是否县区审核', trigger: 'change' },
        ],
        sodType: [
          { required: true, message: '请选择服务机构分配方式', trigger: 'change' },
        ],
        edType: [
          { required: true, message: '请选择专家分配角色', trigger: 'change' },
        ],
        scoreType: [
          { required: true, message: '请选择专家评分方式', trigger: 'change' },
        ] 
      }
    }
  },
  inject: ['province'],
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
    userList({
      capacity:999,
      model: { type: 8 }
    })
      .then(res => {
        this.sjList = res.records
      })

  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      this.fileList = []
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form.provinceId = ''
          this.selectProvince(this.form.provinceId, 0)
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.form.date = [target.startDate, target.endDate]
        this.selectProvince(this.form.provinceId, 1)
        // console.log(this.form.questionnaireTitle);
        if (target.questionnaireId) {
          this.fileList = [{ name: target.questionnaireTitle }]
        }
      })
    },
    // 选择省份
    selectProvince(val, isEdit) {
      if (!isEdit) {
        this.form.cityId = ''
      }
      this.cities = []
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
        })
      // const tempProvince = this.province().find(item => item.id === val)
      // if (tempProvince) {
      //   this.cities = tempProvince.childList
      // }
    },
    // 选择日期
    selectDate(v) {
      this.form.endDate = v[1]
      this.form.startDate = v[0]
    },
    selectImage(item, index) {
      console.log(item, index);
      if (this.selectIndex !== index) {
        this.selectIndex = index
        this.form.imgurl = item.imgurl;
        this.form.imgurlfull = item.imgurlfull;
      }
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      this.isUploading = false
      if (res.code == 200) {
        this.form.imgurl = res.data.imgaddr;
        this.form.imgurlfull = res.data.url;
        this.coustomImage.imgurlfull = res.data.url;
        this.coustomImage.imgurl = res.data.imgaddr;
        this.selectIndex = 3
        this.$message.success('上传成功')
        console.log(this.form.imgUrl);
      } else {
        this.$message.error('上传失败')
      }
    },
    uploadError() {
      this.isUploading=false
    },
    // // 拦截
    beforeAvatarUpload(file) {
      this.isUploading = true
      return true;
    },
    // 上传文件
    upFiles (file) {
      const formdate = new FormData()
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'upload')
      var fileName = file.target.files[0].name//获取上传的文件(单个)
      this.isUploading = true
      // var extName = fileName.substr(fileName.lastIndexOf(".") + 1).toLowerCase();
      importExcel(formdate)
        .then(res => {
          this.$message.success('上传成功！')
          this.form.questionnaireId = res
          this.fileList = [{ name: fileName }]
        })
        .catch(err => {
          // fileList.splice(-1, 1)
          this.$message.error(err.message)
        })
        .finally(() => {
          this.isUploading = false
        })
      this.$refs.upFile.value = null
    },
    remmoveFile() {
      this.form.questionnaireId=''
    },
    clickQusetionier() {
      this.$refs.question.open('问卷信息', { id: this.form.questionnaireId }, 0)
    },
    /**
     * 下载模版
     */
     downloadTemplate () {
      const link = document.createElement('a')
      link.setAttribute('download', 'question_import_moudle.xlsx') //下载的文件名
      link.href = `${window.location.origin}${process.env.VUE_APP_CONTEXT_PATH}/template/question_import_moudle.xlsx`  //文件url
      link.click()
    },
    confirm () {
      if (this.form.questionnaireId == null || this.form.questionnaireId === '') {
        this.$dialog.questionnaireMiss('问卷信息尚未导入，是否继续创建该项目？')
          .then(() => {
            if (this.form.id == null || this.form.id === '') {
              this.__confirmCreate()
              return
            }
            this.__confirmEdit()
          })
      } else {
        if (this.form.id == null || this.form.id === '') {
          this.__confirmCreate()
          return
        }
        this.__confirmEdit()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.select {
  ::v-deep .el-input__inner {
    width: 295px;
  }
  ::v-deep .el-range-input {
    width: 230px;
  }
}
.short-input {
  ::v-deep .el-input__inner {
    width: 600px;
  }
  ::v-deep .el-textarea__inner {
    width: 700px;
  }

}
.line {
  width: 10px;
}
.avatar-uploader{
  width: 160px;
  height: 131px;
  border: 1px dashed #d9d9d9;
  &:hover {
    border-color: #409EFF;
  }
  .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 166px;
  height: 137px;
  line-height: 137px;
  text-align: center;
}
.avatar {
  width: 166px;
  height: 137px;
  display: block;
}
.select-image {
  display: flex;
  .image-contant {
    width: 166px;
      height: 137px;
    border: #00000000 2px solid;
    // background-color: #8c939d;
    margin-right: 10px;
    img {
      width: 166px;
      height: 137px;
    }
  }
  .image-contant-select {
    border: #409EFF 2px solid;
  }


}
</style>
