
<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    v-loading="isUploading"
    element-loading-text="文件上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div v-if="serviceReq" class="tip-style">{{ serviceReq }}</div>
    <div class="serviceList" ref="navBar">
      <div
        v-for="(item, index) in serviceList"
        :key="index"
      >
        <div class="arrow" :class="(index==tempService || (index>serviceLimt&&index==serviceList.length-1))&&'arrow-select'" @click="selectServe(item,index)">
          <div v-if="index" class="arrow-foot"></div>
          <div
            class="arrow-body"
            :class="index==0 || index==serviceList.length-1 ? 'first-last': index<=serviceLimt-1?'normal-body': ''"
          >{{ index==0 ? '首次服务数据' : index==serviceList.length-1 ? '完成服务' : `第${index+1}次服务数据${index>serviceLimt-1?'(可选)':''}` }}</div>
          <div v-if="index != serviceList.length-1" class="arrow-header"></div>
        </div>
      </div>
    </div>
    <div style="height: 10px;"></div>
    <el-form v-if="tempService == serviceList.length-2" ref="form" :model="form" :rules="rules" label-width="130px" label-suffix="：" inline>
      <div>
        <el-form-item label="服务时间" prop="serviceDate">
          <el-date-picker
            v-model="form.serviceDate"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="服务说明" prop="content">
          <el-input
            style="width: 400px;"
            v-model="form.content"
            placeholder="请输入服务说明"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4}"
            v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="服务资料上传" prop="fileList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upFile.click()">上传资料</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="form.fileList"
              linkName="url"
            />
          </div>
        </el-form-item>
        <input type="file" @change="upFiles" ref="upFile" style="display: none;" />
      </div>
      <div v-if="tempService > 0">
        <el-form-item label="补充资料上传" prop="fileExtraList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upExtraFile.click()">上传补充资料</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="form.fileExtraList"
              linkName="url"
            />
          </div>
        </el-form-item>
        <input type="file" @change="upExtraFiles" ref="upExtraFile" style="display: none;" />
      </div>
      <div class="action-style">
        <el-button style="color:#fff; background-color: rgb(86, 188, 190)" @click="submit(1)">提交</el-button>
        <el-button style="color:#fff; background-color: #216EEE;"  @click="submit(0)">保存</el-button>
      </div>
    </el-form>
    <el-form v-else-if="tempService == serviceList.length-1" ref="completeForm" :model="completeForm" label-width="130px" :rules="completeRules" label-suffix="：" inline>
      <div class="tip-waring-style">完成服务后不可退回修改，请谨慎操作</div>
      <div>
        <el-form-item label="服务时间" prop="diagnoseDate">
          <el-date-picker
            v-model="completeForm.diagnoseDate"
            type="datetime"
            placeholder="选择服务时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      
      <div>
        <el-form-item label="企业评分" prop="diagnoseScore">
          <el-input
            v-model="completeForm.diagnoseScore"
            placeholder="请输入企业评分"
            @input="inputAction"
            v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="企业评分表" prop="fileScoreList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upFileScore.click()">上传资料</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="completeForm.fileScoreList"
              linkName="url"
            />
          </div>
        </el-form-item>
        <input type="file" @change="upFileScore" ref="upFileScore" style="display: none;" />
      </div>
      <div>
        <el-form-item label="补充资料上传" prop="fileExtraList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.completeUpExtraFile.click()">上传补充资料</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="completeForm.fileExtraList"
              linkName="url"
            />
          </div>
        </el-form-item>
        <input type="file" @change="completeUpExtraFile" ref="completeUpExtraFile" style="display: none;" />
      </div>
      <div>
        <el-form-item label="诊断报告" prop="fileDiagnosisList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upFileDiagnosis.click()">上传资料</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="completeForm.fileDiagnosisList"
              linkName="url"
            />
          </div>
        </el-form-item>
        <input type="file" @change="upFileDiagnosis" ref="upFileDiagnosis" style="display: none;" />
      </div>
      <div v-if="completeForm.declareDiagnoseList.length">
        <el-form-item label="报告详情" prop="declareDiagnoseList">
          <div v-for="(item, index) in completeForm.declareDiagnoseList" style="margin-bottom: 10px;" :key="index">
            {{ `${item.name}` }}
            <RichEditor :content="{ content : item.content}"  @edit="item.content=$event"></RichEditor>
          </div>
        </el-form-item>
      </div>
      <div class="action-style">
        <el-button style="color:#fff; background-color: rgb(86, 188, 190)" @click="finish(1)">提交</el-button>
        <el-button style="color:#fff; background-color: #216EEE;"  @click="finish(0)">保存</el-button>
      </div>
    </el-form>
    <el-form v-else ref="form2" :model="tempServiceObj" label-width="130px" label-suffix="：" inline>
      <div>
        <el-form-item label="服务时间" prop="createDate">
          {{ tempServiceObj.serviceDate }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="上传用户" prop="creator">
          {{ tempServiceObj.creatorName }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="服务说明" prop="content" >
          {{ tempServiceObj.content }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="服务资料" prop="fileList">
          <FileLink
            :links="tempServiceObj.fileList"
            linkName="fileurlfull"
            :isUpload="false"
          />
        </el-form-item>
      </div>
      <div v-if="tempServiceObj.fileExtraList.length">
        <el-form-item label="补充资料" prop="fileExtraList">
          <FileLink
            :links="tempServiceObj.fileExtraList"
            linkName="fileurlfull"
            :isUpload="false"
          />
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="visible=false">返回</el-button>
    </div>
 
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
// doneServiceList as 
import { fetchList, uploadHCData, editHCData, finishHCData, finishServeInfo } from '@/api/business/declareService'
import { upload } from '@/api/system/common'
import RichEditor from '@/components/common/RichEditor.vue'

export default {
  name: 'UploadServeData',
  extends: BaseOpera,
  components: {
    GlobalWindow,
    RichEditor
  },
  data () {
    
    let socreRule = (rule, value, callback) => {
      // debugger
      console.log(value);
      callback()
    }
    let newRule = (rule, value, callback) => {
      let isComplete = true
      for (const item of this.completeForm.declareDiagnoseList) {
        // console.log(item)
        // debugger
        if (!item.content || item.content=='<p><br></p>') {
          isComplete = false
          break
        }
      }
      if (!isComplete) {
        // debugger
        callback(new Error('请先完善诊断报告'))
      } else {
        callback()
      }
    }
    return {
      isUploading: false,
      // 表单数据
      serviceReq: '',
      form: {
        declareId: null,
        serviceDate: '',
        content: '',
        fileList: [],
        fileExtraList: [],
        type: '0' //服务上传
      },
      completeForm: {
        declareId: null,
        diagnoseDate: '',
        fileScoreList: [],
        fileDiagnosisList:[],
        declareDiagnoseList:[],
        fileExtraList: [],
        diagnoseScore: '0',
        type: '1' 
      },
      serviceLimt: 1,
      projectId: '',
      serviceList: [],
      // completeList: [],
      tempService: 0,
      tempServiceObj: {
        declareDiagnoseList:[],
        fileList: [],
        fileExtraList: [],
      },
      // 验证规则
      rules: {
        serviceDate: [
          { required: true, message: '请选择服务时间', trigger: 'change' },
        ],
        fileList: [
          { required: true, message: '请上传服务资料', trigger: 'change' },
        ],
      },
      // isBack: false,
      // 验证规则
      completeRules: {
        diagnoseDate: [
          { required: true, message: '请选择服务时间', trigger: 'change' },
        ],
        // diagnoseScore: [
        //   { required: true, message: '请输入企业评分', trigger: 'blur' },
        // ],
        // fileScoreList: [
        //   { required: true, message: '请上传企业评分表', trigger: 'change' },
        // ],
        fileDiagnosisList: [
          { required: true, message: '请上传诊断报告', trigger: 'change' },
        ],
        declareDiagnoseList: [
          { required: true, validator: newRule, trigger: ['blur']}
        ],
      },
    }
  },
  created () {
    this.config({
      api: '/business/declareService',
      'field.id': 'id'
    })
  },
  beforeDestroy() {
    // debugger
    const buttonGroup = this.$refs.navBar;
    if (!buttonGroup) {
      return
    }
    buttonGroup.removeEventListener('mousedown', (e) => {
      console.log(e);
    })
    buttonGroup.removeEventListener('mouseleave', (e) => {
      console.log(e);
    })
    buttonGroup.removeEventListener('mouseup', (e) => {
      console.log(e);
    })
    buttonGroup.removeEventListener('mousemove', (e) => {
      console.log(e);
    })
  },
  methods: {
    setListen() {
      const buttonGroup = this.$refs.navBar;
      if (!buttonGroup) {
        return
      }
      let isDragging = false;
      let startX = 0;
      let scrollLeft = 0;

      buttonGroup.addEventListener('mousedown', (e) => {
        isDragging = true;
        startX = e.pageX - buttonGroup.offsetLeft;
        scrollLeft = buttonGroup.scrollLeft;
        console.log(startX, scrollLeft);
      });

      buttonGroup.addEventListener('mouseleave', () => {
        isDragging = false;
      });

      buttonGroup.addEventListener('mouseup', () => {
        isDragging = false;
      });

      buttonGroup.addEventListener('mousemove', (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - buttonGroup.offsetLeft;
        const walk = (x - startX) * 3;
        buttonGroup.scrollLeft = scrollLeft - walk;
      });
    },
    open (title, target) {
      // debugger
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        this.form.declareId = target.id
        this.completeForm.declareId = target.id
        this.serviceLimt = target.serviceLimt
        this.projectId = target.projectId
        this.serviceReq = target.serviceReq
        // this.isBack = target.status==12
        this.initServe()
        this.setListen()
      })
    },
    inputAction(value) {
      if(this.completeForm.diagnoseScore<0) {
        this.completeForm.diagnoseScore = ''
      } else {
        this.completeForm.diagnoseScore = value.replace(/[^\d.]/g, '')
                      .replace(/\.{2,}/g, '.')
                      .replace('.', '$#$')
                      .replace(/\./g, '')
                      .replace('$#$', '.')
                      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
                      .replace(/^\./g, '')
      }
      // console.log(this.completeForm.diagnoseScore);
    },
    initServe() {
      // debugger
      fetchList({
        capacity: 9999,
        model: { declareId: this.form.declareId, type: 0 }
      })
        .then(res => {
          this.serviceList = res.records
          let isNeedLast = true;
          let sLength = res.records.length
          if (this.serviceList != null && this.serviceList.length > 0 && this.serviceList[this.serviceList.length - 1].status === 0) {
            this.form = {
              declareId: this.form.declareId,
              serviceDate: this.serviceList[this.serviceList.length-1].serviceDate,
              content: this.serviceList[this.serviceList.length-1].content,
              fileList: this.serviceList[this.serviceList.length-1].fileList,
              fileExtraList: this.serviceList[this.serviceList.length-1].fileExtraList||[],
              id: this.serviceList[this.serviceList.length-1].id,
              type: '0'
            }
            isNeedLast = false;
            sLength -= 1
          }
          if(isNeedLast){
            this.serviceList.push({
              isLast: true,
              // id: Symbol(1)
            })
            this.form = {
              declareId: this.form.declareId,
              serviceDate: '',
              content: '',
              fileList: [],
              fileExtraList: [],
              id: '',
              type: '0'
            }
          }
          
          this.tempService = this.serviceList.length - 1
          this.serviceList.push({
            canComplete: sLength >= this.serviceLimt,
            // id: Symbol(2)
          })
          this.$nextTick(() => {
            this.$refs.navBar.scrollTo(this.tempService * 110, 0)
            if (this.$refs.form) {
              this.$refs.form.clearValidate()
            }
            if (this.$refs.completeForm) {
              this.$refs.completeForm.clearValidate()
            }
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
      finishServeInfo({ declareId: this.completeForm.declareId })
        .then(res => {
          // this.completeList = [...res]
          // this.completeList.splice(res.length-1)
          let lastRes = res
          // debugger
          this.completeForm.diagnoseScore = lastRes.diagnoseScore;
          this.completeForm.diagnoseDate = lastRes.diagnoseDate;
          this.completeForm.fileScoreList = lastRes.fileScoreList||[];
          this.completeForm.fileDiagnosisList = lastRes.fileDiagnosisList||[];
          this.completeForm.declareDiagnoseList = lastRes.declareDiagnoseList||[];
          this.completeForm.fileExtraList = lastRes.fileExtraList||[];
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
    selectServe(item, index) {
      if (index == this.serviceList.length-1) {
        if (!item.canComplete) {
          this.$message.warning('请先上传服务数据！')
          return
        }
      }
      // debugger
      // this.$refs.form.clearValidate()
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
      if (this.$refs.completeForm) {
        this.$refs.completeForm.clearValidate()
      }
      this.tempServiceObj = item
      this.tempService = index
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.clearValidate()
        }
        if (this.$refs.completeForm) {
          this.$refs.completeForm.clearValidate()
        }
      })
      
    },

    // 上传服务资料
    upFiles(file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          this.form.fileList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upFile.value = null
    },
    // 上传服务补充资料
    upExtraFiles(file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          this.form.fileExtraList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upExtraFiles.value = null
    },
    // 上传完成服务补充资料
    completeUpExtraFile(file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          this.completeForm.fileExtraList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs.completeUpExtraFile.value = null
    },
    // 上传企业评分表
    upFileScore(file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          this.completeForm.fileScoreList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upFileScore.value = null
    },
    // 删除业评分表
    // deleteFileScore(index) {
    //   this.completeForm.fileScoreList.splice(index, 1)
    // },
    // 上传诊断报告
    upFileDiagnosis(file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          this.completeForm.fileDiagnosisList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upFileDiagnosis.value = null
    },
    newValue(v, index) {
      this.completeForm.declareDiagnoseList[index].content = v
    },
    // 删除诊断报告
    // deleteFileDiagnosis(index) {
    //   this.completeForm.fileDiagnosisList.splice(index, 1)
    // },
    submit(status) {
      if (status) {
        this.$refs.form.validate((valid) => {
          // debugger
          if (!valid) {
            this.$message.error('请完整填写完成服务数据')
            return
          }
          this.uploadHC(status)
        })
      } else {
        this.uploadHC(status)
      }
    },
    uploadHC(status) {
      this.isWorking = true
      
      uploadHCData({
        ...this.form,
        status
      })
        .then(res => {
          this.$message.success(status?'提交成功':'保存成功')
          this.visible = false
          this.$emit('success')
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isWorking = false)
    },
    finish(status) {
      if (status) {
        this.$refs.completeForm.validate((valid) => {
          if (!valid) {
            this.$message.error('请完整填写完成服务数据')
            return
          }
          this.finishServe(status)
        })
      } else {
        this.finishServe(status)
      }
    },

    finishServe(status) {
      this.isWorking = true
      // let action = this.isBack ? editHCData : finishHCData
      finishHCData({
        ...this.completeForm,
        status
      })
        .then(res => {
          this.$message.success(status?'提交成功':'保存成功')
          this.$emit('success')
          this.visible = false
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isWorking = false)
    },
  },
}
</script>


<style lang="scss" scoped>
.tip-style {
  background: rgba(33,110,238,0.1);
  border-radius: 2px;
  line-height: 24px;
  
  font-size: 16px;
  color: #333;
  padding: 8px;
  margin-bottom: 10px;
}
.tip-waring-style {
  background: rgba(33,110,238,0.1);
  border-radius: 2px;
  line-height: 24px;
  color: #EE3821;
  font-size: 16px;
  // color: #333;
  padding: 8px;
  margin-bottom: 10px;
}
.serviceList {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important
  }
  -ms-overflow-style: none;
}
.action-style {
  display:flex;
  margin-left: 120px;
  margin-bottom: 20px;
}
.arrow {
  background-color: #DFE2E8;
  color: #333333;
  height: 40px;
  line-height: 40px;
  display: flex;
  cursor: pointer;
  .arrow-foot {
    // background-color: #fff;
    border-left: 20px solid rgba($color: #fff, $alpha: 1);
    border-top: 20px solid rgba($color: #fff, $alpha: 0);
    border-bottom: 20px solid rgba($color: #fff, $alpha: 0);
  }
  
  .arrow-body {
    height: 40px;
    width: 100%;
    min-width: 160px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 16px;
  }
  .first-last {
    min-width: 100px;
  } 
  .normal-body {
    min-width: 110px;
  } 
  .arrow-header {
    // background-color: #DFE2E8;
    border-left: 20px solid rgba($color: #fff, $alpha: 0);
    border-top: 20px solid rgba($color: #fff, $alpha: 1);
    border-bottom: 20px solid rgba($color: #fff, $alpha: 1);
  }
}
.arrow-select {
  background-color: #216EEE;
  color: #fff;
}

.data-list {
  margin-top: 20px;
  .data-item {
    border-radius: 2px;
    // background-color: #F5F6F8;
    height: 36px;
    display: flex;
    // padding: 11px 20px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #216EEE;
    cursor: pointer;
    text-decoration: underline;
    line-height: 13px;
    margin-right: 16px;
    i {
      box-sizing: border-box;
      margin-left: 10px;
      width: 16px;
      height: 16px;
      // background-color: #BFC3CB;
      color: #216EEE;
      border-radius: 50%;
      // padding: 1.5px;
      cursor: pointer;
      &:hover {
        color: #ff0000;
      }
    }
  }
}
::v-deep .ql-container {
  height: 200px !important;
  width: 850px;
}
::v-deep .ql-toolbar {
  // height: 200px !important;
  width: 850px;
}
::v-deep .el-form-item {
  display: flex;
}
::v-deep .el-form-item__content {
  flex: 1;
}
::v-deep .swiper-slide {
  width: 230px !important;
}
</style>
