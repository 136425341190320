<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    width="500px"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-form-item label="类型名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入名称" v-trim/>
      </el-form-item>
      <el-form-item label="价格系数(%)"  prop="ratio">
        <!-- @input="inputZAction(form, 'ratio')" -->
        <el-input v-model="form.ratio" @input="numInput(form, 'ratio')" placeholder="请输入价格系数" v-trim/>
      </el-form-item>
      <el-form-item label="排序码" prop="sortnum">
        <el-input v-model="form.sortnum" placeholder="请输入排序码" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
export default {
  name: 'OperaProjectLbaelWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        ratio: '',
        projectId: '',
        sortnum: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入类型名称', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/business/projectLabel',
      'field.id': 'id'
    })
  },
 
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      // debugger
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
      })
    },
    inputZAction(item, key) {
      // item[key] = item[key].replace(/[0], '')
      // item[key]=item[key].replace(/0|[^\d]+/g, '')
      if (item[key]>=1) {
        item[key] = 1
        return
      }
      item[key] = item[key].replace(/[^\d.]/g, '') //清楚数字和.以外的字符
                      .replace(/\.{2,}/g, '.')
                      .replace('.', '$#$')
                      .replace(/\./g, '')
                      .replace('$#$', '.')
                      .replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3')
                      .replace(/^\./g, '')
    },
    numInput(item, key) {
      // =""
      if (item[key] < 0) {
        item[key] = ''
      }
      item[key]=item[key].replace(/[^\d]+/g, '')
      console.log(item[key]);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
</style>
