<template>
  <TableLayout>
    <div  slot="table-wrap">
      <h2>账户资料</h2>
      <div class="line"></div>
      <el-form ref="searchForm" label-width="100px" label-suffix="：">
        <el-form-item label="登录账号">
          {{ userInfo.username }}
        </el-form-item>
        <el-form-item label="姓名">
          {{ userInfo.realname }}
        </el-form-item>
        <el-form-item label="手机号">
          {{ userInfo.mobile }}
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="change">修改资料</el-button>
    </div>
    <OperaChangeUserInfoWindow ref="operaChangeUserInfoWindow" @success="resetUserInfo"/>
  </TableLayout> 
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import { findUserInfo } from '@/api/system/common'
import OperaChangeUserInfoWindow from '@/components/business/user/OperaChangeUserInfoWindow'

export default {
  name: 'userInfo',
  extends: BaseTable,
  components: { TableLayout, OperaChangeUserInfoWindow },
  methods: {
    change() {
      this.$refs.operaChangeUserInfoWindow.open('修改资料', this.userInfo)
    },
    resetUserInfo() {
      findUserInfo(this.userInfo.id)
        .then(userInfo => {
          // 存储userInfo
          this.$store.commit('setUserInfo',  {
            mobile: userInfo.userInfo,
            realname: userInfo.realname,
            username: userInfo.username,
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.line {
  height: 1px;
  background-color: #f7f7f7;
  margin: 10px 0;
}

.el-button{
  margin-left: 100px;
}
</style>
