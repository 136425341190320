import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/projectRatio/page', data, {
    trim: true
  })
}

// 导出Excel
export function exportExcel (data) {
  return request.post('/business/projectRatio/exportExcel', data, {
    trim: true,
    download: true
  })
}

// 创建
export function create (data) {
  return request.post('/business/projectRatio/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/business/projectRatio/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/business/projectRatio/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/business/projectRatio/delete/batch', {
    params: {
      ids
    }
  })
}
