<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <div class="tips-style">已经服务完成的报名信息关联的子账号不会修改</div>
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="用户名称" prop="sdUserId">
        <el-select v-model="form.sdUserId" placeholder="请选择用户">
          <el-option
            v-for="item in sd"
            :key="item.id"
            :value="item.id"
            :label="item.username"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务机构" prop="soId">
        <el-select v-model="form.soId" filterable placeholder="请选择服务机构">
          <el-option
            v-for="item in so"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { fetchList as childList } from '@/api/business/childrenUser'
import { list } from '@/api/business/company'
import { distributionChildAccount } from '@/api/business/declares'
export default {
  name: 'ComprehenDistributionChild',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        projectId: '',
        sdUserId: '',
        soId: '' // 服务机构id
      },
      sd: [],
      so: [],
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/account',
      'field.id': 'id'
    })
    
  },
  methods: {
    open(title, target) {
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.initData()
      })
    },
    initData() {
      childList({
        capacity: 9999,
        model: {}
      })
        .then(res => {
          this.sd = res.records
        })
        .catch(e => {
          this.$message.error(e)
        })
      list({ type: '1', projectId: this.form.projectId })
        .then(res => {
          this.so = res
        })
        .catch(e => {
          this.$message.error(e)
        })
      
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        distributionChildAccount(this.form)
          .then(() => {
            this.visible = false
            this.$message.success('分配成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$message.error(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
.tips-style {
  height: 44px;
  line-height: 44px;
  // background: rgba(33,110,238,0.1);
  background: rgba(238,56,33,0.06);
  border-radius: 2px;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // color: #216EEE;
  color: #EE3821;
  margin-bottom: 10px;
}
::v-deep .el-select {
  width: 100%;
  .el-input__inner {
    width: 100%;
  }
}
</style>
