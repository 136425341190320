<template>
  <GlobalWindow
    :title="title"
    :confirm-working="false"
    :visible.sync="visible"
    @confirm="confirm"
  > 
    <div class="project-name">项目名称：{{ form.name }}</div>
    <el-button plain @click="$refs.operaContentComprehenWindow.open('关联综合服务单位', { projectId:form.id, companyId: null, price: null })">关联综合服务单位</el-button>
    <div style="height:10px"></div>
    <el-table
      v-loading="isWorking.search"
      :data="tableData.list"
      stripe
      border
    >
      <el-table-column prop="companyName" label="综合服务单位" fixed="left" min-width="140px" show-overflow-tooltip>
        <!-- <template slot-scope="{ row }">
          <el-button type="text" @click="showCompany(row.id)">{{ row.companyName }}</el-button>
        </template> -->
      </el-table-column>
      <!-- <el-table-column prop="price" label="服务单价（元）" min-width="140px"></el-table-column> -->
      <el-table-column prop="createDate" label="创建时间" min-width="100px"></el-table-column>
      <el-table-column prop="status" label="操作" min-width="100px">
        <template slot-scope="{ row }">
          <el-button type="text" @click="deleteServe(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px;">
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"  
      >
      </pagination>
    </div>
    <OperaContentComprehenWindow ref="operaContentComprehenWindow" @success="handlePageChange"/>
    <div slot="footer"></div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaContentComprehenWindow from './OperaContentComprehenWindow'
import { fetchList, deleteById } from '@/api/business/projectSo'

export default {
  name: 'OperaProjectComprehenWindow',
  extends: BaseOpera,
  components: { GlobalWindow, TableLayout, Pagination, OperaContentComprehenWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: ''
      },
      // 是否正在执行
      isWorking: {
        // 搜索中
        search: false,
        // 删除中
        delete: false,
        // 导出中
        export: false
      },
      // 表格数据
      tableData: {
        // 已选中的数据
        selectedRows: [],
        // 排序的字段
        sorts: [],
        // 当前页数据
        list: [],
        // 分页
        pagination: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        }
      },
      province: [],
      cities: []
    }
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.search()
        
      })
    },
   
    deleteServe(id) {
      this.$dialog.deleteConfirm('是否要删除该条服务机构关联？')
        .then(() => {
          deleteById(id)
            .then(res => {
              this.$message.success('删除成功')
              this.handlePageChange()
            })
            .catch(e => {
              this.$message.error(e)
            })
        })
    },
    // 搜索
    search () {
      this.handlePageChange(1)
    },

    // 搜索框重置
    reset () {
      this.$refs.searchForm.resetFields()
      this.search()
    },
    // 每页显示数量变更处理
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.search()
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: { projectId: this.form.id, type: 1 },
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
  }
}
</script>

<style scoped>
.project-name {
  font-weight: 600;
  margin-bottom: 20px;
}
</style>
