import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/projectScore/page', data)
}
// 查询
export function findList (projectId) {
  return request.get(`/business/projectScore/findList`, {params: {projectId}})
}

// 新建
export function create (data) {
  return request.post('/business/projectScore/create', data, {
  // return request.post('/system/user/create', data, {
    trim: true
  })
}
// 新建
export function updateById (data) {
  return request.post('/business/projectScore/updateById', data, {
  // return request.post('/system/user/create', data, {
    trim: true
  })
}

// 删除
export function deleteById (id) {
  return request.get(`/business/projectScore/delete/${id}`)
}

