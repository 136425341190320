<template>
  <TableLayout :permissions="['business:labels:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="searchForm.type" clearable="">
          <el-option v-for="item in type" :key="item.value" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['business:labels:create', 'business:labels:delete']">
        <li><el-button type="primary" @click="$refs.operaLabelsWindow.open('新建标签信息表')" icon="el-icon-plus" v-permissions="['business:labels:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['business:labels:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        border=""
        @selection-change="handleSelectionChange"
      ><el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="名称" align="center" min-width="100px"></el-table-column>
        <el-table-column prop="type" label="类型" align="center" min-width="100px">
          <template slot-scope="{ row }">
            {{ row.type == 0 ? '企业性质' : '所属行业'}}
          </template>
        </el-table-column>
        <el-table-column prop="sortnum" label="排序码" align="center" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" align="center" min-width="100px"></el-table-column>
        <!-- <el-table-column prop="parentId" label="父级编码（关联labels表）" min-width="100px"></el-table-column> -->
        <el-table-column
          v-if="containPermissions(['business:labels:update', 'business:labels:delete'])"
          label="操作"
          align="center"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaLabelsWindow.open('编辑标签信息表', row)" icon="el-icon-edit" v-permissions="['business:labels:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['business:labels:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaLabelsWindow ref="operaLabelsWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaLabelsWindow from '@/components/business/OperaLabelsWindow'
export default {
  name: 'Labels',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaLabelsWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        name: '',
        type: ''
      },
      type: [
        // 0企业性质 1企业所属行业
        { label: '企业性质', value: 0 },
        { label: '企业所属行业', value: 1 }
      ]
    }
  },
  provide() {
    return {
      type: () => this.type
    }
  },
  created () {
    this.config({
      module: '标签信息表',
      api: '/business/labels',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
