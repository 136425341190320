<template>
  <TableLayout :permissions="['business:declareservice:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="主键" prop="id">
        <el-input v-model="searchForm.id" placeholder="请输入主键" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建人编码" prop="creator">
        <el-input v-model="searchForm.creator" placeholder="请输入创建人编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="searchForm.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建时间" @change="search"/>
      </el-form-item>
      <el-form-item label="更新人编码" prop="editor">
        <el-input v-model="searchForm.editor" placeholder="请输入更新人编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="editDate">
        <el-date-picker v-model="searchForm.editDate" value-format="yyyy-MM-dd" placeholder="请输入更新时间" @change="search"/>
      </el-form-item>
      <el-form-item label="是否删除0否 1是" prop="isdeleted">
        <el-input v-model="searchForm.isdeleted" placeholder="请输入是否删除0否 1是" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="searchForm.remark" placeholder="请输入备注" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="申报信息编码（关联declare表）" prop="declareId">
        <el-input v-model="searchForm.declareId" placeholder="请输入申报信息编码（关联declare表）" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="服务说明" prop="content">
        <el-input v-model="searchForm.content" placeholder="请输入服务说明" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="服务时间" prop="serviceDate">
        <el-date-picker v-model="searchForm.serviceDate" value-format="yyyy-MM-dd" placeholder="请输入服务时间" @change="search"/>
      </el-form-item>
      <el-form-item label="状态 0保存 1已提交" prop="status">
        <el-input v-model="searchForm.status" placeholder="请输入状态 0保存 1已提交" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="primary" :loading="isWorking.export" v-permissions="['business:declareservice:exportExcel']" @click="exportExcel">导出</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['business:declareservice:create', 'business:declareservice:delete']">
        <li><el-button type="primary" @click="$refs.operaDeclareServiceWindow.open('新建服务结构上传服务记录表')" icon="el-icon-plus" v-permissions="['business:declareservice:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['business:declareservice:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="creator" label="创建人编码" min-width="100px"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" min-width="100px"></el-table-column>
        <el-table-column prop="editor" label="更新人编码" min-width="100px"></el-table-column>
        <el-table-column prop="editDate" label="更新时间" min-width="100px"></el-table-column>
        <el-table-column prop="isdeleted" label="是否删除0否 1是" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column>
        <el-table-column prop="declareId" label="申报信息编码（关联declare表）" min-width="100px"></el-table-column>
        <el-table-column prop="content" label="服务说明" min-width="100px"></el-table-column>
        <el-table-column prop="serviceDate" label="服务时间" min-width="100px"></el-table-column>
        <el-table-column prop="status" label="状态 0保存 1已提交" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['business:declareservice:update', 'business:declareservice:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaDeclareServiceWindow.open('编辑服务结构上传服务记录表', row)" icon="el-icon-edit" v-permissions="['business:declareservice:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['business:declareservice:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaDeclareServiceWindow ref="operaDeclareServiceWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaDeclareServiceWindow from '@/components/business/OperaDeclareServiceWindow'
export default {
  name: 'DeclareService',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaDeclareServiceWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        remark: '',
        declareId: '',
        content: '',
        serviceDate: '',
        status: ''
      }
    }
  },
  created () {
    this.config({
      module: '服务结构上传服务记录表',
      api: '/business/declareService',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
