<template>
  <GlobalQuestionWindow
    
    width="60%"
    top="5vh"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <div class="title">{{ this.form.title }}</div>
    <div class="line"></div>
    <div v-for="(item, index) in form.problemList" :key="item.id" class="problem">
      <div class="problem-title">{{ `${index + 1}、${item.title}（${item.type == 0 ? '单选题' : '多选题' }）` }}</div>
      <el-radio-group v-if="item.type == 0" v-model="item.select" @input="$forceUpdate()">
        <div v-for="(opt, i) in item.opt" :key="i" class="option">
          <el-radio :label="String(i)" :disabled="type==0"><span class="value">{{ opt.opt }}</span></el-radio>
        </div>
      </el-radio-group>
      <el-checkbox-group v-else v-model="item.select" @change="$forceUpdate()">
        <div v-for="(opt, i) in item.opt" :key="i" class="option">
          <el-checkbox :disabled="type==0" :label="String(i)"><span class="mult-value">{{ opt.opt }}</span></el-checkbox>
        </div>
      </el-checkbox-group>
    </div>
    <div slot="footer">
      <el-button @click="confirm" :loading="isWorking" type="primary">{{ type == 0 ? '导出问卷' : '提交问卷' }}</el-button>
      <el-button @click="visible=false">关闭</el-button>
    </div>
  </GlobalQuestionWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalQuestionWindow from '@/components/common/GlobalQuestionWindow'
import { questionnaireDetail, exportExcel } from '@/api/business/questionnaire'
import { create } from '@/api/business/declareAnswer'
export default {
  name: 'Question',
  extends: BaseOpera,
  components: { GlobalQuestionWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: '',
      },
      type: 0, // 0 查看 1 答题
      companyId: '',
      baseForm: {},
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    open(title, target, type, baseForm) {
      this.title = title || '问卷'
      this.visible = true
      this.type = type
      this.baseForm = baseForm
      
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.getQuestion()
      })
    },
    getQuestion() {
      questionnaireDetail(this.form.id)
        .then(res => {
          this.form = res
          this.form.problemList.forEach((item, index) => {
            item.opt = JSON.parse(item.opt)
            item.select = item.type == 0 ? '' : []
          });
          console.log(this.form.problemList);
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
    confirm() {
      console.log(this.form);
      if (this.type === 0) {
        this.$dialog.exportConfirm('确认导出吗？')
          .then(() => {
            this.isWorking = true
            exportExcel({questionnaireId: this.form.id})
              .then(response => {
                this.download(response)
              })
              .catch(e => {
                this.$message.error(e)
              })
              .finally(() => {
                this.isWorking = false
              })
          })
          .catch(() => {})
      } else {
        const { problemList } = this.form
        let isFull = false
        const content =  problemList.map((item, index) => {
          if (item.select.length != 0) {
            isFull = true
          }
          return { no: index, select: item.type == 0 ? String(item.select) : item.select.join(',')}
        })
        if (!isFull) {
          this.$message.error('请完成问卷')
          return
        }
        create({
          ...this.baseForm,
          content: JSON.stringify(content)
        })
          .then(res => {
            console.log(res)
            this.$message.success('提交成功')
            this.visible = false
            this.$emit('success', res)
          })
          .catch(error => {
            this.$message.error(error.message)
          })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  height: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  line-height: 20px;
  text-align: center;
}
.line {
  margin: 24px 0;
  background-color: #DFE2E8;
  height: 1px;
}
.problem {
  margin-bottom: 30px;
  .problem-title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 24px;
  }
}
.option {
  margin-top: 25px;
  display: flex;
  .el-radio {
    margin-right: 0px;
  }
  ::v-deep .el-radio__label {
    width: 0;
  }
  .value{
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    vertical-align: middle;
  }
  .mult-value{
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    margin-left: 10px;
  }
}
</style>
