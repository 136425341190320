<template>
  <TableLayout>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <div class="title">
        <div class="status"></div>
        {{ form.name }}
        <div class="change" v-permissions="['company:company:update']"  @click="changeCompanyMessage">变更企业信息</div>
      </div>
      <div class="setting">
        <div class="setting-title">社会信用代码：{{ form.creditCode }}</div>
        <div class="parting-line"></div>
        <template  v-if="userInfo.type==1">
          <div class="setting-title">企业所属行业：{{ form.industryName }}</div>
          <div class="parting-line"></div>
          <div class="setting-title">企业性质：{{ form.natureName }}</div>
          <div class="parting-line"></div>
          <div class="setting-title">企业规模：{{ form.labelName }}</div>
        </template>
        <div v-else class="setting-title">单位类别：{{ form.labelName }}</div>
        <div class="parting-line"></div>
        <div class="setting-title">单位地址：{{ form.address }}</div>
      </div>
      <div class="company-message">
        <div class="item-line">
          <div class="item">
            <div class="item-value">
              <div class="value-label">法定代表人：</div>
              <div class="value-value">{{ form.legalPerson }}</div>
            </div>
          </div>
          <div class="item">
            <div class="item-value">
              <div class="value-label">手机/电话：</div>
              <div class="value-value">{{ form.legalPersonPhone }}</div>
            </div>
          </div>
        </div>
        <div class="item-line">
          <div class="item">
            <div class="item-value">
              <div class="value-label">联系人：</div>
              <div class="value-value">{{ form.linkName }}</div>
            </div>
          </div>
          <div class="item">
            <div class="item-value">
              <div class="value-label">手机/电话：</div>
              <div class="value-value">{{ form.linkPhone }}</div>
            </div>
          </div>
        </div>
        <div class="item-line" v-if="userInfo.type==1">
          <div class="item-value">
            <div class="value-label " >主营产品：</div>
            <div class="value-value ">{{ form.product }}</div>
          </div>
        </div>
        <div class="item-line">
          <div class="item-value">
            <!-- <div class="value-label">企业简介：</div> -->
            <div class="value-label">{{ userInfo.type == 1 ? '企业简介：' : '企业(单位)简介：' }}</div>
            <div class="value-value ">{{ form.content }}</div>
          </div>
        </div>
        <div class="item-line">
          <div class="item-value">
            <div class="value-label">{{ userInfo.type == 2 ? '营业执照/事业单位、社会团体登记证：' : '营业执照：' }}</div>
            <!-- <div class="value-label"></div> -->
            <div class="value-value">
              <el-image
                style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
                v-for="(item, index) in form.businessList"
                :key="index"
                :initial-index="index"
                :src="item.fileurlfull"
                :preview-src-list="form.businessList.map(item => item.fileurlfull)"
                :z-index="3000"
              ></el-image>
            </div>
          </div>
        </div>
        <template v-if="userInfo.type==2">
          <div class="item-line">
            <div class="item-value">
              <div class="value-label">企业资质：</div>
              <div class="value-value">
                
                <el-image
                  style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in form.zzFileList"
                  :key="index"
                  :initial-index="index"
                  :src="item.fileurlfull"
                  :preview-src-list="form.zzFileList.map(item => item.fileurlfull)"
                  :z-index="3000"
                ></el-image>
              </div>
            </div>
          </div>
          <div class="item-line">
            <div class="item-value">
              <div class="value-label">中标通知书：</div>
              <div class="value-value">
                <el-image
                  style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in form.noticeFileList"
                  :key="index"
                  :initial-index="index"
                  :src="item.fileurlfull"
                  :preview-src-list="form.noticeFileList.map(item => item.fileurlfull)"
                  :z-index="3000"
                ></el-image>
              </div>
            </div>
          </div>
        </template>
      </div>
      <template v-if="userInfo.type==1">
        <div class="sub-title">企业财务</div>
        <el-table
          :data="form.list"
          stripe
          :header-row-class-name="'table-header'"
          class="doumee-element-table"
        >
          <el-table-column prop="years" label="近三年主要指标" align="center" min-width="100px" fixed="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="assets" label="企业总资产(万元)" align="center" min-width="100px"></el-table-column>
          <el-table-column prop="income" label="营业收入(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="ratio" label="资产负债率(%)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="develop" label="研发费用(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="profit" label="利润总额(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="taxes" label="上缴税金(万元)" align="center" min-width="100px"></el-table-column>
          <el-table-column prop="employee" label="从业人数" align="center" min-width="100px"></el-table-column>
        </el-table>
      </template>
      <div class="changemMessage" v-permissions="['company:company:update']" @click="$refs.operaCompanyMessageWindow.open('修改企业资料', form)">修改资料</div>
    </template>
    <OperaChangeCompanyWindow ref="operaChangeCompanyWindow" @success="resetUserInfo"/>
    <OperaCompanyMessageWindow ref="operaCompanyMessageWindow" @success="resetUserInfo"/>
    <OperaCompanyChangeIngWindow ref="operaCompanyChangeIngWindow" @success="resetUserInfo" />
  </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import { queryCompanyDetail, judgeCompanyServiceIsFinish } from '@/api/business/company'
// import { judgeCompanyServiceIsFinish } from '@/api/business/company'
import { findUserInfo } from '@/api/system/common'
import { treeList } from '@/api/business/areas'
import { getCompanyChance } from '@/api/business/companyChange'
import TableLayout from '@/layouts/TableLayout'
import OperaChangeCompanyWindow from '@/components/business/OperaChangeCompanyWindow'
import OperaCompanyChangeIngWindow from '@/components/business/OperaCompanyChangeIngWindow'
import OperaCompanyMessageWindow from '@/components/business/OperaCompanyMessageWindow'

export default {
  name: 'Company',

  components: { TableLayout, OperaChangeCompanyWindow, OperaCompanyMessageWindow ,OperaCompanyChangeIngWindow},
  data () {
    return {
      form: {
        address: '',
        areaId: '', 
        cityId: '',
        companyChange: '',
        content: '',
        createDate: '',
        creator: '',
        creditCode: '',
        editDate: '',
        editor: '',
        labelId: '',
        labelName: '',
        // fileList: '',
        fileurl: '',
        id: '',
        industryId: '',
        isdeleted: '',
        legalPerson: '',
        legalPersonPhone: '',
        linkName: '',
        linkPhone: '',
        list: [],
        name: '',
        natureId: '',
        origin: '',
        password: '',
        product: '',
        registerDate: '',
        remark: '',
        type: '',
        businessList:[], // 企业营业执照集合
        fileList:[], //企业资料营业执照 
        zzFileList:[], //企业资质
        companyChange:[], //工商变更登记
        noticeFileList:[], //	企业中标通知书集合
        changeFileList:[] //企业变更登记集合
      },
      hasChange: false,
      province: [],
    }
  },
  provide() {
    return {
      province: () => this.province
    }
  },
  computed: {
    ...mapState(['userInfo', 'canJump']),
  },
  created () {
    this.getCompanyMessage(this.userInfo.companyId)
    treeList({ type: 0 })
      .then(res => {
        // console.log(res)
        this.province = res
      })
      .catch(e => {
        this.$message.error(e)
      })
  },
  methods: {
    changeCompanyMessage() {
      if (this.userInfo.type == 2 && !this.canJump) {
        return
      }
      // debugger
      if(this.hasChange){
        this.$refs.operaCompanyChangeIngWindow.open('企业变更资料', { companyId:this.userInfo.companyId })
      }else{
        this.$refs.operaChangeCompanyWindow.open('变更企业信息', { ...this.form, companyId:this.userInfo.companyId })
      }
    },
    getCompanyMessage(companyId, isRest=false) {
      // debugger
      queryCompanyDetail(companyId)
        .then(res => {
          // console.log(res);
          this.form = res
          if (!this.canJump && !isRest) {
            this.$refs.operaCompanyMessageWindow.open('修改企业资料', this.form)
          }
        })
        .catch(e => {
          this.$message.error(e)
        })
      getCompanyChance(companyId)
        .then(res => {
          this.hasChange = true;
        })
    },

    resetUserInfo() {
      this.getCompanyMessage(this.userInfo.companyId, true)
      findUserInfo(this.userInfo.id)
        .then(userInfo => {
          // 存储userInfo
          this.$store.commit('setUserInfo', {
            mobile: userInfo.userInfo,
            realname: userInfo.realname,
            username: userInfo.username,
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
      if (this.userInfo.type == 2) {
        judgeCompanyServiceIsFinish({ id: this.userInfo.companyId })
          .then(res => {
            this.$store.commit('setJump', res.code)
          })
          .catch(e => {
            this.$message.error(e)
          })
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 28px;
  height: 28px;
  display: flex;
  .status {
    margin-top: 3px;
    width: 4px;
    height: 22px;
    background: #216EEE;
    border-radius: 1px;
    margin-right: 4px;
  }
  .change {
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: #216EEE;
    line-height: 28px;
    width: 92px;
    height: 28px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #216EEE;
    margin-left: 8px;
  }
}

.setting {
  display: flex;
  margin-top: 20px;
  .setting-title {
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 14px;
  }
  .parting-line {
    height: 14px;
    background-color: #DFE2E8;
    width: 1px;
    margin: 0 15px;
  }
}

.company-message {
  margin: 30px 0;
  padding: 24px 34px;
  border-radius: 2px;
  background-color: #F4F7FC;
}

.item-line {
  width: 100%;
  display: flex;
  // justify-content: space-between;
  margin-bottom: 24px;
  height: auto;
  .item {
    width: 30%;
  }
}
.item-value {
  display: flex;
  font-size: 14px;
  line-height: 24px;
  .value-label {
    width: 146px;
    text-align: right;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    
  }
  .value-value {
    flex: 1;
    img {
      margin-left:10px;
      width: 100px;
      height: 100px
    }
  }
  .long-message {
    line-height: 24px;
  }
}
.content-line {
  display: flex;
  .content-title {
    width: 146px;
    text-align: right;
  }
  .content {
    flex: 1;
    // margin-top: 19px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
}
.sub-title {
  height: 16px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 16px;
  margin-bottom: 20px;
}
.changemMessage {
  margin: 40px auto;
  width: 96px;
  height: 36px;
  background: #216EEE;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
  cursor: pointer;
}
</style>
