<template>
  <GlobalWindow
    :title="title"
    :confirm-working="false"
    :visible.sync="visible"
    @confirm="confirm"
    
  > 
    <div class="project-name">项目名称：{{ form.name }}</div>
    <el-button plain @click="$refs.operaContentServeWindow.open('新建评分系数', { projectId:form.id, maxNum: null, ratio: null, minNum: null, sortnum: 1 })">新增</el-button>
    <div style="height:10px"></div>
    <el-table
      v-loading="isWorking.search"
      :data="tableData.list"
      stripe
      border
    >
      <el-table-column prop="name" label="评分等级" min-width="140px"></el-table-column>
      <el-table-column label="分值上限(不包含)" min-width="140px">
        <template slot-scope="{ row }">
          {{  row.maxNum }}
        </template>
      </el-table-column>
      <el-table-column prop="ratio" label="评分系数(%)" min-width="140px"></el-table-column>
      <el-table-column prop="createDate" label="创建时间" min-width="140px"></el-table-column>
      <el-table-column prop="status" label="操作" min-width="120px">
        <template slot-scope="{ row }">
          <el-button type="text" @click="$refs.operaContentServeWindow.open('编辑评分系数', row)">编辑</el-button>
          <el-button type="text" @click="deleteRatio(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px;">
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination">
      </pagination>
    </div>
    <OperaSettingSocreWindow ref="operaContentServeWindow" @success="handlePageChange"/>
    <div slot="footer"></div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaSettingSocreWindow from './OperaSettingSocreWindow'
import { fetchList, deleteById } from '@/api/business/projectRatio'
export default {
  name: 'OperaProjectSocreWindow',
  extends: BaseOpera,
  components: { GlobalWindow, TableLayout, Pagination, OperaSettingSocreWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: ''
      },
      // 是否正在执行
      isWorking: {
        // 搜索中
        search: false,
        // 删除中
        delete: false,
        // 导出中
        export: false
      },
      // 表格数据
      tableData: {
        // 已选中的数据
        selectedRows: [],
        // 排序的字段
        sorts: [],
        // 当前页数据
        list: [],
        // 分页
        pagination: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        }
      },
      province: [],
      cities: []
    }
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.search()
      })
    },
    deleteRatio(id) {
      this.$dialog.deleteConfirm('是否要删除该条评分？')
        .then(() => {
          deleteById(id)
            .then(res => {
              this.$message.success('删除成功')
              this.handlePageChange()
            })
            .catch(e => {
              this.$message.error(e)
            })
        })
    },
    // 搜索
    search () {
      this.handlePageChange(1)
    },
    // 导出Excel

    // 搜索框重置
    reset () {
      this.$refs.searchForm.resetFields()
      this.search()
    },
    // 每页显示数量变更处理
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.search()
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: { projectId: this.form.id },
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
  }
}
</script>

<style scoped>
.project-name {
  font-weight: 600;
  margin-bottom: 20px;
}
</style>
