<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="155px" label-suffix="：" inline>
      <!-- <div>
        <el-form-item label="服务机构用户名称" prop="username">
          <el-input v-model="form.username" placeholder="请输入服务机构用户名称" v-trim/>
        </el-form-item>
      </div> -->
      <div>
        <el-form-item label="登录账号" prop="username">
          <el-input v-model="form.username" placeholder="请输入登录账号" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="服务机构名称" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请输入服务机构名称" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="所属区域" prop="cityId">
          <div class="address">
            <el-select v-model="form.provinceId" placeholder="请选择省份" @change="selectProvince">
              <el-option
                v-for="item in province()"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <el-select v-model="form.cityId" placeholder="请选择城市">
            <el-option
              v-for="item in cities"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          </div>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="社会信用代码" prop="creditCode">
          <el-input v-model="form.creditCode" placeholder="请输入社会信用代码" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="法定代表人" prop="legalPerson">
          <el-input v-model="form.legalPerson" placeholder="请输入法定代表人" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="联系人" prop="linkName">
          <el-input v-model="form.linkName" placeholder="请输入姓名" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号" v-trim/>
        </el-form-item>
      </div>
      
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { checkMobile, nameRule, creditCode } from '@/utils/form'
import { treeList } from '@/api/business/areas'
export default {
  name: 'OperaServeUserWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    let newRule = (rule, value, callback) => {
      if (!this.form.provinceId) {
        callback(new Error('请先选择省份'))
      } else if (!this.form.cityId) {
        callback(new Error('请先选择城市'))
      } else {
        callback()
      }
    }
    return {
      // 表单数据
      form: {
        id: null,
        linkName: '',
        username: '',
        creditCode: '',
        legalPerson: '',
        provinceId: '',
        companyName: '',
        cityId: '',
        mobile: '',
        type: '2', //服务机构管理员
        password: '123456'
      },
      cities: [],
      // 验证规则
      rules: {
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入服务机构名称', trigger: 'blur' }
        ],
        cityId: [
          { required: true, validator: newRule, trigger: 'change'}
        ],
        creditCode: [
          { required: true, validator: creditCode, trigger: 'blur' }
        ],
        legalPerson: [
          { required: false, validator: nameRule, trigger: 'blur' }
        ],
        realname: [
          { required: false, validator: nameRule, trigger: 'blur' }
        ],
        mobile: [
          { required: false, validator: checkMobile, trigger: 'blur' }
        ]
      }
    }
  },
  inject: ['province'],
  created () {
    this.config({
      api: '/business/account',
      'field.id': 'id'
    })
    console.log(this.form)
  },
  methods: {
    open(title, target) {
      this.title = title
      this.visible = true
      this.form.provinceId = ''
      this.form.cityId = ''
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.selectProvince(this.form.provinceId, true)
      })
    },
    defaultCity(){
      if(this.form.provinceId != null) {
        const tempProvince = this.province().find(item => item.id === this.form.provinceId)
        if (tempProvince) {
          this.cities = tempProvince.childList
        }
      }
    },
    // 选择省份
    selectProvince(val, isInit) {
      this.cities = []
      if (!isInit) {
        this.form.cityId = ''
      }
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
        })
    },
  },
}

</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
</style>
