<template>
  <TableLayout :permissions="['business:declarehistory:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="主键" prop="id">
        <el-input v-model="searchForm.id" placeholder="请输入主键" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="是否已删除 0未删除 1已删除" prop="deleted">
        <el-input v-model="searchForm.deleted" placeholder="请输入是否已删除 0未删除 1已删除" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建人编码" prop="creator">
        <el-input v-model="searchForm.creator" placeholder="请输入创建人编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="searchForm.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建时间" @change="search"/>
      </el-form-item>
      <el-form-item label="更新人编码" prop="editor">
        <el-input v-model="searchForm.editor" placeholder="请输入更新人编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="editDate">
        <el-date-picker v-model="searchForm.editDate" value-format="yyyy-MM-dd" placeholder="请输入更新时间" @change="search"/>
      </el-form-item>
      <el-form-item label="是否删除0否 1是" prop="isdeleted">
        <el-input v-model="searchForm.isdeleted" placeholder="请输入是否删除0否 1是" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="searchForm.remark" placeholder="请输入备注" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="申报编码（关联declare表)" prop="declareId">
        <el-input v-model="searchForm.declareId" placeholder="请输入申报编码（关联declare表)" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="操作类型 0保存、1待县区审核、2县区审核通过、3县区退回修改、4县区审核驳回、5、双选待服务机构确认、6双选服务机构拒绝、7已分配服务机构、8服务机构诊断、9服务机构服务完成、10分配评分专家、11专家完成评分" prop="type">
        <el-input v-model="searchForm.type" placeholder="请输入操作类型 0保存、1待县区审核、2县区审核通过、3县区退回修改、4县区审核驳回、5、双选待服务机构确认、6双选服务机构拒绝、7已分配服务机构、8服务机构诊断、9服务机构服务完成、10分配评分专家、11专家完成评分" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="最近一次审核信息" prop="checkInfo">
        <el-input v-model="searchForm.checkInfo" placeholder="请输入最近一次审核信息" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="最近一次审核人" prop="checkor">
        <el-input v-model="searchForm.checkor" placeholder="请输入最近一次审核人" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="最近一次审核时间" prop="checkDate">
        <el-date-picker v-model="searchForm.checkDate" value-format="yyyy-MM-dd" placeholder="请输入最近一次审核时间" @change="search"/>
      </el-form-item>
      <el-form-item label="当前申报状态（操作前状态）" prop="status">
        <el-input v-model="searchForm.status" placeholder="请输入当前申报状态（操作前状态）" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="primary" :loading="isWorking.export" v-permissions="['business:declarehistory:exportExcel']" @click="exportExcel">导出</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['business:declarehistory:create', 'business:declarehistory:delete']">
        <li><el-button type="primary" @click="$refs.operaDeclareHistoryWindow.open('新建申报审核历史记录表')" icon="el-icon-plus" v-permissions="['business:declarehistory:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['business:declarehistory:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="deleted" label="是否已删除 0未删除 1已删除" min-width="100px"></el-table-column>
        <el-table-column prop="creator" label="创建人编码" min-width="100px"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" min-width="100px"></el-table-column>
        <el-table-column prop="editor" label="更新人编码" min-width="100px"></el-table-column>
        <el-table-column prop="editDate" label="更新时间" min-width="100px"></el-table-column>
        <el-table-column prop="isdeleted" label="是否删除0否 1是" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column>
        <el-table-column prop="declareId" label="申报编码（关联declare表)" min-width="100px"></el-table-column>
        <el-table-column prop="type" label="操作类型 0保存、1待县区审核、2县区审核通过、3县区退回修改、4县区审核驳回、5、双选待服务机构确认、6双选服务机构拒绝、7已分配服务机构、8服务机构诊断、9服务机构服务完成、10分配评分专家、11专家完成评分" min-width="100px"></el-table-column>
        <el-table-column prop="checkInfo" label="最近一次审核信息" min-width="100px"></el-table-column>
        <el-table-column prop="checkor" label="最近一次审核人" min-width="100px"></el-table-column>
        <el-table-column prop="checkDate" label="最近一次审核时间" min-width="100px"></el-table-column>
        <el-table-column prop="status" label="当前申报状态（操作前状态）" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['business:declarehistory:update', 'business:declarehistory:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaDeclareHistoryWindow.open('编辑申报审核历史记录表', row)" icon="el-icon-edit" v-permissions="['business:declarehistory:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['business:declarehistory:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaDeclareHistoryWindow ref="operaDeclareHistoryWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaDeclareHistoryWindow from '@/components/business/OperaDeclareHistoryWindow'
export default {
  name: 'DeclareHistory',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaDeclareHistoryWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        deleted: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        remark: '',
        declareId: '',
        type: '',
        checkInfo: '',
        checkor: '',
        checkDate: '',
        status: ''
      }
    }
  },
  created () {
    this.config({
      module: '申报审核历史记录表',
      api: '/business/declareHistory',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
