import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/project/page', data, {
    trim: true
  })
}

// 已报名项目
export function pageDeclared (data) {
  return request.post('/business/project/pageDeclared', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/business/project/create', data)
}

// 修改
export function projectDetail (id) {
  return request.get(`/business/project/${id}`)
}
// 修改
export function updateById (data) {
  return request.post('/business/project/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/business/project/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/business/project/delete/batch', {
    params: {
      ids
    }
  })
}
