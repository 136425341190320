
<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    v-loading="isUploading"
    element-loading-text="文件上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    @confirm="confirm"
  >
    

    <!-- <div class="line"></div> -->
<!--  style="display:flex; margin-bottom: 20px; flex-wrap: wrap;" -->
    <div class="tip-style" v-if="hcTips">{{ hcTips }}</div>
    <div class="serviceList" ref="navBar">
      <div
        class="arrow"
        :class="index==tempService&&'arrow-select'"
        v-for="(item, index) in serviceList"
        :key="index"
        @click="selectCheck(index)"
      >
        <div v-if="index" class="arrow-foot"></div>
        <div class="arrow-body">{{ index==0 ? '首次核查资料' : `第${index+1}次核查` }}</div>
        <div v-if="index != serviceList.length-1" class="arrow-header"></div>
      </div>
    </div>
    <!--  -->
    <el-form v-if="tempService < serviceList.length-1" ref="form2" :model="tempServiceObj" label-width="100px" label-suffix="：" inline>
      <div>
        <el-form-item label="核查时间" prop="createDate">
          {{ tempServiceObj.serviceDate }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="上传用户" prop="creator">
          {{ tempServiceObj.creatorName }}
        </el-form-item>
      </div>
      <div v-if="tempServiceObj.content">
        <el-form-item label="核查说明" prop="content">
          {{ tempServiceObj.content }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="核查资料">
          <FileLink
            :links="tempServiceObj.fileList"
            linkName="fileurlfull"
            :isUpload="false"
          />
        </el-form-item>
      </div>
      <div v-if="tempServiceObj.fileExtraList.length">
        <el-form-item label="补充资料">
          <FileLink
            :links="tempServiceObj.fileExtraList"
            linkName="fileurlfull"
            :isUpload="false"
          />
        </el-form-item>
      </div>
    </el-form>

    <template v-else>
      <el-form ref="form" :model="form" label-width="130px" :rules="rules" label-suffix="：" inline>
        
        <div>
          <el-form-item label="核查时间" prop="serviceDate">
            <el-date-picker
              v-model="form.serviceDate"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="核查说明" prop="content">
            <el-input
              style="width: 400px;"
              v-model="form.content"
              placeholder="请输入核查说明"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4}"
              v-trim/>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="核查资料上传" prop="fileList">
            <div>
              <el-button type="primary" icon="el-icon-upload" @click="$refs.upFile.click()">上传资料</el-button>
            </div>
            <div class="data-list">
              <FileLink
                :links="form.fileList"
                linkName="url"
              />
            </div>
          </el-form-item>
          <input type="file" @change="upFiles" ref="upFile" style="display: none;" />
        </div>
        <!-- {{ tempService }} -->
        <div v-if="tempService > 0">
          <el-form-item label="补充资料" prop="fileExtraList">
            <div>
              <el-button type="primary" icon="el-icon-upload" @click="$refs.upFileExtra.click()">上传资料</el-button>
            </div>
            <div class="data-list">
              <FileLink
                :links="form.fileExtraList"
                linkName="url"
              />
            </div>
          </el-form-item>
          <input type="file" @change="upFileExtra" ref="upFileExtra" style="display: none;" />
        </div>
      </el-form>
      <div class="action-style">
        <el-button style="color:#fff; background-color: rgb(86, 188, 190)" @click="submit(1)">提交</el-button>
        <el-button style="color:#fff; background-color: #216EEE;"  @click="submit(0)">保存</el-button>
      </div>
    </template>

    <div slot="footer">
      <el-button @click="visible=false">返回</el-button>
    </div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { fetchList, uploadHCData  } from '@/api/business/declareService'
import { upload } from '@/api/system/common'
export default {
  name: 'UploadServeCheck',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      isUploading: false,
      // 表单数据
      form: {
        id: null,
        declareId: null,
        serviceDate: '',
        content: '',
        fileList: [],
        fileExtraList: [],
        type: '1', //核查资料上传

      },
      serviceList: [
        
      ],
      hcTips: '',
      tempService: 0,
      tempServiceObj: {},
      // 验证规则
      rules: {
        serviceDate: [
          { required: true, message: '请选择核查时间', trigger: 'change' },
        ],
        fileList: [
          { required: true, message: '请上传核查资料', trigger: 'change' },
        ],
      }
    }
  },
  created () {
    this.config({
      api: '/business/declareService',
      'field.id': 'id'
    })
  },
  methods: {
    setListen() {
      const buttonGroup = this.$refs.navBar;
      if (!buttonGroup) {
        return
      }
      let isDragging = false;
      let startX = 0;
      let scrollLeft = 0;
      buttonGroup.removeEventListener('mousedown', (e) => {
        console.log(e);
      })
      buttonGroup.removeEventListener('mouseleave', (e) => {
        console.log(e);
      })
      buttonGroup.removeEventListener('mouseup', (e) => {
        console.log(e);
      })
      buttonGroup.removeEventListener('mousemove', (e) => {
        console.log(e);
      })

      buttonGroup.addEventListener('mousedown', (e) => {
        isDragging = true;
        startX = e.pageX - buttonGroup.offsetLeft;
        scrollLeft = buttonGroup.scrollLeft;
        console.log(startX, scrollLeft);
      });

      buttonGroup.addEventListener('mouseleave', () => {
        isDragging = false;
      });

      buttonGroup.addEventListener('mouseup', () => {
        isDragging = false;
      });

      buttonGroup.addEventListener('mousemove', (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - buttonGroup.offsetLeft;
        const walk = (x - startX) * 3;
        buttonGroup.scrollLeft = scrollLeft - walk;
      });
    },
    open (title, target) {
      this.title = title
      this.visible = true
      this.hcTips = target.hcTips
      this.$nextTick(() => {
        this.$refs.form.resetFields()
        this.form.declareId = target.id
        this.getServeList()
        this.setListen()
      })
    },
    
    getServeList() {
      fetchList({
        capacity: 1000000,
        model: { declareId: this.form.declareId, type: 1 }
      })
        .then(res => {
          // debugger
          let checkLength = res.records.length-1
          this.serviceList = res.records
          if (res.records.length > 0) {
            let lastSerce = this.serviceList[checkLength]
            if (lastSerce.status == 0) {
              this.form = {
                id: lastSerce.id,
                declareId: lastSerce.declareId,
                serviceDate: lastSerce.serviceDate,
                content: lastSerce.content,
                fileList: lastSerce.fileList,
                fileExtraList: lastSerce.fileExtraList,
              }
              // this.serviceList.splice(checkLength, 1)
            } else {
              this.serviceList.push({})
            }
          } else {
        
            this.serviceList.push({})
          }
          this.tempService = this.serviceList.length-1
          this.$nextTick(() => {
            this.$refs.navBar.scrollTo(this.tempService * 110, 0)
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
    
    upFiles(file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          console.log(this.form);
          this.form.fileList.push({
            name: res.originname,
            url: res.url,
            fileurl: res.imgaddr
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upFile.value = null
    },
    upFileExtra(file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          console.log(this.form);
          this.form.fileExtraList.push({
            name: res.originname,
            url: res.url,
            fileurl: res.imgaddr
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upFileExtra.value = null
    },
    submit(status) {
      if (status) {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            this.$message.error('请完善核查资料')
            return
          }
          this.submitAction(status)
        })
      } else {
        this.submitAction(status)
      }
      
    },
    submitAction(status) {
      this.isWorking = true
      uploadHCData({
        ...this.form,
        status,
        type: 1
      })
        .then(res => {
          this.$message.success(status?'提交成功':'保存成功')
          this.visible = false
          this.$emit('success')
        }).catch(e => {
          this.$message.error(e)
        })
      // eslint-disable-next-line no-return-assign
        .finally(() => this.isWorking = false)
    },
    selectCheck(index) {
      this.tempService = index
      this.tempServiceObj = this.serviceList[index]
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
      // this.$refs.form.clearValidate()
    }
  },
}
</script>


<style lang="scss" scoped>
.tip-style {
  background: rgba(33,110,238,0.1);
  border-radius: 2px;
  line-height: 24px;
  // color: #ff0000;
  font-size: 16px;
  color: #333;
  padding: 8px;
  margin-bottom: 10px;
}
::v-deep .el-form-item {
  display: flex;
}
::v-deep .el-form-item__content {
  flex: 1;
}
.line {
  background-color: #DFE2E8;
  height: 1px;
  margin: 20px 0;
}
.action-style {
  display:flex;
  margin-left: 120px;
  margin-bottom: 20px;
}
.serviceList {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important
  }
  -ms-overflow-style: none;
}
.arrow {
  background-color: #DFE2E8;
  color: #333333;
  height: 40px;
  line-height: 40px;
  display: flex;
  cursor: pointer;
  // margin-left: -5px;
  // margin-right: -5px;
  .arrow-foot {
    // background-color: #fff;
    border-left: 20px solid rgba($color: #fff, $alpha: 1);
    border-top: 20px solid rgba($color: #fff, $alpha: 0);
    border-bottom: 20px solid rgba($color: #fff, $alpha: 0);
  }
  
  .arrow-body {
    height: 40px;
    min-width: 110px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 16px;
  }
  .first-last {
    min-width: 100px;
  } 
  .normal-body {
    min-width: 110px;
  } 
  .arrow-header {
    // background-color: #DFE2E8;
    border-left: 20px solid rgba($color: #fff, $alpha: 0);
      border-top: 20px solid rgba($color: #fff, $alpha: 1);
      border-bottom: 20px solid rgba($color: #fff, $alpha: 1);
  }
}
.arrow-select {
  background-color: #216EEE;
  color: #fff;
}

.data-list {
  margin-top: 20px;
  .data-item {
    border-radius: 2px;
    // background-color: #F5F6F8;
    height: 36px;
    display: flex;
    // padding: 11px 20px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #216EEE;
    line-height: 13px;
    margin-right: 16px;
    i {
      box-sizing: border-box;
      margin-left: 10px;
      width: 16px;
      height: 16px;
      // background-color: #BFC3CB;
      color: #216EEE;
      border-radius: 50%;
      // padding: 1.5px;
      cursor: pointer;
      &:hover {
        color: #ff0000;
      }
    }
  }
}

.file-style {
  border-radius: 2px;
  // background-color: #F5F6F8;
  height: 36px;
  display: flex;
  // padding: 11px 20px;
  box-sizing: border-box;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #216EEE;
  line-height: 36px;
  margin-right: 16px;
  i {
    box-sizing: border-box;
    margin-left: 10px;
    width: 16px;
    height: 16px;
    // background-color: #BFC3CB;
    color: #216EEE;
    border-radius: 50%;
    // padding: 1.5px;
    cursor: pointer;
    &:hover {
      color: #216EEE;
    }
  }

}

</style>