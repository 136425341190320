// // 验证手机号码
// export function checkMobile (rule, value, callback) {
//   if (value == null || value.trim() === '') {
//     callback()
//     return
//   }
//   if (!/\d{11}$/.test(value)) {
//     callback(new Error('手机号码格式不正确'))
//     return
//   }
//   callback()
// }

export function checkMobile(rule, value, callback) {
  if (value == null || value.trim() === '') {
    if (rule.required) {
      callback(new Error('请输入手机号'))
    } else {
      callback()
    }
    return
  }
  let re = /^[0-9]{11}$/;
  var result = re.test(value); 
  if(!result) {
    callback(new Error('请输入正确的手机号'))
  } else {
    callback()
  }
}

export function nameRule (rule, value, callback) {
  if (value == null || value.trim() === '') {
    if (rule.required) {
      callback(new Error('请输入名字'))
    } else {
      callback()
    }
    return
  }
  callback()
  // let re =  /^[\u4e00-\u9fa5]+$/g;
  // var result = re.test(value)
  // if(!result) {
  //   callback(new Error('请输入正确的名字'))
  // } else {
  //   callback()
  // }
}
export function creditCode (rule, value, callback) {
  if (value == null || value.trim() === '') {
    if (rule.required) {
      callback(new Error('请输入社会信用代码'))
    } else {
      callback()
    }
    return
  }
  let re = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/
  var result = re.test(value)
  if(!result) {
    callback(new Error('请输入正确的社会信用代码'))
  } else {
    callback()
  }
}


// 验证邮箱
export function checkEmail (rule, value, callback) {
  if (value == null || value.trim() === '') {
    callback()
    return
  }
  if (!/^\S+@\S+\.\S+$/.test(value)) {
    callback(new Error('邮箱格式不正确'))
    return
  }
  callback()
}
