<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <div class="main-container">
      <!-- {{ this.form.checkInfo.split(' ') }} -->
      <div class="tip-style" v-if=" this.form.status==3|| this.form.status==4">审查结果：{{this.form.status==3?'退回修改':'审核驳回'}}！ 原因是：{{this.form.checkInfo && this.form.checkInfo.split('审核备注为：')[1] ||'无'}}</div>
      <div class="tip-style" v-if="this.form.status==6">{{this.form.checkInfo && this.form.checkInfo.split(' ')[3].split('，')[0]}}， 请重新选择</div>
      <!-- @tab-click="handleClick" -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="报名信息" name="first">
          <div class="container">

            <div class="title">
              <div class="status"></div>
              {{ form.name }}
            </div>
              <div class="setting">
                <div class="setting-title">所属城市：<span class="setting-value">{{ form.cityName }}</span></div>
                <div class="parting-line"></div>
                <!-- {{ form.startDate }} ~ {{ form.endDate }} -->
                <div class="setting-title">报名时间：
                  <span class="setting-value" v-if="form.startDate">{{ `${form.startDate.substring(0,form.startDate.length-3)} ~ ${form.endDate.substring(0,form.startDate.length-3)}` }}</span>
                </div>
              </div>
            <div class="content">{{ form.content || '暂无' }}</div>
            <div class="splating-ling"></div>
            <div class="sub-title">
              报名信息
            </div>
            <div class="apply-list">
             
              <div class="apply-item">
                <div class="label">联系人：</div>
                <div class="value">{{ form.linkname }}</div>
                <!-- {{ form. }} -->
              </div>
              <div class="apply-item">
                <div class="label">联系电话：</div>
                <div class="value">{{ form.linkphone }}</div>
                <!-- {{ form.linkphone }} -->
              </div>
            </div>
            <div class="apply-item">
                <div class="label">项目诊断类型：</div>
                <div class="value">{{ form.diagnoseType || '暂无' }}</div>
              </div>
            <div class="sub-title" style="margin-top: 20px;">企业资料</div>
            <div class="data-list">
              <FileLink
                v-if="form.fileServiceList.length"
                :links="form.fileServiceList"
                linkName="fileurlfull"
                :isUpload="false"
              />
              <div v-else>暂无</div>
            </div>

            <template v-if="form.answerId">
              <div class="sub-title question-title" style="margin-top: 30px;">问卷信息</div>
              <div class="questionnaire-container">
                <div v-if="form.examScore" class="quetionnaire_score">
                  <div>问卷得分：</div>
                  <div class="score-show" @click="showQuestion">{{ form.examScore }}</div>
                </div>
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane label="企业资料" name="second">
          <div class="container">
            <div class="title">
              <div class="status"></div>
              {{ company().name }}
            </div>
            <div class="setting">
              <div class="setting-title">社会信用代码：{{ company().creditCode || '暂无' }}</div>
              <div class="parting-line"></div>
              <div class="setting-title">企业所属行业：{{ company().industryName || '暂无' }}</div>
              <div class="parting-line"></div>
              <div class="setting-title">企业性质：{{ company().natureName || '暂无' }}</div>
              <div class="parting-line"></div>
              <div class="setting-title">企业规模：{{ company().labelName || '暂无' }}</div>
              <div class="parting-line"></div>
              <div class="setting-title">单位地址：{{ company().address || '暂无' }}</div>
            </div>
            <div class="company-message">
              <div class="item-line">
                <div class="item">
                  <div class="item-value">
                    <div class="value-label">企业法定代表人：</div>
                    <div class="value-value">{{ company().legalPerson || '法人' }}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-value">
                    <div class="value-label">手机/电话：</div>
                    <div class="value-value">{{ company().legalPersonPhone || '15511111111' }}</div>
                  </div>
                </div>
              </div>
              <div class="item-line">
                <div class="item">
                  <div class="item-value">
                    <div class="value-label">联系人：</div>
                    <div class="value-value">{{ company().linkName || '联系人' }}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-value">
                    <div class="value-label">手机/电话：</div>
                    <div class="value-value">{{ company().linkPhone || '15511111111' }}</div>
                  </div>
                </div>
              </div>
              <div class="item-line">
                <div class="item-value">
                  <div class="value-label ">主营产品：</div>
                  <div class="value-value ">{{ company().product || '待完善' }}</div>
                </div>
              </div>
              <div class="item-line">
                <div class="item-value">
                  <div class="value-label">企业简介：</div>
                  <div class="value-value ">{{ company().content || '待完善' }}</div>
                </div>
              </div>
              <div class="item-line">
                <div class="item-value">
                  <div class="value-label">营业执照：</div>
                  <div class="value-value">
                    <el-image
                      style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
                      v-for="(item, index) in company().businessList"
                      :key="index"
                      :initial-index="index"
                      :src="item.fileurlfull"
                      :preview-src-list="company().businessList.map(item => item.fileurlfull)"
                      :z-index="3000"
                    ></el-image>
                    <!-- <img v-for="item in company().businessList" :src="item.fileurlfull" style="margin-left: 10px;width: 100px;height: 100px;" alt=""> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="sub-title">企业财务</div>
            <el-table
              :data="company().list"
              stripe
              border
            >
              <el-table-column prop="years" label="近三年主要指标" align="center" min-width="100px" fixed="left" show-overflow-tooltip></el-table-column>
              <el-table-column prop="assets" label="企业总资产(万元)" align="center" min-width="100px"></el-table-column>
              <el-table-column prop="income" label="营业收入(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
              <el-table-column prop="ratio" label="资产负债率(%)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
              <el-table-column prop="develop" label="研发费用(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
              <el-table-column prop="profit" label="利润总额(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
              <el-table-column prop="taxes" label="上缴税金(万元)" align="center" min-width="100px"></el-table-column>
              <el-table-column prop="employee" label="从业人数" align="center" min-width="100px"></el-table-column>
            </el-table>
            <div class="sub-title" style="margin-top: 20px;">附件信息</div>
            <div class="data-list">
              <!-- <FileLink
                :links="form.fileServiceList"
                linkName="fileurlfull"
                :isUpload="false"
              /> -->
              <FileLink
                v-if="form.fileServiceList.length"
                :links="form.fileServiceList"
                linkName="fileurlfull"
                :isUpload="false"
              />
              <div v-else>暂无</div>
            </div>
            <div v-if="form.answerId" class="score">
              <div class="top-btn" @click="showQuestion">问卷得分 ></div>
              <div class="score-value">{{ form.examScore || 0 }} 分</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="诊断资料" name="three" v-if="this.form.status !=null && this.form.status>7">
          <div class="container">
            <div class="title">
              <div class="status"></div>
              {{ serviceMessage.companyName }}
            </div>
            <div class="setting">
              <div class="setting-title">社会信用代码：{{ serviceMessage.creditCode || '暂无' }}</div>
              <div class="parting-line"></div>
              <!-- <div class="setting-title">企业所属行业：{{ serviceMessage.indusrtyName || '暂无' }}</div>
              <div class="parting-line"></div>
              <div class="setting-title">企业性质：{{ serviceMessage.natureName || '暂无' }}</div> -->
              <!-- <template  v-if="userInfo.type==1">
                <div class="setting-title">企业所属行业：{{ serviceMessage.industryName }}</div>
                <div class="parting-line"></div>
                <div class="setting-title">企业性质：{{ serviceMessage.natureName }}</div>
              </template> -->
              <div  class="setting-title">单位类型：{{ serviceMessage.labelName }}</div>
              <div class="parting-line"></div>
              <div class="parting-line"></div>
              <div class="setting-title">单位地址：{{ serviceMessage.address || '暂无' }}</div>
            </div>
            <template v-if="serviceList.length">
              <div class="sub-title" style="margin-top: 36px;">服务机构服务数据</div>
              <div  class="serviceList" ref="navBar">
                <div
                  class="arrow"
                  :class="index==tempService&&'arrow-select'"
                  v-for="(item, index) in serviceList"
                  :key="index"
                  @click="selectServe(item, index)"
                >
                  <div v-if="index" class="arrow-foot"></div>
                  <div class="arrow-body">{{item.serviceTitle}}</div>
                  <div class="arrow-header" v-if="serviceList.length-1!=index"></div>
                </div>
              </div>
              <div class="service-detail" v-if="!!serviceList.length">
                <div class="item-line">
                  <div class="item-value">
                    <div class="value-label" style="width: 100px;">服务时间：</div>
                    <div class="value-value" v-if="tempServiceObj.serviceDate">{{  tempServiceObj.serviceDate }}</div>
                  </div>
                </div>
                <div class="item-line" v-if="!!tempServiceObj.creatorName">
                  <div class="item-value">
                    <div class="value-label" style="width: 100px;">上传用户：</div>
                    <div class="value-value" >{{  tempServiceObj.creatorName }}</div>
                  </div>
                </div>
                <div class="item-line" v-if="tempServiceObj.content">
                  <div class="item-value">
                    <div class="value-label" style="width: 100px;">服务说明：</div>
                    <div class="value-value ">{{ tempServiceObj.content }}</div>
                  </div>
                </div>
                <div class="item-line" v-if="tempServiceObj.fileList&&tempServiceObj.fileList.length">
                  <div class="item-value">
                    <div class="value-label" style="width: 100px;">服务资料：</div>
                    <div>
                      <FileLink
                        :links="tempServiceObj.fileList"
                        linkName="fileurlfull"
                        :isUpload="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="item-line"  v-if="tempServiceObj.fileScoreList && tempServiceObj.fileScoreList.length">
                    <div class="item-value">
                      <div class="value-label" style="width: 100px;">企业评分表：</div>
                      <div>
                        <FileLink
                          :links="tempServiceObj.fileScoreList"
                          linkName="fileurlfull"
                          :isUpload="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="item-line" v-if="tempServiceObj.diagnoseScore">
                    <div class="item-value">
                      <div class="value-label" style="width: 100px;">企业评分分数：</div>
                      <div class="value-value ">{{ tempServiceObj.diagnoseScore || 0}}</div>
                    </div>
                  </div>
                  <div class="item-line" v-if="tempServiceObj.fileExtraList&&tempServiceObj.fileExtraList.length">
                    <div class="item-value">
                      <div class="value-label" style="width: 100px;">补充资料：</div>
                      <div>
                        <FileLink
                          :links="tempServiceObj.fileExtraList"
                          linkName="fileurlfull"
                          :isUpload="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="item-line" v-if= "tempServiceObj.fileDiagnosisList && tempServiceObj.fileDiagnosisList.length">
                    <!-- {{ tempServiceObj.fileDiagnosisList }} -->
                    <div class="item-value">
                      <div class="value-label" style="width: 100px;">诊断报告：</div>
                      <!-- <div class="value-value ">{{ company.product || '待完善' }}</div> -->
                      <div>
                        <FileLink
                          :links="tempServiceObj.fileDiagnosisList"
                          linkName="fileurlfull"
                          :isUpload="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="item-line" v-if="tempServiceObj.declareDiagnoseList&&tempServiceObj.declareDiagnoseList.length">
                    <div class="item-value">
                      <div class="value-label" style="width: 100px;">报告详情：</div>
                      <div class="value-value ">
                        <div v-for="(item, index) in tempServiceObj.declareDiagnoseList" :key="item.id" style="margin-bottom: 10px;">
                          <div>{{ `${item.name}` }}</div>
                          <Editor
                            v-model="item.content"
                            :defaultConfig="{ readOnly : true }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </template>
            <template v-else>
              <div class="empty">
                <img src="@/assets/images/default_nodata@2x.png" alt="">
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="declareHistoryList!=null && declareHistoryList.length>0" label="查看进度" name="four">
          <!-- :icon="index==0&&'icon-dio'" -->
          <div class="declare-history">
            <el-timeline>
              <el-timeline-item
                v-for="(declare, index) in declareHistoryList"
                :key="index"
                hide-timestamp
                :class="index==0&&'first'"
                :size="index==0?'large':'normal'"
                :timestamp="declare.checkDate"
              >
                <h4>{{ declare.checkDate }}</h4>
                <p>{{ declare.checkInfo }}</p>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <input type="file" @change="upFiles" ref="upFile" style="display: none;" /> -->
    <QuestionAnswer ref="questionAnswer" />
    <!-- @success="questionnaire" -->
    <div slot="footer">
      <!-- // 0已保存、1待审核、2审核通过、3退回修改、4审核驳回、5待服务机构确认、
        // 6服务机构拒绝、7已分配服务机构、8诊断中（成功上传第一份服务资料）、
        // 9服务完成、10已分配评分专家、11已完成 -->
      <el-button v-if="(form.status==2||form.status==6) && ( form.sodType == 0)" @click="$refs.OperaCompanySelectServeWindow.open('选择服务机构', form)" type="primary">选择服务机构</el-button>
      <!-- <el-button v-if="form.status==7 && form.sodType == 0" @click="$refs.OperaCompanySelectServeWindow.open('选择服务机构', form)" type="primary">重选择服务机构</el-button> -->
      <el-button @click="visible=false">返回</el-button>
    </div>
    <OperaCompanySelectServeWindow ref="OperaCompanySelectServeWindow" @success="handleSelectChange" />
  </GlobalWindow>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import QuestionAnswer from './QuestionAnswer'
import OperaCompanySelectServeWindow from './OperaCompanySelectServeWindow'
import { Editor } from '@wangeditor/editor-for-vue'
import { serviceDetail  } from '@/api/business/declareService'
import { getListByDeclareId as declareHistory } from '@/api/business/declareHistory'
export default {
  name: 'ProjectApplyDetail',
  extends: BaseOpera,
  components: {
    GlobalWindow,
    TableLayout,
    Editor,
    OperaCompanySelectServeWindow,
    QuestionAnswer },
  data () {
    return {
      activeName: 'first',
      // 表单数据
      form: {
        name: '',
        diagnoseType: null,
        linkname: null,
        linkphone: null,
        cityName: null,
        checkInfo: null,
        id: null,
        deleted: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
       
        remark: '',
        startDate: '',
        endDate: '',
        sodType: '',
        edType: '',
        scoreType: '',
        areaCheck: '',
        checkReq: '',
        fileReq: '',
        serviceReq: '',
        questionnaireId: '',
        content: '',
        status: '',
        answerId: '',
        examScore: '',
        fileServiceList: [],
        serviceLimt: '',
        checkInfo: '',
        projectId:''
      },
      
      serviceMessage: {},
      serviceList: [],
      tempService: 0,
      tempServiceObj: {},
      declareHistoryList: [],
      answerId: '',
      projectId: '',
      // 验证规则
      rules: {
      }
    }
  },
  inject: ['company'],
  created () {
    console.log(this.form)
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    setListen() {
      const buttonGroup = this.$refs.navBar;
      if (!buttonGroup) {
        return
      }
      let isDragging = false;
      let startX = 0;
      let scrollLeft = 0;
      buttonGroup.removeEventListener('mousedown', (e) => {
        console.log(e);
      })
      buttonGroup.removeEventListener('mouseleave', (e) => {
        console.log(e);
      })
      buttonGroup.removeEventListener('mouseup', (e) => {
        console.log(e);
      })
      buttonGroup.removeEventListener('mousemove', (e) => {
        console.log(e);
      })

      buttonGroup.addEventListener('mousedown', (e) => {
        isDragging = true;
        startX = e.pageX - buttonGroup.offsetLeft;
        scrollLeft = buttonGroup.scrollLeft;
        console.log(startX, scrollLeft);
      });

      buttonGroup.addEventListener('mouseleave', () => {
        isDragging = false;
      });

      buttonGroup.addEventListener('mouseup', () => {
        isDragging = false;
      });

      buttonGroup.addEventListener('mousemove', (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - buttonGroup.offsetLeft;
        const walk = (x - startX) * 3;
        buttonGroup.scrollLeft = scrollLeft - walk;
      });
    },
    open (title, target, projectId) {
      debugger
      this.activeName = 'first'
      this.title = title
      this.visible = true
      this.projectId = projectId
      this.serviceList = []
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.form.projectId = projectId;
        console.log(this.form);
        this.setListen()
      })
    },
    handleSelectChange(){
      this.form.status = 5
      this.$emit('success')
    },
    handleClick() {
      if (this.activeName === 'three') {
        serviceDetail({ declareId: this.form.id })
          .then(res => {
            this.serviceMessage = res
            this.tempService = 0
            if (this.serviceMessage.dsServiceList != null && this.serviceMessage.dsServiceList.length) {
              // debugger
              this.tempServiceObj = this.serviceMessage.dsServiceList[0]
              this.serviceList = []
              // console.log(this.tempServiceObj);
              this.serviceMessage.dsServiceList.forEach((item, index) => {
                this.serviceList.push({
                  ...item,
                  serviceTitle: index==0?'首次服务数据' : `第${index+1}次服务数据`
                })
              })
            }
            if (this.serviceMessage.dsZHList != null && this.serviceMessage.dsZHList.length) {
              this.tempHcServiceObj = this.serviceMessage.dsZHList[0]
            }
            this.serviceMessage.declareDoneList.forEach((item, index) => {
              this.serviceList.push({
                ...item,
                serviceDate: item.diagnoseDate,
                serviceTitle: index==0?'完成服务' : `第${index}次退回服务`
              })
            })
            this.$nextTick(() => {
              this.setListen()
            })
          })
          .catch(e => {
            this.$message.error(e)
          })
      } else if (this.activeName === 'four') {
        declareHistory({ declareId: this.form.id })
          .then(res => {
            this.declareHistoryList = [...res, ...res]
          })
          .catch(e => {
            this.$message.error(e)
          })
      }
    },
    selectServe(item, index) {
      this.tempService = index
      this.tempServiceObj = item
    },
    showQuestion() {
      console.log(this.form);
      this.$refs.questionAnswer.open(
        '',
        { id: this.form.answerId },
        0)
    },
    showQuestionOld() {
      console.log(this.form);
      this.$refs.question.open(
        '',
        { id: this.form.questionnaireId },
        0,
        { questionnaireId: this.form.questionnaireId, companyId: this.company().id, projectId: this.form.id })
    },
    downloadFile(url) {
      window.open(url, '_blank')
    },
    questionnaire(data) {
      this.answerId = data.id
    },
    confirm() {
      this.visible = false
    }
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  background-color: #F4F7FC;
  padding: 20px;
}
.container {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  position: relative;
  .score {
    width: 140px;
    height: 61px;
    position: absolute;
    top: 30px;
    right: 30px;
    border-radius: 2px;
    border: 1px solid #DFE2E8;
    overflow: hidden;
    text-align: center;
    .top-btn {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      background-color: #216EEE;
      color: #FFFFFF;
      line-height: 30px;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
      height: 30px;
      cursor: pointer;
    }
    .score-value {
      font-size: 15px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #216EEE;
      line-height: 30px;
      height: 30px;
    }
  }
}
.questionnaire-container {
  display: flex;
  .quetionnaire_score {
    display: flex;
    vertical-align: middle;
    line-height: 33px;
    margin-left: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    .score-show {
      font-size: 15px;
      font-weight: 600;
      color: #216EEE;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 28px;
  height: 28px;
  display: flex;
  .status {
    margin-top: 3px;
    width: 4px;
    height: 22px;
    background: #216EEE;
    border-radius: 1px;
    margin-right: 4px;
  }
  .change {
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: #216EEE;
    line-height: 28px;
    width: 92px;
    height: 28px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #216EEE;
    margin-left: 8px;
  }
}
.setting {
  display: flex;
  margin-top: 20px;
  .setting-title {
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 14px;
    .setting-value {
      font-weight: 600;
    }
  }
  .parting-line {
    height: 14px;
    background-color: #DFE2E8;
    width: 1px;
    margin: 0 15px;
  }
}
.company-message {
  margin: 30px 0;
  padding: 24px 34px;
  border-radius: 2px;
  background-color: #F4F7FC;
}

.item-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  height: auto;
  .item {
    width: 50%;
  }
}
.item-value {
  display: flex;
  font-size: 14px;
  line-height: 24px;
  .value-label {
    width: 146px;
    text-align: right;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    
  }
  .value-value {
    flex: 1;
  }
  .long-message {
    line-height: 24px;
  }
}

.sub-title {
  height: 16px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 16px;
  margin-bottom: 16px;
}

.data-list {
  margin-bottom: 20px;
  .data-item {
    height: 24px;
    display: flex;
    box-sizing: border-box;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 24px;
    margin-right: 16px;
    i {
      line-height: 24px;
      box-sizing: border-box;
      margin-left: 10px;
      width: 16px;
      height: 16px;
      color: #828D9E;
      border-radius: 50%;
      padding: 1.5px;
      cursor: pointer;
      &:hover {
        color: #216EEE;
      }
    }
  }
}
::v-deep .el-tabs__header {
  background-color: #fff;
  margin-bottom: 0;
  padding: 30px 30px 0;
}
.serviceList {
  display: flex;
  // flex-wrap: wrap;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important
  }
  -ms-overflow-style: none;
}
.arrow {
  background-color: #DFE2E8;
  color: #333333;
  height: 40px;
  line-height: 40px;
  display: flex;
  cursor: pointer;
  // margin-left: -5px;
  // margin-right: -5px;
  .arrow-foot {
    // background-color: #fff;
    border-left: 20px solid rgba($color: #fff, $alpha: 1);
    border-top: 20px solid rgba($color: #fff, $alpha: 0);
    border-bottom: 20px solid rgba($color: #fff, $alpha: 0);
  }
  
  .arrow-body {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 16px;
  }
  .arrow-header {
    // background-color: #DFE2E8;
    border-left: 20px solid rgba($color: #fff, $alpha: 0);
      border-top: 20px solid rgba($color: #fff, $alpha: 1);
      border-bottom: 20px solid rgba($color: #fff, $alpha: 1);
  }
}
.arrow-select {
  background-color: #216EEE;
  color: #fff;
}
.service-detail {
  margin-top: 20px;
  padding: 30px;
  border-radius: 0px 0px 2px 2px;
  border: 1px solid #DFE2E8;
}
.declare-history {
  background-color: #fff;
  padding: 30px;
}
.first {
  ::v-deep .el-timeline-item__node {
    background-color: #fff !important;
    width: 8px !important;
    height: 8px !important;
    transform: translateX(-3px);
    border: #216EEE solid 6px !important;
  }
}

 .tip-style {
   color: #ff0000;
   font-size: 16px;
  //  height: 28px;
   line-height: 28px;
   padding: 0 8px;
   margin-bottom: 10px;
   font-weight: bold;
 }

 .empty {
  width: 100%;
  height: 400px;
  position: relative;
  img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 268px;
    height: 268px;
  }
}
.splating-ling {
  margin: 29px 0;
  height: 1px;
  background-color: #DFE2E8;
}
.content {
  margin-top: 19px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}
.apply-list {
  display: flex;
  margin-bottom: 20px;
}
.apply-item {
    margin-right: 10px;
    display: flex;
    .label {
      margin: auto 0;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      
      line-height: 14px;
      height: 14px;
      min-width: 100px;
      text-align: right;
    
    }
    .value {
      margin: auto 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      height: 14px;
      min-width: 50px;
      color: #333333;
    }
  }
</style>
