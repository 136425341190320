<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    v-loading="isUploading"
    element-loading-text="文件上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" label-width="150px" label-suffix="：" :rules="rules" inline>
      <div class="tip-style"><div class="tip-icon">i</div><div>企业名称、社会信用代码、所属区域、企业法定代表人、营业执照信息修改后需管理员审核，请谨慎修改！</div></div>
      <div class="item-line">
        <el-form-item :label="userInfo.type==1?'企业名称':'企业(单位)名称'"  prop="name">
          <el-input v-model="form.name" :placeholder="userInfo.type==1?'请输入企业名称':'请输入企业(单位)名称'" v-trim/>
        </el-form-item>
      </div>
      <div class="item-line">
        <el-form-item label="所属区域" prop="address">
          <div class="address">
            <el-select v-model="form.provinceId" placeholder="请选择省份" @change="selectProvince">
              <el-option
                v-for="item in province()"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line"></div>
            <el-select v-model="form.cityId" placeholder="请选择城市" @change="selectCity">
              <el-option
                v-for="item in cities"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line"></div>
            <el-select v-model="form.areaId" placeholder="请选择地区">
              <el-option
                v-for="item in areas"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
      </div>
      <div class="short-line">
        <el-form-item label="统一社会信用代码" prop="creditCode">
          <el-input v-model="form.creditCode" placeholder="请输入统一社会信用代码，不可重复" v-trim/>
        </el-form-item>
      </div>
      <div class="short-line">
        <el-form-item label="法定代表人" prop="legalPerson">
          <el-input v-model="form.legalPerson" placeholder="请输入法定代表人姓名" v-trim/>
        </el-form-item>
      </div>
      <div class="pic-line">
        <!-- 企业类型0企业 1服务机构 2综合服务单位 -->
        <el-form-item :label="userInfo.type==1?'营业执照':'营业执照/事业单位、社会团体登记证'" prop="fileList">
          <UploadImage
            :fileList="form.fileList"
            @beginUpload="begin"
            @endUpload="end"
          />
        </el-form-item>
      </div>
      <div class="pic-line">
        <el-form-item label="工商变更登记" prop="changeFileList">
          <UploadImage
            :fileList="form.changeFileList"
            @beginUpload="begin"
            @endUpload="end"
          />
        </el-form-item>
      </div>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import UploadImage from '../common/UploadImage.vue'
import { create } from '@/api/business/companyChange'
import { checkMobile, nameRule, creditCode } from '@/utils/form'
import { mapState } from 'vuex'
import { treeList } from '@/api/business/areas'
export default {
  name: 'OperaChangeCompanyWindow',
  extends: BaseOpera,
  components: { GlobalWindow, UploadImage },
  data () {
    let newRule = (rule, value, callback) => {
      if (!this.form.provinceId) {
        callback(new Error('请先选择省份'))
      } else if (!this.form.cityId) {
        callback(new Error('请先选择城市'))
      } else if (!this.form.areaId) {
        callback(new Error('请先选择地区'))
      } else {
        callback()
      }
    }
    return {
      isUploading: false,
      // 表单数据
      form: {
        id: null,
       
        name: '',
        legalPerson: '',
        provinceId: '',
        areaId: '',
        cityId: '',
        creditCode: '',
        fileList: [],
        changeFileList: []
      },
      
      cities: [],
      areas: [],
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        fileList: [
          { required: true, message: '请输入上传', trigger: 'change' }
        ],
        changeFileList: [
          { required: true, message: '请输入上传工商变更照片', trigger: 'change' }
        ],
        address: [
          { required: true, validator: newRule, trigger: ['blur']}
        ],
        creditCode: [
          { required: true, validator: creditCode, trigger: 'blur' }
        ],
        legalPerson: [
          { required: true, validator: nameRule, trigger: 'blur' }
        ]
      }
    }
  },
  inject: ['province'],
  computed: {
    ...mapState(['userInfo']),
  },
  created () {
    this.config({
      api: '/business/company',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      
      this.title = title
      this.visible = true
      this.form.fileList = []
      this.form.changeFileList = []
      // 编辑
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
        this.$refs.form.resetFields()
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        if (this.form.fileList) {
          this.form.fileList = JSON.parse(JSON.stringify(target.fileList)) 
        } else {
          this.form.fileList = JSON.parse(JSON.stringify(target.businessList)) || []
        }
        this.form.fileList.forEach(item => item.url = item.fileurlfull)
        this.form.changeFileList = JSON.parse(JSON.stringify(this.form.changeFileList)) || []
        this.form.changeFileList.forEach(item => item.url = item.fileurlfull)
        this.selectProvince(this.form.provinceId, true)
      })
    },
   
    // 选择省份
    selectProvince(val, isInit) {
      if (!isInit) {
        this.cities = []
        this.areas = []
        this.form.cityId = ''
        this.form.areaId = ''
      }
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
          if (isInit) {
            this.selectCity(this.form.cityId, isInit)
          }
        })
    },
    // 选择城市
    selectCity(val, isInit) {
      if (!isInit) {
        this.areas = []
        this.form.areaId = ''
      }
      treeList({ type: 2, parentId: val })
        .then(res => {
          this.areas = res
          
        })
    },
    begin() {
      this.isUploading=true
    },
    end() {
      this.isUploading=false
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        create({
          ...this.form,
          companyId: this.form.id
        })
          .then(() => {
            this.visible = false
            this.$refs.form.resetFields()
            this.$message.success('修改成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$message.error(e)
          })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.item-line {
  ::v-deep .el-form-item__content {
    width: 480px;
  }
}
.short-line {
  ::v-deep .el-form-item__content {
    width: 286px;
  }
}
.pic-line {
  ::v-deep .el-form-item__content {
    width: 500px;
  }
}
.address {
  display: flex;
  .line {
    width: 10px;
  }
}
.tip-style {
  height: 17px;
  color: #EE3821;
  display: flex;
  margin-bottom: 20px;
  line-height: 17px;
  vertical-align: middle;
  font-size: 14px;
  .tip-icon {
    vertical-align: middle;
    border: 1px #EE3821 solid;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    margin-right: 6px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
  }
}

</style>
