<template>
  <div class="main-header">
    <div class="header">
      <h2>
        <i class="el-icon-s-unfold" v-if="menuData.collapse" @click="switchCollapseMenu(null)"></i>
        <i class="el-icon-s-fold" v-else @click="switchCollapseMenu(null)"></i>
        {{title}}
      </h2>
      <tagsview class="tags"></tagsview>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import tagsview from "./tagsview.vue"
export default {
  name: 'Header',
  components: { tagsview },
  computed: {
    ...mapState(['menuData']),
    title () {
      return this.$route.meta.title
    }
  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.header {
  overflow: hidden;
  padding: 0 25px;
  background: #fff;
  height: 100%;
  display: flex;
  overflow: hidden;
  h2 {
    flex-shrink: 0;
    line-height: 48px;
    width: 200px;
    font-size: 19px;
    color: #606263;
    font-weight: normal;
    display: inline;
    & > i {
      font-size: 20px;
      margin-right: 12px;
    }
  }
}
// .tags {
//     padding-bottom: 16px;
//   }

</style>
