<template>
  <TableLayout :permissions="['business:project:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="已报名" name="first"></el-tab-pane>
        <el-tab-pane label="未报名" name="second"></el-tab-pane>
      </el-tabs>
      <el-form-item label="名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入项目名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item  v-if="activeName=='first'" label="流程状态" prop="declareStatus" >
        <el-select v-model="searchForm.declareStatus" placeholder="请选择流程状态">
          <el-option
            v-for="item in declareStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item  v-else label="项目状态" prop="status" >
        <el-select v-model="searchForm.status" placeholder="请选择项目状态">
          <el-option
            v-for="item in projectStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap style="overflow: hidden">
      <div v-if="tableData.list.length">
        <div class="project">
          <div v-for="item in tableData.list" :key="item.id" class="project-item">
            <ProjectItem
              :id="item.id"
              :canApply="true"
              :isApply="activeName=='first'"
              :projectNmae="item.name || item.projectName"
              :status="item.status"
              :imgurlfull="item.imgurlfull"
              :applyDate="item.startDate.substring(0,item.startDate.length-3)+'~'+item.endDate.substring(0,item.endDate.length-3)"
              :declareStatus="item.status"
              :city="item.cityName"
              :projectId="item.projectId"
              :remark="item.remark"
              @projectDetail="selectProject(item)"
              @apply="apply"
            />
          </div>
        </div>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </div>
      <div v-else class="empty">
        <img src="@/assets/images/default_nodata@2x.png" alt="">
      </div>
    </template>
    <!-- 新建/修改 -->
    <OperaProjectDetailWindow ref="operaProjectDetailWindow" @success="handlePageChange"/>
    <OperaProjectApplyWindow ref="operaProjectApplyWindow" @success="handlePageChange"/>
    <ProjectApplyDetail ref="projectApplyDetail" @success="handlePageChange"/>
    <OperaCompanyMessageWindow ref="operaCompanyMessageWindow" @success="initData"/>
  </TableLayout>
</template>

<script>

import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaProjectDetailWindow from '@/components/business/OperaProjectDetailWindow'
import OperaProjectApplyWindow from '@/components/business/OperaProjectApplyWindow'
import ProjectItem from '@/components/business/ProjectItem'
import ProjectApplyDetail from '@/components/business/ProjectApplyDetail'
import OperaCompanyMessageWindow from '@/components/business/OperaCompanyMessageWindow'
import { pageDeclared, projectDetail } from '@/api/business/project'
import { queryCompanyDetail, isFinishData } from '@/api/business/company'
import { findByProjectId } from '@/api/business/declares'
import { treeList } from '@/api/business/areas'
export default {
  name: 'Project',
  extends: BaseTable,
  components: {
    TableLayout,
    Pagination,
    OperaProjectDetailWindow,
    ProjectItem,
    OperaProjectApplyWindow,
    ProjectApplyDetail,
    OperaCompanyMessageWindow
  },
  data () {
    return {
      activeName: 'first',
      // 搜索
      searchForm: {
        declareStatus: '',
        status: '',
        name: ''
      },
      declareStatus: [
        // 0已保存、1待审核、2审核通过、3退回修改、4审核驳回、5待服务机构确认、
        // 6服务机构拒绝、7已分配服务机构、8诊断中（成功上传第一份服务资料）、
        // 9服务完成、10已分配评分专家、11已完成
        { label: '已保存', value: 0 },
        { label: '待审核', value: 1 },
        { label: '审核通过', value: 2 },
        { label: '退回修改', value: 3 },
        { label: '审核驳回', value: 4 },
        { label: '待服务机构确认', value: 5 },
        { label: '服务机构拒绝', value: 6 },
        { label: '已分配服务机构', value: 7 },
        { label: '诊断中', value: 8 },
        { label: '服务完成', value: 9 },
        { label: '已分配评分专家', value: 10 },
        { label: '已完成', value: 11 }
      ],
      projectStatus: [
        { label: '未开始', value: 0 },
        { label: '进行中', value: 1 },
        { label: '已截止', value: 2 }
      ],
      company: {},
      isFinishData: false,
      province: []
    }
  },
  provide() {
    return {
      company: () => this.company,
      province: () => this.province
    }
  },
  created () {
    this.config({
      module: '项目信息表',
      api: '/business/project',
      'field.id': 'id',
      'field.main': 'id'
    })
    treeList({ type: 0 })
      .then(res => {
        // console.log(res)
        this.province = res
      })
      .catch(e => {
        this.$message.error(e)
      })
    this.tableData.pagination = {
      pageIndex: 1,
      pageSize: 9,
      pageSizes: [9, 18, 27, 36],
      total: 0
    }
    this.initData()
    this.search()
  },
  methods: {
    initData () {
      queryCompanyDetail(this.userInfo.companyId)
        .then(res => {
          this.company = res
        })
        .catch(e => {
          this.$message.error(e)
        })
      isFinishData({ id: this.userInfo.companyId })
        .then(res => {
          this.isFinishData = res.code
        })
        .catch(e => {
          this.$message.error(e)
        })
      // this.handlePageChange();
    },
    selectProject(item) {
      console.log(item);
      if (this.activeName === 'first') {
        Promise.all([findByProjectId(item.projectId), projectDetail(item.projectId)])
            .then(res => {
              // debugger
              this.$refs.projectApplyDetail.open('报名详情', {
                  ...res[0],
                  ...res[1],
                  id: res[0].id,
                  status: res[0].status
                  // fileServiceList: res[0].fileServiceList,
                  // diagnoseType: res[0].diagnoseType,
                  // linkname: res[0].linkname,
                  // linkphone: res[0].linkphone,
                  // // remark: remark,
                  // answerId: res[0].declareAnswer && res[0].declareAnswer.id || 1,
                  // examScore: res[0].declareAnswer && res[0].declareAnswer.score || 100,
                },
                item.projectId
              )
            })
            .catch(e => {
              this.$message.error(e)
            })
        // findByProjectId(item.projectId)
        //   .then(res => {
        //     this.$refs.projectApplyDetail.open('报名详情', {...res, sodType: item.sodType }, item.projectId)
        //   })
        //   .catch(e => {
        //     this.$message.error(e)
        //   })
      } 
      // else {
      //   projectDetail(item.id)
      //     .then(res => {
      //       this.$refs.operaProjectDetailWindow.open('项目详情', res)
      //     })
      //     .catch(() => {
      //       this.$messages.error('获取项目详情出错')
      //     })
      // }
    },
    apply (id, declareId,remark,status) {
      if (this.isFinishData) {
        if (declareId) {
          Promise.all([findByProjectId(id), projectDetail(id)])
            .then(res => {
              // debugger
              this.$refs.operaProjectApplyWindow.open('企业报名', {
                ...res[1],
                fileList: res[0].fileServiceList,
                linkname: res[0].linkname,
                linkphone: res[0].linkphone,
                diagnoseTypeId: res[0].diagnoseTypeId,
                remark: remark,
                answerId: res[0].declareAnswer && res[0].declareAnswer.id,
                score: res[0].declareAnswer && res[0].declareAnswer.score,
                status: status
              })
            })
            .catch(e => {
              this.$message.error(e)
            })
          return
        }
        projectDetail(id)
          .then(res => {
            this.$refs.operaProjectApplyWindow.open('企业报名', res)
          })
          .catch(() => {
            this.$message.error('获取项目详情出错')
          })
      } else {
        this.$dialog.messageComplete('您还没有完善信息，请先完善信息后报名项目?')
          .then(() => {
            this.$refs.operaCompanyMessageWindow.open('修改企业资料', this.company)
          })
      }
    },
    handleClick(tab, event) {
      this.reset()
      // this.tableData.list = []r
    },
    handlePageChange (pageIndex) {
      this.__checkApi()
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      const action = this.activeName === 'first' ? pageDeclared : this.api.fetchList
      action({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm,
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
  }
}
</script>

<style lang="scss" scoped>

.project {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.project-item {
  width: calc(33% - 1px);
  box-sizing: border-box;
  padding: 10px;
}
.empty {
  width: 100%;
  height: 400px;
  position: relative;
  img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 268px;
    height: 268px;
  }
}
</style>
