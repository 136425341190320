<template>
  <GlobalQuestionWindow
    
    width="60%"
    top="5vh"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <div class="title">{{ this.title }}</div>
    <div class="line"></div>
    <div v-for="(item, index) in this.problemList" :key="item.id" class="problem">
      <div class="problem-title">{{ `${index + 1}、${item.title}（${item.type == 0 ? '单选题' : '多选题' }）（${item.score}分` }}）</div>
      <el-radio-group v-if="item.type == 0" v-model="item.select" @input="$forceUpdate()">
        <div v-for="(opt, i) in item.opt" :key="i" class="option">
          <el-radio :label="String(i)" :disabled="true"><span class="value">{{ opt.opt }}</span></el-radio>
        </div>
      </el-radio-group>
      <el-checkbox-group v-else v-model="item.answer" @change="$forceUpdate()">
        <div v-for="(opt, i) in item.opt" :key="i" class="option">
          <el-checkbox  :disabled="true" :label="String(i)"><span class="mult-value">{{ opt.opt }}</span></el-checkbox>
        </div>
      </el-checkbox-group>
    </div>
    <div class="score">总得分：<span>{{ this.score}}</span></div>
    <div slot="footer">
      <el-button @click="confirm" :loading="isWorking" type="primary"> 导出问卷</el-button>
      <el-button @click="visible=false">返回</el-button>
    </div>
  </GlobalQuestionWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalQuestionWindow from '@/components/common/GlobalQuestionWindow'
import { exportAnswerExcel, questionnaireAnswerDetail} from '@/api/business/questionnaire'
export default {
  name: 'QuestionAnswer',
  extends: BaseOpera,
  components: { GlobalQuestionWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: ''
      },
      isWorking: false,
      problemList: [],
      title: '问卷',
      score: 0
    }
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      this.title = title || '问卷'
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.getQuestion()
      })
    },
    getQuestion () {
      questionnaireAnswerDetail(this.form.id)
        .then(res => {
          this.form = res
          this.score = res.score
          this.problemList = JSON.parse(res.content)
          if (this.problemList != null && this.problemList.length > 0) {
            for (let i = 0; i < this.problemList.length; i++) {
              const select = this.problemList[i].select
              if (select != null && select !== '') {
                this.problemList[i].answer = select.split(',')
              } else {
                this.problemList[i].answer = []
              }
            }
          }
          this.title = res.title
          console.log(this.problemList)
        })
    },
    confirm () {
      console.log(this.form)
      this.$dialog.exportConfirm('确认导出吗？')
        .then(() => {
          this.isWorking = true
          exportAnswerExcel({ id: this.form.id })
            .then(response => {
              this.download(response)
            })
            .catch(e => {
              console.log(e)
              this.$message.error('数据下载失败！')
            })
            .finally(() => {
              this.isWorking = false
            })
        })
        .catch(() => {
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  height: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  line-height: 20px;
  text-align: center;
}
.line {
  margin: 24px 0;
  background-color: #DFE2E8;
  height: 1px;
}
.problem {
  margin-bottom: 30px;
  .problem-title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 24px;
  }
}
.option {
  margin-top: 25px;
  display: flex;
  .el-radio {
    margin-right: 0px;
  }
  ::v-deep .el-radio__label {
    width: 0;
  }
  .value{
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    vertical-align: middle;
  }
  .mult-value{
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    margin-left: 10px;
  }
}
::v-deep .el-radio__input.is-disabled .el-radio__inner {
  background-color: #fff !important;
}
::v-deep .el-radio__input.is-disabled.is-checked {
  .el-radio__inner {
    
    border-color: #216EEE !important;
    background-color: #216EEE !important;
  }
}
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: #fff !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked {
  .el-checkbox__inner {
    border-color: #216EEE !important;
    background: #216EEE !important;
    // &&::after {
    //   border-color: #fff;
    // }
  }
}
.score {
  font-size: 16px;
  font-weight: 600;
  color: #222222;
  line-height: 16px;
  span {
    color: #216EEE;
  }
}
</style>
