<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" label-suffix="：" :rules="rules" inline>
      <div>
        <el-form-item label="专家" prop="expertId">
          <el-select v-model="form.expertId" filterable placeholder="请选择专家">
            <el-option
              v-for="item in this.exportList"
              :key="item.id"
              :value="item.id"
              :label="item.username"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { assignExperts } from '@/api/business/declareExpert'
import { fetchList } from '@/api/business/account'
export default {
  name: 'OperaDistributeExportWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        declareId: null,
        expertId: null
      },
      exportList: [],
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/areas',
      'field.id': 'id'
    })
    
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        this.form.declareId = target.id
        this.form.expertId = target.expertId
        this.getExportList()
      })
    },
    getExportList() {
      // debugger
      fetchList({
        capacity: 9999,
        model: { type: '6' }
      })
        .then(res => {
          this.exportList = res.records
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
    confirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        console.log(this.form);
        // 调用新建接口
        this.isWorking = true
        assignExperts(this.form)
          .then(() => {
            this.visible = false
            this.$message.success('新建成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$message.error(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
::v-deep .el-select {
    width: 100%;
    .el-input__inner {
      width: 100%;
    }
  }
</style>
