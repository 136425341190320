<template>
  <el-dialog
    class="global-window"
    title="title"
    :visible="visible"
    :with-header="true"
    :width="width"
    :close-on-press-escape="false"
    :wrapper-closable="false"
    :append-to-body="true"
    @close="close"
  >
    <div slot="title" class="window__header">
      {{title}}
    </div>
    <div class="window__body">
      <slot></slot>
    </div>
    <div v-if="withFooter" class="window__footer">
      <slot name="footer">
        <el-button @click="confirm" :loading="confirmWorking" type="primary">确定</el-button>
        <el-button @click="close">返回</el-button>
      </slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'GlobalAlertWindow',
  props: {
    width: {
      type: String,
      default: '50%'
    },
    // 是否包含底部操作
    withFooter: {
      type: Boolean,
      default: true
    },
    // 确认按钮loading状态
    confirmWorking: {
      type: Boolean,
      default: false
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 是否展示
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    confirm () {
      // console.log(this.title);
      // debugger
      this.$emit('confirm')
    },
    close () {
      // console.log(this.title);
      // debugger
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 输入框高度
$input-height: 32px;
::v-deep .el-dialog__header {
  background-color: #f7f7f7;
  font-weight: 500;
  color: #222;
}
::v-deep .el-dialog__body {
  padding: 20px 20px 0;
  box-sizing: border-box;
}

.global-window {
  // top: 80px !important;
  // left: 218px !important;
  width: 100%;
  box-sizing: border-box;
  // 头部标题
  ::v-deep .el-dialog__header {
    padding: 19px 24px;
    height: 56px;
    line-height: 18px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    // text-align: center;
    .el-dialog__headerbtn {
      width: 18px;
      height: 18px;
      .el-dialog__close {
        width: 18px;
        height: 18px;
        font-size: 19px;
        line-height: 18px;
      }
    }
  }
  // 主体
  .window__body {
    min-height: 200px;
  }
  .window__footer {
      user-select: none;
      border-top: 1px solid #eee;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }
}
</style>
