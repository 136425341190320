<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    v-loading="isUploading"
    element-loading-text="文件上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    @confirm="submit"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="125px"
      label-suffix="："
      inline
    >
      <div>
        <el-form-item
          label="评分"
          prop="expertScore"
        >
          <el-input
            placeholder="请输入评分"
            v-model="form.expertScore"
          />
        </el-form-item>
      </div>
      <div>
        <el-form-item label="评分报告上传" prop="multiFileDTOs">
          <div v-if="form.multiFileDTOs.length" class="data-list">
            <FileLink
              :links="form.multiFileDTOs"
              linkName="url"
            />
            <!-- <div v-for="(item, index) in form.multiFileDTOs" :key="index">
              <FileLink
                :link="item.url"
                :name="item.name"
                @deleteAction="deleteFile(index)"
              />
            </div> -->
          </div>
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upFile.click()">上传资料</el-button>
          </div>
        </el-form-item>
        <input type="file" @change="upFiles" ref="upFile" style="display: none;" />
      </div>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { checkExpertDeclares } from '@/api/business/declares'
import { upload } from '@/api/system/common'
export default {
  name: 'GiveMarkWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      isUploading: false,
      // 表单数据
      form: {
        multiFileDTOs: [],
        expertScore: '',
        declareId: '',
      },
      // 验证规则
      rules: {
        expertScore: [
          { required: true, message: '请输入评分', trigger: 'blur' }
        ],
        multiFileDTOs: [
        { required: true, message: '请先上传评分报告上传', trigger: 'blur' }
        ]
      },
      approveResults: [
        // 2审核通过、3退回修改、4审核驳回
        { label:'审核通过', value: '2' },
        { label:'退回修改', value: '3' },
        { label:'审核驳回', value: '4' }
      ]
    }
  },
  created () {
    
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
        this.form.declareId = target.declareId
      })
    },
    upFiles(file) {
      const formdate = new FormData()
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      this.isUploading = true
      upload(formdate)
        .then(res => {
          this.form.multiFileDTOs.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
          console.log(this.form);
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upFile.value = null
    },
    submit() {
      this.$refs.form.validate(
        v=>{
          if (!v) {
            return
          }
          this.isWorking = true
          checkExpertDeclares({
            ...this.form
          })
            .then( res => {
              console.log(res)
              this.$emit('success')
            })
            .catch (err => {
              this.$message.error(err.message)
            })
            .finally(() => {
              this.isWorking = false
              this.visible = false
            })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
.data-list {
  margin-top: 3px;
  margin-bottom: 20px;
}
</style>
