<template>
  <GlobalWindow
    :title="title"
    :confirm-working="false"
    :visible.sync="visible"
    @confirm="confirm"
  >
    <TableLayout :permissions="['business:declares:query']">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="120px" label-suffix="：" inline>
        <el-form-item label="企业名称" prop="companyName">
          <el-input v-model="searchForm.companyName" placeholder="请输入企业名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
<!--        <el-form-item label="所属区域" prop="cityId">
          <div style="display:flex">
            <el-select v-model="searchForm.provinceId" placeholder="请选择省份" @change="selectProvinceNew">
              <el-option
                v-for="item in province"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line" style="width: 20px"></div>
            <el-select v-model="searchForm.cityId" placeholder="请选择城市">
              <el-option
                v-for="item in cities"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>-->
        <el-form-item label="流程状态" prop="status">
          <el-select v-model="searchForm.status" placeholder="请选择流程状态">
            <el-option
              v-for="item in declareStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="服务机构名称" prop="soId"  >
          <el-select v-model="searchForm.soId" filterable placeholder="请选择服务机构">
            <el-option
                v-for="item in this.serviceList"
                :key="item.companyId"
                :value="item.companyId"
                :label="item.companyName"
            ></el-option>
          </el-select>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
          <el-button type="primary" :loading="isWorking.export" v-permissions="['business:declares:exportExcel']" @click="exportExcel">导出</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          border
        >
          <el-table-column prop="companyName" label="企业名称" fixed="left" min-width="140px" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <el-button type="text" @click="showCompany(row)">{{ row.companyName }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="projectName" label="项目名称" min-width="140px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cityName" label="所属区域" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="dascore" label="问卷分数" min-width="100px"></el-table-column>
          <el-table-column prop="serviceName" label="服务机构" min-width="100px"></el-table-column>
          <el-table-column prop="servicePrice" label="服务单价（元）" min-width="100px"></el-table-column>
          <el-table-column prop="realZJName" label="专家名称" min-width="100px"></el-table-column>
          <el-table-column prop="expertScore" label="专家评分" min-width="100px"></el-table-column>
          <el-table-column prop="servicePrice2" label="服务价格" min-width="100px"></el-table-column>
          <el-table-column prop="realName" label="分配子账号" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="status" label="状态 " min-width="120px">
            <template slot-scope="{ row }">
              {{ statusToStr(row.status) }}
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        >
        </pagination>
      </template>
    </TableLayout>
    <AreaProjectApplyDetail ref="projectApplyDetail" @success="handlePageChange" />
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import AreaProjectApplyDetail from './comprehensiveServe/AreaProjectApplyDetail'
import { getPageByProjectId as fetchList, exportExcel } from '@/api/business/declares'
import { treeList } from '@/api/business/areas'
import { projectDetail } from '@/api/business/project'
import {fetchList as projectSo} from "@/api/business/projectSo";
export default {
  name: 'OperaProjectWindow',
  extends: BaseOpera,
  components: { GlobalWindow, TableLayout, Pagination, AreaProjectApplyDetail },
  data () {
    return {
      // 表单数据
      searchForm: {
        projectId: null,
        companyName: '',
        provinceId: '',
        cityId: '',
        serviceName: '',
        status: '',
        so_id: ''
      },
      serviceList: [],
      // 是否正在执行
      isWorking: {
        // 搜索中
        search: false,
        // 删除中
        delete: false,
        // 导出中
        export: false
      },
      declareStatus: [
        // 0已保存、1待审核、2审核通过、3退回修改、4审核驳回、5待服务机构确认、
        // 6服务机构拒绝、7已分配服务机构、8诊断中（成功上传第一份服务资料）、
        // 9服务完成、10已分配评分专家、11已完成
        { label: '已保存', value: 0 },
        { label: '待审核', value: 1 },
        { label: '审核通过', value: 2 },
        { label: '服务退回', value: 3 },
        { label: '审核驳回', value: 4 },
        { label: '待服务机构确认', value: 5 },
        { label: '服务机构拒绝', value: 6 },
        { label: '已分配服务机构', value: 7 },
        { label: '诊断中', value: 8 },
        { label: '服务完成', value: 9 },
        { label: '已分配评分专家', value: 10 },
        { label: '已完成', value: 11 },
        { label: '服务退回', value: 12 },
        { label: '退回修改完成', value: 13 },
      ],
      // 表格数据
      tableData: {
        // 已选中的数据
        selectedRows: [],
        // 排序的字段
        sorts: [],
        // 当前页数据
        list: [],
        // 分页
        pagination: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        }
      },
      project: {
        startDate: '',
        endDate: ''
      },
      province: [],
      cities: []
    }
  },
  provide() {
    return {
      project: () => this.project
    }
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      // debugger
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.searchForm) {
          this.searchForm[key] = target[key]
        }
        projectDetail(target.projectId)
          .then(res => {
            // debugger
            this.project = {
              ...res,
              startDate: res.startDate.substring(0,res.startDate.length-3),
              endDate: res.endDate.substring(0,res.endDate.length-3),
            }
          })
        this.search()
        this.getServiceList()
      })
    },
    getServiceList () {
      projectSo({
        capacity: 9999,
        model: { projectId: this.searchForm.projectId || '0', type: 0 }
      }).then(res => {
        this.serviceList = res.records
      }).catch(e => {
        this.$message.error(e)
      })
    },
    statusToStr(status) {
      const temp = this.declareStatus.find(item => item.value === status)
      if (temp) {
        return temp.label
      }
      return '-'
    },
    showCompany (row) {
      // debugger
      this.$refs.projectApplyDetail.open('报名详情', row, this.project)
    },
    // 选择省份
    selectProvinceNew (val) {
      this.cities = []
      this.searchForm.cityId = ''
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
        })
    },
    // 搜索
    search () {
      this.handlePageChange(1)
    },
    // 导出Excel
    exportExcel () {
      this.$dialog.exportConfirm('确认导出吗？')
        .then(() => {
          this.isWorking.export = true
          exportExcel({
            page: this.tableData.pagination.pageIndex,
            capacity: 1000000,
            model: this.searchForm,
            sorts: this.tableData.sorts
          })
            .then(response => {
              this.download(response)
            })
            .catch(e => {
              this.$message.error(e)
            })
            .finally(() => {
              this.isWorking.export = false
            })
        })
        .catch(() => {})
    },
    // 搜索框重置
    reset () {
      this.$refs.searchForm.resetFields()
      this.searchForm.province = ''
      this.search()
    },
    // 每页显示数量变更处理
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.search()
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm,
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    confirm() {
      this.visible = false
    }
  }
}
</script>
