<template>
  <div class="contant">
    <CommonHeader :isLogined="false" />
    <div class="login-content">
      <div class="wrap">
        <div class="select-title">请选择注册账号类型</div>
        <div class="rigister-type">
          <div v-for="(item, index) in registerType" :key="index" class="register-item" :class="selectedType==index && 'type-selected'" @click="selectedType=index">
            <img :src="item.img" alt="">
            <div class="item-content">
              <div class="item-title">{{ item.title }}</div>
              <div class="item-sub-title">{{ item.subTitle }}</div>
            </div>
            <img v-if="selectedType==index" class="selected" src="@/assets/images/zhuce_ic_select@2x.png" alt="">
          </div>
        </div>
        <div class="loginBtn"><el-button @click="register">立即注册</el-button></div>
      </div>
      <div class="copyright">Copyright © 2022 - 2023 智能制造诊断综合服务平台. All Rights Reserved.  版权所有</div>
      <!-- <div class="copyright">Copyright © 2022 - 2023 合肥市智改数转诊断服务平台. All Rights Reserved.  版权所有</div> -->
    </div>
  </div>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
export default {
  name: 'Login',
  components: {
    CommonHeader
  },
  data () {
    return {
      registerType: [
        { img: require('@/assets/images/zhuce_ic_qiye@2x.png'), title: '企业入驻', subTitle: '需要准备企业营业执照、法人信息', type: '1', pathName: 'register'},
        { img: require('@/assets/images/zhuce_ic_fuwujigou@2x.png'), title: '服务机构入驻', subTitle: '需要准备企业营业执照、法人信息', type: '2', pathName: 'serviceRegister'},
        { img: require('@/assets/images/zhuce_ic_zonghefuwu@2x.png'), title: '综合服务单位入驻', subTitle: '需要准备企业营业执照、法人信息', type: '3', pathName: 'serviceRegister'},
        { img: require('@/assets/images/zhuce_ic_zj@2x.png'), title: '专家入驻', subTitle: '需要准备个人身份证信息', type: '4', pathName: 'exportRegister'},
      ],
      selectedType: 0
    }
  },
  methods: {
    register() {
      let temp = this.registerType[this.selectedType]
      // console.log(temp);
      this.$router.push({ name: temp.pathName, params:{ type: temp.type } })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
$input-gap: 30px;
.contant {
  height: 100%;
  background-color: #F3F7FA;
  
}
.login-content {
  height: calc(100% - 70px);
  background-image: url("../assets/images/login_bg.png");
  background-repeat: no-repeat;
  background-clip: content-box;
  background-position: center;
  position: relative;
}
.wrap {
  box-sizing: border-box;
  padding: 80px 30px;
  width: 1000px;
  height: 568px;
  background: #FFFFFF;
  box-shadow: 0px 20px 60px 0px rgba(0,0,0,0.11);
  border-radius: 8px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .select-title {
    text-align: center;
    height: 26px;
    font-size: 26px;
    font-weight: 600;
    color: #222222;
    line-height: 26px;
  }
  .rigister-type {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    height: 240px;
    justify-content: space-between;
    .register-item {
      box-sizing: border-box;
      padding: 22px;
      display: flex;
      width: 460px;
      height: 110px;
      background: #F4F7FC;
      border-radius: 2px;
      border: 1px solid rgba(33,110,238,0.12);
      margin-bottom: 20px;
      .item-content {
        margin-top: 8px;
        margin-left: 12px;
        flex: 1;
        .item-title {
          height: 18px;
          font-size: 18px;
          font-weight: 600;
          color: #222222;
          line-height: 18px;
        }
        .item-sub-title {
          margin-top: 15px;
          height: 18px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
        }
      }
      .selected {
        width: 24px;
        height: 24px;
        margin: auto 0;
        background: #216EEE;
      }
    }
    .type-selected {
      background: #216EEE;
      .item-title {
        color: #fff !important;
      }
      .item-sub-title {
        color: #fff !important;
      }
      
    }
  }
}
.loginBtn {
  margin: 52px auto 0;
  width: 400px;
  .el-button {
    height: 35px;
    width: 100%;
    color: #fff;
    font-size: 12px;
    background: linear-gradient(130deg, $primary-title-start-color 0%, $primary-title-color 100%);
  }
}
.copyright {
  text-align: center;
  width: 100%;
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #828D9E;
  line-height: 12px;
  position: absolute;
  bottom: 20px;
}
.login-title {
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 14px;

}
.register {
  margin-top: 18px;
  height: 14px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // color: #DFE2E8;
  color: #333;
  // font-weight: 600;
  line-height: 14px;
  
}
</style>
