<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" label-width="100px" label-suffix="：" :rules="rules">
      <el-form-item :label="form.type==0?'市名称':'县区名称'" prop="name">
        <el-input v-model="form.name" :placeholder="form.type==0?'输入市名称':'输入县区名称'" v-trim/>
      </el-form-item>
      <el-form-item label="排序码" prop="sortnum">
        <el-input v-model="form.sortnum" placeholder="请输入排序码" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
export default {
  name: 'OperaAreasWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        parentId: null,
        name: null,
        sortnum: '0',
        type: '',
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/areas',
      'field.id': 'id'
    })
  },
  methods: {
    // 确认新建
    __confirmCreate () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        this.api.create(this.form)
          .then(() => {
            this.visible = false
            this.$message.success('新建成功')
            this.$emit('success', this.form.parentId)
          })
          .catch(e => {
            this.$message.error(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    },
    // 确认修改
    __confirmEdit () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        this.api.updateById(this.form)
          .then(() => {
            this.visible = false
            this.$message.success('修改成功')
            this.$emit('success', this.form.parentId)
          })
          .catch(e => {
            this.$message.error(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    }
  },
}
</script>
