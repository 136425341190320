<template>
  <TableLayout :permissions="['business:projectso:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="主键" prop="id">
        <el-input v-model="searchForm.id" placeholder="请输入主键" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="是否已删除 0未删除 1已删除" prop="deleted">
        <el-input v-model="searchForm.deleted" placeholder="请输入是否已删除 0未删除 1已删除" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建人编码" prop="creator">
        <el-input v-model="searchForm.creator" placeholder="请输入创建人编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="searchForm.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建时间" @change="search"/>
      </el-form-item>
      <el-form-item label="更新人编码" prop="editor">
        <el-input v-model="searchForm.editor" placeholder="请输入更新人编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="editDate">
        <el-date-picker v-model="searchForm.editDate" value-format="yyyy-MM-dd" placeholder="请输入更新时间" @change="search"/>
      </el-form-item>
      <el-form-item label="是否删除0否 1是" prop="isdeleted">
        <el-input v-model="searchForm.isdeleted" placeholder="请输入是否删除0否 1是" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="searchForm.remark" placeholder="请输入备注" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="服务机构编码（关联company表)" prop="companyId">
        <el-input v-model="searchForm.companyId" placeholder="请输入服务机构编码（关联company表)" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="项目编码（关联project表)" prop="projectId">
        <el-input v-model="searchForm.projectId" placeholder="请输入项目编码（关联project表)" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="服务单价" prop="price">
        <el-input v-model="searchForm.price" placeholder="请输入服务单价" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="排序码" prop="sortnum">
        <el-input v-model="searchForm.sortnum" placeholder="请输入排序码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="primary" :loading="isWorking.export" v-permissions="['business:projectso:exportExcel']" @click="exportExcel">导出</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['business:projectso:create', 'business:projectso:delete']">
        <li><el-button type="primary" @click="$refs.operaProjectSoWindow.open('新建项目关联服务机构关联表')" icon="el-icon-plus" v-permissions="['business:projectso:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['business:projectso:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="deleted" label="是否已删除 0未删除 1已删除" min-width="100px"></el-table-column>
        <el-table-column prop="creator" label="创建人编码" min-width="100px"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" min-width="100px"></el-table-column>
        <el-table-column prop="editor" label="更新人编码" min-width="100px"></el-table-column>
        <el-table-column prop="editDate" label="更新时间" min-width="100px"></el-table-column>
        <el-table-column prop="isdeleted" label="是否删除0否 1是" min-width="100px"></el-table-column>
        <el-table-column prop="name" label="名称" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column>
        <el-table-column prop="companyId" label="服务机构编码（关联company表)" min-width="100px"></el-table-column>
        <el-table-column prop="projectId" label="项目编码（关联project表)" min-width="100px"></el-table-column>
        <el-table-column prop="price" label="服务单价" min-width="100px"></el-table-column>
        <el-table-column prop="sortnum" label="排序码" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['business:projectso:update', 'business:projectso:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaProjectSoWindow.open('编辑项目关联服务机构关联表', row)" icon="el-icon-edit" v-permissions="['business:projectso:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['business:projectso:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaProjectSoWindow ref="operaProjectSoWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaProjectSoWindow from '@/components/business/OperaProjectSoWindow'
export default {
  name: 'ProjectSo',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaProjectSoWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        deleted: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        name: '',
        remark: '',
        companyId: '',
        projectId: '',
        price: '',
        sortnum: '',
        type: 0
      }
    }
  },
  created () {
    this.config({
      module: '项目关联服务机构关联表',
      api: '/business/projectSo',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
