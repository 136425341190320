<template>
  <TableLayout :permissions="['business:project:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入项目名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="所属区域" prop="cityId">
        <div style="display:flex">
          <el-select v-model="searchForm.provinceId" placeholder="请选择省份" @change="selectProvince">
            <el-option
              v-for="item in province"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          <div class="line"></div>
          <el-select v-model="searchForm.cityId" placeholder="请选择城市">
            <el-option
              v-for="item in cities"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="searchForm.status" placeholder="请选择状态">
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="活动时间">
        <el-date-picker
          v-model="data"
          type="datetimerange"
          range-separator="至"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="selectDate">
        </el-date-picker>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['business:project:create']">
        <li><el-button type="primary" @click="createProject" v-permissions="['business:project:create']">新建</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        border
      >
        <el-table-column prop="name" label="项目名称" align="center" fixed="left" min-width="200px" show-overflow-tooltip>
          <template slot-scope="{row}">
            <el-button type="text" @click="showProjectBasic(row)">{{ row.name }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="cityName" label="所属区域" align="center" min-width="100px"></el-table-column>
        <el-table-column prop="startDate" label="报名开始时间" align="center" min-width="140px"></el-table-column>
        <el-table-column prop="endDate" label="报名结束时间" align="center" min-width="180px"></el-table-column>
        <el-table-column label="状态" align="center" min-width="80px">
          <template slot-scope="{row}">
            {{ row.status == 0 ? '未开始' : row.status == 1 ? '进行中' : '已结束' }}
          </template>
        </el-table-column>
        <el-table-column prop="sortnum" label="排序码" align="center" min-width="80px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['business:project:update', 'business:project:delete'])"
          label="操作"
          min-width="100"
          fixed="right"
        >
          <template slot-scope="{row}">
            <template v-if="row.status != 2">
              <el-button type="text" @click="$refs.operaProjectWindow.open('编辑', row)" v-permissions="['business:project:update']">编辑</el-button>
              <el-button type="text" v-if="row.status==0" @click="deleteById(row)" v-permissions="['business:project:delete']">删除</el-button>
            </template>
            <template v-else>-</template>
            
          </template>
        </el-table-column>
        <!-- v-if="containPermissions(['business:project:update', 'business:project:delete'])" -->
        <el-table-column
          label="配置项目"
          min-width="300"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="serveConfig(row)">关联服务机构</el-button>
            <el-button type="text" @click="comprehrnConfig(row)">关联综合服务单位</el-button>
            <el-button type="text" @click="scoreSetting(row)">配置评分系数</el-button>
            <el-button type="text" @click="scorRole(row)">角色评分系数</el-button>
            <el-button type="text" @click="diagnosisSetting(row)">诊断详情配置</el-button>
            <el-button type="text" @click="$refs.operaProjectLabelListWindow.open('项目诊断类型', row)">项目诊断类型</el-button>
            <el-button type="text" v-if="row.questionnaireId" @click="showQuestion(row.questionnaireId)">查看问卷</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <OperaProjectWindow ref="operaProjectWindow" @success="handlePageChange" />
    <OperaSystemProjectDetailWindow ref="operaSystemProjectDetailWindow" @showQuestion="showQuestion" @success="handlePageChange" />
    <OperaAboutServeWindow ref="operaAboutServeWindow" @success="handlePageChange" />
    <OperaProjectSocreWindow ref="operaProjectSocreWindow" @success="handlePageChange" />
    <OperaProjectComprehenWindow ref="operaProjectComprehenWindow" @success="handlePageChange" />
    <OperaProjectDiagnoseListWindow ref="operaProjectDiagnoseListWindow" @success="handlePageChange" />
    <OperaProjectLabelListWindow ref="operaProjectLabelListWindow" @success="handlePageChange" />
    <OperaProjectRoleSorcelListWindow ref="operaProjectRoleSorcelListWindow" @success="handlePageChange" />
    <Question ref="question" />
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaProjectWindow from '@/components/business/OperaProjectWindow'
import OperaSystemProjectDetailWindow from '@/components/business/OperaSystemProjectDetailWindow'
import OperaAboutServeWindow from '@/components/business/OperaAboutServeWindow'
import OperaProjectSocreWindow from '@/components/business/OperaProjectSocreWindow'
import OperaProjectComprehenWindow from '@/components/business/OperaProjectComprehenWindow'
import OperaProjectDiagnoseListWindow from '@/components/business/OperaProjectDiagnoseListWindow'
import OperaProjectLabelListWindow from '@/components/business/OperaProjectLabelListWindow'
import OperaProjectRoleSorcelListWindow from '@/components/business/OperaProjectRoleSorcelListWindow'

import { projectDetail } from "@/api/business/project"
import Question from '@/components/business/Question'
import { treeList } from '@/api/business/areas'
export default {
  name: 'Project',
  extends: BaseTable,
  components: {
    TableLayout,
    Pagination,
    OperaProjectWindow,
    OperaSystemProjectDetailWindow,
    OperaAboutServeWindow,
    OperaProjectSocreWindow,
    OperaProjectComprehenWindow,
    OperaProjectDiagnoseListWindow,
    OperaProjectLabelListWindow,
    OperaProjectRoleSorcelListWindow,
    Question
  },
  data () {
    return {
      activeName: 'first',
      // 搜索
      searchForm: {
        status: '',
        name: '',
        endDate: '',
        startDate: '',
        provinceId: '',
        cityId: '',
      },
      data: [],
      status: [
        // 0未开始 1进行中 2已结束,
        { label: '未开始', value: 0 },
        { label: '进行中', value: 1 },
        { label: '已结束', value: 2 }
      ],
      province: [],
      cities: []
    }
  },
  provide() {
    return {
      province: () => this.province
    }
  },
  created () {
    this.config({
      module: '项目信息表',
      api: '/business/project',
      'field.id': 'id',
      'field.main': 'id'
    })
    // this.tableData.pagination = {
    //   pageIndex: 1,
    //   pageSize: 9,
    //   pageSizes: [9, 18, 27, 36],
    //   total: 0
    // }
    this.search()
     // 地区
    treeList({ type: 0 })
      .then(res => {
        // console.log(res)
        // this.province = res
        res.forEach(item => {
          if (this.province.findIndex(p => p.id === item.id) === -1) {
            this.province.push(item)
          }
        })
      })
      .catch(e => {
        this.$message.error(e)
      })
  },
  methods: {
    // 搜索框重置
    reset () {
      this.$refs.searchForm.resetFields()
      this.searchForm.provinceId = ''
      this.searchForm.endDate = ''
      this.searchForm.startDate = ''
      this.data = []
      this.search()
    },
    selectDate(v) {
      this.searchForm.endDate = v[1]
      this.searchForm.startDate = v[0]
    },
    // 选择省份
    selectProvince(val) {
      this.cities = []
      this.searchForm.cityId = ''
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
          
        })
      // const tempProvince = this.province.find(item => item.id === val)
      // if (tempProvince) {
      //   this.cities = tempProvince.childList
      // }
    },
    createProject() {
      console.log(this.$refs.operaProjectWindow);
      this.$refs.operaProjectWindow.open('新建项目')
    },
    showProjectBasic(row) {
      this.$refs.operaSystemProjectDetailWindow.open('项目基础信息', row)
    },
    // 查看问卷
    showQuestion(questionId) {
      if(!questionId){
        this.$message.error('对不起，该项目尚未设置问卷信息！')
        return
      }
      this.$refs.question.open('', { id: questionId }, 0)
    },
    // 关联服务机构
    serveConfig(row) {
      this.$refs.operaAboutServeWindow.open('关联服务机构', row)
    },
    // 关联综合服务单位
    comprehrnConfig(row) {
      this.$refs.operaProjectComprehenWindow.open('关联综合服务单位', row)
    },
    //配 置评分系数
    scoreSetting(row) {
      this.$refs.operaProjectSocreWindow.open('配置评分系数', row)
    },
    //角色评分配置
    scorRole(row) {
      this.$refs.operaProjectRoleSorcelListWindow.open('角色评分配置', row)
    },
    // 诊断详情配置
    diagnosisSetting(row) {
      this.$refs.operaProjectDiagnoseListWindow.open('诊断详情配置', row)
    }
    
  }
}

</script>

<style lang="scss" scoped>
.project {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.project-item {
  width: calc(33% - 1px);
  box-sizing: border-box;
  padding: 10px;
}
.empty {
  width: 100%;
  height: 400px;
  position: relative;
  img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 268px;
    height: 268px;
  }
}
.line {
  width: 10px;
}
</style>
