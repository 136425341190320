<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
  >
    <div class="service">
      <el-descriptions size="medium" labelClassName="title-style" :column="2">
        <el-descriptions-item label="企业名称">{{ companyMessag.name }}</el-descriptions-item>
        <el-descriptions-item label="统一社会信用代码">{{ companyMessag.creditCode }}</el-descriptions-item>
        <el-descriptions-item label="成立时间">{{ companyMessag.registerDate }}</el-descriptions-item>
        <el-descriptions-item label="所属区域">{{ companyMessag.belongAreaName }}</el-descriptions-item>
        <el-descriptions-item label="单位类别" :span="2">{{ companyMessag.labelName }}</el-descriptions-item>
        <el-descriptions-item label="单位地址" :span="2">{{ companyMessag.address }}</el-descriptions-item>
        <el-descriptions-item label="联系人">{{ companyMessag.linkName }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{ companyMessag.linkPhone }}</el-descriptions-item>
        <el-descriptions-item label="企业(单位)简介" :span="2">{{ companyMessag.content }}</el-descriptions-item>
        <el-descriptions-item label="营业执照/事业单位、社会团体登记证" :span="2">
          <el-image
            style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
            v-for="(item, index) in companyMessag.businessList"
            :key="index"
            :initial-index="index"
            :src="item.fileurlfull"
            :preview-src-list="companyMessag.businessList.map(item => item.fileurlfull)"
            :z-index="3000"
          ></el-image>
        </el-descriptions-item>
        <el-descriptions-item label="企业资质" :span="2">
          <el-image
            style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
            v-for="(item, index) in companyMessag.zzFileList"
            :key="index"
            :initial-index="index"
            :src="item.fileurlfull"
            :preview-src-list="companyMessag.zzFileList.map(item => item.fileurlfull)"
            :z-index="3000"
          ></el-image>
        </el-descriptions-item>
        <el-descriptions-item label="中标通知" :span="2">
          <el-image
            style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
            v-for="(item, index) in companyMessag.noticeFileList"
            :key="index"
            :initial-index="index"
            :src="item.fileurlfull"
            :preview-src-list="companyMessag.noticeFileList.map(item => item.fileurlfull)"
            :z-index="3000"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div slot="footer">
      <el-button @click="visible=false">返回</el-button>
    </div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { queryCompanyDetail } from '@/api/business/company'

export default {
  name: 'OperaCompanyChangeIngWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      form: {
        companyId: ''
      },
      companyMessag: {},
    }
  },
  created () {
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.initData()
      })
    },
    closeThis(){
      this.visible =false
    },
    initData() {
      queryCompanyDetail(this.form.companyId)
        .then(res => {
          this.companyMessag = res
          let registerDate = this.companyMessag.registerDate
          this.companyMessag.registerDate = registerDate.substring(0, registerDate.length-8)
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.company-message {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .sub-company-message {
    width: calc(50% - 6px);
    margin-top: 20px;
    .message-title {
      height: 14px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 14px;
    }
    .after {
      color: #216EEE;
    }
    .message-content {
      border-radius: 2px;
      border: 1px solid #DFE2E8;
      margin-top: 16px;
      padding: 20px;
      ::v-deep .el-form-item {
        margin-bottom: 1px;
      }
    }
  }
}
.long-title-style {
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  overflow: hidden;
}
img {
  width: 90px;
  height: 90px;
}
.tip-style {
  background: rgba(33,110,238,0.1);
  color: #216EEE;
  display: flex;
  height: 44px;
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 44px;
  padding-left: 20px;
  img {
    width: 16px;
    height: 16px;
    margin: auto 8px auto 0;
  }
  margin-bottom: 20px;
}

.fail-style {
  background: rgba(238,56,33,0.1);
  color: #EE3821;
}
.success-style {
  background: rgba(0,186,126,0.1);
  color: #00BA7E;
}

</style>

