import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/declareScore/page', data)
}
// 查询
export function findByDeclareId (declareId) {
  // debugger
  return request.get('/business/declareScore/findByDeclareId', { params: { declareId }})
}

// 新建
export function create (data) {
  return request.post('/business/declareScore/createBatch', data)
}
// 新建
export function updateById (data) {
  return request.post('/business/declareScore/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/business/declareScore/delete/${id}`)
}

