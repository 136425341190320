
<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <div class="serviceList">
      <div
        v-for="(item, index) in serviceList"
        :key="index"
      >
        <div class="arrow" :class="index==tempService&&'arrow-select'" @click="selectServe(item,index)">
          <div v-if="index" class="arrow-foot"></div>
          <div class="arrow-body">{{ index==0 ? '首次服务数据' : index==serviceList.length-1 ? '完成服务' : `第${index+1}次服务数据` }}</div>
          <div v-if="index != serviceList.length-1" class="arrow-header"></div>
        </div>
      </div>
    </div>
    1
    <el-form v-if="tempService == serviceList.length-1" ref="completeForm" :model="tempServiceObj" label-width="130px" label-suffix="：" inline>
      <div>
        <el-form-item label="服务时间" prop="diagnoseDate">
          {{ tempServiceObj.diagnoseDate }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="企业评分" prop="diagnoseScore">
          {{ tempServiceObj.diagnoseScore }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="企业评分表" prop="fileScoreList">
          <div v-for="(item, index) in tempServiceObj.fileScoreList" :key="index" class="data-item">
            {{ item }}
            <div class="like-link" @click="showFile(item.url)">{{ item.name }}</div>
            <i class="el-icon-download" @click="deleteFileScore(item, index)"></i>
          </div>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="诊断报告" prop="fileDiagnosisList">
          
          <div v-for="(item, index) in tempServiceObj.fileDiagnosisList" :key="index" class="data-item">
            <div>{{ item.name }}</div>
            <i class="el-icon-download" @click="deleteFileDiagnosis(item, index)"></i>
          </div>
        </el-form-item>
      </div>
      <div class="rich-text">
        <el-form-item label="报告详情" prop="declareDiagnoseList">
          <div v-for="(item, index) in tempServiceObj.declareDiagnoseList" class="diagnose" style="margin-bottom: 10px; width: 100%;" :key="index">
            {{ `${item.name}` }}
            <div style="width:100px" v-html="item.content"></div>
            <!-- <quill-editor
              v-model="item.content"
              :options="editorOption"
            /> -->
          </div>
        </el-form-item>
      </div>
    </el-form>
    <el-form v-else ref="form2" :model="tempServiceObj" label-width="130px" label-suffix="：" inline>
      <div>
        <el-form-item label="服务时间" prop="createDate">
          {{ tempServiceObj.createDate }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="上传用户" prop="creator">
          {{ tempServiceObj.creator }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="服务说明" prop="content">
          {{ tempServiceObj.content }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="服务资料" prop="fileList">
          <div v-for="(item, index) in tempServiceObj.fileList" :key="index">
            <a :href="item.fileurl">{{ item.name }}</a>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="visible = false" type="primary">确定</el-button>
    </div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { fetchList, finishServeInfo } from '@/api/business/declareService'
import { fileType } from '../../../utils/util'
export default {
  name: 'ServeDetailData',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    
    return {
      declareId: null,
      serviceList: [],
      tempService: 0,
      tempServiceObj: {},
    }
  },
  created () {
    this.config({
      api: '/business/declareService',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      // debugger
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        this.declareId = target.id
        this.initServe()
      })
    },
    
    initServe() {
      Promise.all([
        fetchList({ capacity: 9999, model: { declareId: this.declareId, type: 0 }}),
        finishServeInfo({ declareId: this.declareId })
      ])
        .then(res => {
          this.serviceList = [...res[0].records, res[1]]
          this.tempService = this.serviceList.length - 1
          this.tempServiceObj = this.serviceList[this.tempService]
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
    selectServe(item, index) {
      this.tempServiceObj = item
      this.tempService = index
    },
    confirm() {
      this.visible = false
    }
  }
}
</script>


<style lang="scss" scoped>
.serviceList {
  display: flex;
  margin-bottom: 20px;
}
.action-style {
  display:flex;
  margin-left: 120px;
  margin-bottom: 20px;
}
.arrow {
  background-color: #DFE2E8;
  color: #333333;
  height: 40px;
  line-height: 40px;
  display: flex;
  cursor: pointer;
  // margin-left: -5px;
  // margin-right: -5px;
  .arrow-foot {
    // background-color: #fff;
    border-left: 20px solid rgba($color: #fff, $alpha: 1);
    border-top: 20px solid rgba($color: #fff, $alpha: 0);
    border-bottom: 20px solid rgba($color: #fff, $alpha: 0);
  }
  
  .arrow-body {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 16px;
  }
  .arrow-header {
    // background-color: #DFE2E8;
    border-left: 20px solid rgba($color: #fff, $alpha: 0);
      border-top: 20px solid rgba($color: #fff, $alpha: 1);
      border-bottom: 20px solid rgba($color: #fff, $alpha: 1);
  }
}
.arrow-select {
  background-color: #216EEE;
  color: #fff;
}

.data-list {
  margin-top: 20px;
}

.data-item {
    border-radius: 2px;
    padding: 10px 0;
    // background-color: #F5F6F8;
    height: 36px;
    display: flex;
    // padding: 11px 20px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #216EEE;
    line-height: 13px;
    margin-right: 16px;
    i {
      box-sizing: border-box;
      margin-left: 10px;
      width: 16px;
      height: 16px;
      // background-color: #BFC3CB;
      color: #216EEE;
      border-radius: 50%;
      // padding: 1.5px;
      cursor: pointer;
      &:hover {
        color: #ff0000;
      }
    }
  }

.diagnose {
  height: 200px;
  width: 850px;
  overflow: scroll;
  border: 1px solid #DFE2E8;
  
}
::v-deep .ql-container {
  height: 200px !important;
  width: 850px;
}
::v-deep .ql-toolbar {
  // height: 200px !important;
  width: 850px;
}
</style>

<style>
.rich-text img {
  width: 50% !important;
}
</style>
