<template>
  <TableLayout :permissions="['business:project:query']">
    12123
  </TableLayout>

</template>

<script>
import TableLayout from '@/layouts/TableLayout'
export default {
  components: { TableLayout }
}
</script>
