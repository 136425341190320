<template>
  <TableLayout :permissions="['business:project:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入项目名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="所属城市" prop="cityId">
        <div style="display:flex">
          <el-select v-model="searchForm.provinceId" placeholder="请选择省份" @change="selectProvinceNew">
            <el-option
                v-for="item in province"
                :key="item.id"
                :value="item.id"
                :label="item.name"
            ></el-option>
          </el-select>
          <div class="line" style="width: 20px"></div>
          <el-select v-model="searchForm.cityId" placeholder="请选择城市">
            <el-option
                v-for="item in cities"
                :key="item.id"
                :value="item.id"
                :label="item.name"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="searchForm.status" placeholder="请选择流程状态">
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <div v-if="tableData.list.length">
        <!-- {{ tableData.list }} -->
        <div class="project">
          <div v-for="item in tableData.list" :key="item.id" class="project-item">
            <ProjectItem
              :id="item.id"
              :canApply="false"
              :isApply="false"
              :projectNmae="item.name"
              :companyNum="item.serverCompanyNum"
              :status="item.status"
              :imgurlfull="item.imgurlfull"
              :applyDate="item.startDate.substring(0,item.startDate.length-3)+'~'+item.endDate.substring(0,item.endDate.length-3)"
              :declareStatus="item.declareStatus"
              :city="item.cityName"
              @showCompany="showCompany"
              @projectDetail="showCompany"
            />
          </div>
        </div>

        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </div>
      <div v-else class="empty">
        <img src="@/assets/images/default_nodata@2x.png" alt="">
      </div>
    </template>
    <!-- 新建/修改 -->
    <OperaProjectDetailWindow ref="operaProjectDetailWindow" @success="handlePageChange"/>
    <OperaCompanyListWindow ref="OperaCompanyListWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaProjectDetailWindow from '@/components/business/OperaProjectDetailWindow'
import OperaCompanyListWindow from '@/components/business/OperaCompanyListWindow'
import ProjectItem from '@/components/business/ProjectItem'
import { projectDetail } from '@/api/business/project'
import { treeList } from '@/api/business/areas'
export default {
  name: 'Project',
  extends: BaseTable,
  components: { TableLayout, Pagination, ProjectItem, OperaProjectDetailWindow, OperaCompanyListWindow },
  data () {
    return {
      activeName: 'first',
      // 搜索
      searchForm: {
        status: '',
        provinceId: '',
        cityId: '',
        name: ''
      },
      province: [],
      cities: [],
      status: [
        // 0未开始 1进行中 2已结束,
        { label: '未开始', value: 0 },
        { label: '进行中', value: 1 },
        { label: '已结束', value: 2 }
      ]
    }
  },
  created () {
    this.config({
      module: '项目信息表',
      api: '/business/project',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.tableData.pagination = {
      pageIndex: 1,
      pageSize: 9,
      pageSizes: [9, 18, 27, 36],
      total: 0
    }
    // 地区
    treeList({ type: 0 })
      .then(res => {
        this.province = res
      })
    this.search()
  },
  methods: {
    // 选择省份
    selectProvinceNew(val) {
      this.cities = []
      this.searchForm.cityId = ''
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
          
        })
      // const tempProvince = this.province.find(item => item.id === val)
      // if (tempProvince) {
      //   this.cities = tempProvince.childList
      // }
    },
    showCompany (id) {
      this.$refs.OperaCompanyListWindow.open('企业列表', { projectId: id })
    }
  }
}
</script>

<style lang="scss" scoped>
.project {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.project-item {
  width: calc(33% - 1px);
  box-sizing: border-box;
  padding: 10px;
}
.empty {
  width: 100%;
  height: 400px;
  position: relative;
  img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 268px;
    height: 268px;
  }
}
</style>
