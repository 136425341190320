<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="是否已删除 0未删除 1已删除" prop="deleted">
        <el-input v-model="form.deleted" placeholder="请输入是否已删除 0未删除 1已删除" v-trim/>
      </el-form-item>
      <el-form-item label="创建人编码" prop="creator">
        <el-input v-model="form.creator" placeholder="请输入创建人编码" v-trim/>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="form.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="更新人编码" prop="editor">
        <el-input v-model="form.editor" placeholder="请输入更新人编码" v-trim/>
      </el-form-item>
      <el-form-item label="更新时间" prop="editDate">
        <el-date-picker v-model="form.editDate" value-format="yyyy-MM-dd" placeholder="请输入更新时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="是否删除0否 1是" prop="isdeleted">
        <el-input v-model="form.isdeleted" placeholder="请输入是否删除0否 1是" v-trim/>
      </el-form-item>
      <el-form-item label="数据来源 0平台注册 1后台导入" prop="origin">
        <el-input v-model="form.origin" placeholder="请输入数据来源 0平台注册 1后台导入" v-trim/>
      </el-form-item>
      <el-form-item label="名称（不可重复）" prop="name">
        <el-input v-model="form.name" placeholder="请输入名称（不可重复）" v-trim/>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注" v-trim/>
      </el-form-item>
      <el-form-item label="法定代表人姓名" prop="legalPerson">
        <el-input v-model="form.legalPerson" placeholder="请输入法定代表人姓名" v-trim/>
      </el-form-item>
      <el-form-item label="法定代表人手机号码" prop="legalPersonPhone">
        <el-input v-model="form.legalPersonPhone" placeholder="请输入法定代表人手机号码" v-trim/>
      </el-form-item>
      <el-form-item label="成立时间" prop="registerDate">
        <el-input v-model="form.registerDate" placeholder="请输入成立时间" v-trim/>
      </el-form-item>
      <el-form-item label="所属区域编码（关联areas表）" prop="areaId">
        <el-input v-model="form.areaId" placeholder="请输入所属区域编码（关联areas表）" v-trim/>
      </el-form-item>
      <el-form-item label="所属城市编码" prop="cityId">
        <el-input v-model="form.cityId" placeholder="请输入所属城市编码" v-trim/>
      </el-form-item>
      <el-form-item label="主营产品" prop="product">
        <el-input v-model="form.product" placeholder="请输入主营产品" v-trim/>
      </el-form-item>
      <el-form-item label="单位地址" prop="address">
        <el-input v-model="form.address" placeholder="请输入单位地址" v-trim/>
      </el-form-item>
      <el-form-item label="统一社会信用代码，不可重复" prop="creditCode">
        <el-input v-model="form.creditCode" placeholder="请输入统一社会信用代码，不可重复" v-trim/>
      </el-form-item>
      <el-form-item label="联系人姓名" prop="linkName">
        <el-input v-model="form.linkName" placeholder="请输入联系人姓名" v-trim/>
      </el-form-item>
      <el-form-item label="联系人电话" prop="linkPhone">
        <el-input v-model="form.linkPhone" placeholder="请输入联系人电话" v-trim/>
      </el-form-item>
      <el-form-item label="企业性质编码（关联labels表）" prop="natureId">
        <el-input v-model="form.natureId" placeholder="请输入企业性质编码（关联labels表）" v-trim/>
      </el-form-item>
      <el-form-item label="企业行业编码（关联labels表）" prop="industryId">
        <el-input v-model="form.industryId" placeholder="请输入企业行业编码（关联labels表）" v-trim/>
      </el-form-item>
      <el-form-item label="企业简介" prop="content">
        <el-input v-model="form.content" placeholder="请输入企业简介" v-trim/>
      </el-form-item>
      <el-form-item label="企业类型0企业 1服务机构 2综合服务单位" prop="type">
        <el-input v-model="form.type" placeholder="请输入企业类型0企业 1服务机构 2综合服务单位" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCompanyWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        deleted: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        origin: '',
        name: '',
        remark: '',
        legalPerson: '',
        legalPersonPhone: '',
        registerDate: '',
        areaId: '',
        cityId: '',
        product: '',
        address: '',
        creditCode: '',
        linkName: '',
        linkPhone: '',
        natureId: '',
        industryId: '',
        content: '',
        type: ''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/company',
      'field.id': 'id'
    })
  }
}
</script>
