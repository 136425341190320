<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="130px" label-suffix="：" inline>
      <div>
        <el-form-item label="县区用户名称" prop="username">
          <el-input v-model="form.username" placeholder="请输入县区用户名称" v-trim/>
        </el-form-item>
      </div>
      <div >
        <el-form-item label="所属区域" prop="areaId">
          <el-select v-model="form.areaId" placeholder="请选择区域">
            <el-option
              v-for="item in areas()"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="姓名" prop="realname">
          <el-input v-model="form.realname" placeholder="请输入姓名" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号" v-trim/>
        </el-form-item>
      </div>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import { mapState } from 'vuex'
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { checkMobile, nameRule, creditCode } from '@/utils/form'
import { treeList } from '@/api/business/areas'
export default {
  name: 'OperaCompanyUserWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    const newRule = (rule, value, callback) => {
      if (!this.form.areaId) {
        callback(new Error('请先选择县区'))
      } else {
        callback()
      }
    }
    return {
      // 表单数据
      form: {
        id: null,
        realname: '',
        username: '',
        cityId: '',
        areaId: '',
        parentId: '',
        mobile: '',
        type: '7',
        password: '123456'
      },
      // 验证规则
      rules: {
        username: [
          { required: true, message: '请输入县区用户名称', trigger: 'blur' }
        ],
        realname: [
          { required: false, validator: nameRule, trigger: 'blur' }
        ],
        mobile: [
          { required: false, validator: checkMobile, trigger: 'blur' }
        ],
        areaId: [
          { required: true, validator: newRule, trigger: 'change' }
        ]
      },
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  inject: ['areas'],
  created () {
    this.config({
      api: '/business/account',
      'field.id': 'id'
    })
    this.form.parentId = this.userInfo.id
    this.form.cityId = this.userInfo.cityId
  },
  methods: {
    
    
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
</style>
