<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="submit"
  >
    <div class="company-message">
      <div class="sub-company-message">
        <div class="message-title">变更前：</div>
        <div class="message-content">
          <el-form
            label-width="120px"
            label-suffix="："
            inline
          >
            <div>
              <el-form-item :label="form.companyType==0?'企业名称':'企业(单位)名称'">
                <span class="long-title-style">{{ compnayMessag.name }}</span>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="社会信用代码">
                <span class="long-title-style">{{ compnayMessag.creditCode }}</span>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="所属区域">
                <span class="long-title-style">{{ compnayMessag.provinceName + compnayMessag.cityName + compnayMessag.areaName }}</span>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="法定代表人">{{ compnayMessag.legalPerson }}</el-form-item>
            </div>
            <div>
              <!-- <el-form-item label="营业执照/事业单位、社会团体登记证"> -->
              <el-form-item :label="form.companyType==0?'营业执照':'营业执照/事业单位、社会团体登记证'">
                <div>
                  <el-image
                    style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
                    v-for="(item, index) in compnayMessag.fileurls"
                    :key="index"
                    :initial-index="index"
                    :src="item"
                    :preview-src-list="compnayMessag.fileurls"
                    :z-index="3000"
                  ></el-image>
                </div>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="工商变更表" v-if="changeMessag.fileChangeUrls&&changeMessag.fileChangeUrls.length">
                <el-image
                  style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in compnayMessag.chanceFileUrls"
                  :key="index"
                  :initial-index="index"
                  :src="item"
                  :preview-src-list="compnayMessag.chanceFileUrls"
                  :z-index="3000"
                ></el-image>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <div class="sub-company-message">
        <div class="message-title after">变更后：</div>
        <div class="message-content">
          <el-form
            label-width="120px"
            label-suffix="："
            inline
          >
            <div>
              <el-form-item :label="form.companyType==0?'企业名称':'企业(单位)名称'">{{ changeMessag.name }}</el-form-item>
            </div>
            <div>
              <el-form-item label="社会信用代码">{{ changeMessag.creditCode }}</el-form-item>
            </div>
            <div>
              <el-form-item label="所属区域">{{ changeMessag.companyAddress }}</el-form-item>
            </div>
            <div>
              <el-form-item label="法定代表人">{{ changeMessag.legalPerson }}</el-form-item>
            </div>
            <div>
              <!-- <el-form-item label="营业执照/事业单位、社会团体登记证"> -->
                <!-- 企业类型0企业 1服务机构 2综合服务单位 -->
              <el-form-item :label="form.companyType==0?'营业执照':'营业执照/事业单位、社会团体登记证'">
                <!-- <div v-for="(item, index) in changeMessag.fileurls" :key="index">
                  <img :src="item" alt="">
                </div> -->
                <el-image
                  style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in changeMessag.fileurls"
                  :key="index"
                  :initial-index="index"
                  :src="item"
                  :preview-src-list="changeMessag.fileurls"
                  :z-index="3000"
                ></el-image>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="工商变更表">
                <!-- <div v-for="(item, index) in changeMessag.fileChangeUrls" :key="index">
                  <img :src="item" alt="">
                </div> -->
                <el-image
                  style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in changeMessag.fileChangeUrls"
                  :key="index"
                  :initial-index="index"
                  :src="item"
                  :preview-src-list="changeMessag.fileChangeUrls"
                  :z-index="3000"
                ></el-image>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <el-form
      ref="form"
      :model="form"
      :rules="rule"
    >
      <el-form-item label="审核状态：">
        <el-select
          v-model="form.status"
        >
          <el-option
            v-for="item in approveResults"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        class="check-with"
        label="审核备注："
        prop="remark"
      >
        <el-input
          type="textarea"
          :autosize="{minRows: 4}"
          placeholder="请简要描述审核备注，不超过300字"
          maxlength="300"
          v-model="form.remark"
        />
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { checkCompanyChang, getCompanyChance, queryCompanyInfo } from '@/api/business/companyChange'
// import { getCompanyChance } from '@/api/business/companyChange'
// import { queryCompanyInfo } from '@/api/business/companyChange'

export default {
  name: 'OperaCompanyChangeWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        companyType: null,
        status: '1',
        companyId: '',
        remark: '',
        id: ''
      },
      compnayMessag: {},
      changeMessag: {},
      // 验证规则
      rule: {
        remark: [
          { max: 300, message:'输入字数不超过300', trigger: 'change'}
        ]
      },
      approveResults: [
        // 2审核通过、3退回修改、4审核驳回
        { label:'审核通过', value: '1' },
        { label:'审核不通过', value: '2' }
      ]
    }
  },
  created () {
  },
  methods: {
    open (title, target) {
      debugger
      this.title = title
      this.visible = true
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        console.log(this.form);
        this.initData()
      })
    },
    initData() {
      getCompanyChance(this.form.companyId)
        .then(res => {
          this.changeMessag = res
        })
        .catch(e => {
          this.$message.error(e)
        })
      queryCompanyInfo(this.form.companyId)
        .then(res => {
          this.compnayMessag = res
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
    submit () {
      this.$refs.form.validate(
        v => {
          if (!v) {
            return
          }
          // console.log('审核')
          this.isWorking = true
          checkCompanyChang({
            ...this.form
          }).then(res => {
            // console.log(res)
            this.$emit('success')
            this.visible = false
          }).catch (err => {
            this.$message.error(err.message)
          }).finally(() => {
            this.isWorking = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.company-message {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .sub-company-message {
    width: calc(50% - 6px);
    margin-top: 20px;
    .message-title {
      height: 14px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 14px;
    }
    .after {
      color: #216EEE;
    }
    .message-content {
      border-radius: 2px;
      border: 1px solid #DFE2E8;
      margin-top: 16px;
      padding: 20px;
      ::v-deep .el-form-item {
        margin-bottom: 1px;
      }
    }
  }
}
.long-title-style {
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  overflow: hidden;
}
img {
  width: 90px;
  height: 90px;
}
</style>
