<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    width="400px"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" label-suffix="：" label-width="110px" :rules="rules" inline>
      <div>
        <el-form-item label="服务机构" prop="companyId">
          <el-select v-model="form.companyId" filterable placeholder="请选择服务机构">
            <el-option
              v-for="item in this.serviceList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="服务单价(元)" prop="price">
        <!-- onkeyup="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
          oninput="if(value<0) { value = ''} else {value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')}" -->
        <el-input
          v-model="form.price"
          placeholder="请输入服务单价（元）"
          @input="inputAction"
        />
      </el-form-item>
    </el-form>
    
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { create } from '@/api/business/projectSo'
import { list } from '@/api/business/company'
export default {
  name: 'OperaContentServeWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        projectId: null,
        companyId: null,
        price: null,
        type: 0
      },
      serviceList: [],
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/areas',
      'field.id': 'id'
    })
    // this.getServiceList()
  },
  methods: {
    getServiceList(projectId) {
      list({ type: '1', noProjectId: projectId })
        .then(res => {
          this.serviceList = res
        })
    },
    open (title, target) {
      this.title = title
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.getServiceList(this.form.projectId)
      })
    },
    confirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        create({
          ...this.form,
          type: 0
        })
          .then(() => {
            this.visible = false
            this.$message.success('新建成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$message.error(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    },
    inputAction(value) {
      if(this.form.price<0) {
        this.form.price = ''
      } else {
        this.form.price = value.replace(/[^\d.]/g, '')
                      .replace(/\.{2,}/g, '.')
                      .replace('.', '$#$')
                      .replace(/\./g, '')
                      .replace('$#$', '.')
                      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
                      .replace(/^\./g, '')
      }
      console.log(this.form.price);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
::v-deep .el-select {
  width: 100%;
    .el-input__inner {
      width: 100%;
    }
  }
</style>
