<template>
  <GlobalWindow
    :title="title"
    :confirm-working="false"
    :visible.sync="visible"
    @confirm="confirm"
  > 
    <div class="project-name">项目名称：{{ form.name }}</div>
    <el-button plain @click="createRoleRate">新建</el-button>
    <div style="height:10px"></div>
    <el-table
      v-loading="isWorking.search"
      :data="tableData.list"
      stripe
      border
    >
      <el-table-column prop="name" label="角色" min-width="100px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          {{ roleTypeToStr(row.roleType) }}
        </template>
      </el-table-column>
      <el-table-column prop="rate" label="评分权重(%)" min-width="100px"  align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="type" label="评分方式" align="center" min-width="100px">
        <template slot-scope="{row}">
          {{row.type==1?'线下':'线上'}}
        </template>
      </el-table-column>
      <el-table-column prop="editDate" label="编辑时间" align="center" min-width="140px"></el-table-column>
      <el-table-column prop="editorRealName" label="修改人" align="center" min-width="100px"></el-table-column>
      <el-table-column label="操作" align="center" min-width="120px">
        <template slot-scope="{ row }">
          <el-button type="text" @click="editRoleRate(row)">编辑</el-button>
          <el-button type="text" style="color:red" @click="deleteDiagnose(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <OperaProjectRoleSorceWindow ref="operaProjectRoleSorceWindow" @success="search"/>
    <div slot="footer"></div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaProjectRoleSorceWindow from './OperaProjectRoleSorceWindow'
import { findList, deleteById } from '@/api/business/projectScore'
export default {
  name: 'OperaProjectRoleSorcelListWindow',
  extends: BaseOpera,
  components: { GlobalWindow, TableLayout, Pagination, OperaProjectRoleSorceWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: ''
      },
      // 是否正在执行
      isWorking: {
        // 搜索中
        search: false,
        // 删除中
        delete: false,
        // 导出中
        export: false
      },
      // 表格数据
      tableData: {
        // 已选中的数据
        selectedRows: [],
        // 排序的字段
        sorts: [],
        // 当前页数据
        list: [],
        // 分页
       
      },
      province: [],
      cities: [],
      roleType: [
        { name: '企业', value: 0 },
        { name: '县区', value: 1 },
        { name: '服务机构', value: 2 },
        { name: '综合服务单位', value: 3 },
        { name: '专家', value: 4 },
        { name: '市局', value: 5 },
      ],
      canSetRoleType: []
    }
  },
  provide() {
    return {
      roleType: () => this.canSetRoleType
    }
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.search()
      })
    },
    roleTypeToStr(type) {
      let tempType = this.roleType.find(item => type == item.value)
      return tempType ? tempType.name : '-'
    },
    createRoleRate() {
      this.$refs.operaProjectRoleSorceWindow.open('新建角色评分', null, this.form.id, this.canSetRoleType)
    },
    editRoleRate(row) {
      this.$refs.operaProjectRoleSorceWindow.open('编辑角色评分', row, this.form.id, this.roleType)
    },
    deleteDiagnose(id) {
      this.$dialog.deleteConfirm('是否要删除该条诊断配置？')
        .then(() => {
          deleteById(id)
            .then(res => {
              this.$message.success('删除成功')
              this.search()
            })
            .catch(e => {
              this.$message.error(e)
            })
        })
    },
    // 搜索
    search () {
      this.canSetRoleType = []
      findList(this.form.id)
        .then(res => {
          this.tableData.list = res
          debugger
          this.roleType.forEach(type => {
            let index = this.tableData.list ? this.tableData.list.findIndex(item => type.value == item.roleType) : -1
            if (index == -1) {
              this.canSetRoleType.push(type)
            }
          })
        })
    },
    // 导出Excel

    // 搜索框重置
    reset () {
      this.$refs.searchForm.resetFields()
      this.search()
    },
    // 每页显示数量变更处理
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.search()
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: { projectId: this.form.id },
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
  }
}
</script>

<style scoped>
.project-name {
  font-weight: 600;
  margin-bottom: 20px;
}
</style>
