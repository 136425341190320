<template>
  <TableLayout :permissions="['system:user:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="用户名称" prop="username">
        <el-input v-model="searchForm.username" v-trim placeholder="请输入用户名" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="所属省份" prop="provinceId">
        <div style="display:flex">
          <el-select v-model="searchForm.provinceId" placeholder="请选择省份" @change="selectProvinceNew">
            <el-option
              v-for="item in province"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="城市" prop="cityId">
        <el-select v-model="searchForm.cityId" placeholder="请选择城市" @change="selectCity">
          <el-option
            v-for="item in cities"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="县区" prop="areaId">
        <el-select v-model="searchForm.areaId" placeholder="请选择县区">
          <el-option
            v-for="item in areas"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar">
        <li v-permissions="['system:user:create']"><el-button icon="el-icon-plus" type="primary" @click="$refs.operaAreaUserWindow.open('新建用户')">新建</el-button></li>
        <li><el-button type="primary" @click="resetUserPwd">重置密码</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        border
        :header-row-class-name="'table-header'"
        class="doumee-element-table"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="username" label="县区用户名称" align="center" min-width="140px" fixed="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="所属区域" align="center" min-width="140px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="sjname" label="所属市局" align="center" min-width="140px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="realname" label="联系人" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="mobile" label="手机号" align="center" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="mobile" label="状态" align="center" min-width="120px">
          <template slot-scope="{row}">
            <el-switch
              v-model="row.status"
              active-color="#13ce66"
              inactive-color="#999"
              :active-value="0"
              :inactive-value="1"
              @change="changeStatus(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" min-width="140px"></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="140"
          fixed="right"
        >
        
        <!--  v-if="row.id !== userInfo.id && row.roles.findIndex(r => r.code === adminCode) === -1" -->
          <template slot-scope="{row}">
            {{ row.roles }}
            <el-button type="text" @click="$refs.operaAreaUserWindow.open('编辑', row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaAreaUserWindow ref="operaAreaUserWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
  </TableLayout>
</template>

<script>
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaAreaUserWindow from '@/components/business/user/OperaAreaUserWindow'
import { treeList } from '@/api/business/areas'
import { resetPwd, changeStatus } from '@/api/business/account'

export default {
  name: 'SystemUser',
  extends: BaseTable,
  components: { OperaAreaUserWindow, TableLayout, Pagination },
  data () {
    return {
      // 搜索
      searchForm: {
        username: '', // 名字
        provinceId: '', // 姓名
        cityId: null, // 部门ID
        areaId: null,
        type: '7' //县区
      },
      province: [],
      cities: [],
      areas: []
    }
  },
  provide() {
    return {
      province: () => this.province
    }
  },
  created () {
    
    this.config({
      module: '用户',
      api: '/business/account',
      'field.main': 'realname',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }]
    })
    // 地区
    treeList({ type: 0 })
      .then(res => {
        // console.log(res)
        this.province = res
      })
      .catch(e => {
        this.$message.error(e)
      })
    this.search()
  },
  methods: {
    // 选择省份
    selectProvinceNew(val) {
      this.cities = []
      this.areas = []
      this.searchForm.cityId = ''
      this.searchForm.areaId = ''
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
          
        })
      // const tempProvince = this.province.find(item => item.id === val)
      // if (tempProvince) {
      //   this.cities = tempProvince.childList
      // }
    },
    // 选择城市
    selectCity(val) {
      this.areas = []
      this.searchForm.areaId = ''
      treeList({ type: 2, parentId: val })
        .then(res => {
          this.areas = res
          
        })
      // const tempCity = this.cities.find(item => item.id === val)
      // if (tempCity) {
      //   this.areas = tempCity.childList
      // }
    },
    changeStatus({id, status}) {
      changeStatus({ id, value:status })
        .then(res => {
          this.$message.success('修改成功')
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.handlePageChange()
        })
    },
    resetUserPwd() {
      if (this.tableData.selectedRows.length === 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let message = `确认重置已选中的 ${this.tableData.selectedRows.length} 个账号密码吗?`
      this.$dialog.resetConfirm(message)
        .then(() => {
          this.isWorking.delete = true
          resetPwd({ ids: this.tableData.selectedRows.map(row => row.id).join(',') })
            .then(() => {
              this.$message.success('重置成功')
              this.handlePageChange()
            })
            .catch(e => {
              this.$message.error(e)
            })
            .finally(() => {
              this.isWorking.delete = false
            })
        })
        .catch(() => {})
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
