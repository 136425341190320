<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" label-width="100px" label-suffix="：" :rules="rules" inline>
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入名称" v-trim/>
      </el-form-item>
      <!-- <el-form-item label="类型" prop="type">
        <el-select v-model="form.type" clearable="">
          <el-option v-for="item in type()" :key="item.value" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注" v-trim/>
      </el-form-item>
      <el-form-item label="排序码" prop="sortnum">
        <el-input v-model="form.sortnum" placeholder="请输入排序码" v-trim/>
      </el-form-item>

    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
export default {
  name: 'OperaLabelsWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        remark: '',
        sortnum: '',
        type: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ]
      }
    }
  },
  // inject: ['type'],
  created () {
    this.config({
      api: '/business/labels',
      'field.id': 'id'
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/alertstyle.scss";

</style>
