import { render, staticRenderFns } from "./OperaProjectApplyWindow.vue?vue&type=template&id=5b33a1d2&scoped=true&"
import script from "./OperaProjectApplyWindow.vue?vue&type=script&lang=js&"
export * from "./OperaProjectApplyWindow.vue?vue&type=script&lang=js&"
import style0 from "./OperaProjectApplyWindow.vue?vue&type=style&index=0&id=5b33a1d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b33a1d2",
  null
  
)

export default component.exports