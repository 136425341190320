<template>
  <TableLayout :permissions="['business:companychange:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="企业名称" prop="companyName">
        <el-input v-model="searchForm.companyName" placeholder="请输入名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="审核状态" prop="status">
        <el-select v-model="searchForm.status" placeholder="请选择审核状态">
          <el-option
            v-for="item in status"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        border
      >
        <el-table-column prop="companyType" label="用户类型" align="center" min-width="60px">
          <template slot-scope="{row}">
            {{ row.companyType==0?'企业':row.companyType==1?'服务机构':'综合服务单位' }}
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="企业名称" align="center" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="companyAddress" label="单位地址" align="center" min-width="120px" how-overflow-tooltip></el-table-column>
        <el-table-column prop="companyRegisterDate" label="注册时间" align="center" min-width="120px"></el-table-column>
        <el-table-column prop="createDate" label="提交时间" align="center" min-width="120px"></el-table-column>
        <el-table-column prop="status" label="审核状态" align="center" min-width="60px">
          <template slot-scope="{row}">
            {{ row.status==0?'待审核':row.status==1?'审核通过':'审核不通过' }}
          </template>
        </el-table-column>
        <el-table-column prop="checkInfo" label="审核备注" align="center" min-width="120px"></el-table-column>
        <el-table-column label="操作" align="center" min-width="60px">
          <template slot-scope="{row}">
            <el-button v-if="row.status==0" type="text" @click="$refs.operaCompanyChangeWindow.open('审核变更', { ...row, status:'1'})">审核</el-button>
            <div v-else>-</div>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <OperaCompanyChangeWindow ref="operaCompanyChangeWindow" @success="handlePageChange" />
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCompanyChangeWindow from '@/components/business/OperaCompanyChangeWindow'
import { getCompanyChance } from '@/api/business/companyChange'
import { queryCompanyDetail } from '@/api/business/company'
export default {
  name: 'CompanyChange',
  extends: BaseTable,
  components: { TableLayout, Pagination , OperaCompanyChangeWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        companyName: '',
        status: ''
      },
      status: [
      // 0待审核 1审核通过 3审核驳回
        { label: '待审核', value: '0' },
        { label: '审核通过', value: '1' },
        { label: '审核不通过', value: '2' },
      ]
    }
  },
  created () {
    this.config({
      module: '企业变更信息记录表',
      api: '/business/companyChange',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods: {
    check(row) {
      // companyChangeDetail(id)
      getCompanyChance(row.companyId)
        .then(res => {
          // this.$refs.operaCompanyChangeWindow.open('审核变更', {id: row.id, status:'1'})
        })
        .catch(e => {
          this.$message.error(e)
        })
    }
  }
}
</script>
