<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="submit"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="form.status != '7' ? rules : rule"
    >
      <el-form-item label="确认类型：">
        <el-select
          v-model="form.status"
          @change="chechStatus"
        >
          <el-option
            v-for="item in approveResults"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        class="check-with"
        label="说明："
        prop="remark"
      >
        <el-input
          type="textarea"
          :autosize="{minRows: 5}"
          placeholder="请输入简要说明，不超过300字"
          maxlength="300"
          v-model="form.remark"
        />
      </el-form-item>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import {areaToExamine, confirmService} from '@/api/business/declares'

export default {
  name: 'ComfirmServeWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        status: '7',
        remark: '',
        id: ''
      },
      // 验证规则
      rules: {
        remark: [
          { required: true, message: '请输入说明', trigger: 'blur' },
          { max: 300, message:'输入字数不超过300', trigger: 'change'}
        ]
      },
      rule: {
        remark: [
          { max: 300, message:'输入字数不超过300', trigger: 'change'}
        ]
      },
      approveResults: [
        // 7 接受服务  6拒绝服务
        { label:'接受服务', value: '7' },
        { label:'拒绝服务', value: '6' },
      ]
    }
  },
  created () {
  },
  methods: {
    submit() {
      this.$refs.form.validate(
        v=>{
          if (!v) {
            return
          }
          console.log('审核')
          this.isWorking = true
          confirmService({
            ...this.form
          }).then( res => {
            console.log(res)
            this.visible=false
            this.$emit('success')
          }).catch (err => {
            this.$message.error(err.message)
          }).finally(() => {
            this.isWorking = false
            this.visible = false
          })
      })
    },
    chechStatus(v) {
      console.log(v)
      if (v === '1') {
        this.$refs.form.clearValidate()
      }
    }
  }
}
</script>
 

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";

::v-deep .el-select {
    width: 100%;
    .el-input__inner {
      width: 100%;
    }
  }
  ::v-deep .el-textarea__inner {
    width: 100%;
  }
</style>
