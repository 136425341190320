<template>
   <el-dialog
    class="global-window"
    :visible="visible"
    :with-header="true"
    :close-on-press-escape="false"
    :wrapper-closable="false"
    :append-to-body="true"
    @close="visible=false"
    width="70%"
  >
    <div class="pdf-container">
      <iframe
        :src="`${path}/pdfjs/web/viewer.html?file=${form.url}`"
        frameborder="0"
        style="width: 100%; height: 100%;"
      ></iframe>
    </div>
  </el-dialog>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'

import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
export default {
  name: 'OperaCompanyUserWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      path: process.env.VUE_APP_CONTEXT_PATH == '/' ? '' : process.env.VUE_APP_CONTEXT_PATH,
      // 表单数据
      form: {
        url: ''
      }
    }
  },
  created () {
    this.config({
      api: '/business/account',
      'field.id': 'id'
    })
  }
}

</script>

<style lang="scss" scoped>
.address {

  ::v-deep .el-form-item__content {
    flex: 1;
  }
  ::v-deep .el-select {
    width: 32%;
    .el-input__inner {
      width: 100%;
    }
  }
}
.pdf-container {
  width: 100%;
  height: calc(80vh - 100px);
  overflow-y: scroll;
}
::v-deep .el-dialog__header {
    padding: 0 !important;
    height: 40px;
    line-height: 40px;
    text-align: center;
    // background-color: rgba($color: #000000, $alpha: 0.3);
    
  }
::v-deep .window__footer {
  height: 1px;
}
// ::v-deep .el-dialog__body {
  // background-color: rgba($color: #000000, $alpha: 0.3);
// }
.ins-pdf-button-box {
  display: flex;
  padding: 20px;
  div {
    width: 80px;
    // background-color: #fff;
    background-color: #f5f5f5;
    margin-right: 10px;
    text-align: center;
    padding: 8px;
  }
  .last-style {
    border: 1px solid #666;
    
  }
}
</style>
