<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="标题名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入名称" v-trim/>
      </el-form-item>
      <el-form-item label="模板内容" prop="content">
        <!-- <el-input v-model="form.content" placeholder="请输入内容" v-trim/> -->
        <!-- {{ form.content }} -->
        <RichEditor :content="{ content: form.content }" @edit="form.content=$event"></RichEditor>
      </el-form-item>
      
      <el-form-item label="排序码" prop="sortnum">
        <el-input v-model="form.sortnum" placeholder="请输入排序码" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import RichEditor from '../common/RichEditor.vue'

export default {
  name: 'OperaProjectDiagnoseWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow, RichEditor },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        projectId: '',
        content: '',
        sortnum: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/business/projectDiagnose',
      'field.id': 'id'
    })
  },
 
  methods: {
    newValue(v) {
      this.form.content = v
    },
    // confirm() {
    //   console.log(this.form);
    // }
   
  }
}
</script>

<style lang="scss" scoped>
</style>
