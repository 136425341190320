<template>
  <GlobalWindow
    :title="title"
    :confirm-working="false"
    :visible.sync="visible"
    @confirm="confirm"
  >
    <TableLayout :permissions="['business:declares:query']">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="120px" label-suffix="：" inline>
        <el-form-item label="企业名称" prop="companyName">
          <el-input v-model="searchForm.companyName" placeholder="请输入企业名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="所属区域" prop="cityId" v-if="userInfo.type!=7">
          <div style="display:flex">
            <el-select v-model="searchForm.provinceId" placeholder="请选择省份" @change="selectProvince">
              <el-option
                v-for="item in province"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line"></div>
            <el-select v-model="searchForm.cityId" placeholder="请选择城市" @change="selectCity">
              <el-option
                v-for="item in cities"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line"></div>
            <el-select v-model="searchForm.areaId" placeholder="请选择县区">
              <el-option
                v-for="item in areas"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="流程状态" prop="status">
          <el-select v-model="searchForm.status" placeholder="请选择流程状态">
            <el-option
              v-for="item in declareStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="服务机构名称" prop="soId" v-if="userInfo.type!=2&&userInfo.type!=3">
          <el-select v-model="searchForm.soId" filterable placeholder="请选择服务机构">
            <el-option
                v-for="item in this.serviceList"
                :key="item.companyId"
                :value="item.companyId"
                :label="item.companyName"
            ></el-option>
          </el-select>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
          <el-button :loading="isWorking.export" v-permissions="['business:declares:exportExcel']" @click="exportExcel">导出</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->

      <template v-slot:table-wrap>
        <ul v-if="userInfo.type==2||userInfo.type==4" class="toolbar">
          <li><el-button type="primary" @click="distributeChildren">分配给子账号</el-button></li>
        </ul>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column v-if="userInfo.type==2" type="selection" width="55"></el-table-column>
          <el-table-column prop="companyName" label="企业名称" fixed="left" min-width="140px" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <el-button v-if="userInfo.type==6 || userInfo.type==2||userInfo.type==3" type="text" @click="showCompany(row,2)">{{ row.companyName }}</el-button>
              <el-button v-else type="text" @click="showCompany(row)">{{ row.companyName }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="projectName" label="项目名称" min-width="140px" show-overflow-tooltip></el-table-column>

          <!--  0系统用户 1企业用户 2服务机构管理员 3服务机构子账号 4综合服务单位管理员 5综合服务单位子账号 6专家 7县区用户 8市局用户,示例值(1)
           -->
           <!-- 6专家 -->
          <template v-if="userInfo.type==6">
            <el-table-column prop="areaName" label="所属区域" min-width="100px" show-overflow-tooltip></el-table-column>
            <el-table-column prop="serviceName" label="服务机构" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.serviceName || '-' }}
              </template>
            </el-table-column>
            <el-table-column prop="dascore" label="问卷分数" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.dascore || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="roleScore" label="诊断得分" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.roleScore || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="130px">
              <template slot-scope="{ row }">
                {{ statusToStr(row.status) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="160px">
              <template slot-scope="{ row }">
                <el-button v-if="row.status==10" type="text" @click="$refs.approveApplyWindow.open('评分', {id: row.id, mark:row.mark, status: '2' })">评分</el-button>
              </template>
            </el-table-column>
          </template>
          <!-- 2服务机构管理员 -->
          <template v-if="userInfo.type==2||userInfo.type==3">
            <el-table-column prop="areaName" label="所属区域" min-width="100px" show-overflow-tooltip></el-table-column>
            <el-table-column prop="dascore" label="问卷分数" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.dascore || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="realServiceName" label="关联子账号" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.realServiceName || '-' }}
              </template>
            </el-table-column>
            <el-table-column prop="roleScore" label="诊断得分" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.roleScore || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="servicePrice2" label="诊断费用" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.servicePrice2 || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="130px">
              <template slot-scope="{ row }">
                {{ statusToStr(row.status) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="160px">
              <template slot-scope="{ row }">
                <el-button v-if="row.status==5" type="text" @click="$refs.comfirmServeWindow.open('确认服务', {id: row.id, status: '7' })">确认服务</el-button>
                <el-button v-if="row.status==7 || row.status==8" type="text" @click="$refs.uploadServeData.open('上传服务数据', { ...row, serviceLimt: form.serviceLimt, serviceReq: serviceReq })">上传服务数据</el-button>
                <el-button v-if="row.status==12" type="text" @click="$refs.reUploadServeData.open('上传服务数据', { ...row, serviceLimt: form.serviceLimt, serviceReq: serviceReq })">上传服务数据</el-button>
<!--                <el-button v-if="row.status==9" type="text" @click="$refs.serveDetailData.open('服务详情', {id: row.id})">服务详情</el-button>-->
                <!-- <el-button v-if="row.status==9 ||  row.status==10||row.status==11" type="text" @click="showCompany(row)">服务详情</el-button> -->
                <el-button type="text" v-if="row.status>=8"  @click="showCompany(row, 2)">服务详情</el-button>
              </template>
            </el-table-column>
          </template>
          <!-- 4综合服务单位管理员 -->
          <template v-if="userInfo.type==4||userInfo.type==5">
            <el-table-column prop="areaName" label="所属区域" min-width="100px" show-overflow-tooltip></el-table-column>
            <el-table-column prop="dascore" label="问卷分数" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.dascore || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="serviceName" label="服务机构" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.serviceName || '-' }}
              </template>
            </el-table-column>
            <el-table-column prop="servicePrice" label="服务单价（元）" min-width="120px">
              <template slot-scope="{ row }">
                {{ row.servicePrice || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="realZJName" label="专家名称" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.realZJName || '-' }}
              </template>
            </el-table-column>
            <el-table-column prop="roleScore" label="诊断得分" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.roleScore || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="servicePrice2" label="诊断费用" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.servicePrice2 || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="realName" label="分配子账号" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.realName || '-' }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="130px">
              <template slot-scope="{ row }">
                {{ statusToStr(row.status) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="265px">
              <template slot-scope="{ row }">
                <!-- {{ row.status }} -->
                <el-button v-if="row.status==2 && row.sodType == 2 && userInfo.type==4" type="text" @click="$refs.OperaCompanySelectServeWindow.open('分配服务机构', row, false)">分配服务机构</el-button>
                <el-button v-if="row.status==7 || row.status==8" type="text" @click="$refs.uploadServeCheck.open('上传核查资料', {id: row.id, mark:row.mark, status: '2' ,hcTips:hcTips})"> 上传核查资料</el-button>
                <el-button type="text" v-if="(row.status==7||row.status==8||row.status==6) && row.sodType == 2 && userInfo.type==4" @click="$refs.OperaCompanySelectServeWindow.open('重新分配服务机构', row, false)">重新分配服务机构</el-button>
                <el-button  v-if="(row.status==9 ||row.status==13) && row.edType==1"  type="text" @click="$refs.operaDistributeExportWindow.open('分配评分专家', {id: row.id, mark:row.mark, status: '2' })">分配评分专家</el-button>
                <el-button v-if="row.status==10 && row.edType==1"  type="text" @click="$refs.operaDistributeExportWindow.open('重分配评分专家', row)">重分配评分专家</el-button>
                <el-button type="text" v-if="row.status>=8"  @click="showCompany(row, 1)">服务详情</el-button>
                <el-button type="text" v-if="row.status==9||row.status==13" @click="returnBack(row.id)">退回服务</el-button>
                <el-button type="text" v-if="userInfo.type==4" @click="$refs.comprehensiveSorce.open('评分', row.id, searchForm.projectId)">评分</el-button>
              </template>
            </el-table-column>
          </template>
          <!-- 市局 -->
          <template v-if="userInfo.type==8||userInfo.type==9">
            <el-table-column prop="areaName" label="所属区域" min-width="100px" show-overflow-tooltip></el-table-column>
            <el-table-column prop="dascore" label="问卷分数" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.dascore || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="serviceName" label="服务机构" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.serviceName || '-' }}
              </template>
            </el-table-column>
            <el-table-column prop="servicePrice" label="服务单价（元）" min-width="120px">
              <template slot-scope="{ row }">
                {{ row.servicePrice || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="realZJName" label="专家名称" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.realZJName || '-' }}
              </template>
            </el-table-column>
            <el-table-column prop="roleScore" label="诊断得分" min-width="100px">
              <template slot-scope="{ row }">
                {{ (row.roleScore && row.roleScore.toFixed(2)) || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="servicePrice2" label="诊断费用" min-width="100px">
              <template slot-scope="{ row }">
                {{ (row.servicePrice2 && row.servicePrice2.toFixed(2))  || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="130px">
              <template slot-scope="{ row }">
                {{ statusToStr(row.status) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="220px">
              <template slot-scope="{ row }">
                <el-button v-if="row.status==2 && row.sodType==1" type="text" @click="$refs.OperaCompanySelectServeWindow.open('分配服务机构', row, false)">分配服务机构</el-button>
                <el-button v-if="(row.status==7||row.status==8||row.status==6) && row.sodType == 1" type="text" @click="$refs.OperaCompanySelectServeWindow.open('重新分配服务机构', row, false)">重新分配服务机构</el-button>
                <el-button  v-if="(row.status==9||row.status==13) && row.edType==0"  type="text" @click="$refs.operaDistributeExportWindow.open('分配评分专家', row)">分配评分专家</el-button>
                <el-button v-if="row.status==10 && row.edType==0"  type="text" @click="$refs.operaDistributeExportWindow.open('重分配评分专家', row)">重分配评分专家</el-button>
<!--                <el-button v-if="row.status==11" type="text" @click="$refs.serveDetailData.open('服务详情', {id: row.id})">服务详情</el-button>-->
                <!-- <el-button v-if="row.status==11" type="text" @click="showCompany(row)">服务详情</el-button> -->
                <el-button type="text"  v-if="row.status>=8" @click="showCompany(row)">服务详情</el-button>
                <el-button type="text"  v-if="row.status==9||row.status==13" @click="returnBack(row.id)">退回服务</el-button>
              </template>
            </el-table-column>
          </template>
          <!-- 县区 -->
          <template v-if="userInfo.type==7">
            <el-table-column prop="dascore" label="问卷分数" min-width="100px">
              <template slot-scope="{ row }">
                {{ row.dascore || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="serviceName" label="服务机构" min-width="100px">
              <template slot-scope="{ row }">{{ row.serviceName || '-' }}</template>
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="130px">
              <template slot-scope="{ row }">
                {{ statusToStr(row.status) }}
              </template>
            </el-table-column>
            <el-table-column prop="createDate" label="报名时间" min-width="140px"></el-table-column>
            <el-table-column prop="mark" label="备注" min-width="120px" show-overflow-tooltip>
              <template slot-scope="{ row }">{{ row.remark || '-' }}</template>
            </el-table-column>
            <el-table-column   label="操作" fixed="right" min-width="100px">
              <template slot-scope="{ row }">
                <el-button v-if="row.status==1" type="text" @click="$refs.approveApplyWindow.open('审核', {id: row.id, mark:row.mark, status: '2' })">审核</el-button>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        >
        </pagination>
      </template>
    </TableLayout>
    <div slot="footer">
      <el-button @click="back">返回</el-button>
    </div>
    <AreaProjectApplyDetail ref="projectApplyDetail" @success="handlePageChange" />
    <ApproveApplyWindow ref="approveApplyWindow" @success="handlePageChange" />
    <ComfirmServeWindow ref="comfirmServeWindow" @success="handlePageChange" />
    <OperaDistributeServeWindow ref="operaDistributeServeWindow" @success="handlePageChange" />
    <OperaDistributeExportWindow ref="operaDistributeExportWindow" @success="handlePageChange" />
    <UploadServeCheck ref="uploadServeCheck" @success="handlePageChange" />
    <UploadServeData ref="uploadServeData" @success="handlePageChange" />
    <ReUploadServeData ref="reUploadServeData" @success="handlePageChange" />
    <ServeDetailData ref="serveDetailData"/>
    <ComprehenDistributionChild ref="comprehenDistributionChild"/>
    <ServiceDistributionChild ref="serviceDistributionChild"/>
    <OperaCompanySelectServeWindow ref="OperaCompanySelectServeWindow" @success="handlePageChange" />
    <declareRenturn ref="declareRenturn" @success="handlePageChange" />
    <comprehensiveSorce ref="comprehensiveSorce" @success="handlePageChange" />
  </GlobalWindow>
</template>

<script>
import { mapState } from 'vuex'
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import ComprehenDistributionChild from './ComprehenDistributionChild'
import ServiceDistributionChild from './ServiceDistributionChild'
import ApproveApplyWindow from './ApproveApplyWindow'
import ComfirmServeWindow from './ComfirmServeWindow'
import AreaProjectApplyDetail from './AreaProjectApplyDetail'
import OperaDistributeServeWindow from './OperaDistributeServeWindow'
import OperaDistributeExportWindow from './OperaDistributeExportWindow'
import UploadServeCheck from './UploadServeCheck'
import UploadServeData from './UploadServeData'
import ReUploadServeData from './ReUploadServeData'
import ServeDetailData from './ServeDetailData'
import declareRenturn from './declareReturn.vue'
import comprehensiveSorce from './comprehensiveSorce.vue'
import OperaCompanySelectServeWindow from '../OperaCompanySelectServeWindow'
import { getPageByProjectId as fetchList, exportExcel, confirmService, declaresDetail } from '@/api/business/declares'
import { treeList } from '@/api/business/areas'
import { fetchList as projectSo } from '@/api/business/projectSo'
import { projectDetail } from '@/api/business/project'


export default {
  name: 'projectApply',
  extends: BaseOpera,
  components: {
    GlobalWindow,
    TableLayout,
    Pagination,
    ComprehenDistributionChild,
    ServiceDistributionChild,
    ApproveApplyWindow,
    AreaProjectApplyDetail,
    OperaDistributeServeWindow,
    ComfirmServeWindow,
    OperaDistributeExportWindow,
    UploadServeCheck,
    UploadServeData,
    ReUploadServeData,
    ServeDetailData,
    OperaCompanySelectServeWindow,
    declareRenturn,
    comprehensiveSorce
  },
  data () {
    return {
      form: {
        serviceLimt: 1
      },
      hcTips: '',
      serviceReq: '',
      // 表单数据
      searchForm: {
        projectId: null,
        companyName: '',
        provinceId: '',
        cityId: '',
        areaId: '',
        serviceName: '',
        status: '',
        soId: ''
      },
      serviceList: [],
      // 是否正在执行
      isWorking: {
        // 搜索中
        search: false,
        // 删除中
        delete: false,
        // 导出中
        export: false
      },
      declareStatus: [
        // 0已保存、1待审核、2审核通过、3退回修改、4审核驳回、5待服务机构确认、
        // 6服务机构拒绝、7已分配服务机构、8诊断中（成功上传第一份服务资料）、
        // 9服务完成、10已分配评分专家、11已完成
        { label: '已保存', value: 0 },
        { label: '待审核', value: 1 },
        { label: '审核通过', value: 2 },
        { label: '退回修改', value: 3 },
        { label: '审核驳回', value: 4 },
        { label: '待服务机构确认', value: 5 },
        { label: '服务机构拒绝', value: 6 },
        { label: '已分配服务机构', value: 7 },
        { label: '诊断中', value: 8 },
        { label: '服务完成', value: 9 },
        { label: '已分配评分专家', value: 10 },
        { label: '已完成', value: 11 },
        { label: '服务退回', value: 12 },
        { label: '退回修改完成', value: 13 },
      ],
      // 表格数据
      tableData: {
        // 已选中的数据
        selectedRows: [],
        // 排序的字段
        sorts: [],
        // 当前页数据
        list: [],
        // 分页
        pagination: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        }
      },
      province: [],
      cities: [],
      areas: [],
      project: {
        
      }
    }
  },
  provide() {
    return {
      project: () => this.project
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
    // 地区
    treeList({ type: 0 })
      .then(res => {
        // console.log(res)
        this.province = res
      })
      .catch(e => {
        this.$message.error(e)
      })
  },
  methods: {
    open (title, target) {
      // debugger
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        this.$refs.searchForm.resetFields()
        this.searchForm.projectId = target.projectId
        this.form.serviceLimt = target.serviceLimt
        this.hcTips = target.hcTips
        this.serviceReq = target.serviceReq
        // console.log(this.searchForm)
        this.reset()
        this.getServiceList()
        projectDetail(target.projectId)
          .then(res => {
            this.project = {
              ...res,
              startDate: res.startDate.substring(0,res.startDate.length-3),
              endDate: res.endDate.substring(0,res.endDate.length-3),
            }
          })
      })
    },
    getServiceList () {
      if (this.userInfo.type !== 2 && this.userInfo.type !== 3) {
        projectSo({
          capacity: 9999,
          model: { projectId: this.searchForm.projectId, type: 0 }
        })
          .then(res => {
            this.serviceList = res.records
          })
          .catch(e => {
            this.$message.error(e)
          })
      }
    },
    distributeChildren () {
      if (this.userInfo.type == 2) {
        if (this.tableData.selectedRows.length === 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        for (const element of this.tableData.selectedRows) {
          if (element.status != 8 && element.status != 7  && element.status != 12) {
            this.$message.warning(`${element.companyName} 的报名状态不支持分配子账号`)
            return
          }
        }
        const ids = this.tableData.selectedRows.map(item => item.id).join(',')
        this.$refs.serviceDistributionChild.open('分配给子账号', { ids, projectId: this.searchForm.projectId })
      } else if (this.userInfo.type == 8) {

      } else {
        this.$refs.comprehenDistributionChild.open('分配给子账号', { projectId: this.searchForm.projectId })
      }
    },
    statusToStr (status) {
      const temp = this.declareStatus.find(item => item.value === status)
      if (temp) {
        return temp.label
      }
      return '-'
    },
    back () {
      this.visible = false
      this.$emit('success')
    },
    showCompany (row, flag) {
      this.$refs.projectApplyDetail.open('报名详情', row, flag)
    },
    // 选择省份
    selectProvince (val) {
      this.cities = []
      this.areas = []
      this.searchForm.cityId = ''
      this.searchForm.areaId = ''
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
        })
      // const tempProvince = this.province.find(item => item.id === val)
      // if (tempProvince) {
      //   this.cities = tempProvince.childList
      // }
    },
    selectCity (val) {
      this.areas = []
      this.searchForm.areaId = ''
      treeList({ type: 2, parentId: val })
        .then(res => {
          this.areas = res
        })
      // const tempCity = this.cities.find(item => item.id === val)
      // if (tempCity) {
      //   this.areas = tempCity.childList
      // }
    },
    // 搜索
    search () {
      this.handlePageChange(1)
    },
    // 导出Excel
    exportExcel () {
      this.$dialog.exportConfirm('确认导出吗？')
        .then(() => {
          this.isWorking.export = true
          exportExcel({
            page: this.tableData.pagination.pageIndex,
            capacity: 1000000,
            model: this.searchForm,
            sorts: this.tableData.sorts
          })
            .then(response => {
              // debugger
              this.download(response)
            })
            .catch(e => {
              // debugger
              this.$message.error(e)
            })
            .finally(() => {
              this.isWorking.export = false
            })
        })
        .catch(() => {})
    },
    // 搜索框重置
    reset () {
      this.$refs.searchForm.resetFields()
      this.searchForm.provinceId = ''
      this.searchForm.cityId = ''
      this.searchForm.areaId = ''
      this.search()
    },
    // 每页显示数量变更处理
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.search()
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm,
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    // 行选中处理
    handleSelectionChange (selectedRows) {
      this.tableData.selectedRows = selectedRows
    },
    distribution () {
      if (this.tableData.selectedRows.length === 0) {
        this.$message.warning('请选择企业')
      } else if (this.tableData.selectedRows.length === 1) {
        const item = this.tableData.selectedRows[0]
        // projectId: '',
        // companyId: '',
        // { projectId: item.projectId, companyId: item.companyId, soId: '', sdUserId: item }
        this
      } else {
        this.$message.warning('一次只能分配一个企业')
      }
    },
    confirmServe (id) {
      // 6服务机构拒绝、7已分配服务机构
      let status = 6
      this.$dialog.confirmServe('确认是否服务该企业')
        .then(() => {
          status = 7
        })
        .catch(() => {
          status = 6
        })
        .finally(() => {
          confirmService({ id, status })
            .finally(() => {
              this.handlePageChange()
            })
        })
    },
    returnBack(declareId) {
      // backSoServer({declareId})
      this.$refs.declareRenturn.open('退回服务', { declareId, backInfo: '' })
    }
  }
}
</script>
