<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking.commit"
    @confirm="confirm"
  >
    <TableLayout :permissions="['business:declares:query']">
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="120px" label-suffix="：" inline>
        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="searchForm.companyName" placeholder="请输入公司名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <template v-slot:table-wrap>
        <!-- <ul class="toolbar" v-permissions="['business:declares:exportExcel']">
          <el-button :loading="isWorking.export" v-permissions="['business:declares:exportExcel']" @click="exportExcel">导出</el-button>
        </ul> -->
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="companyName" label="服务机构" fixed="left" min-width="140px" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="companyBelongAddress" label="所属区域" min-width="140px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="companyLinkName" label="联系人" min-width="140px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="companyLinkPhone" label="手机号" min-width="140px"></el-table-column>
          <el-table-column fixed="right" label="操作" min-width="100px">
            <template slot-scope="{ row }">
              <!-- @click="$refs.giveMarkWindow.open('评分', { declareId: row.id })" -->
              <el-button type="text" @click="$refs.serviceDetail.open('服务机构详情', { companyId: row.companyId })">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        >
        </pagination>
      </template>
    </TableLayout>
    <ServiceDetail ref="serviceDetail" />
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import ServiceDetail from './ServiceDetail.vue'
import { chooseService, distributionServiceSave } from '@/api/business/declares'
// import { list } from '@/api/business/company'
import { fetchList as projectSo } from '@/api/business/projectSo'
export default {
  name: 'OperaDistributeServeWindow',
  extends: BaseOpera,
  components: { GlobalWindow, TableLayout, Pagination, ServiceDetail },
  data () {
    return {
      // 表单数据
      form: {
        projectId: null,
        id: null,
        soId: null
      },
      serviceList: [],
      searchForm: {
        projectId: null,
        companyName: '',
        type: 0
      },
      isCompany: false,
      // 是否正在执行
      isWorking: {
        // 搜索中
        search: false,
        // 删除中
        delete: false,
        commit: false,
        // 导出中
        export: false
      },
      // 表格数据
      tableData: {
        // 已选中的数据
        selectedRows: [],
        // 排序的字段
        sorts: [],
        // 当前页数据
        list: [],
        // 分页
        pagination: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        }
      }
    }
  },
  created () {
    this.config({
      api: '/business/areas',
      'field.id': 'id'
    })
    
  },
  methods: {
    open (title, target, isCompany=true) {
      // debugger
      this.title = title
      this.visible = true
      this.isCompany = isCompany
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.searchForm.projectId = target.projectId
        this.search()
      })
    },
    search () {
      this.handlePageChange(1)
    },
    // 搜索框重置
    reset () {
      this.$refs.searchForm.resetFields()
      this.search()
    },
    // 每页显示数量变更处理
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.search()
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      projectSo({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm,
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    // 行选中处理
    handleSelectionChange (selectedRows) {
      this.tableData.selectedRows = selectedRows
    },
    confirm () {
      if (this.tableData.selectedRows.length != 1) {
        this.$message.warning('必须且只能选择一个服务机构')
        return
      }
      this.isWorking.commit = true
      let selectAction = this.isCompany ? chooseService : distributionServiceSave
      selectAction({
        ...this.form,
        soId: this.tableData.selectedRows[0].companyId
      })
        .then(() => {
          this.visible = false
          this.$message.success('操作成功')
          this.$emit('success')
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.commit = false
        })
    }
  },
}
</script>

<style lang="scss" scoped>
// @import "@/assets/style/alertstyle.scss";
// ::v-deep .el-select {
//     width: 100%;
//     .el-input__inner {
//       width: 100%;
//     }
//   }
</style>
