<template>
  <div class="file-link">
    <div class="like-link" @click="showFile(link)">{{ name }}</div>
    <i v-if="isUpload" class="el-icon-delete delete" @click="deleteFile()"></i>
    <i v-else class="el-icon-download download" @click="downloadFile(link)"></i>
  </div>
</template>

<script>

export default {
  props: {
    link: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },

    isUpload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showViewer: false
    }
  },
  methods: {
    deleteFile() {
      this.$emit('deleteAction')
    },
    downloadFile(url) {
      window.open(url, '_blank')
    },
    showFile(link) {
      this.$emit('showFile',link)
    }
  },
}
</script>

<style lang="scss" scoped>
.file-link {
  // height: 26px;
  display: flex;
  font-size: 14px;
  line-height: 26px;

  .like-link {
    line-height: 26px;
    color: #216EEE;
    cursor: pointer;
    text-decoration: underline;
  }
  .delete {
    box-sizing: border-box;
    line-height: 26px;
    margin-left: 10px;
    width: 16px;
    height: 16px;
    // background-color: #BFC3CB;
    color: #216EEE;
    border-radius: 50%;
    padding: 1.5px;
    cursor: pointer;
    &:hover {
      color: #ff0000;
    }
  }
  .download {
    line-height: 26px;
    box-sizing: border-box;
    margin-left: 10px;
    width: 16px;
    height: 16px;
    color: #828D9E;
    border-radius: 50%;
    padding: 1.5px;
    cursor: pointer;
    &:hover {
      color: #216EEE;
    }
  }
}
</style>
