// 去空
export function trim (data) {
  if (data == null) {
    return null
  }
  if (typeof data === 'string') {
    return data.trim()
  }
  if (data instanceof Array) {
    for (const item of data) {
      trim(item)
    }
  }
  if (typeof data === 'object') {
    for (const key in data) {
      data[key] = trim(data[key])
    }
  }
  return data
}

/**
 * 根据文件url 返回文件类型
 * @param {文件url} url 
 * @returns 1、图片类型  2、pdf类型 3、其他类型
 */
export function fileType(url) {
  let lastStr = url.slice(url.lastIndexOf('.')+1).toLocaleLowerCase()
  if (['bmp','jpg','jpeg','png','tif','gif','pcx','tga','exif','fpx','svg','psd','cdr','pcd','dxf','ufo','eps','ai','raw','WMF','webp','avif','apng'].indexOf(lastStr) != -1) {
    // 图片
    return 1
  } else if (lastStr === 'pdf') {
    // pdf
    return 2
  } else {
    // 其他
    return 3
  }
}
