<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="150px" label-suffix="：" inline>
      <div class="title">基础信息</div>
      <el-form-item label="企业名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入企业名称" v-trim/>
      </el-form-item>
      <br>
      <el-form-item label="服务机构" prop="serveOrg">
        <!-- <el-select v-model="searchForm.serveOrg" clearable="">
          <el-option v-for="item in serveList" :key="item.id" :value="item.id" :label="item.companyName"></el-option>
        </el-select> -->
        <el-input v-model="form.serveOrg" placeholder="请输入服务机构" v-trim/>
      </el-form-item>
      <br>
      <el-form-item label="所属行业" prop="industry">
        <el-input v-model="form.industry" placeholder="请输入所属行业" v-trim/>
      </el-form-item>
      <br>
      <el-form-item label="所属地区" prop="areaId">
        <div style="display:flex">
            <!-- <el-select v-model="form.provinceId" placeholder="请选择省份" @change="selectProvince">
              <el-option
                v-for="item in province"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line"></div>
            <el-select v-model="form.cityId" placeholder="请选择城市" @change="selectCity">
              <el-option
                v-for="item in cities"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line"></div> -->
            <el-select v-model="form.areaId" placeholder="请选择县区">
              <el-option
                v-for="item in province"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
      </el-form-item>
      <br>
      <!-- <el-form-item label="县区名称" prop="areaname">
        <el-input v-model="form.areaname" placeholder="请输入县区名称" v-trim/>
      </el-form-item> -->
      <el-form-item label="上年度营业收入" prop="revenue">
        <el-input v-model="form.revenue" placeholder="请输入上年度营业收入" v-trim/>单位：万元
      </el-form-item>
      <br>
      <el-form-item label="诊断类别" prop="diagnoseType">
        <el-input v-model="form.diagnoseType" placeholder="请输入诊断类别" v-trim/>
      </el-form-item>
      <br>
      <el-form-item label="成熟度等级" prop="level">
        <!-- <el-input v-model="form.level" placeholder="请输入成熟度等级" v-trim/> -->
        <el-select v-model="form.level" placeholder="请选择县区">
              <el-option
                v-for="item in levels"
                :key="item.id"
                :value="item.label"
                :label="item.label"
              ></el-option>
            </el-select>
      </el-form-item>
      <div  class="title">8个能力域</div>
      <el-form-item label="人员" prop="data0">
        <el-input v-model="form.data0" placeholder="请输入人员评分" @input="inputZAction(form, 'data0')" v-trim/>
      </el-form-item>
      <el-form-item label="技术" prop="data1">
        <el-input v-model="form.data1" placeholder="请输入技术评分" @input="inputZAction(form, 'data1')" v-trim/>
      </el-form-item>
      <el-form-item label="资源" prop="data2">
        <el-input v-model="form.data2" placeholder="请输入资源评分" @input="inputZAction(form, 'data2')" v-trim/>
      </el-form-item>
      <el-form-item label="设计" prop="data3">
        <el-input v-model="form.data3" placeholder="请输入设计评分" @input="inputZAction(form, 'data3')" v-trim/>
      </el-form-item>
      <el-form-item label="生产" prop="data4">
        <el-input v-model="form.data4" placeholder="请输入生产评分" @input="inputZAction(form, 'data4')" v-trim/>
      </el-form-item>
      <el-form-item label="物流" prop="data5">
        <el-input v-model="form.data5" placeholder="请输入物流评分" @input="inputZAction(form, 'data5')" v-trim/>
      </el-form-item>
      <el-form-item label="销售" prop="data6">
        <el-input v-model="form.data6" placeholder="请输入销售评分" @input="inputZAction(form, 'data6')" v-trim/>
      </el-form-item>
      <el-form-item label="服务" prop="data7">
        <el-input v-model="form.data7" placeholder="请输入服务评分" @input="inputZAction(form, 'data7')" v-trim/>
      </el-form-item>
      <el-form-item label="总得分" prop="data8">
        <el-input v-model="form.data8" placeholder="请输入总得分" @input="inputZAction(form, 'data8')" v-trim/>
      </el-form-item>
      <div class="title">20个能力子域</div>
      <el-form-item label="组织战略" prop="data9">
        <el-input v-model="form.data9" placeholder="请输入组织战略评分" @input="inputZAction(form, 'data9')" v-trim/>
      </el-form-item>
      <el-form-item label="人员技能" prop="data10">
        <el-input v-model="form.data10" placeholder="请输入人员技能评分" @input="inputZAction(form, 'data10')" v-trim/>
      </el-form-item>
      <el-form-item label="数据" prop="data11">
        <el-input v-model="form.data11" placeholder="请输入数据评分" @input="inputZAction(form, 'data11')" v-trim/>
      </el-form-item>
      <el-form-item label="集成" prop="data12">
        <el-input v-model="form.data12" placeholder="请输入集成评分" @input="inputZAction(form, 'data12')" v-trim/>
      </el-form-item>
      <el-form-item label="信息安全" prop="data13">
        <el-input v-model="form.data13" placeholder="请输入信息安全评分" @input="inputZAction(form, 'data13')" v-trim/>
      </el-form-item>
      <el-form-item label="装备" prop="data14">
        <el-input v-model="form.data14" placeholder="请输入装备评分" @input="inputZAction(form, 'data14')" v-trim/>
      </el-form-item>
      <el-form-item label="网络" prop="data15">
        <el-input v-model="form.data15" placeholder="请输入网络评分" @input="inputZAction(form, 'data15')" v-trim/>
      </el-form-item>
      <el-form-item label="产品设计" prop="data16">
        <el-input v-model="form.data16" placeholder="请输入产品设计评分" @input="inputZAction(form, 'data16')" v-trim/>
      </el-form-item>
      <el-form-item label="工艺设计" prop="data17">
        <el-input v-model="form.data17" placeholder="请输入工艺设计评分" @input="inputZAction(form, 'data17')" v-trim/>
      </el-form-item>
      <el-form-item label="采购" prop="data18">
        <el-input v-model="form.data18" placeholder="请输入采购评分" @input="inputZAction(form, 'data18')" v-trim/>
      </el-form-item>
      <el-form-item label="计划与调度" prop="data19">
        <el-input v-model="form.data19" placeholder="请输入计划与调度评分" @input="inputZAction(form, 'data19')" v-trim/>
      </el-form-item>
      <el-form-item label="生产作业" prop="data20">
        <el-input v-model="form.data20" placeholder="请输入生产作业评分" @input="inputZAction(form, 'data20')" v-trim/>
      </el-form-item>
      <el-form-item label="设备管理" prop="data21">
        <el-input v-model="form.data21" placeholder="请输入设备管理评分" @input="inputZAction(form, 'data21')" v-trim/>
      </el-form-item>
      <el-form-item label="安全环保" prop="data22">
        <el-input v-model="form.data22" placeholder="请输入安全环保评分" @input="inputZAction(form, 'data22')" v-trim/>
      </el-form-item>
      <el-form-item label="仓储配送" prop="data23">
        <el-input v-model="form.data23" placeholder="请输入仓储配送评分" @input="inputZAction(form, 'data23')" v-trim/>
      </el-form-item>
      <el-form-item label="能源管理" prop="data24">
        <el-input v-model="form.data24" placeholder="请输入能源管理评分" @input="inputZAction(form, 'data24')" v-trim/>
      </el-form-item>
      <el-form-item label="物流(子域)" prop="data25">
        <el-input v-model="form.data25" placeholder="请输入物流评分" @input="inputZAction(form, 'data25')" v-trim/>
      </el-form-item>
      <el-form-item label="销售(子域)" prop="data26">
        <el-input v-model="form.data26" placeholder="请输入销售评分" @input="inputZAction(form, 'data26')" v-trim/>
      </el-form-item>
      <el-form-item label="客户服务" prop="data27">
        <el-input v-model="form.data27" placeholder="请输入客户服务评分" @input="inputZAction(form, 'data27')" v-trim/>
      </el-form-item>
      <el-form-item label="产品服务" prop="data28">
        <el-input v-model="form.data28" placeholder="请输入产品服务评分" @input="inputZAction(form, 'data28')" v-trim/>
      </el-form-item>
      <!-- <el-form-item label="总得分" prop="data">
        <el-input v-model="form.data" placeholder="请输入" v-trim/>
      </el-form-item> -->
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { treeList } from '@/api/business/areas'
export default {
  name: 'OperaCmmWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        remark: '',
        name: '',
        serveOrg: '',
        industry: '',
        provinceId: '',
        cityId: '',
        areaId: '',
        areaname: '',
        revenue: '',
        diagnoseType: '',
        level: '',
        data0: '',
        data2: '',
        data3: '',
        data4: '',
        data5: '',
        data6: '',
        data7: '',
        data8: '',
        data1: '',
        data9: '',
        data10: '',
        data11: '',
        data12: '',
        data13: '',
        data14: '',
        data15: '',
        data16: '',
        data17: '',
        data18: '',
        data19: '',
        data20: '',
        data21: '',
        data22: '',
        data23: '',
        data24: '',
        data25: '',
        data26: '',
        data27: '',
        data28: '',
      },
      serveList: [],
      province: [],
      cities: [],
      areas: [],
      levels: [
        { label: '未达标', id: 1 },
        { label: '一级', id: 2 },
        { label: '二级', id: 3 },
        { label: '三级', id: 4 },
        { label: '四级', id: 5 },
      ],
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/cmm',
      'field.id': 'id'
    })
    // 地区
    treeList({ type: 2, parentId: '340200' })
      .then(res => {
        // console.log(res)
        this.province = res
      })
      .catch(e => {
        this.$message.error(e)
      })
  },
  methods: {
    // 选择省份
    selectProvince (val) {
      this.cities = []
      this.areas = []
      this.form.cityId = ''
      this.form.areaId = ''
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
        })
      // const tempProvince = this.province.find(item => item.id === val)
      // if (tempProvince) {
      //   this.cities = tempProvince.childList
      // }
    },
    selectCity (val) {
      this.areas = []
      this.form.areaId = ''
      treeList({ type: 2, parentId: val })
        .then(res => {
          this.areas = res
        })
      // const tempCity = this.cities.find(item => item.id === val)
      // if (tempCity) {
      //   this.areas = tempCity.childList
      // }
    },
    inputZAction(item, key) {
      item[key] = item[key].replace(/[^\d.]/g, '')
        .replace(/\.{2,}/g, '.')
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        .replace(/^\./g, '')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
.line {
  width: 10px;
}
.title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #111;
}
</style>
