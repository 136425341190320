<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="125px" label-suffix="：" inline>
      <div>
        <el-form-item label="登录账号" prop="username">
          <el-input v-model="form.username" placeholder="请输入登录账号" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="企业名称" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请输入企业名称" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="所属区域" prop="areaId">
          <div class="address-plus">
            <el-select v-model="form.provinceId" placeholder="请选择省份" @change="selectProvince">
              <el-option
                v-for="item in province()"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <el-select v-model="form.cityId" placeholder="请选择城市" @change="selectCity">
              <el-option
                v-for="item in cities"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <el-select v-model="form.areaId" placeholder="请选择县区">
              <el-option
                  v-for="item in areas"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="社会信用代码" prop="creditCode">
          <el-input v-model="form.creditCode" placeholder="请输入社会信用代码" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="法定代表人" prop="legalPerson">
          <el-input v-model="form.legalPerson" placeholder="请输入法定代表人" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="联系人" prop="linkName">
          <el-input v-model="form.linkName" placeholder="请输入姓名" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号" v-trim/>
        </el-form-item>
      </div>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { checkMobile, nameRule, creditCode } from '@/utils/form'
import { treeList } from '@/api/business/areas'
export default {
  name: 'AddCompanyUserWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    let newRule = (rule, value, callback) => {
      if (!this.form.provinceId) {
        callback(new Error('请先选择省份'))
      } else if (!this.form.cityId) {
        callback(new Error('请先选择城市'))
      } else if (!this.form.areaId) {
        callback(new Error('请先选择县区'))
      } else {
        callback()
      }
    }
    return {
      // 表单数据
      form: {
        id: null,
        linkName: '',
        username: '',
        companyName: '',
        creditCode: '',
        legalPerson: '',
        provinceId: '',
        cityId: '',
        areaId: '',
        mobile: '',
        type: '1',
        password: '123456'
      },
      cities: [],
      areas: [],
      // 验证规则
      rules: {
        username: [
          { required: true, message: '请输入用户名称', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur'}
        ],
        areaId: [
          { required: true, validator: newRule, trigger: 'blur'}
        ],
        creditCode: [
          { required: true, validator: creditCode, trigger: 'blur'}
        ],
        mobile: [
          { required: false, validator: checkMobile, trigger: 'blur'}
        ],
        legalPerson: [
          { required: false, validator: nameRule, trigger: 'blur'}
        ],
        linkName: [
          { required: false, validator: nameRule, trigger: 'blur'}
        ]
      }
    }
  },
  inject: ['province'],
  created () {
    this.config({
      api: '/business/account',
      'field.id': 'id'
    })
    
  },
  methods: {
    open(title, target) {
      this.title = title
      this.visible = true
      this.form.provinceId = ''
      this.form.cityId = ''
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.selectProvince(this.form.provinceId, true)
      })
    },
    
    // 选择省份
    selectProvince(val, isInit) {
      if (!isInit) {
        this.cities = []
        this.areas = []
        this.form.cityId = ''
        this.form.areaId = ''
      }
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
          if (isInit) {
            this.selectCity(this.form.cityId, isInit)
          }
        })
      // const tempProvince = this.province().find(item => item.id === val)
      // if (tempProvince) {
      //   this.cities = tempProvince.childList
      //   isInit && this.selectCity(this.form.cityId, isInit)
      // }
    },
    // 选择城市
    selectCity(val, isInit) {
      if (!isInit) {
        this.areas = []
        this.form.areaId = ''
      }
      treeList({ type: 2, parentId: val })
        .then(res => {
          this.areas = res
          
        })
      // const tempCity = this.cities.find(item => item.id === val)
      // if (tempCity) {
      //   this.areas = tempCity.childList
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";

</style>
