<template>
  <div class="contant">
    <CommonHeader :isLogined="false" />
    <div class="login-content">
      <div class="wrap">
        
        <div class="introduce">
          <h2>智能制造诊断综合服务平台</h2>
          <!-- <h2>合肥市智改数转诊断服务平台</h2> -->
        </div>
        <div class="login">
          <h1>账号登录</h1>
          <div class="info-input">
            <div class="login-title">用户名</div>
            <el-input v-model="username" placeholder="请输入用户名" maxlength="50" v-trim/>
            <div class="login-title">密码</div>
            <el-input v-model="password" placeholder="请输入密码" type="password" maxlength="30" show-password/>
            <div class="login-title">验证码</div>
            <div class="captcha-input">
              <el-input v-model="captcha.value" placeholder="图片验证码" maxlength="4" @keypress.enter.native="login"/>
              <img v-if="!captcha.loading" :src="captcha.uri" @click="refreshCaptcha">
              <span v-else><i class="el-icon-loading"></i></span>
            </div>
          </div>
          <div class="loginBtn"><el-button :loading="loading" @click="login">立即登录</el-button></div>
          <div class="register">
            还没有账号？<el-button type="text" @click="gotoRegister(0)" size="medium">去注册</el-button>
            <!-- <div style="display: inline-block;margin-left: 10px;margin-right: 10px;">|</div>
            <el-button type="text" @click="gotoRegister(1)" size="medium">服务机构注册</el-button> -->
          </div>
        </div>
      </div>
      <div class="copyright">Copyright © 2022 - 2023 智能制造诊断综合服务平台. All Rights Reserved.  版权所有</div>
      <!-- <div class="copyright">Copyright © 2022 - 2023 合肥市智改数转诊断服务平台. All Rights Reserved.  版权所有</div> -->
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CommonHeader from '@/components/common/CommonHeader'
import { getCaptcha, loginByPassword } from '@/api/system/common'

export default {
  name: 'Login',
  components: {
    CommonHeader
  },
  data () {
    return {
      loading: false,
      username: '',
      password: '',
      // 验证码
      captcha: {
        loading: false,
        value: '',
        uuid: '',
        uri: ''
      }
    }
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    // 登录
    login () {
      if (this.loading) {
        return
      }
      if (!this.__check()) {
        return
      }
      this.loading = true
      loginByPassword({
        username: this.username.trim(),
        password: this.password,
        code: this.captcha.value.trim(),
        uuid: this.captcha.uuid
      })
        .then(() => {
          window.location.href = process.env.VUE_APP_CONTEXT_PATH
        })
        .catch(e => {
          this.refreshCaptcha()
          this.$tip.error(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 刷新验证码
    refreshCaptcha () {
      this.captcha.loading = true
      getCaptcha()
        .then(data => {
          this.captcha.uri = data.image
          this.captcha.uuid = data.uuid
        })
        .catch(e => {
          this.$tip.error(e)
        })
        .finally(() => {
          setTimeout(() => {
            this.captcha.loading = false
          }, 150)
        })
    },
    // 登录前验证
    __check () {
      // debugger
      if (this.username.trim() === '') {
        this.$message.error('请输入用户名')
        return false
      }
      if (this.password === '') {
        this.$message.error('请输入密码')
        return false
      }
      if (this.captcha.value.trim() === '') {
        this.$message.error('请输入图片验证码')
        return false
      }
      return true
    },
    gotoRegister(type) {
      this.$router.push({ name: 'registerSelect' })
      // if (type) {
      // } else {
      //   this.$router.push({ name: 'register' })
      // }
    }
  },
  created () {
    this.refreshCaptcha()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
$input-gap: 30px;
.contant {
  height: 100%;
  background-color: #F3F7FA;
  
}
.login-content {
  height: calc(100% - 70px);
  background-image: url("../assets/images/login_bg.png");
  background-repeat: no-repeat;
  // background-size: auto 180%;
  background-clip: content-box;
  background-position: center;
  position: relative;
}
.wrap {
  display: flex;
  width: 900px;
  height: 570px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // background-image: url("../assets/images/login.jpg");
  // background-repeat: no-repeat;
  // background-size: auto 180%;
  // background-clip: content-box;
  // background-position: center;
  box-shadow: 0px 20px 60px 0px rgba(0,0,0,0.11);
  border-radius: 8px;
  // 左边介绍
  .introduce {
    // padding-left: 10%;
    width: 420px;
    height: 100%;
    box-sizing: border-box;
    background-image: url("../assets/images/login_img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background-clip: content-box;
    background-position: center;
    color: #fff;
    // background: rgba(0, 0, 0, 0.4);
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    h2 {
      text-align: center;
      font-size:28px;
      // font-style: italic;
      font-weight: 900;
      margin-top: 75px;
      line-height: 34px;
      text-shadow: 0px 2px 14px rgba(0,0,0,0.17);
    }
    
  }
  // 右边登录
  .login {
    height: 100%;
    width: 480px;
    // max-width: 560px;
    // min-width: 460px;
    flex-shrink: 0;
    box-sizing: border-box;
    // text-align: center;
    background: #fff;
    padding: 120px 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    h1 {
      font-size: 26px;
      font-weight: 600;
      color: #222222;
    }
    .info-input {
      margin-top: 46.5px;
      margin-bottom: 30px;
      ::v-deep .el-input {
        margin-top: 10px;
        margin-bottom: 18px;
        .el-input__inner {
          height: 30px;
          // background-color: #fff;
          // border: 1px solid transparent;
          &:focus {
            border: 1px solid $primary-color;
          }
        }
      }
    }
    // 验证码输入
    .captcha-input {
      display: flex;
      margin-top: 12px;
      height: 30px;
      .el-input {
        width: 100%;
        margin-top: 0;
      }
      span, img {
        width: 45%;
        height: 100%;
        flex-shrink: 0;
        margin-left: 16px;
        background-color: #f7f7f7;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f7f7f7;
        border-radius: 8px;
      }
    }
    .loginBtn {
      .el-button {
        height: 35px;
        width: 100%;
        color: #fff;
        font-size: 12px;
        background: linear-gradient(130deg, $primary-title-start-color 0%, $primary-title-color 100%);
      }
    }
  }
}
.copyright {
  text-align: center;
  width: 100%;
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #828D9E;
  line-height: 12px;
  position: absolute;
  bottom: 20px;
}
.login-title {
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 14px;

}
.register {
  margin-top: 18px;
  height: 14px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // color: #DFE2E8;
  color: #333;
  // font-weight: 600;
  line-height: 14px;
  
}
</style>
