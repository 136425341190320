let decimalPoint = 2
let patternStr = ''
patternStr = /^(\-)*(\d+)\.(\d\d).*$/
export default {
  // 价格 正负数
  inserted: function (el, binding, vnode) {
    let input = vnode.elm.children[0]
    input.addEventListener('compositionstart', () => {
      vnode.inputLocking = true
    })
    input.addEventListener('compositionend', () => {
      vnode.inputLocking = false
      input.dispatchEvent(new Event('input'))
    })
    input.addEventListener('input', () => {
      if (vnode.inputLocking) {
        return
      }
      let oldValue = input.value
      let newValue = input.value
      let obj = input
      let t = obj.value.charAt(0)
      obj.value = obj.value
        .replace('.', '$#$') //把第一个字符'.'替换成'$#$'
        .replace(/\./g, '') //把其余的字符'.'替换为空
        .replace('$#$', '.') //把字符'$#$'替换回原来的'.'
        .replace(/[^\d.]/g, '') //只能输入数字和'.'
        .replace(/^\./g, '') //不能以'.'开头
      // .replace(/([0-9]+\.[0-9]{2})[0-9]*/, '$1') //只保留2位小数
      obj.value = obj.value.replace(new RegExp(patternStr, 'g'), '$1$2.$3') //只保留2位小数
      if (t == '-') {
        obj.value = '-' + obj.value
      }
      // 判断是否需要更新，避免进⼊死循环
      if (newValue !== oldValue) {
        input.value = newValue
        input.dispatchEvent(new Event('input')) // 通知v-model更新
      }
    })
    // input 事件⽆法处理⼩数点后⾯全是零的情况因为⽆法确定⽤户输⼊的0是否真的应该清除，如3.02。放在blur中去处理
    input.addEventListener('blur', () => {
      let oldValue = input.value
      let newValue = input.value
      if (newValue) {
        newValue = Number(newValue).toString()
      }
      // 判断是否需要更新，避免进⼊死循环
      if (newValue !== oldValue) {
        input.value = newValue
        input.dispatchEvent(new Event('input')) // 通知v-model更新
      }
    })
  }
}