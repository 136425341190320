<template>
  <GlobalQuestionWindow
    :title="title"
    width="60%"
    top="5vh"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <div v-html="content"></div>
    <div slot="footer">
      <el-button @click="visible=false">返回</el-button>
    </div>
  </GlobalQuestionWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalQuestionWindow from '@/components/common/GlobalQuestionWindow'
import { companyUserRules } from '@/api/system/dictData'
export default {
  name: 'Question',
  extends: BaseOpera,
  components: { GlobalQuestionWindow },
  data () {
    return {
      content: '',
      // 表单数据
      form: {
        id: '',
      },
      type: 0, // 0 查看 1 答题
      companyId: '',
      baseForm: {},
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    open(title, target) {
      this.title = title
      this.visible = true
      this.getProtocl()
    },
    getProtocl() {
      companyUserRules()
        .then(res => {
          console.log(res);
          this.content = res.code
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
    
  },
}
</script>

<style lang="scss" scoped>
.title {
  height: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  line-height: 20px;
  text-align: center;
}
.line {
  margin: 24px 0;
  background-color: #DFE2E8;
  height: 1px;
}
.problem {
  margin-bottom: 30px;
  .problem-title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 24px;
  }
}
.option {
  margin-top: 25px;
  display: flex;
  .el-radio {
    margin-right: 0px;
  }
  ::v-deep .el-radio__label {
    width: 0;
  }
  .value{
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    vertical-align: middle;
  }
  .mult-value{
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    margin-left: 10px;
  }
}
</style>
