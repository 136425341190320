<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" label-width="100px" label-suffix="：" :rules="rules">
      <!-- <el-form-item :label="form.type==0?'市名称':'县区名称'" prop="name">
        <el-input v-model="form.name" :placeholder="form.type==0?'输入市名称':'输入县区名称'" v-trim/>
      </el-form-item> -->
      <el-form-item label="退回原因" prop="backInfo">
        <el-input v-model="form.backInfo" type="textarea" placeholder="请输入退回原因" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { backSoServer } from '@/api/business/declares'
export default {
  name: 'OperaAreasWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        declareId: null,
        backInfo: null,
        // name: null,
        // sortnum: '0',
        // type: '',
      },
      // 验证规则
      rules: {
        backInfo: [
          // { required: true, message: '请输入退回原因', tigger: 'blur' }
          { required: true, message: '请输入退回原因', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/business/areas',
      'field.id': 'id'
    })
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        backSoServer(this.form)
          .then(res => {
            this.$emit('success')
            this.$message.success('退回成功')
            this.visible = false
          })
          .catch(e => {
            this.$message.error(e)
          }) 
      })
      
    },
    
  },
}
</script>
