<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
  >
    <div class="tip-style fail-style" v-if="changeMessag && changeMessag.status == 2"><img src="@/assets/images/ic_shenheshibai.png" alt=""> 审核不通过：{{ changeMessag.checkInfo }}</div>
    <div class="tip-style success-style" v-else-if="changeMessag && changeMessag.status == 1" ><img src="@/assets/images/ic_shenhetongguo.png" alt="">审核通过！</div>
    <div class="tip-style" v-else><img src="@/assets/images/ic_shenhezhong.png" alt="">正在审核中，请耐心等待，将在24小时内完成审核。</div>
    <div class="company-message">
      <div class="message-content">
          <el-form
            label-width="200px"
            label-suffix="："
            inline
          >
            <div>
              <el-form-item :label="userInfo.type == 2 ?'企业(单位)名称：' : '企业名称：'">{{ changeMessag.name }}</el-form-item>
            </div>
            <div>
              <el-form-item label="统一社会信用代码">{{ changeMessag.creditCode }}</el-form-item>
            </div>
            <div>
              <el-form-item label="所属区域">{{ changeMessag.companyAddress }}</el-form-item>
            </div>
            <div>
              <el-form-item label="企业法定代表人">{{ changeMessag.legalPerson }}</el-form-item>
            </div>
            <div>
              <!-- <el-form-item label="营业执照/事业单位、社会团体登记证"> -->
              <el-form-item :label="userInfo.type == 2 ?'营业执照/事业单位、社会团体登记证' : '营业执照'">
                <!-- <div v-for="(item, index) in changeMessag.fileurls" :key="index">
                  <img :src="item" alt="">
                </div> -->
                <el-image
                  style="margin-left: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in changeMessag.fileurls"
                  :key="index"
                  :initial-index="index"
                  :src="item"
                  :preview-src-list="changeMessag.fileurls"
                  :z-index="3000"
                ></el-image>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="工商变更登记">
                <!-- <div v-for="(item, index) in changeMessag.fileChangeUrls" :key="index">
                  <img :src="item" alt="">
                </div> -->
                <el-image
                  style="margin-left: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in changeMessag.fileChangeUrls"
                  :key="index"
                  :initial-index="index"
                  :src="item"
                  :preview-src-list="changeMessag.fileChangeUrls"
                  :z-index="3000"
                ></el-image>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    <div slot="footer">
      <el-button @click="changeCompanyMessage" v-if="changeMessag && (changeMessag.status == 2 || changeMessag.status == 1)" type="primary">重新变更企业信息</el-button>
      <el-button @click="visible=false">返回</el-button>
    </div>
    <OperaChangeCompanyWindow ref="operaChangeCompanyWindow" @success="closeThis"/>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { getCompanyChance } from '@/api/business/companyChange'
import OperaChangeCompanyWindow from '@/components/business/OperaChangeCompanyWindow'
import { mapState } from 'vuex'
export default {
  name: 'OperaCompanyChangeIngWindow',
  extends: BaseOpera,
  components: { GlobalWindow,OperaChangeCompanyWindow },
  data () {
    return {
      form: {
        companyId: ''
      },
      changeMessag: {},
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  created () {
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        
        this.initData()
      })
    },
    closeThis(){
      this.visible =false
    },
    changeCompanyMessage() {
      // console.log(this.changeMessag)
      // debugger
      this.$refs.operaChangeCompanyWindow.open('变更企业信息', this.changeMessag)
    },
    initData() {
      getCompanyChance(this.form.companyId)
        .then(res => {
          // debugger
          this.changeMessag = res
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.company-message {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .sub-company-message {
    width: calc(50% - 6px);
    margin-top: 20px;
    .message-title {
      height: 14px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 14px;
    }
    .after {
      color: #216EEE;
    }
    .message-content {
      border-radius: 2px;
      border: 1px solid #DFE2E8;
      margin-top: 16px;
      padding: 20px;
      ::v-deep .el-form-item {
        margin-bottom: 1px;
      }
    }
  }
}
.long-title-style {
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  overflow: hidden;
}
img {
  width: 90px;
  height: 90px;
}
.tip-style {
  background: rgba(33,110,238,0.1);
  color: #216EEE;
  display: flex;
  height: 44px;
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 44px;
  padding-left: 20px;
  img {
    width: 16px;
    height: 16px;
    margin: auto 8px auto 0;
  }
  margin-bottom: 20px;
}

.fail-style {
  background: rgba(238,56,33,0.1);
  color: #EE3821;
}
.success-style {
  background: rgba(0,186,126,0.1);
  color: #00BA7E;
}

</style>
