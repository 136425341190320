<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" label-width="120px" label-suffix="：" :rules="rules" inline>
      <div class="input-style">
        <el-form-item label="登录账号" prop="username">
          <el-input v-model="form.username" placeholder="请输入登录账号" v-trim/>
        </el-form-item>
      </div>
      <div class="input-style">
        <el-form-item label="姓名" prop="realname">
          <el-input v-model="form.realname" placeholder="请输入姓名" v-trim/>
        </el-form-item>
      </div>
      <div class="input-style">
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号" v-trim/>
        </el-form-item>
      </div>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { checkMobile, nameRule } from '@/utils/form'
export default {
  name: 'OperaCompanyUserWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {

    return {
      // 表单数据
      form: {
        id: null,
        username: '',
        realname: '',
        companyId: '',
        mobile: '',
        type: ''
      },
      // 验证规则
      rules: {
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        realname: [
          { required: false, validator: nameRule, trigger: 'blur'}
        ],
        mobile: [
          { required: false, validator: checkMobile, trigger: 'blur'}
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/business/childrenUser',
      'field.id': 'id'
    })
  }
}
</script>

<style lang="scss" scoped>
.input-style {
  ::v-deep .el-input__inner {
    width: 200px;
  }
}
</style>


