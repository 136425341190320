<template>
  <TableLayout :permissions="['business:cmmdata:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="主键" prop="id">
        <el-input v-model="searchForm.id" placeholder="请输入主键" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建人编码" prop="creator">
        <el-input v-model="searchForm.creator" placeholder="请输入创建人编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="searchForm.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建时间" @change="search"/>
      </el-form-item>
      <el-form-item label="更新人编码" prop="editor">
        <el-input v-model="searchForm.editor" placeholder="请输入更新人编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="editDate">
        <el-date-picker v-model="searchForm.editDate" value-format="yyyy-MM-dd" placeholder="请输入更新时间" @change="search"/>
      </el-form-item>
      <el-form-item label="是否删除0否 1是" prop="isdeleted">
        <el-input v-model="searchForm.isdeleted" placeholder="请输入是否删除0否 1是" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="searchForm.remark" placeholder="请输入备注" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="关联总表编码（cmm)" prop="cmmId">
        <el-input v-model="searchForm.cmmId" placeholder="请输入关联总表编码（cmm)" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="数据类型 0人员 1技术 2资源 3设计4生产 5物流 6销售 7服务 8总得分 9组织战略 10人员技能	11数据 12集成 14信息安全	14装备 15网络 16产品设计	17工艺设计 18采购 19计划与调度 20生产作业 21设备管理 22安全与环保 23仓储与配送 24能源管理 25物流(子域) 26销售(子域) 27客户服务 28产品服务
            " prop="type">
        <el-input v-model="searchForm.type" placeholder="请输入数据类型 0人员 1技术 2资源 3设计4生产 5物流 6销售 7服务 8总得分 9组织战略 10人员技能	11数据 12集成 14信息安全	14装备 15网络 16产品设计	17工艺设计 18采购 19计划与调度 20生产作业 21设备管理 22安全与环保 23仓储与配送 24能源管理 25物流(子域) 26销售(子域) 27客户服务 28产品服务
            " @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="数据值" prop="val">
        <el-input v-model="searchForm.val" placeholder="请输入数据值" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="数据类型名称" prop="typeName">
        <el-input v-model="searchForm.typeName" placeholder="请输入数据类型名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="primary" :loading="isWorking.export" v-permissions="['business:cmmdata:exportExcel']" @click="exportExcel">导出</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['business:cmmdata:create', 'business:cmmdata:delete']">
        <li><el-button type="primary" @click="$refs.operaCmmDataWindow.open('新建企业能力成熟度数据明细行')" icon="el-icon-plus" v-permissions="['business:cmmdata:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['business:cmmdata:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="creator" label="创建人编码" min-width="100px"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" min-width="100px"></el-table-column>
        <el-table-column prop="editor" label="更新人编码" min-width="100px"></el-table-column>
        <el-table-column prop="editDate" label="更新时间" min-width="100px"></el-table-column>
        <el-table-column prop="isdeleted" label="是否删除0否 1是" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column>
        <el-table-column prop="cmmId" label="关联总表编码（cmm)" min-width="100px"></el-table-column>
        <el-table-column prop="type" label="数据类型 0人员 1技术 2资源 3设计4生产 5物流 6销售 7服务 8总得分 9组织战略 10人员技能	11数据 12集成 14信息安全	14装备 15网络 16产品设计	17工艺设计 18采购 19计划与调度 20生产作业 21设备管理 22安全与环保 23仓储与配送 24能源管理 25物流(子域) 26销售(子域) 27客户服务 28产品服务
            " min-width="100px"></el-table-column>
        <el-table-column prop="val" label="数据值" min-width="100px"></el-table-column>
        <el-table-column prop="typeName" label="数据类型名称" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['business:cmmdata:update', 'business:cmmdata:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCmmDataWindow.open('编辑企业能力成熟度数据明细行', row)" icon="el-icon-edit" v-permissions="['business:cmmdata:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['business:cmmdata:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCmmDataWindow ref="operaCmmDataWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCmmDataWindow from '@/components/business/OperaCmmDataWindow'
export default {
  name: 'CmmData',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCmmDataWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        remark: '',
        cmmId: '',
        type: '',
        val: '',
        typeName: ''
      }
    }
  },
  created () {
    this.config({
      module: '企业能力成熟度数据明细行',
      api: '/business/cmmData',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
