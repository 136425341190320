import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  // 登录用户信息
  userInfo: null,
  canJump: true,
  // 首页
  homePage: null,
  // 菜单
  menuData: {
    // 菜单列表
    list: [],
    // 是否收起
    collapse: false
  },
  //tags数组
  tags:[],
  //tagsview标签显示隐藏
  isCollapse:false
}

const mutations = {
  // 切换菜单状态
  switchCollapseMenu (state, value) {
    if (value != null) {
      state.menuData.collapse = value
    } else {
      state.menuData.collapse = !state.menuData.collapse
    }
    window.localStorage.setItem('MENU_STATUS', state.menuData.collapse)
  },
  // 设置已登录的用户信息
  setUserInfo: (state, data) => {
    state.userInfo = {
      ...state.userInfo,
      ...data
    }
  },
  setJump: (state, data) => {
    state.canJump = data
  },
  // 设置首页路由信息
  setHomePage (state, homePage) {
    state.homePage = homePage
  },
  // 重置菜单
  resetMenus: (state) => {
    state.menuData.list = []
  },
  //  tags
  pushtags(state,val){
    //如果等于-1说明tabs不存在那么插入，否则什么都不做
    //findindex找角标，循环判断一下，如果等于那么就代表有相同的，就不必添加，如果找不到那就是-1.就添加
    let result = state.tags.findIndex(item => item.label === val.label)
    if (result === -1) {
      state.tags.push({...val, keepAlive: false})
    } else {
      state.tags[result]= {...val, keepAlive: true}
    }
    // result === -1 ? state.tags.push(val) : (state.tags[result]==val)
  },
  //关闭标签
  closeTab(state, val) {
    //同上，找角标，然后用角标的位置对应删除一位。splice：这是数组的删除方法
    let result = state.tags.findIndex(item => item.label === val.label)
    state.tags.splice(result, 1)
  },
  //关闭所有tagsview标签
  cleartagsview(state,val){
    //清空数组
    state.tags=[]
    //跳转到首页,val接受传过来的当前路由
    if(val !== "/index"){
      router.push({path:"/index"})
    }
  },
  //改变tagsview显示隐藏
  changeisshow(state){
    state.isCollapse=!state.isCollapse
  }
}
const actions = {}
const getters = {}
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
