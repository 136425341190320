<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="submit"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="form.status != '2' ? rules : rule"
    >
      <div>
        <el-form-item label="审核状态：">
          <el-select
            v-model="form.status"
            @change="chechStatus"
          >
            <el-option
              v-for="item in approveResults"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item
          class="check-with"
          label="审核备注："
          prop="remark"
        >
          <el-input
            type="textarea"
            :autosize="{minRows: 4}"
            placeholder="请简要描述审核备注，不超过300字"
            maxlength="300"
            v-model="form.remark"
          />
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="submit" :loading="isWorking" type="primary">提交</el-button>
      <el-button @click="visible=false">返回</el-button>
    </div>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { areaToExamine } from '@/api/business/declares'

export default {
  name: 'ApproveApplyWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        status: '2',
        remark: '',
        id: ''
      },
      // 验证规则
      rules: {
        status: [
          { required: true, message: '请选择审核状态', trigger: 'change' },
        ],
        remark: [
          { required: true, message: '请输入审核备注', trigger: 'blur' },
          { max: 300, message:'输入字数不超过300', trigger: 'change'}
        ]
      },
      rule: {
        status: [
          { required: true, message: '请选择审核状态', trigger: 'change' },
        ],
        remark: [
          { max: 300, message:'输入字数不超过300', trigger: 'change'}
        ]
      },
      approveResults: [
        // 2审核通过、3退回修改、4审核驳回
        { label:'审核通过', value: '2' },
        { label:'退回修改', value: '3' },
        { label:'审核驳回', value: '4' }
      ]
    }
  },
  created () {
    
  },
  methods: {
    submit() {
      this.$refs.form.validate(
        v=>{
          if (!v) {
            return
          }
          console.log('审核')
          this.isWorking = true
          areaToExamine({
            ...this.form
          }).then(res => {
            console.log(res)
            this.$emit('success')
            this.$message.success('审核操作成功！')
          }).catch (err => {
            this.$message.error(err.message)
          }).finally(() => {
            this.cleanRewardForm()
            this.isWorking = false
            this.visible = false
          })
      })
    },
    chechStatus(v) {
      console.log(v)
      if (v === '1') {
        this.$refs.form.clearValidate()
      }
    },
    cleanRewardForm() {
      this.form.approveStatus = ''
      this.form.detail = ''
      this.form.learningCircleId = ''
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";

::v-deep .el-select {
  width: 100%;
  .el-input__inner {
    width: 100%;
  }
}
::v-deep .el-textarea__inner {
  width: 100%;
}
</style>
