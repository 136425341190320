<template>
  <div style="width:100%">
    <CommonHeader />
    <div style="height:10px; background: #fff"></div>
    <el-container class="app-layout">
      <el-aside :class="{collapse:menuData.collapse}">
        <Menu/>
      </el-aside>
      <el-main>
        <header>
          <AppHeader/>
        </header>
        <div style="height:10px;"></div>
        <main>
          <transition name="fade">
            <router-view></router-view>
          </transition>
        </main>
      </el-main>
    </el-container>
    <div class="comfirm" v-if="isFinishData">
      <div class="container">
        <div>您还没有完善资料，是否去完善资料？</div>
        <div class="complete">
          <el-button type="warning" @click="complete">去完善</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { judgeCompanyServiceIsFinish } from '@/api/business/company'
import Header from '@/components/common/Header'
import CommonHeader from '@/components/common/CommonHeader'
import Menu from '@/components/common/Menu'
export default {
  name: 'DefaultLayout',
  components: { AppHeader: Header, Menu, CommonHeader },
  data() {
    return {
      isFinishData: false
    }
  },
  computed: {
    ...mapState(['menuData', 'userInfo'])
  },
  created() {
    if (this.userInfo.type == 2) {
      judgeCompanyServiceIsFinish({ id: this.userInfo.companyId })
        .then(res => {
          console.log(res);
          this.isFinishData = !res.code
          this.$store.commit('setJump', res.code)
        })
        .catch(e => {
          this.$tip.error(e)
        })
    }
  },
  methods: {
    complete() {
      this.$router.push('/business/companyDetail')
      this.isFinishData = false
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.el-container {
  background: #F7F8F9;
  height: 100%;
  
  display: flex;
  // overflow: hidden;
  overflow: scroll;
  // 左边菜单
  .el-aside {
    width: $menu-width !important;
    flex-shrink: 0;
    // height: 100%;
    // height: 900px;
    height: calc(100vh - 80px);
    overflow-y: scroll;
    overflow-x: hidden;
    background: $primary-color;
    color: #fff;
    transition: width ease .3s;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    &.collapse {
      width: 64px !important;
    }
  }
  // 右边内容
  .el-main {
    width: 100%;
    // height: 100%;
    height: calc(100vh - 90px);
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    & > header {
      height: 48px;
      flex-shrink: 0;
    }
    & > main {
      height: 100%;
      overflow-y: auto;
    }
  }
}
// 页面过渡动画
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
  opacity: 1;
  position: absolute;
  width: 100%;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(200px);
  opacity: 0;
  transition: all .5s;
  position: absolute;
}
.comfirm {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  .container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #fff;
    width: 500px;
    height: 120px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .complete {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
</style>
