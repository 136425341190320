import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/declares/page', data, {
    trim: true
  })
}

// 企业用户查询
export function getPageByProjectId (data) {
  return request.post('/business/declares/getPageByProjectId', data, {
    trim: true
  })
}

// 企业用户查询
export function declaresDetail (id) {
  return request.get(`/business/declares/${id}`)
}

// 导出Excel
export function exportExcel (data) {
  return request.post('/business/declares/exportExcelForProject', data, {
    trim: true,
    download: true
  })
}

// 企业项目报名/保存
export function create (data) {
  return request.post('/business/declares/create', data)
}
// 企业项目修改诊断类型
export function updateDiagnoseType (data) {
  return request.post('/business/declares/updateDiagnoseType', data)
}
// 专家评分
export function checkExpertDeclares (data) {
  return request.post('/business/declares/checkExpertDeclares', data)
}

// 
export function findByProjectId (id) {
  return request.get(`/business/declares/findByProjectId?projectId=${id}`)
}

// 企业项目报名 县区审核
export function areaToExamine (data) {
  return request.post('/business/declares/areaToExamine', data)
}
// 分配服务机构保存
export function distributionServiceSave (data) {
  return request.post('/business/declares/distributionServiceSave', data)
}
// 企业申报选择服务机构保存
export function chooseService (data) {
  return request.post('/business/declares/chooseService', data)
}
// 服务机构确认-拒绝服务
export function confirmService (data) {
  return request.post('/business/declares/confirmService', data)
}
// 综合服务单位分配给子账号
export function distributionChildAccount (data) {
  return request.post('/business/declares/distributionChildAccount', data)
}
// 综合服务单位分配给子账号
export function backSoServer (data) {
  return request.post('/business/declareDone/backSoServer', data)
}
