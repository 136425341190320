<template>
  <TableLayout :permissions="['business:company:query']">
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['business:company:create', 'business:company:delete']">
        <li><el-button type="primary" @click="$refs.operaCompanyWindow.open('新建企业信息表')" icon="el-icon-plus" v-permissions="['business:company:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['business:company:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="主键" min-width="100px"></el-table-column>
        <el-table-column prop="deleted" label="是否已删除 0未删除 1已删除" min-width="100px"></el-table-column>
        <el-table-column prop="creator" label="创建人编码" min-width="100px"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" min-width="100px"></el-table-column>
        <el-table-column prop="editor" label="更新人编码" min-width="100px"></el-table-column>
        <el-table-column prop="editDate" label="更新时间" min-width="100px"></el-table-column>
        <el-table-column prop="isdeleted" label="是否删除0否 1是" min-width="100px"></el-table-column>
        <el-table-column prop="origin" label="数据来源 0平台注册 1后台导入" min-width="100px"></el-table-column>
        <el-table-column prop="name" label="名称（不可重复）" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column>
        <el-table-column prop="legalPerson" label="法定代表人姓名" min-width="100px"></el-table-column>
        <el-table-column prop="legalPersonPhone" label="法定代表人手机号码" min-width="100px"></el-table-column>
        <el-table-column prop="registerDate" label="成立时间" min-width="100px"></el-table-column>
        <el-table-column prop="areaId" label="所属区域编码（关联areas表）" min-width="100px"></el-table-column>
        <el-table-column prop="cityId" label="所属城市编码" min-width="100px"></el-table-column>
        <el-table-column prop="product" label="主营产品" min-width="100px"></el-table-column>
        <el-table-column prop="address" label="单位地址" min-width="100px"></el-table-column>
        <el-table-column prop="creditCode" label="统一社会信用代码，不可重复" min-width="100px"></el-table-column>
        <el-table-column prop="linkName" label="联系人姓名" min-width="100px"></el-table-column>
        <el-table-column prop="linkPhone" label="联系人电话" min-width="100px"></el-table-column>
        <el-table-column prop="natureId" label="企业性质编码（关联labels表）" min-width="100px"></el-table-column>
        <el-table-column prop="industryId" label="企业行业编码（关联labels表）" min-width="100px"></el-table-column>
        <el-table-column prop="content" label="企业简介" min-width="100px"></el-table-column>
        <el-table-column prop="type" label="企业类型0企业 1服务机构 2综合服务单位" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['business:company:update', 'business:company:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCompanyWindow.open('编辑企业信息表', row)" icon="el-icon-edit" v-permissions="['business:company:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['business:company:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCompanyWindow ref="operaCompanyWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCompanyWindow from '@/components/business/OperaCompanyWindow'
export default {
  name: 'Company',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCompanyWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        deleted: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        origin: '',
        name: '',
        remark: '',
        legalPerson: '',
        legalPersonPhone: '',
        registerDate: '',
        areaId: '',
        cityId: '',
        product: '',
        address: '',
        creditCode: '',
        linkName: '',
        linkPhone: '',
        natureId: '',
        industryId: '',
        content: '',
        type: ''
      }
    }
  },
  created () {
    this.config({
      module: '企业信息表',
      api: '/business/company',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
