<template>
  <div>
    <el-upload
      :action="uploadImgUrl"
      :data="uploadData"
      list-type="picture-card"
      :file-list="fileList"
      accept=".jpg,.png"
      :before-upload="beforeUpload"
      :on-success="uploadSuccess"
      :on-error="fail"
    >
      <i class="el-icon-plus icon"></i>
      <div slot="file" slot-scope="{file}">
        <img
          class="el-upload-list__item-thumbnail"
          :src="file.url" alt=""
          style="width: 100px;height: 100px;"
        >
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-image-viewer 
      v-if="showViewer" 
      :on-close="closeViewer"
      :initialIndex="tempIndex"
      :url-list="srcList"
      :z-index="3000"
    />
  </div>
  
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    ElImageViewer
  },
  props: {
    fileList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      uploadImgUrl: process.env.VUE_APP_API_PREFIX + '/public/uploadPicture',
      uploadData: {
        folder: 'znzz/company_file'
      },
      realList: [],
      srcList: [],
      tempIndex: 0,
      showViewer: false,
    }
  },
  watch: {
    fileList: {
      handler(val) {
        console.log(val);
        if (val.length==0) {
          this.realList = []
          this.srcList = []
        }
      }
      
    }
  },
  methods: {
    beforeUpload(file) {
      this.$emit('beginUpload')
      return true
    },
     // 上传图片成功
     uploadSuccess (res, file, fileList) {     
      // console.log('this.fileList', this.fileList);
      // console.log('fileList', fileList);
      this.$emit('endUpload')
      this.realList = fileList
      this.srcList.push(res.data.url)
      // console.log('file', file);
      if (res.code === 200) {
        this.fileList.push(
          {
            fileurl: res.data.imgaddr,
            name: res.data.originname,
            url: res.data.url
          }
        )
      } else {
        this.$message.error(res.msg || '上传失败')
      }
    },
    fail (err, file, fileList) {
      this.$emit('endUpload')
      this.$message.error('上传失败')
    },
    handlePictureCardPreview(file) {
      // console.log(file);
      this.tempIndex = this.fileList.findIndex(item => item.url == file.url )
      // console.log( this.tempIndex);
      this.srcList = this.fileList.map(item => item.url)
      // console.log( this.srcList);
      this.showViewer = true
    },
    closeViewer() {
      this.showViewer = false
    },
    handleRemove(file) {
      let tempIndex = this.fileList.findIndex(item => {
        // debugger
        let res = item.url === file.url
        // console.log(item.fileurl, file.response.data.imgaddr, res);
        return res
      })
      // debugger
      this.realList.splice(tempIndex, 1)
      this.fileList.splice(tempIndex, 1)
      this.srcList.splice(tempIndex, 1)
      
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-upload--picture-card{
  width: 90px !important;
  height: 90px !important;
}
::v-deep .el-upload-list__item {
  width: 90px !important;
  height: 90px !important;
}
.icon {
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(0%, -85%);
}
::v-deep .el-upload-list__item {
  width: 90px !important;
  height: 90px !important;
}
</style>

