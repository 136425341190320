import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/projectLabel/page', data)
}

// 新建
export function create (data) {
  return request.post('/business/projectLabel/create', data, {
  // return request.post('/system/user/create', data, {
    trim: true
  })
}
// 新建
export function updateById (data) {
  return request.post('/business/projectLabel/updateById', data, {
  // return request.post('/system/user/create', data, {
    trim: true
  })
}

// 删除
export function deleteById (id) {
  return request.get(`/business/projectLabel/delete/${id}`)
}

