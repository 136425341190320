<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <div class="title">
      <div
        class="status"
        :style="'font-weight: 600; border:none;' + (form.status == 2 ? 'color: #666666; background: #fff' : form.status == 1 ? 'color: #fff;  background: #00BA7E' : 'color: #fff;  background: #D74BFF')"
      >{{ form.status == 2 ? '项目已截止' : form.status == 1 ? '进行中' : '未开始' }}</div>
      {{ form.name }}
    </div>
    <div class="setting">
      <div class="setting-title">所属城市：<span class="setting-value">{{ form.cityName || '暂无' }}</span></div>
      <div class="parting-line"></div>
      <div class="setting-title">报名时间：<span class="setting-value">{{ `${form.startDate.substring(0,form.startDate.length-3)} ~ ${form.endDate.substring(0,form.startDate.length-3)}` }}</span></div>
      <div class="parting-line"></div>
      <!-- 0线上 1线下, -->
      <div class="setting-title">专家评分方式：<span class="setting-value">{{ form.scoreType == 0 ? '线上' : '线下' }}</span></div>
      <div class="parting-line"></div>
      <div class="setting-title">服务机构分配方式：<span class="setting-value">{{ form.sodType == 0 ? '双选' : form.sodType == 1 ? '市局分配' : '综合服务单位分配' }}</span></div>
      <!-- <div class="parting-line"></div> -->
    </div>
    <div class="setting">
      <div class="setting-title">专家分配角色：<span class="setting-value">{{ form.edType == 0 ? '市局分配' : '综合服务单位分配' }}</span></div>
      <div class="parting-line"></div>
      <div class="setting-title">县区审核：<span class="setting-value">{{ form.areaCheck == 0 ? '开启' : '关闭' }}</span></div>
      <div class="parting-line"></div>
      <div class="setting-title">最少服务次数：<span class="setting-value">{{ form.serviceLimt }}</span></div>
    </div>
    <div class="content">{{ form.content || '暂无' }}</div>
    <div class="line"></div>
    <div v-if="form.questionnaireId" class="item-line">
      <div class="item-value">
        <div class="value-label ">问卷信息：</div>
        <div class="value-value "><div class="btn" @click="showQuestion">查看详情</div></div>
      </div>
    </div>
    <div class="item-line">
      <div class="item-value">
        <div class="value-label ">附件上传要求：</div>
        <div class="value-value ">{{ form.fileReq }}</div>
      </div>
    </div>
    <div class="item-line">
      <div class="item-value">
        <div class="value-label ">上传服务数据说明：</div>
        <div class="value-value ">{{ form.serviceReq }}</div>
      </div>
    </div>
    <div class="item-line">
      <div class="item-value">
        <div class="value-label ">上传核查说明：</div>
        <div class="value-value ">{{ form.checkReq }}</div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="visible=false">返回</el-button>
    </div>
    <Question ref="question" />
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import Question from './Question'
export default {
  name: 'OperaProjectDetailWindow',
  extends: BaseOpera,
  components: { GlobalWindow, Question },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        cityName: null,
        cityId: '',
        areaCheck: '',
        content: '',
        createDate: '',
        edType: '',
        endDate: '',
        name: '',
        fileReq: '',
        startDate: '',
        imgurl: '',
        imgurlfull: '',
        provinceId: '',
        questionnaireId: '',
        scoreType: '',
        serverCompanyNum: '',
        serviceLimt: '',
        serviceReq: '',
        sodType: '',
        status: '',
        checkReq:''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
      })
    },
    confirm() {
      this.visible = false
    },
    showQuestion() {
      this.$refs.question.open('', { id: this.form.questionnaireId }, 0)
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 30px;
  height: 30px;
  .status {
    display: inline-block;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
    // line-height: 14px;
    line-height: 30px;
    height: 30px;
    border-radius: 4px;
    padding: 0 10px;
  }
}
.setting {
  display: flex;
  margin-top: 20px;
  .setting-title {
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 14px;
    .setting-value {
      font-weight: 600;
    }
  }
  .parting-line {
    height: 14px;
    background-color: #DFE2E8;
    width: 1px;
    margin: 0 15px;
  }
}
.content {
  margin-top: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}
.line {
  background-color: #DFE2E8;
  height: 1px;
  margin: 30px 0;
}

.item-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  height: auto;
  .item {
    width: 50%;
  }
}
.item-value {
  display: flex;
  font-size: 14px;
  line-height: 24px;
  .value-label {
    width: 146px;
    text-align: right;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    
  }
  .value-value {
    flex: 1;
  }
  .long-message {
    line-height: 24px;
  }
}
.btn {
  cursor: pointer;
  color: #216EEE;
}
</style>
