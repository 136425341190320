<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    width="500px"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <!-- {{ roleType }} -->
      <el-form-item label="角色名称" prop="roleType">
        <el-select :disabled="isEdit" v-model="form.roleType" placeholder="请选择角色">
          <el-option
            v-for="item in roleType"
            :key="item.value"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="评价权重(%)"  prop="rate">
        <!-- @input="inputZAction(form, 'ratio')" -->
        <el-input v-model="form.rate" @input="numInput(form, 'rate')" placeholder="请输入评价权重(0-100)" v-trim/>
      </el-form-item>
      <el-form-item label="评价方式" prop="type">
        <el-select v-model="form.type" placeholder="请选择评价方式">
          <el-option
            v-for="item in type"
            :key="item.value"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="评价方式" prop="type">
        <el-select v-model="form.type" placeholder="请选择评价方式">
          <el-option
            v-for="item in type"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item> -->
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
export default {
  name: 'OperaProjectRoleSorceWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        roleType: '',
        rate: '',
        projectId: '',
        type: 1
      },
      type: [
        { name: '线上', value: 0 },
        { name: '线下', value: 1 },
      ],
      roleType: [],
      isEdit: false,
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入类型名称', trigger: 'blur' }
        ]
      }
    }
  },
  // inject: ['roleType'],
  created () {
    this.config({
      api: '/business/projectScore',
      'field.id': 'id'
    })
  },
 
  methods: {
    open (title, target, projectId, roleType) {
      this.title = title
      this.visible = true
      // debugger
      this.form.projectId = projectId
      this.roleType = roleType
      this.isEdit = true
      // 新建
      if (target == null) {
        this.isEdit = false
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
       
      })
    },
    inputZAction(item, key) {
      // item[key] = item[key].replace(/[0], '')
      // item[key]=item[key].replace(/0|[^\d]+/g, '')
      if (item[key]>=1) {
        item[key] = 1
        return
      }
      item[key] = item[key].replace(/[^\d.]/g, '') //清楚数字和.以外的字符
                      .replace(/\.{2,}/g, '.')
                      .replace('.', '$#$')
                      .replace(/\./g, '')
                      .replace('$#$', '.')
                      .replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3')
                      .replace(/^\./g, '')
    },
    numInput(item, key) {
      // =""
      if (item[key] < 0) {
        item[key] = ''
      }
      item[key]=item[key].replace(/[^\d]+/g, '')
      console.log(item[key]);
    },
  }
}
/**
 * 

 */
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
</style>
