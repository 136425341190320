<template>
  <div>
    <!-- {{ links }} -->
    <FileLinkItem
      v-for="(item, index) in links"
      :key="index"
      :link="item[linkName]"
      :name="item.name"
      :isUpload="isUpload"
      @showFile="showFile"
      @deleteAction="deleteFile(index)"
    />
    <PDFPreview ref="PDFPreview" />
    <el-image-viewer
      v-if="showViewer" 
      :on-close="close"
      :initialIndex="tempIndex"
      :url-list="srcList"
      :zIndex="3000"
    />
  </div>
</template>

<script>
import { fileType } from '../../utils/util';
import FileLinkItem from './FileLinkItem.vue';
import PDFPreview from '@/components/common/PDFPreview'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    FileLinkItem,
    PDFPreview,
    ElImageViewer
  },
  props: {
    links: {
      type: Array,
      required: true
    },
    isUpload: {
      type: Boolean,
      default: true
    },
    linkName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showViewer: false
    }
  },
  computed: {
    // 图片地址
    srcList() {
      return this.links.filter(item => {
        let link = item[this.linkName]
        return fileType(link) == 1
      }).map(item => item[this.linkName])
    }
  },
  methods: {
    deleteFile(index) {
      this.links.splice(index, 1)
    },
    downloadFile(url) {
      window.open(url, '_blank')
    },
    showFile(url) {
      // debugger
      let type = fileType(url)
      switch (type) {
        case 1:
          this.tempIndex = this.srcList.findIndex(item => item == url)
          this.showViewer = true
          break;
        case 2:
          this.$refs.PDFPreview.open('', { url })
          break;
        case 3:
          window.open(url, '_blank')
          break;
        default:
          break;
      }
    },
    close() {
      this.showViewer = false
    }
  },
}
</script>
