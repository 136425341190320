<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="是否已删除 0未删除 1已删除" prop="deleted">
        <el-input v-model="form.deleted" placeholder="请输入是否已删除 0未删除 1已删除" v-trim/>
      </el-form-item>
      <el-form-item label="创建人编码" prop="creator">
        <el-input v-model="form.creator" placeholder="请输入创建人编码" v-trim/>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="form.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="更新人编码" prop="editor">
        <el-input v-model="form.editor" placeholder="请输入更新人编码" v-trim/>
      </el-form-item>
      <el-form-item label="更新时间" prop="editDate">
        <el-date-picker v-model="form.editDate" value-format="yyyy-MM-dd" placeholder="请输入更新时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="是否删除0否 1是" prop="isdeleted">
        <el-input v-model="form.isdeleted" placeholder="请输入是否删除0否 1是" v-trim/>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注" v-trim/>
      </el-form-item>
      <el-form-item label="申报编码（关联declare表)" prop="declareId">
        <el-input v-model="form.declareId" placeholder="请输入申报编码（关联declare表)" v-trim/>
      </el-form-item>
      <el-form-item label="操作类型 0保存、1待县区审核、2县区审核通过、3县区退回修改、4县区审核驳回、5、双选待服务机构确认、6双选服务机构拒绝、7已分配服务机构、8服务机构诊断、9服务机构服务完成、10分配评分专家、11专家完成评分" prop="type">
        <el-input v-model="form.type" placeholder="请输入操作类型 0保存、1待县区审核、2县区审核通过、3县区退回修改、4县区审核驳回、5、双选待服务机构确认、6双选服务机构拒绝、7已分配服务机构、8服务机构诊断、9服务机构服务完成、10分配评分专家、11专家完成评分" v-trim/>
      </el-form-item>
      <el-form-item label="最近一次审核信息" prop="checkInfo">
        <el-input v-model="form.checkInfo" placeholder="请输入最近一次审核信息" v-trim/>
      </el-form-item>
      <el-form-item label="最近一次审核人" prop="checkor">
        <el-input v-model="form.checkor" placeholder="请输入最近一次审核人" v-trim/>
      </el-form-item>
      <el-form-item label="最近一次审核时间" prop="checkDate">
        <el-date-picker v-model="form.checkDate" value-format="yyyy-MM-dd" placeholder="请输入最近一次审核时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="当前申报状态（操作前状态）" prop="status">
        <el-input v-model="form.status" placeholder="请输入当前申报状态（操作前状态）" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaDeclareHistoryWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        deleted: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        remark: '',
        declareId: '',
        type: '',
        checkInfo: '',
        checkor: '',
        checkDate: '',
        status: ''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/declareHistory',
      'field.id': 'id'
    })
  }
}
</script>
