import request from '../../utils/request'

// 查询
export function fetchList (data) {
  // return request.post('/business/areas/treeList', data, {
  //   trim: true
  // })
  return request.post('/business/areas/page', data, {
    trim: true
  })
}
export function listByParentId (data) {
  return request.post('/business/areas/listByParentId', data)
}

// 创建
export function create (data) {
  return request.post('/business/areas/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/business/areas/updateById', data)
}

// 树形
export function treeList (data) {
  return request.post('/business/areas/listByParentId', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/business/areas/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/business/areas/delete/batch', {
    params: {
      ids
    }
  })
}
