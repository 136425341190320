<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="是否已删除 0未删除 1已删除" prop="deleted">
        <el-input v-model="form.deleted" placeholder="请输入是否已删除 0未删除 1已删除" v-trim/>
      </el-form-item>
      <el-form-item label="创建人编码" prop="creator">
        <el-input v-model="form.creator" placeholder="请输入创建人编码" v-trim/>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="form.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="更新人编码" prop="editor">
        <el-input v-model="form.editor" placeholder="请输入更新人编码" v-trim/>
      </el-form-item>
      <el-form-item label="更新时间" prop="editDate">
        <el-date-picker v-model="form.editDate" value-format="yyyy-MM-dd" placeholder="请输入更新时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="是否删除0否 1是" prop="isdeleted">
        <el-input v-model="form.isdeleted" placeholder="请输入是否删除0否 1是" v-trim/>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注" v-trim/>
      </el-form-item>
      <el-form-item label="项目编码（关联project表)" prop="projectId">
        <el-input v-model="form.projectId" placeholder="请输入项目编码（关联project表)" v-trim/>
      </el-form-item>
      <el-form-item label="最大值" prop="maxNum">
        <el-input v-model="form.maxNum" placeholder="请输入最大值" v-trim/>
      </el-form-item>
      <el-form-item label="最小值" prop="minNum">
        <el-input v-model="form.minNum" placeholder="请输入最小值" v-trim/>
      </el-form-item>
      <el-form-item label="系数" prop="ratio">
        <el-input v-model="form.ratio" placeholder="请输入系数" v-trim/>
      </el-form-item>
      <el-form-item label="排序码" prop="sortnum">
        <el-input v-model="form.sortnum" placeholder="请输入排序码" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaProjectRatioWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        deleted: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        remark: '',
        projectId: '',
        maxNum: '',
        minNum: '',
        ratio: '',
        sortnum: ''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/projectRatio',
      'field.id': 'id'
    })
  }
}
</script>
