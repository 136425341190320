<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="创建人编码" prop="creator">
        <el-input v-model="form.creator" placeholder="请输入创建人编码" v-trim/>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="form.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="更新人编码" prop="editor">
        <el-input v-model="form.editor" placeholder="请输入更新人编码" v-trim/>
      </el-form-item>
      <el-form-item label="更新时间" prop="editDate">
        <el-date-picker v-model="form.editDate" value-format="yyyy-MM-dd" placeholder="请输入更新时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="是否删除0否 1是" prop="isdeleted">
        <el-input v-model="form.isdeleted" placeholder="请输入是否删除0否 1是" v-trim/>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注" v-trim/>
      </el-form-item>
      <el-form-item label="关联总表编码（cmm)" prop="cmmId">
        <el-input v-model="form.cmmId" placeholder="请输入关联总表编码（cmm)" v-trim/>
      </el-form-item>
      <el-form-item label="数据类型 0人员 1技术 2资源 3设计4生产 5物流 6销售 7服务 8总得分 9组织战略 10人员技能	11数据 12集成 14信息安全	14装备 15网络 16产品设计	17工艺设计 18采购 19计划与调度 20生产作业 21设备管理 22安全与环保 23仓储与配送 24能源管理 25物流(子域) 26销售(子域) 27客户服务 28产品服务
            " prop="type">
        <el-input v-model="form.type" placeholder="请输入数据类型 0人员 1技术 2资源 3设计4生产 5物流 6销售 7服务 8总得分 9组织战略 10人员技能	11数据 12集成 14信息安全	14装备 15网络 16产品设计	17工艺设计 18采购 19计划与调度 20生产作业 21设备管理 22安全与环保 23仓储与配送 24能源管理 25物流(子域) 26销售(子域) 27客户服务 28产品服务
            " v-trim/>
      </el-form-item>
      <el-form-item label="数据值" prop="val">
        <el-input v-model="form.val" placeholder="请输入数据值" v-trim/>
      </el-form-item>
      <el-form-item label="数据类型名称" prop="typeName">
        <el-input v-model="form.typeName" placeholder="请输入数据类型名称" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCmmDataWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        remark: '',
        cmmId: '',
        type: '',
        val: '',
        typeName: ''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/cmmData',
      'field.id': 'id'
    })
  }
}
</script>
