import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/declareHistory/page', data, {
    trim: true
  })
}
export function getListByDeclareId (data) {
  return request.post('/business/declareHistory/getListByDeclareId', data, {
    trim: true
  })
}

// 导出Excel
export function exportExcel (data) {
  return request.post('/business/declareHistory/exportExcel', data, {
    trim: true,
    download: true
  })
}

// 创建
export function create (data) {
  return request.post('/business/declareHistory/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/business/declareHistory/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/business/declareHistory/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/business/declareHistory/delete/batch', {
    params: {
      ids
    }
  })
}
