
<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    v-loading="isUploading"
    element-loading-text="文件上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="serviceList" ref="navBar">
      <div
        v-for="(item, index) in serviceList"
        :key="index"
      >
        <div class="arrow" :class="index==tempService&&'arrow-select'" @click="selectServe(item,index)">
          <div v-if="index" class="arrow-foot"></div>
          <div class="arrow-body" :class="index==0 || index==serviceList.length-1 ? 'first-last': index<=serviceLimt-1?'normal-body': ''">{{ item.title }}</div>
          <div v-if="index != serviceList.length-1" class="arrow-header"></div>
        </div>
      </div>
    </div>
    <!-- {{ tempService }} -->
    <!-- 服务提交 -->
    <el-form v-if="tempService < serve.length" ref="form2" :model="tempServiceObj" label-width="130px"   :rules="rules" label-suffix="：" inline>
      <div>
        <el-form-item label="服务时间" prop="createDate">
          {{ tempServiceObj.serviceDate }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="上传用户" prop="creator">
          {{ tempServiceObj.creatorName }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="服务说明" prop="content" >
          {{ tempServiceObj.content }}
        </el-form-item>
      </div>
      <div>
        <el-form-item label="服务资料" prop="fileList">
          <FileLink
              :links="tempServiceObj.fileList"
              linkName="fileurlfull"
              :isUpload="false"
            />
        </el-form-item>
      </div>
      <div v-if="tempServiceObj.fileExtraList.length">
        <el-form-item label="补充资料" prop="fileExtraList">
          <FileLink
            :links="tempServiceObj.fileExtraList||[]"
            linkName="url"
          />
        </el-form-item>
      </div>
    </el-form>
    
    <!-- 退回提交 -->
    <el-form v-else-if="tempService == serviceList.length-1" ref="completeForm" :model="completeForm" label-width="130px" :rules="completeRules" label-suffix="：" inline>
      <div class="tip-waring-style">退回原因：{{ tempServiceObj.backInfo }}</div>
      <div>
        <el-form-item label="服务时间" prop="diagnoseDate">
          <el-date-picker
            v-model="completeForm.diagnoseDate"
            type="datetime"
            placeholder="选择服务时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      
      <div>
        <el-form-item label="企业评分" prop="diagnoseScore">
          <el-input
            v-model="completeForm.diagnoseScore"
            placeholder="请输入企业评分"
            @input="inputAction"
            v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="企业评分表" prop="fileScoreList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upFileScore.click()">上传资料</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="completeForm.fileScoreList"
              linkName="url"
            />
          </div>
        </el-form-item>
        <input type="file" @change="upFileScore" ref="upFileScore" style="display: none;" />
      </div>
      <div>
        <el-form-item label="补充资料上传" prop="fileExtraList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.completeUpExtraFile.click()">上传补充资料</el-button>
          </div>
          <div class="data-list">
            <FileLink
              :links="completeForm.fileExtraList"
              linkName="url"
            />
          </div>
        </el-form-item>
        <input type="file" @change="completeUpExtraFile" ref="completeUpExtraFile" style="display: none;" />
      </div>
      <div>
        <el-form-item label="诊断报告" prop="fileDiagnosisList">
          <div>
            <el-button type="primary" icon="el-icon-upload" @click="$refs.upFileDiagnosis.click()">上传资料</el-button>
          </div>
          <!-- {{ completeForm.fileDiagnosisList }} -->
          <div class="data-list">
            <FileLink
              :links="completeForm.fileDiagnosisList"
              linkName="url"
            />
          </div>
        </el-form-item>
        <input type="file" @change="upFileDiagnosis" ref="upFileDiagnosis" style="display: none;" />
      </div>
      <div v-if="completeForm.declareDiagnoseList.length">
        <el-form-item label="报告详情" prop="declareDiagnoseList">
          <div v-for="(item, index) in completeForm.declareDiagnoseList" style="margin-bottom: 10px;" :key="index">
            {{ `${item.name}` }}
            <RichEditor :content="{ content : item.content}"  @edit="item.content=$event"></RichEditor>
          </div>
        </el-form-item>
      </div>
      <div class="action-style">
        <el-button style="color:#fff; background-color: rgb(86, 188, 190)" @click="finish(1)">提交</el-button>
        <el-button style="color:#fff; background-color: #216EEE;"  @click="finish(0)">保存</el-button>
      </div>
    </el-form>
    <!-- 完成提交-->
    <el-form v-else ref="form" :model="form" label-width="130px" :rules="rules" label-suffix="：" inline>
      <div class="tip-waring-style" v-if="!!tempServiceObj.backInfo">退回原因：{{ tempServiceObj.backInfo }}</div>
      <!-- 完成提交 -->
      <div>
        <el-form-item label="服务时间" prop="diagnoseDate">
          <!-- <el-date-picker
            v-model="completeForm.diagnoseDate"
            type="datetime"
            placeholder="选择服务时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker> -->
          {{ tempServiceObj.diagnoseDate }}
        </el-form-item>
      </div>
      
      <div v-if="!!tempServiceObj.diagnoseScore">
        <el-form-item label="企业评分" prop="diagnoseScore">
          <!-- <el-input
            v-model="completeForm.diagnoseScore"
            placeholder="请输入企业评分"
            @input="inputAction"
            v-trim/> -->
          {{ tempServiceObj.diagnoseScore }}
        </el-form-item>
      </div>
      <div v-if="tempServiceObj.fileScoreList.length">
        <el-form-item label="企业评分表" prop="fileScoreList">
          <FileLink
            :links="tempServiceObj.fileScoreList||[]"
            linkName="url"
            :isUpload="false"
          />
        </el-form-item>
      </div>
      <div v-if="tempServiceObj.fileExtraList.length">
        <el-form-item label="补充资料" prop="fileExtraList">
          <FileLink
            :links="tempServiceObj.fileExtraList||[]"
            linkName="url"
          />
        </el-form-item>
      </div>
      <div v-if="tempServiceObj.fileDiagnosisList.length">
        <el-form-item label="诊断报告" prop="fileDiagnosisList">
          <FileLink
            :links="tempServiceObj.fileDiagnosisList"
            linkName="url"
            :isUpload="false"
          />
        </el-form-item>
      </div>
      <div v-if="tempServiceObj.declareDiagnoseList.length">
        <el-form-item label="报告详情" prop="declareDiagnoseList">
          <div v-for="(item, index) in tempServiceObj.declareDiagnoseList" style="margin-bottom: 10px;" :key="index">
            {{ `${item.name}` }}
            <!-- {{ item.declareDiagnoseList }} -->
            <!-- <RichEditor :content="{ content : item.content}"  @edit="item.content=$event"></RichEditor> -->
            <Editor
              v-model="item.content"
              :defaultConfig="{ readOnly : true }"
            />
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="visible=false">返回</el-button>
    </div>
 
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { fetchList, uploadHCData, editHCData, finishHCData, doneServiceList as finishServeInfo } from '@/api/business/declareService'
import { upload } from '@/api/system/common'
import RichEditor from '@/components/common/RichEditor.vue'
import { Editor } from '@wangeditor/editor-for-vue'

export default {
  name: 'UploadServeData',
  extends: BaseOpera,
  components: {
    GlobalWindow,
    RichEditor,
    Editor
  },
  data () {
    let socreRule = (rule, value, callback) => {
      // debugger
      console.log(value);
      callback()
    }
    let newRule = (rule, value, callback) => {
      let isComplete = true
      for (const item of this.completeForm.declareDiagnoseList) {
        // console.log(item)
        // debugger
        if (!item.content || item.content=='<p><br></p>') {
          isComplete = false
          break
        }
      }
      if (!isComplete) {
        // debugger
        callback(new Error('请先完善诊断报告'))
      } else {
        callback()
      }
    }
    return {
      isUploading: false,
      // 表单数据
      serviceReq: '',
      form: {
        declareId: null,
        serviceDate: '',
        content: '',
        fileList: [],
        fileExtraList: [],
        type: '0' //服务上传
      },
      completeForm: {
        declareId: null,
        diagnoseDate: '',
        fileScoreList: [],
        fileDiagnosisList:[],
        declareDiagnoseList:[],
        fileExtraList: [],
        diagnoseScore: '0',
        type: '1' 
      },
      serviceLimt: 1,
      projectId: '',
      serviceList: [],
      completeList: [],
      tempService: 0,
      tempServiceObj: {
        fileList: [],
        fileScoreList: [],
        fileDiagnosisList:[],
        fileExtraList: [],
        declareDiagnoseList:[],
        declareDiagnoseList:[],
      },
      serve: [],
      // 验证规则
      rules: {
        // serviceDate: [
        //   { required: true, message: '请选择服务时间', trigger: 'change' },
        // ],
        // fileList: [
        //   { required: true, message: '请上传服务资料', trigger: 'change' },
        // ],
      },
      isBack: false,
      // 验证规则
      completeRules: {
        diagnoseDate: [
          { required: true, message: '请选择服务时间', trigger: 'change' },
        ],
        // diagnoseScore: [
        //   { required: true, message: '请输入企业评分', trigger: 'blur' },
        // ],
        // fileScoreList: [
        //   { required: true, message: '请上传企业评分表', trigger: 'change' },
        // ],
        fileDiagnosisList: [
          { required: true, message: '请上传诊断报告', trigger: 'change' },
        ],
        declareDiagnoseList: [
          { required: true, validator: newRule, trigger: ['blur']}
        ],
      },
    }
  },
  created () {
    this.config({
      api: '/business/declareService',
      'field.id': 'id'
    })
  },
  beforeDestroy() {
    const buttonGroup = this.$refs.navBar;
    if (!buttonGroup) {
      return
    }
    buttonGroup.removeEventListener('mousedown', (e) => {
      console.log(e);
    })
    buttonGroup.removeEventListener('mouseleave', (e) => {
      console.log(e);
    })
    buttonGroup.removeEventListener('mouseup', (e) => {
      console.log(e);
    })
    buttonGroup.removeEventListener('mousemove', (e) => {
      console.log(e);
    })
  },
  methods: {
    setListen() {
      const buttonGroup = this.$refs.navBar;
      if (!buttonGroup) {
        return
      }
      let isDragging = false;
      let startX = 0;
      let scrollLeft = 0;

      buttonGroup.addEventListener('mousedown', (e) => {
        isDragging = true;
        startX = e.pageX - buttonGroup.offsetLeft;
        scrollLeft = buttonGroup.scrollLeft;
      });

      buttonGroup.addEventListener('mouseleave', () => {
        isDragging = false;
      });

      buttonGroup.addEventListener('mouseup', () => {
        isDragging = false;
      });

      buttonGroup.addEventListener('mousemove', (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - buttonGroup.offsetLeft;
        const walk = (x - startX) * 3;
        buttonGroup.scrollLeft = scrollLeft - walk;
      });
    },
    open (title, target) {
      // debugger
      this.title = title
      this.visible = true
      this.serviceList = []
      this.serve = []
      this.completeForm = {
        declareId: null,
        diagnoseDate: '',
        fileScoreList: [],
        fileDiagnosisList:[],
        declareDiagnoseList:[],
        fileExtraList: [],
        diagnoseScore: '0',
        type: '1' 
      }
      this.$nextTick(() => {
        this.form.declareId = target.id
        this.completeForm.declareId = target.id
        this.serviceLimt = target.serviceLimt
        this.projectId = target.projectId
        this.serviceReq = target.serviceReq
        this.isBack = target.status==12
        this.initServe()
        this.setListen()
      })
    },
    inputAction(value) {
      if(this.completeForm.diagnoseScore<0) {
        this.completeForm.diagnoseScore = ''
      } else {
        this.completeForm.diagnoseScore = value.replace(/[^\d.]/g, '')
                      .replace(/\.{2,}/g, '.')
                      .replace('.', '$#$')
                      .replace(/\./g, '')
                      .replace('$#$', '.')
                      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
                      .replace(/^\./g, '')
      }
    },
    initServe() {
      Promise.all([
        fetchList({
          capacity: 9999,
          model: { declareId: this.form.declareId, type: 0 }
        }),
        finishServeInfo({ declareId: this.completeForm.declareId })
      ])
        .then(([serve, finish])=> {
          // console.log(serve, finish);
          serve.records.forEach((item, index) => {
            this.serviceList.push({
              ...item,
              title: index==0?'首次服务':`第${index+1}次服务数据`
            })
          })
          this.serve = serve.records
          let value = finish[finish.length-1]
          // let lastRes = finish[finish.length-1]
          this.completeForm.diagnoseScore = value.diagnoseScore;
          this.completeForm.diagnoseDate = value.diagnoseDate;
          this.completeForm.fileScoreList = value.fileScoreList ? value.fileScoreList.map(item => {
            let newItem = item
            item.url = item.fileurlfull
            return newItem
          }):[]
          this.completeForm.fileScoreList = value.fileScoreList || []
          this.completeForm.fileDiagnosisList = value.fileDiagnosisList ? value.fileDiagnosisList.map(item => {
            let newItem = item
            item.url = item.fileurlfull
            return newItem
          }):[];
          this.completeForm.declareDiagnoseList = value.declareDiagnoseList ? value.declareDiagnoseList.map(item => {
            let newItem = item
            item.url = item.fileurlfull
            return newItem
          }):[];
          this.completeForm.fileExtraList = value.fileExtraList ? value.fileExtraList.map(item => {
            let newItem = item
            item.url = item.fileurlfull
            return newItem
          }):[];
          finish.forEach((item, index) => {
            // debugger
            this.serviceList.push({
                ...item,
                title: index==0?'完成服务':index==1?`退回修改`:`退回修改${index}`
              })

            if (index==finish.length-1) {
              // debugger
              // 0保存中 1已提交 2已退回
              // this.serviceList.splice(this.serviceList.length-1, 1)
                
              if (item.status==2) {
                this.serviceList.push({
                  ...item,
                  title: index==0?`退回修改`:`退回修改${index + 1}`
                })
              } else {
                this.serviceList.splice(this.serviceList.length-1, 1)
                this.serviceList.push({
                  ...item,
                  backInfo: finish[index-1].backInfo,
                  title: index==0?'完成服务':index==1?`退回修改`:`退回修改${index}`
                })
              }
            } 
          })
          this.tempService = this.serviceList.length - 1
          this.tempServiceObj = this.serviceList[this.serviceList.length - 1]
          this.$nextTick(() => {
            this.$refs.navBar.scrollTo(this.tempService * 110, 0)
          })
        })
      
    },
    selectServe(item, index) {
      debugger
      // this.$refs.form.clearValidate()
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
      if (this.$refs.completeForm) {
        this.$refs.completeForm.clearValidate()
      }
      // debugger
      this.tempServiceObj = item
      this.tempService = index
      console.log(item);
      console.log(this.tempServiceObj);
    },

    // 上传服务资料
    upFiles(file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          this.form.fileList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upFile.value = null
    },
    // 上传服务补充资料
    upExtraFiles(file) {
      // debugger
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          this.form.fileExtraList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upExtraFiles.value = null
    },
    // 上传完成服务补充资料
    completeUpExtraFile(file) {
      const formdate = new FormData()
      this.isUploading = true
      // debugger
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          this.completeForm.fileExtraList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs.completeUpExtraFile.value = null
    },
    // 上传企业评分表
    upFileScore(file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          this.completeForm.fileScoreList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upFileScore.value = null
    },
    // 删除业评分表
    // deleteFileScore(index) {
    //   this.completeForm.fileScoreList.splice(index, 1)
    // },
    // 上传诊断报告
    upFileDiagnosis(file) {
      const formdate = new FormData()
      this.isUploading = true
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          this.completeForm.fileDiagnosisList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upFileDiagnosis.value = null
    },
    newValue(v, index) {
      this.completeForm.declareDiagnoseList[index].content = v
    },
    // 删除诊断报告
    // deleteFileDiagnosis(index) {
    //   this.completeForm.fileDiagnosisList.splice(index, 1)
    // },
    submit(status) {
      if (status) {
        this.$refs.form.validate((valid) => {
          // debugger
          if (!valid) {
            this.$message.error('请完整填写完成服务数据')
            return
          }
          this.uploadHC(status)
        })
      } else {
        this.uploadHC(status)
      }
    },
    uploadHC(status) {
      this.isWorking = true
      
      uploadHCData({
        ...this.form,
        status
      })
        .then(res => {
          this.$message.success(status?'提交成功':'保存成功')
          this.visible = false
          this.$emit('success')
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isWorking = false)
    },
    finish(status) {
      if (status) {
        this.$refs.completeForm.validate((valid) => {
          if (!valid) {
            this.$message.error('请完整填写完成服务数据')
            return
          }
          this.finishServe(status)
        })
      } else {
        this.finishServe(status)
      }
    },

    finishServe(status) {
      this.isWorking = true
      editHCData({
        ...this.completeForm,
        status
      })
        .then(res => {
          this.$message.success(status?'提交成功':'保存成功')
          this.$emit('success')
          this.visible = false
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => this.isWorking = false)
    },
  },
}
/**
 *  
 *   
 */
</script>


<style lang="scss" scoped>
.tip-style {
  background: rgba(33,110,238,0.1);
  border-radius: 2px;
  line-height: 24px;
  
  font-size: 16px;
  color: #333;
  padding: 8px;
  margin-bottom: 10px;
}
.tip-waring-style {
  background: rgba(33,110,238,0.1);
  border-radius: 2px;
  line-height: 24px;
  color: #EE3821;
  font-size: 16px;
  // color: #333;
  padding: 8px;
  margin-bottom: 10px;
}
.serviceList {
  display: flex;
  // flex-wrap: wrap;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 20px;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important
  }
  -ms-overflow-style: none;
}
.action-style {
  display:flex;
  margin-left: 120px;
  margin-bottom: 20px;
}
.arrow {
  background-color: #DFE2E8;
  color: #333333;
  height: 40px;
  line-height: 40px;
  display: flex;
  cursor: pointer;
  .arrow-foot {
    // background-color: #fff;
    border-left: 20px solid rgba($color: #fff, $alpha: 1);
    border-top: 20px solid rgba($color: #fff, $alpha: 0);
    border-bottom: 20px solid rgba($color: #fff, $alpha: 0);
  }
  
  .arrow-body {
    height: 40px;
    width: 100%;
    min-width: 120px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 16px;
  }
  .first-last {
    min-width: 100px;
  } 
  .normal-body {
    min-width: 110px;
  } 
  .arrow-header {
    // background-color: #DFE2E8;
    border-left: 20px solid rgba($color: #fff, $alpha: 0);
    border-top: 20px solid rgba($color: #fff, $alpha: 1);
    border-bottom: 20px solid rgba($color: #fff, $alpha: 1);
  }
}
.arrow-select {
  background-color: #216EEE;
  color: #fff;
}

.data-list {
  margin-top: 20px;
  .data-item {
    border-radius: 2px;
    // background-color: #F5F6F8;
    height: 36px;
    display: flex;
    // padding: 11px 20px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #216EEE;
    cursor: pointer;
    text-decoration: underline;
    line-height: 13px;
    margin-right: 16px;
    i {
      box-sizing: border-box;
      margin-left: 10px;
      width: 16px;
      height: 16px;
      // background-color: #BFC3CB;
      color: #216EEE;
      border-radius: 50%;
      // padding: 1.5px;
      cursor: pointer;
      &:hover {
        color: #ff0000;
      }
    }
  }
}
::v-deep .ql-toolbar {
  // height: 200px !important;
  width: 850px;
}
// ::v-deep .el-form-item {
//   display: flex;
// }
::v-deep .el-form-item__content {
  flex: 1;
}
/**

*/
</style>
