<template>
  <TableLayout :permissions="['system:user:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="80px" inline>
      <el-form-item label="用户名称" prop="username">
        <el-input v-model="searchForm.username" v-trim placeholder="请输入用户名" @keypress.enter.native="search"/>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar">
        <li v-permissions="['system:user:create']"><el-button type="primary" @click="$refs.operaChildrenUserWindow.open('新建用户')">新建</el-button></li>
        <li><el-button type="primary" @click="resetUserPwd">重置密码</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        border
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="username" label="登录账号" align="center" min-width="140px" fixed="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="账号类型" align="center" min-width="140px" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ row.type == 5 ? '综合服务单位子账号' : '服务机构子账号' }}
          </template>
        </el-table-column>
        <el-table-column prop="realname" label="姓名" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="mobile" label="手机号" align="center" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="mobile" label="状态" align="center" min-width="120px">
          <template slot-scope="{row}">
            <el-switch
              v-model="row.status"
              active-color="#13ce66"
              inactive-color="#999"
              :active-value="0"
              :inactive-value="1"
              @change="changeStatus(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" min-width="140px"></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaChildrenUserWindow.open('编辑用户', row)">编辑</el-button>
            <el-button type="text" @click=" deleteById(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaChildrenUserWindow ref="operaChildrenUserWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
  </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import Pagination from '@/components/common/Pagination'
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import OperaChildrenUserWindow from '@/components/business/user/OperaChildrenUserWindow'
import { resetPwd, changeStatus } from '@/api/business/account'

export default {
  name: 'SystemUser',
  extends: BaseTable,
  components: { OperaChildrenUserWindow, TableLayout, Pagination },
  data () {
    return {
      // 搜索
      searchForm: {
        username: ''
      },
      province: [],
      cities: [],
      areas: []
    }
  },

  created () {
    this.config({
      module: '用户',
      api: '/business/childrenUser',
      'field.main': 'realname',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }]
    })
    this.search()
  },
  methods: {
    changeStatus({id, status}) {
      changeStatus({ id, value:status })
        .then(res => {
          this.$message.success('修改成功')
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.handlePageChange()
        })
    },
    resetUserPwd() {
      if (this.tableData.selectedRows.length === 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let message = `确认重置已选中的 ${this.tableData.selectedRows.length} 个账号密码吗?`
      this.$dialog.resetConfirm(message)
        .then(() => {
          this.isWorking.delete = true
          resetPwd({ ids: this.tableData.selectedRows.map(row => row.id).join(',') })
            .then(() => {
              this.$message.success('重置成功')
              this.handlePageChange()
            })
            .catch(e => {
              this.$message.error(e)
            })
            .finally(() => {
              this.isWorking.delete = false
            })
        })
        .catch(() => {})
    }
    
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 列表头像处理
.table-column-avatar {
  img {
    width: 48px;
  }
}
</style>
