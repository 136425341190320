<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form ref="form" v-model="form" label-width="146px" label-suffix="：" inline>
      <div class="item-line">
        <div class="item">
          <el-form-item :label="form.type==0?'企业名称':'企业(单位)名称'">
            <span class="setting-value">{{ form.name  }}</span>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label="社会信用代码">
            <span class="setting-value">{{ form.creditCode }}</span>
          </el-form-item>
        </div>
      </div>
      <div class="item-line">
        <div class="item">
          <el-form-item label="所属区域">
            <span class="setting-value">{{ form.belongAreaName }}</span>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label="单位地址">
            <span class="setting-value">{{ form.address }}</span>
          </el-form-item>
        </div>
      </div>
      <div class="item-line">
        <div class="item">
          <el-form-item label="法定代表人">
            <span class="setting-value">{{ form.legalPerson }}</span>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label="手机/电话">
            <span class="setting-value">{{ form.legalPersonPhone }}</span>
          </el-form-item>
        </div>
      </div>
      <div class="item-line">
        <div class="item">
          <el-form-item label="联系人">
            <span class="setting-value">{{ form.linkName }}</span>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label="手机/电话">
            <span class="setting-value">{{ form.linkPhone }}</span>
          </el-form-item>
        </div>
      </div>
      
      <div class="item-line" v-if="form.type==0">
       
        <template>
          <div class="item">
            <el-form-item label="企业性质">
              <span class="setting-value">{{ form.natureName }}</span>
            </el-form-item>
          </div>
          <div class="item">
            <el-form-item label="企业所属行业">
              <span class="setting-value">{{ form.industryName }}</span>
            </el-form-item>
          </div>
        </template>
        
      </div>
      <div class="item-line">
        <div class="item" v-if="form.type==0">
          <el-form-item label="企业规模">
            <span class="setting-value">{{ form.labelName }}</span>
          </el-form-item>
        </div>
        <div class="item" v-else>
          <el-form-item label="单位类别">
            <span class="setting-value">{{ form.labelName }}</span>
          </el-form-item>
        </div>
      </div>
      <div class="item-line">
        <div class="item">
          <div class="item-value">
            <div class="value-label">{{form.type==0?'企业简介：':'企业(单位)简介：'}}</div>
            <div class="value-value"><span class="setting-value">{{ form.content }}</span></div>
          </div>
        </div>
        <div class="item">
          <div class="item-value">
            <div class="value-label">成立时间：</div>
            <div class="value-value"><span class="setting-value">{{ form.registerDate && form.registerDate.substring(0,form.registerDate.length-8) }}</span></div>
          </div>
        </div>
      </div>
      <div class="item-line">
        <div class="item" v-if="form.type==0">
          <div class="item-value" >
            <div class="value-label">主营产品：</div>
            <div class="value-value"><span class="setting-value">{{ form.product }}</span></div>
          </div>
        </div>
        <div class="item">
           <!-- form.type==0?'企业名称':'企业(单位)名称'" -->
          <el-form-item :label="form.type==0?'营业执照':'营业执照/事业单位、社会团体登记证'">
            <span class="setting-value">
              <div class="value-value" v-if="form.businessList">
                <el-image
                  style="margin-left: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in form.businessList"
                  :key="index"
                  :initial-index="index"
                  :src="item.fileurlfull"
                  :preview-src-list="form.businessList.map(item => item.fileurlfull)"
                  :z-index="3000"
                ></el-image>
              </div>
            </span>
          </el-form-item>
        
        </div>
        <div class="item" v-if="form.type==1">
          <el-form-item label="中标通知书">
            <span class="setting-value">
              <div class="value-value" v-if="form.noticeFileList">
                <el-image
                  style="margin-left: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in form.noticeFileList"
                  :key="index"
                  :initial-index="index"
                  :src="item.fileurlfull"
                  :preview-src-list="form.noticeFileList.map(item => item.fileurlfull)"
                  :z-index="3000"
                ></el-image>
              </div>
            </span>
          </el-form-item>
        
        </div>
        <!-- <div class="item" v-if="form.type==1">
          <div class="item-value" >
            <div class="value-label">主营产品：</div>
            <div class="value-value"><span class="setting-value">{{ form.product }}</span></div>
          </div>
        </div> -->
      </div>
      <div class="item-line" v-if="form.type==1">
        
        <div class="item" >
          <el-form-item label="企业资质">
            <span class="setting-value">
              <div class="value-value"  v-if="form.zzFileList">
                <el-image
                  style="margin-left: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in form.zzFileList"
                  :key="index"
                  :initial-index="index"
                  :src="item.fileurlfull"
                  :preview-src-list="form.zzFileList.map(item => item.fileurlfull)"
                  :z-index="3000"
                ></el-image>
                
              </div>
            </span>
          </el-form-item>
        
        </div>
        <!-- <div class="item" v-if="form.type==1">
          <div class="item-value" >
            <div class="value-label">主营产品：</div>
            <div class="value-value"><span class="setting-value">{{ form.product }}</span></div>
          </div>
        </div> -->
      </div>
      <el-table
      v-if="form.list"
        :data="form.list"
        stripe
      >
        <el-table-column prop="years" label="近三年主要指标" align="center" min-width="100px" fixed="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="assets" label="企业总资产(万元)" align="center" min-width="100px"></el-table-column>
        <el-table-column prop="income" label="营业收入(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="ratio" label="资产负债率(%)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="develop" label="研发费用(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="profit" label="利润总额(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column prop="taxes" label="上缴税金(万元)" align="center" min-width="100px"></el-table-column>
        <el-table-column prop="employee" label="从业人数" align="center" min-width="100px"></el-table-column>
      </el-table>
    </el-form>
    <div slot="footer"><el-button @click="visible=false">返回</el-button></div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCompanyUserWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        address: '',
        areaId: '', 
        cityId: '',
        companyChange: '',
        content: '',
        createDate: '',
        creator: '',
        creditCode: '',
        editDate: '',
        editor: '',
        fileList: '',
        fileurl: '',
        belongAreaName: '',
        id: '',
        natureName: '',
        industryId: '',
        industryName: '',
        isdeleted: '',
        legalPerson: '',
        legalPersonPhone: '',
        linkName: '',
        linkPhone: '',
        list: [],
        name: '',
        natureId: '',
        labelName: '',
        origin: '',
        product: '',
        registerDate: '',
        remark: '',
        type: '',
        password: '123456',
        businessList:[],
        noticeFileList:[],
        zzFileList:[],
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/account',
      'field.id': 'id'
    })
  }
}
</script>

<style lang="scss" scoped>
.item-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .item {
    width: 50%;
  }
}
.item-value {
  display: flex;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 18px;
  .value-label {
    width: 134px;
    margin-right: 12px;
    text-align: right;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    
  }
  .value-value {
    flex: 1;
  }
  .long-message {
    line-height: 24px;
  }
}
.content-line {
  display: flex;
  .content-title {
    width: 146px;
    text-align: right;
  }
  .content {
    flex: 1;
    // margin-top: 19px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
}
</style>
