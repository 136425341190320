<template>
  <TableLayout :permissions="['business:areas:query']">
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        lazy
        :load="load"
        :tree-props="{ children: 'childList', hasChildren: 'hasChildren' }"
        row-key="id"
        stripe
        border
        :header-row-class-name="'table-header'"
        class="doumee-element-table"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="name" label="地区名称" min-width="100px"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center" min-width="140px"></el-table-column>
        <el-table-column prop="sortnum" label="排序码" align="center" min-width="140px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['business:areas:update', 'business:areas:create', 'business:areas:delete'])"
          label="操作"
          align="center"
          min-width="220"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="edit(row)" v-permissions="['business:areas:update']">编辑</el-button>
            <el-button v-if="row.type!=2" type="text" @click="createChild(row)" v-permissions="['business:areas:create']">新建{{ row.type==0 ? '市' : '区县' }}</el-button>
            <el-button type="text" @click="deleteById(row)" v-permissions="['business:areas:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- 新建/修改 -->
    <OperaAreasWindow ref="operaAreasWindow" @success="update"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaAreasWindow from '@/components/business/OperaAreasWindow'
import { listByParentId } from '@/api/business/areas'
export default {
  name: 'Areas',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaAreasWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        type: 0,
        parentId: ''
      },
      treeMaps: new Map(),
      parentId: null
    }
  },
  created () {
    this.config({
      module: '省市区信息表',
      api: '/business/areas',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods: {
  
    // 页码变更处理
    handlePageChange (pageIndex) {

      this.isWorking.search = true
      listByParentId(this.searchForm)
        .then(data => {
          this.tableData.list = this.dataAddBool(data)
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    dataAddBool(array) {
      array.forEach(item => {
        item.hasChildren = item.type != 2
        // item.childList = item.childList && this.dataAddBool(item.childList)
      })
      return array
    },
    load(tree, treeNode, resolve) {
      this.treeMaps.set(tree.id, { tree, treeNode, resolve })
      listByParentId({ parentId: tree.id, type: tree.type + 1 })
        .then(data => {
          resolve(this.dataAddBool(data||[]))
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    refreshLoadTree(parentId) {
      if (this.treeMaps.get(parentId)) {
        const { tree, treeNode, resolve } = this.treeMaps.get(parentId)
        this.$set(this.$refs.table.store.states.lazyTreeNodeMap, parentId, [])
        if (tree) { // 重新执行父节点加载子级操作
          this.load(tree, treeNode, resolve)
          if (tree.parentId) { // 若存在爷爷结点，则执行爷爷节点加载子级操作，防止最后一个子节点被删除后父节点不显示删除按钮
            const a = this.treeMaps.get(tree.parentId)
            this.load(a.tree, a.treeNode, a.resolve)
          }
        }
      } else {
        this.handlePageChange()
      }
    },
    deleteById (row, childConfirm = true) {
      // let message = `确认删除${this.module}【${row[this.configData['field.main']]}】吗?`
      let message = `确认删除该记录吗?`
      if (childConfirm && row.childList != null && row.childList.length > 0) {
        // message = `确认删除${this.module}【${row[this.configData['field.main']]}】及其子${this.module}吗?`
        message = `确认删除该记录及其子数据吗?`
      }
      this.$dialog.deleteConfirm(message)
        .then(() => {
          this.isWorking.delete = true
          this.api.deleteById(row[this.configData['field.id']])
            .then(() => {
              this.$message.success('删除成功')
              this.refreshLoadTree(row.parentId)
            })
            .catch(e => {
              this.$message.error(e)
            })
            .finally(() => {
              this.isWorking.delete = false
            })
        })
        .catch(() => {})
    },
    edit(row) {
      // this.parentId = row.type==0 ? null : row.type==1 ? '编辑市' : '编辑区县'
      this.$refs.operaAreasWindow.open(row.type==0 ? '编辑省' : row.type==1 ? '编辑市' : '编辑区县', row)
    },
    createChild(row) {
      this.$refs.operaAreasWindow.open(row.type == 0 ? '新建市' : '新建县区', { parentId: row.id, name: '', type: row.type + 1 })
    },
    update(parentId) {
      this.refreshLoadTree(parentId)
    }
  }
}
</script>
