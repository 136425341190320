<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
<!--    <div class="title">
      基础项目信息
    </div>-->
    <el-form ref="form" v-model="form" label-width="144px" label-suffix="：" inline>
      <div class="item-line">
        <div class="item">
          <div class="setting-title">项目名称：</div>
          <div class="setting-value" :title="form.name">{{ form.name }}</div>
        </div>
        <div class="item">
          <div class="setting-title">所属城市：</div>
          <div class="setting-value" :title="form.cityName">{{ form.cityName }}</div>
          <!-- <el-form-item label="所属城市"><span class="setting-value">{{ form.cityName }}</span></el-form-item> -->
        </div>
      </div>
      <div class="item-line">
        <div class="item">
          <div class="setting-title">报名开始时间：</div>
          <div class="setting-value" :title="form.startDate">{{ form.startDate }}</div>
         
        </div>
        <div class="item">
          <div class="setting-title">报名结束时间：</div>
          <div class="setting-value" :title="form.endDate">{{ form.endDate }}</div>
        </div>
        <!-- <div class="item">
          <el-form-item label="报名开始时间"><span class="setting-value">{{ form.startDate }}</span></el-form-item>
        </div>
        <div class="item">
          <el-form-item label="报名结束时间"><span class="setting-value">{{ form.endDate }}</span></el-form-item>
        </div> -->
      </div>
      <div class="item-line">
        <div class="item">
          <div class="setting-title">专家评分方式：</div>
          <div class="setting-value">{{ form.scoreType == 0 ? '线上' : '线下' }}</div>
         
        </div>
        <div class="item">
          <div class="setting-title">服务机构分配方式：</div>
          <div class="setting-value">{{ form.sodType == 0 ? '双选' : form.sodType == 1 ? '市局分配' : '综合服务单位分配'  }}</div>
        </div>
        <!-- <div class="item">
          <el-form-item label="专家评分方式"><span class="setting-value">{{ form.scoreType == 0 ? '线上' : '线下' }}</span></el-form-item>
        </div>
        <div class="item">
          <el-form-item label="服务机构分配方式"><span class="setting-value">{{ form.sodType == 0 ? '双选' : form.sodType == 1 ? '市局分配' : '' }}</span></el-form-item>
        </div> -->
      </div>
      <div class="item-line">
        <div class="item">
          <div class="setting-title">专家分配角色：</div>
          <div class="setting-value" >{{ form.edType == 0 ? '市局' : '综合服务单位' }}</div>
         
        </div>
        <div class="item">
          <div class="setting-title">状态：</div>
          <div class="setting-value green">{{ form.status == 0 ? '未开始' : form.status == 1 ? '进行中' : '已结束' }}</div>
        </div>
        <!-- <div class="item">
          <el-form-item label="专家分配角色"><span class="setting-value">{{ form.edType == 0 ? '市局' : '综合服务单位' }}</span></el-form-item>
        </div>
        <div class="item">
          <el-form-item label="状态"><span class="setting-value">{{ form.status == 0 ? '未开始' : form.status == 1 ? '进行中' : '已结束'  }}</span></el-form-item>
        </div> -->
      </div>
      <div class="item-line">
        <div class="item">
          <div class="setting-title">县区审核：</div>
          <div class="setting-value" >{{  form.areaCheck == 0 ? '开启' : '关闭' }}</div>
         
        </div>
        <div class="item">
          <div class="setting-title">最少服务次数：</div>
          <div class="setting-value">{{ form.serviceLimt }}</div>
        </div>
        
        <!-- <div class="item">
          <el-form-item label="关联市局"><span class="setting-value">{{ form.checkReq == 0 ? '开启' : '关闭' }}</span></el-form-item>
        </div> -->
        <!-- <div class="item">
          <el-form-item label="最少服务次数"><span class="setting-value">{{ form.serviceLimt }}</span></el-form-item>
        </div> -->
      </div>
      <div class="item-line">
        <div class="item">
          <el-form-item label="问卷信息"><el-button type="text" @click="$emit('showQuestion', form.questionnaireId)">查看详情</el-button></el-form-item>
        </div>
        <div class="item">
          <div class="setting-title">关联市局：</div>
          <div class="setting-value">{{ form.sjUserName }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="message-line">
        <div class="item-value">
          <div class="value-label ">项目介绍：</div>
          <div class="value-value ">{{ form.content }}</div>
        </div>
      </div>
      <div class="message-line">
        <div class="item-value">
          <div class="value-label ">附件上传要求：</div>
          <div class="value-value ">{{ form.fileReq }}</div>
        </div>
      </div>
      <div class="message-line">
        <div class="item-value">
          <div class="value-label ">上传服务数据说明：</div>
          <div class="value-value ">{{ form.serviceReq }}</div>
        </div>
      </div>
      <div class="message-line">
        <div class="item-value">
          <div class="value-label ">上传核查说明：</div>
          <div class="value-value ">{{ form.checkReq }}</div>
        </div>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="visible=false">返回</el-button>
    </div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'

export default {
  name: 'OperaSystemProjectDetailWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        areaCheck: '',
        checkReq: '',
        cityId: '',
        cityName: '',
        content: '',
        createDate: '',
        creator: '',
        declareStatus: '',
        edType: '',
        editDate: '',
        editor: '',
        endDate: '',
        fileReq: '',
        imgurl: '',
        imgurlfull: '',
        name: '',
        questionnaireId: '',
        remark: '',
        scoreType: '',
        serverCompanyNum: '',
        serviceLimt: '',
        serviceReq: '',
        sodType: '',
        startDate: '',
        sjUserName: '',
        status: ''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 30px;
  height: 30px;
  margin-bottom: 30px;
  .status {
    display: inline-block;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
    // line-height: 14px;
    line-height: 30px;
    height: 30px;
    border-radius: 4px;
    padding: 0 10px;
  }
}
.setting {
  display: flex;
  margin-top: 20px;
  .setting-title {
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 14px;
    
  }
  .parting-line {
    height: 14px;
    background-color: #DFE2E8;
    width: 1px;
    margin: 0 15px;
  }
}
.setting-value {
  display: inline;
  font-weight: 500;
  width: 500px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}
.green{
  color: #00BA7E;
}
.line {
  background-color: #DFE2E8;
  height: 1px;
  margin: 30px 0;
}
.item-line {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  .item {
    width: 50%;
    display: flex;
    .setting-title {
      text-align: right;
      width: 184px;
      padding-right: 12px;
      font-size: 14px;
      color: #606266;
    }
  }
}
.content-line {
  display: flex;
  .content-title {
    width: 146px;
    text-align: right;
  }
  .content {
    flex: 1;
    // margin-top: 19px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
}
.el-form-item--small.el-form-item {
    margin-bottom: 10px;
}
.message-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  height: auto;
  .item {
    width: 50%;
  }
}
.item-value {
  display: flex;
  font-size: 14px;
  line-height: 24px;
  .value-label {
    width: 146px;
    text-align: right;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    
  }
  .value-value {
    flex: 1;
  }
  .long-message {
    line-height: 24px;
  }
}
</style>
