import { render, staticRenderFns } from "./OperaSjAreaUserWindow.vue?vue&type=template&id=90ac2cb8&scoped=true&"
import script from "./OperaSjAreaUserWindow.vue?vue&type=script&lang=js&"
export * from "./OperaSjAreaUserWindow.vue?vue&type=script&lang=js&"
import style0 from "./OperaSjAreaUserWindow.vue?vue&type=style&index=0&id=90ac2cb8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90ac2cb8",
  null
  
)

export default component.exports