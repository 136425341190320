import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/companyChange/page', data, {
    trim: true
  })
}
// 查询
export function create (data) {
  return request.post('/business/companyChange/create', data, {
    trim: true
  })
}
// 变更详情
export function companyChangeDetail (id) {
  return request.get(`/business/companyChange/${id}`)
}

// 
export function getCompanyChance (companyId) {
  return request.get(`/business/companyChange/getCompanyChance?companyId=${companyId}`)
}
export function queryCompanyInfo (companyId) {
  return request.get(`/business/company/queryCompanyInfo?companyId=${companyId}`)
}

// 审核变更
export function checkCompanyChang (data) {
  return request.post('/business/companyChange/checkCompanyChang', data, {
    trim: true
  })
}