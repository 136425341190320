<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="100px" label-suffix="：" inline>
      <div>
        <el-form-item label="姓名" prop="realname">
          <el-input v-model="form.realname" placeholder="请输入姓名" v-trim/>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号" v-trim/>
        </el-form-item>
      </div>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { updateSystemUser } from '@/api/business/account'
import { checkMobile, nameRule, creditCode } from '@/utils/form'
export default {
  name: 'OperaChangeUserInfoWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        type: 0,
        realname: '',
        mobile: ''
      },
      // 验证规则
      rules: {
        realname: [
          { required: false, validator: nameRule, trigger: 'blur' }
        ],
        mobile: [
          { required: false, validator: checkMobile, trigger: 'blur' }
        ]
      },
    }
  },
  created () {
    this.config({
      api: '/business/childrenUser',
      'field.id': 'id'
    })
  },
  methods: {
    confirm() {
      console.log(this.form);
      
      this.isWorking = true
      updateSystemUser(this.form)
        .then(() => {
          this.visible = false
          this.$message.success('修改成功')
          this.$emit('success')
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking = false
        })
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  .el-input__inner {
    width: 200px;
  }
}
</style>
