<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    width="500px"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" label-width="100px" label-suffix="：" :rules="rules">
      <el-form-item label="项目名称" prop="projectName">
        {{ form.projectName }}
      </el-form-item>
      <el-form-item label="报名企业" prop="companyName">
        {{ form.companyName }}
      </el-form-item>
      <el-form-item label="诊断类型" prop="diagnoseTypeId">
        <el-select v-model="form.diagnoseTypeId" placeholder="请选择诊断类型">
          <el-option
            v-for="item in diagnoseType"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { updateDiagnoseType } from '@/api/business/declares'
import { fetchList } from '@/api/business/projectLabel'
export default {
  name: 'OperaAreasWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        diagnoseTypeId: null,
        projectId: null,
        id: null,
        companyName: null,
        projectName: null,
        // type: '',
      },
      diagnoseType: [],
      // 验证规则
      rules: {
        diagnoseTypeId: [
          // { required: true, message: '请输入退回原因', tigger: 'blur' }
          { required: true, message: '请输选择诊断类型', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/business/areas',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      
      // 编辑
      this.$nextTick(() => {
        this.diagnoseType = []
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        fetchList({
          capacity: 9999,
          model: { projectId: this.form.projectId }
        })
          .then(res => {
            // console.log(res);
            this.diagnoseType = res.records
            // console.log(this.diagnoseType);
          })
      })
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        this.isWorking = true
        updateDiagnoseType(this.form)
          .then(res => {
            this.$emit('success')
            this.$message.success('退回成功')
            this.visible = false
          })
          .catch(e => {
            this.$message.error(e)
          }) 
          .finally(() => {
            this.isWorking = false
          })
      })
      
    },
    
  },
}
</script>
