<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="是否已删除 0未删除 1已删除" prop="deleted">
        <el-input v-model="form.deleted" placeholder="请输入是否已删除 0未删除 1已删除" v-trim/>
      </el-form-item>
      <el-form-item label="创建人编码" prop="creator">
        <el-input v-model="form.creator" placeholder="请输入创建人编码" v-trim/>
      </el-form-item>
      <el-form-item label="创建时间" prop="createDate">
        <el-date-picker v-model="form.createDate" value-format="yyyy-MM-dd" placeholder="请输入创建时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="更新人编码" prop="editor">
        <el-input v-model="form.editor" placeholder="请输入更新人编码" v-trim/>
      </el-form-item>
      <el-form-item label="更新时间" prop="editDate">
        <el-date-picker v-model="form.editDate" value-format="yyyy-MM-dd" placeholder="请输入更新时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="是否删除0否 1是" prop="isdeleted">
        <el-input v-model="form.isdeleted" placeholder="请输入是否删除0否 1是" v-trim/>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注" v-trim/>
      </el-form-item>
      <el-form-item label="企业编码（关联company表)" prop="companyId">
        <el-input v-model="form.companyId" placeholder="请输入企业编码（关联company表)" v-trim/>
      </el-form-item>
      <el-form-item label="项目编码（关联project表)" prop="projectId">
        <el-input v-model="form.projectId" placeholder="请输入项目编码（关联project表)" v-trim/>
      </el-form-item>
      <el-form-item label="问卷答题记录编码（关联questionnaire表)" prop="questionnaireId">
        <el-input v-model="form.questionnaireId" placeholder="请输入问卷答题记录编码（关联questionnaire表)" v-trim/>
      </el-form-item>
      <el-form-item label="申报信息编码（关联declare表）" prop="declareId">
        <el-input v-model="form.declareId" placeholder="请输入申报信息编码（关联declare表）" v-trim/>
      </el-form-item>
      <el-form-item label="总得分" prop="score">
        <el-input v-model="form.score" placeholder="请输入总得分" v-trim/>
      </el-form-item>
      <el-form-item label="答题详情，json数组，属性为 opt,score,select" prop="content">
        <el-input v-model="form.content" placeholder="请输入答题详情，json数组，属性为 opt,score,select" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaDeclareAnswerWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        deleted: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        remark: '',
        companyId: '',
        projectId: '',
        questionnaireId: '',
        declareId: '',
        score: '',
        content: ''
      },
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/declareAnswer',
      'field.id': 'id'
    })
  }
}
</script>
