<template>
  <el-dialog
    class="global-window"
    title="title"
    :visible="visible"
    :with-header="true"
    :top="top"
    :width="width"
    :close-on-press-escape="false"
    :wrapper-closable="false"
    :append-to-body="true"
    @close="close"
  >
    <div slot="title" style="min-height: 10px;" class="window__header">
      {{title}}
    </div>
    <div class="window__body">
      <slot></slot>
    </div>
    <div v-if="withFooter" class="window__footer">
      <slot name="footer">
        <el-button @click="confirm" :loading="confirmWorking" type="primary">确定</el-button>
        <el-button @click="close">返回</el-button>
      </slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'GlobalQuestionWindow',
  props: {
    width: {
      type: String,
      default: '40%'
    },
    top: {
      type: String,
      default: '15vh'
    },
    // 是否包含底部操作
    withFooter: {
      type: Boolean,
      default: true
    },
    // 确认按钮loading状态
    confirmWorking: {
      type: Boolean,
      default: false
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 是否展示
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    confirm () {
      // console.log(this.title);
      // debugger
      this.$emit('confirm')
    },
    close () {
      // console.log(this.title);
      // debugger
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 输入框高度
$input-height: 32px;
::v-deep .el-dialog__header {
  background-color: #f7f7f7;
  font-weight: 500;
  color: #222;
}
::v-deep .el-dialog__body {
  padding: 30px 20px 0;
  box-sizing: border-box;
}

.global-window {
  ::v-deep .el-drawer__header {
    padding: 0 10px 0 0;
    line-height: 40px;
    border-bottom: 1px solid #eee;
    text-align: center;
    width: 100%;
  }
  ::v-deep .el-dialog__body {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    bottom: 0;
    width: 100%;
    height: calc(90vh - 52px);
    overflow: hidden;
    background-color: #f7f7f7;
    // 内容
    .window__body {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      // 标签
      .el-form-item__label {
        float: none;
      }
      // 元素宽度为100%
      .el-form-item__content > *{
        width: 100%;
      }
    }
    // 尾部
    .window__footer {
      user-select: none;
      border-top: 1px solid #eee;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }
  }
}
</style>
