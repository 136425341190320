import request from '@/utils/request'

// 已报名项目
export function fetchList (data) {
  return request.post('/system/user/findAreaPage', data, {
    trim: true
  })
}

// // 新建
// export function create (data) {
//   return request.post('/system/user/createSystemUSer', data, {
//   // return request.post('/system/user/create', data, {
//     trim: true
//   })
// }
// // 修改
// export function updateById (data) {
//   return request.post('/system/user/updateSystemUser', data, {
//     trim: true
//   })
// }

// // 重置密码
// export function resetPwd (data) {

//   return request.post('/system/user/resetPwd', data)
// }