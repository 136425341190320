<template>
  <GlobalWindow
    :title="title"
    :confirm-working="false"
    :visible.sync="visible"
    @confirm="confirm"
  > 
    <TableLayout :permissions="['system:user:query']">
      <!-- 搜索表单 -->
      <el-form ref="form" slot="search-form" :model="form" label-width="80px" inline>
        <el-form-item label="登录账号" prop="username">
          <el-input v-model="form.username" v-trim placeholder="请输入用户名" @keypress.enter.native="search"/>
        </el-form-item>
        <section>
          <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['system:user:create']">
          <li v-permissions="['system:user:create']">
            <el-button
              type="primary"
              @click="$refs.operaChildrenUserWindow.open('新建账号', null, form.parentId)"
            >新建</el-button>
          </li>
          <li><el-button type="primary" @click="resetUserPwd">重置密码</el-button></li>
        </ul>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="username" label="登录账号" align="center" min-width="140px" fixed="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="realname" label="姓名" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="mobile" label="手机号" align="center" min-width="120px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center" min-width="140px"></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="120"
            fixed="right"
          >
            <template slot-scope="{row}">
              <el-button type="text" @click="$refs.operaChildrenUserWindow.open('编辑用户', row, form.parentId)">编辑</el-button>
              <el-button type="text" @click=" deleteById(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </template>
      <!-- 新建/修改 -->
      <OperaChildrenUserWindow ref="operaChildrenUserWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    </TableLayout>
    <div slot="footer"></div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaChildrenUserWindow from '@/components/business/user/SjChildrenUserWindow'
import { fetchList, resetPwd } from '@/api/business/sjChildrenUser'
export default {
  name: 'ChildrenListWindow',
  extends: BaseOpera,
  components: { GlobalWindow, TableLayout, Pagination, OperaChildrenUserWindow },
  data () {
    return {
      // 表单数据
      form: {
        parentId: '',
        username: ''
      },
      // 是否正在执行
      isWorking: {
        // 搜索中
        search: false,
        // 删除中
        delete: false,
        // 导出中
        export: false
      },
      // 表格数据
      tableData: {
        // 已选中的数据
        selectedRows: [],
        // 排序的字段
        sorts: [],
        // 当前页数据
        list: [],
        // 分页
        pagination: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        }
      },
      province: [],
      cities: []
    }
  },
  created () {
    this.config({
      api: '/business/sjChildrenUser',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        // debugger
        this.search()
      })
    },
    // deleteChildrenUser(id) {
    //   this.$dialog.deleteConfirm('是否要删除该子账号？')
    //     .then(() => {
    //       deleteById(id)
    //         .then(res => {
    //           this.$message.success('删除成功')
    //           this.handlePageChange()
    //         })
    //         .catch(e => {
    //           this.$message.error(e)
    //         })
    //     })
    // },
    // 搜索
    search () {
      this.handlePageChange(1)
    },
    // 导出Excel

    // 搜索框重置
    reset () {
      this.$refs.form.resetFields()
      this.search()
    },
    // 每页显示数量变更处理
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.search()
    },
    // 行选中处理
    handleSelectionChange (selectedRows) {
      this.tableData.selectedRows = selectedRows
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.form,
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    resetUserPwd() {
      if (this.tableData.selectedRows.length === 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let message = `确认重置已选中的 ${this.tableData.selectedRows.length} 个账号密码吗?`
      this.$dialog.resetConfirm(message)
        .then(() => {
          this.isWorking.delete = true
          resetPwd({ ids: this.tableData.selectedRows.map(row => row.id).join(',') })
            .then(() => {
              this.$message.success('重置成功')
              this.handlePageChange()
            })
            .catch(e => {
              this.$message.error(e)
            })
            .finally(() => {
              this.isWorking.delete = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.project-name {
  font-weight: 600;
  margin-bottom: 20px;
}
</style>


