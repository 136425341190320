<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    v-loading="isUploading"
    element-loading-text="文件上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    @confirm="confirm"
  >
    <div class="main-container">

      <div class="tip-style" v-if=" this.status==3">审查结果：退回修改！ 原因是：{{this.remark||'无'}}</div>

      <!-- 项目信息 -->
      <div slot="search-form" class="container">
        <div class="title">
          <div class="status"></div>
          {{ form.name }}
        </div>
          <div class="setting">
            <div class="setting-title">所属城市：<span class="setting-value">{{ form.cityName }}</span></div>
            <div class="parting-line"></div>
            <!-- {{ form.startDate }} ~ {{ form.endDate }} -->
            <div class="setting-title">报名时间：
              <span
                class="setting-value"
                v-if="form.startDate"
              >
                {{ `${form.startDate.substring(0,form.startDate.length-3)} ~ ${form.endDate.substring(0,form.startDate.length-3)}` }}
              </span>
            </div>
          </div>
        <div class="content">{{ form.content || '暂无' }}</div>
        <div class="splating-ling"></div>
        <div class="sub-title">
          报名信息
        </div>
        <div class="apply-list">
          
          <div class="apply-item"><div class="label question-title">联系人：</div><el-input v-model="linkname" placeholder="请输入联系人姓名" /></div>
          <div class="apply-item"><div class="label question-title">联系电话：</div><el-input v-model="linkphone" placeholder="请输入联系方式" /></div>
        </div>
        <div class="apply-item" v-if="diagnoseType.length">
            <div class="label question-title">项目诊断类型：</div>
            <el-select v-model="diagnoseTypeId" placeholder="请选择">
                <el-option
                  v-for="item in diagnoseType"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
          </div>

        <div class="sub-title" style="margin-top: 20px;">上传企业资料</div>
        <div v-if="form.fileReq" class="req">{{ form.fileReq }}</div>
        <div class="data-list">
          <FileLink
            :links="fileList"
            linkName="url"
          />
        </div>
        <el-button type="primary" icon="el-icon-upload" @click="$refs.upFile.click()">上传企业资料</el-button>
        <template v-if="form.questionnaireId">
          <div class="sub-title question-title" style="margin-top: 30px;">问卷信息</div>
          <div class="questionnaire-container">
            <el-button type="primary" icon="el-icon-edit-outline" @click="showQuestion">填写问卷</el-button>
            <div v-if="score" class="quetionnaire_score">
              <div>问卷得分：</div>
              <div class="score" @click="showAnswer">{{ score }}</div>
            </div>
          </div>
        </template>


      </div>
      <!-- 企业信息 -->
      
    </div> 
    <input type="file" @change="upFiles" ref="upFile" style="display: none;" />
    <Question ref="question" @success="questionnaire"/>
    <QuestionAnswer ref="questionAnswer" />
    <div slot="footer">
      <el-button @click="confirm(1)" :loading="isWorking" type="primary">确认提交</el-button>
      <el-button @click="confirm(0)">保存</el-button>
    </div>
  </GlobalWindow>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import Question from './Question'
import QuestionAnswer from './QuestionAnswer'
import { upload } from '@/api/system/common'
import { create } from '@/api/business/declares'
import { fetchList } from '@/api/business/projectLabel'


export default {
  name: 'OperaProjectApplyWindow',
  extends: BaseOpera,
  components: { GlobalWindow, TableLayout, Question, QuestionAnswer },
  data () {
    return {
      isUploading: false,
      // 表单数据
      form: {
        id: null,
        deleted: '',
        creator: '',
        createDate: '',
        editor: '',
        editDate: '',
        isdeleted: '',
        name: '',
        remark: '',
        startDate: '',
        endDate: '',
        sodType: '',
        edType: '',
        scoreType: '',
        areaCheck: '',
        checkReq: '',
        fileReq: '',
        serviceReq: '',
        questionnaireId: '',
        content: '',
        status: '',
        cityName: '',
        serviceLimt: '',
        businessList: []
      },
      answerId: '',
      score: '',
      remark:'',
      status:'',
      fileList: [],
      linkname: '',
      linkphone: '',
      diagnoseTypeId: '',
      diagnoseType: [],
      // 验证规则
      rules: {
      }
    }
  },
  inject: ['company'],
  created () {
    this.config({
      api: '/business/project',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      // debugger
      this.title = title
      this.visible = true
      this.remark = target.remark
      this.status = target.status
      this.answerId = target.answerId
      this.score = target.score
      this.fileList = []
     
      this.linkname = target.linkname
      this.linkphone = target.linkphone
      this.diagnoseTypeId = target.diagnoseTypeId
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        if (target.fileList) {
          this.fileList = target.fileList.map(item => {
            item.url = item.fileurlfull
            return item
          })
        }
        fetchList({
          capacity: 9999,
          model: { projectId: this.form.id }
        })
          .then(res => {
            // console.log(res);
            this.diagnoseType = res.records
            // console.log(this.diagnoseType);
          })
      })
    },
    showQuestion() {
      this.$refs.question.open(
        '',
        { id: this.form.questionnaireId },
        1,
        { questionnaireId: this.form.questionnaireId, companyId: this.company().id, projectId: this.form.id })
    },
    showAnswer() {
      this.$refs.questionAnswer.open('', { id: this.answerId })
    },
    upFiles(file) {
      this.isUploading = true
      const formdate = new FormData()
      formdate.append('file', file.target.files[0])
      formdate.append('folder', 'znzz/project_file')
      upload(formdate)
        .then(res => {
          console.log(res);
          this.fileList.push({
            name: res.originname,
            fileurl: res.imgaddr,
            url: res.url
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => this.isUploading = false)
      this.$refs.upFile.value = null
    },
    deleteFile(index) {
      this.fileList.splice(index, 1)
    },
    questionnaire(data) {
      console.log(data)
      this.answerId = data.id
      this.score = data.score
    },
    confirm(status) {
      // debugger
      let { linkname, linkphone, diagnoseTypeId } = this
      if (status) {
        if (!this.answerId && this.form.questionnaireId) {
          this.$message.error('请完成问卷')
          return
        }

        if (this.diagnoseType.length && !diagnoseTypeId) {
          // debugger
          this.$message.error('请选择诊断类型')
          return
        }
        if (!linkname) {
          // debugger
          this.$message.error('请输入联系人')
          return
        }
        if (linkphone == null || linkphone.trim() === '') {
          this.$message.error('请输入联系电话')
          return
        } else {
          let re = /^[0-9]{11}$/;
          var result = re.test(linkphone); 
          if(!result) {
            this.$message.error('请输入正确的手机号')
            return
          } 
        }
      }
      
      // 提交
      create({
        status,
        linkname,
        linkphone,
        diagnoseTypeId,
        answerId: this.answerId,
        projectId: this.form.id,
        fileList: this.fileList
      })
        .then(res => {
          this.$message.success(status?'报名成功':'保存成功')
          this.answerId = ''
          this.fileList = []
          this.visible = false
          this.$emit('success')
        })
        .catch(err => {
          this.$message.error(err)
        })
    }
  }
}
/**
 * 
 * <div slot="table-wrap" class="container" style="margin-top: 20px;">
        <div class="title">
          <div class="status"></div>
          {{ company().name }}
        </div>
        <div class="setting">
          <div class="setting-title">社会信用代码：{{ company().creditCode || '暂无' }}</div>
          <div class="parting-line"></div>
          <div class="setting-title">企业所属行业：{{ company().industryName || '暂无' }}</div>
          <div class="parting-line"></div>
          <div class="setting-title">企业性质：{{ company().natureName || '暂无' }}</div>
          <div class="parting-line"></div>
          <div class="setting-title">单位地址：{{ company().address || '暂无' }}</div>
        </div>
        <div class="company-message">
          <div class="item-line">
            <div class="item">
              <div class="item-value">
                <div class="value-label">企业法定代表人：</div>
                <div class="value-value">{{ company().legalPerson || '法人' }}</div>
              </div>
            </div>
            <div class="item">
              <div class="item-value">
                <div class="value-label">手机/电话：</div>
                <div class="value-value">{{ company().legalPersonPhone || '15511111111' }}</div>
              </div>
            </div>
          </div>
          <div class="item-line">
            <div class="item">
              <div class="item-value">
                <div class="value-label">联系人：</div>
                <div class="value-value">{{ company().linkName || '联系人' }}</div>
              </div>
            </div>
            <div class="item">
              <div class="item-value">
                <div class="value-label">手机/电话：</div>
                <div class="value-value">{{ company().linkPhone || '15511111111' }}</div>
              </div>
            </div>
          </div>
          <div class="item-line">
            <div class="item-value">
              <div class="value-label ">主营产品：</div>
              <div class="value-value ">{{ company().product || '待完善' }}</div>
            </div>
          </div>
          <div class="item-line">
            <div class="item-value">
              <div class="value-label">企业简介：</div>
              <div class="value-value ">{{ company().content || '待完善' }}</div>
            </div>
          </div>
          <div class="item-line">
            <div class="item-value">
              <div class="value-label">营业执照：</div>
              <div class="value-value">
                <!-- <img v-for="item in company().businessList" :src="item.fileurlfull" style="margin-left: 10px;width: 100px;height: 100px;" alt=""> -->
                <el-image
                  style="margin-right: 10px; width: 90px; height: 90px; border-radius: 2px;"
                  v-for="(item, index) in company().businessList"
                  :key="index"
                  :initial-index="index"
                  :src="item.fileurlfull"
                  :preview-src-list="company().businessList.map(item => item.fileurlfull)"
                  :z-index="3000"
                ></el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-title">企业财务</div>
        <el-table
          :data="company().list"
          stripe
          :header-row-class-name="'table-header'"
          class="doumee-element-table"
        >
          <el-table-column prop="years" label="近三年主要经济指标" align="center" min-width="120px" fixed="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="assets" label="企业总资产(万元)" align="center" min-width="100px"></el-table-column>
          <el-table-column prop="income" label="营业收入(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="ratio" label="资产负债率(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="develop" label="研发费用率(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="profit" label="利润总额(万元)" align="center" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="taxes" label="上缴税金(万元)" align="center" min-width="100px"></el-table-column>
          <el-table-column prop="employee" label="从业人数" align="center" min-width="100px"></el-table-column>
        </el-table>
        
      </div>
 */
</script>

<style lang="scss" scoped>
.main-container {
  // background-color: #F4F7FC;
  // padding: 20px;
}
.container {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
}
.title {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 28px;
  height: 28px;
  display: flex;
  .status {
    margin-top: 3px;
    width: 4px;
    height: 22px;
    background: #216EEE;
    border-radius: 1px;
    margin-right: 4px;
  }
  .change {
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: #216EEE;
    line-height: 28px;
    width: 92px;
    height: 28px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #216EEE;
    margin-left: 8px;
  }
}
.setting {
  display: flex;
  margin-top: 20px;
  .setting-title {
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 14px;
    // display: flex;
    .setting-value {
      font-weight: 600;
    }
  }
  .parting-line {
    height: 14px;
    background-color: #DFE2E8;
    width: 1px;
    margin: 0 15px;
  }
}
.content {
  margin-top: 19px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}
.line {
  background-color: #DFE2E8;
  height: 1px;
  margin: 30px 0;
}

.company-message {
  margin: 30px 0;
  padding: 24px 34px;
  border-radius: 2px;
  background-color: #F4F7FC;
}
.splating-ling {
  margin: 29px 0;
  height: 1px;
  background-color: #DFE2E8;
}
.item-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  height: auto;
  .item {
    width: 50%;
  }
}
.item-value {
  display: flex;
  font-size: 14px;
  line-height: 24px;
  .value-label {
    width: 146px;
    text-align: right;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    
  }
  .value-value {
    flex: 1;
  }
  .long-message {
    line-height: 24px;
  }
}

.sub-title {
  height: 16px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 16px;
  margin-bottom: 16px;
}

.data-list {
  display: flex;
  flex-wrap: wrap;
  .data-item {
    margin-bottom: 10px;
    border-radius: 2px;
    background-color: #F5F6F8;
    height: 36px;
    display: flex;
    padding: 11px 20px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3F4F69;
    line-height: 13px;
    margin-right: 16px;
    i {
      box-sizing: border-box;
      margin-left: 10px;
      width: 16px;
      height: 16px;
      background-color: #BFC3CB;
      color: #FFFFFF;
      border-radius: 50%;
      padding: 1.5px;
      cursor: pointer;
      &:hover {
        color: #216EEE;
      }
    }
  }
}
.req {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-bottom: 10px;
}

.tip-style {
  color: #ff0000;
  font-size: 16px;
  // height: 28px;
  line-height: 28px;
  padding: 0 8px;
  margin-bottom: 10px;
  font-weight: bold;
}

.question-title {
  &::before {
    content: '*';
    color: #ff0000;
    margin-right: 5px;
  }
}
.questionnaire-container {
  display: flex;
  .quetionnaire_score {
    display: flex;
    vertical-align: middle;
    line-height: 33px;
    margin-left: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    .score {
      font-size: 15px;
      font-weight: 600;
      color: #216EEE;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.apply-list {
  display: flex;
  margin-bottom: 20px;
}
.apply-item {
    margin-right: 10px;
    display: flex;
    .label {
      margin: auto 0;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      height: 14px;
      min-width: 110px;
      text-align: right;
    }
  }
</style>
