<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    v-loading="isUploading"
    element-loading-text="文件上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="this.userInfo.type==2?rules:rules2" label-suffix="：" inline>
      <div class="tip-style"><div class="tip-icon">i</div><div>企业名称、社会信用代码、所属区域、企业法定代表人、营业执照信息修改后需管理员审核，请谨慎修改！</div></div>
      <div class="first-line">
        <div class="enterprise-name">
          <el-form-item label="" :label="userInfo.type == 2 ? '企业(单位)名称' : '企业名称'"  prop="name">
            <el-input v-model="form.name" :disabled="!(this.userInfo.type==2&&!form.name)" placeholder="请输入企业(单位名称)"/>
          </el-form-item>
        </div>
        <div class="credit-code">
          <el-form-item label="统一社会信用代码" prop="creditCode">
            <el-input v-model="form.creditCode" :disabled="canEdit.creditCode" placeholder="请输入社会信用代码" />
          </el-form-item>
        </div>
      </div>
      <div >
        <el-form-item label="单位地址" prop="address">
          <div class="first-line">
            <el-select v-model="form.provinceId" :disabled="canEdit.provinceId" placeholder="请选择省份" @change="selectProvince">
              <el-option
                v-for="item in province()"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line"></div>
            <el-select v-model="form.cityId" :disabled="canEdit.cityId" placeholder="请选择城市" @change="selectCity">
              <el-option
                v-for="item in cities"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line"></div>
            <el-select v-model="form.areaId" :disabled="canEdit.areaId" placeholder="请选择地区">
              <el-option
                v-for="item in areas"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <div class="line"></div>
            <div class="address">
              <el-input v-model="form.address" placeholder="请输入详细地址" />
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="first-line">
        <el-form-item label="成立时间" prop="registerDate">
          <el-date-picker
            v-model="form.registerDate"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <div class="line"></div>
        <template v-if="userInfo.type==1" >
          <el-form-item label="企业性质" prop="natureId">
            <el-select v-model="form.natureId" placeholder="请选择企业性质">
              <el-option v-for="item in nature" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <div class="line"></div>
          <el-form-item label="企业所属行业" prop="industryId">
            <el-select v-model="form.industryId" placeholder="请选择企业所属行业">
              <el-option v-for="item in industry" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <div class="line"></div>
          <el-form-item label="企业规模" prop="labelId">
            <el-select v-model="form.labelId" placeholder="请选择企业规模">
              <el-option v-for="item in scale" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item v-else label="单位类别" prop="labelId">
          <el-select v-model="form.labelId" placeholder="请选择单位类别">
            <el-option v-for="item in caterogy" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="parting-line"></div>
      <div style="height:30px"></div>
      <div class="legal-person">
        <el-form-item label="法定代表人" prop="legalPerson">
          <el-input v-model="form.legalPerson" :disabled="canEdit.legalPerson" placeholder="请输入企业法定代表人" />
        </el-form-item>
        <div class="line"></div>
        <el-form-item label="手机/电话" prop="legalPersonPhone">
          <el-input v-model="form.legalPersonPhone" placeholder="请输入联系方式" />
        </el-form-item>
      </div>
      <div class="legal-person">
        <el-form-item label="联系人" prop="linkName">
          <el-input v-model="form.linkName" placeholder="请输入联系人姓名" />
        </el-form-item>
        <div class="line"></div>
        <el-form-item label="手机/电话" prop="linkPhone">
          <el-input v-model="form.linkPhone" placeholder="请输入联系方式" />
        </el-form-item>
      </div>
      <div class="legal-person product" v-if="this.userInfo.type==1">
        <el-form-item label="主营产品" prop="product">
          <el-input
            v-model="form.product"
            placeholder="请输入主营产品"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 12}"
            :maxlength="100"
            show-word-limit
          />
        </el-form-item>
      </div>
      <div class="legal-person product">
        <el-form-item prop="content">
          <div slot="label" class="label-style">
            <div>{{userInfo.type == 2 ?'企业(单位)简介：' : '企业简介：'}}</div>
            <div class="sub-label">(500字以内)</div>
          </div>
          <el-input
            placeholder="请输入企业(单位)简介"
            v-model="form.content"
            type="textarea"
            :autosize="{ minRows: 10, maxRows: 12}"
            :maxlength="500"
            show-word-limit
          />
        </el-form-item>
      </div>
      <div class="legal-person">
        <el-form-item :label="userInfo.type == 2 ? '营业执照/事业单位、社会团体登记证：' : '营业执照：'" prop="businessList">
          <div v-if="canEdit.businessList">
            <el-image
              style="width: 90px; height: 90px; margin-right: 10px;"
              v-for="(item, index) in form.businessList"
              :key="index"
              :initial-index="index"
              :src="item.fileurlfull"
              :preview-src-list="form.businessList.map(item => item.fileurlfull)"
              :z-index="3000"
            ></el-image>
          </div>
          <div v-if="!canEdit.businessList">
            <UploadImage
              :fileList="form.businessList"
              @beginUpload="begin"
              @endUpload="end"
            />
          </div>
        </el-form-item>
      </div>
      <div class="legal-person" v-if="userInfo.type==2">
        <el-form-item label="企业资质" prop="zzFileList">
          <UploadImage
              :fileList="form.zzFileList"
              @beginUpload="begin"
              @endUpload="end"
            />
        </el-form-item>
      </div>
      <div class="legal-person" v-if="userInfo.type==2">
        <el-form-item label="中标通知书" prop="noticeFileList">
          <UploadImage
            :fileList="form.noticeFileList"
            @beginUpload="begin"
            @endUpload="end"
          />
        </el-form-item>
      </div>
      <el-table
        :data="form.list"
        v-if="userInfo.type==1"
        stripe
        :header-row-class-name="'table-header'"
        class="doumee-element-table"
      >
        <el-table-column prop="years" label="近三年主要经济指标" align="center" min-width="120px" fixed="left" >
          <template slot-scope="{ row }">
            {{ row.years }}年
          </template>
        </el-table-column>
        <el-table-column prop="assets" label="企业总资产(万元)" align="center" min-width="100px">
          <template slot-scope="{ row }">
            <!-- onkeyup="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
              oninput="if(value<0) { value = ''} else {value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')}" -->
            <el-input
              v-model="row.assets"
              placeholder="请输入"
              @input="inputAction(row, 'assets')"
              
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="income" label="营业收入(万元)" align="center" min-width="100px" >
          <template slot-scope="{ row }">
            <el-input
              v-model="row.income"
              placeholder="请输入"
              @input="inputAction(row, 'income')"
              
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="ratio" label="资产负债率(%)" align="center" min-width="100px" >
          <template slot-scope="{ row }">
            <el-input
              v-model="row.ratio"
              placeholder="请输入"
              pattern="-?\d+(\.\d{2})?"
              @input="inputAction(row, 'ratio')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="develop" label="研发费用(万元)" align="center" min-width="100px" >
          <template slot-scope="{ row }">
            <el-input
              v-model="row.develop"
              placeholder="请输入"
              @input="inputAction(row, 'develop')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="profit" label="利润总额(万元)" align="center" min-width="100px" >
          <template slot-scope="{ row }">
            <el-input
             
              v-model="row.profit"
              placeholder="请输入"
              @input="inputAction(row, 'profit')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="taxes" label="上缴税金(万元)" align="center" min-width="100px">
          <template slot-scope="{ row }">
            <el-input
              v-model="row.taxes"
              placeholder="请输入"
              @input="inputAction(row, 'taxes')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="employee" label="从业人数" align="center" min-width="100px">
          <template slot-scope="{ row }">
            <el-input
              v-model="row.employee"
              placeholder="请输入"
              @input="numInput(row, 'employee')"
             
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    
    </el-form>
  </GlobalWindow>
</template>

<script>
import { mapState } from 'vuex'
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { fetchList as getTypes } from '@/api/business/labels'
import { checkMobile, nameRule, creditCode } from '@/utils/form'
import UploadImage from '../common/UploadImage.vue'
import { treeList } from '@/api/business/areas'
export default {
  name: 'OperaCompanyMessageWindow',
  extends: BaseOpera,
  components: { GlobalWindow, UploadImage },
  data () {
    let newRule = (rule, value, callback) => {
      if (!this.form.provinceId) {
        callback(new Error('请先选择省份'))
      } else if (!this.form.cityId) {
        callback(new Error('请先选择城市'))
      } else if (!this.form.areaId) {
        callback(new Error('请先选择地区'))
      } else if (!this.form.address) {
        callback(new Error('请输入详细地址'))
      } else {
        callback()
      }
    }
    let picValue = (rule, value, callback) => {
      //debugger
      console.log(value);
      if (value.length<=0) {
        callback(new Error(rule.message || '请上传'))
      } else {
        callback()
      }
    }
    return {
      isUploading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      // 表单数据
      form: {
        address: '',
        provinceId: '',
        areaId: '', 
        cityId: '',
        companyChange: '',
        content: '',
        createDate: '',
        creator: '',
        creditCode: '',
        editDate: '',
        editor: '',
        fileList: '',
        fileurl: '',
        id: '',
        industryId: '',
        isdeleted: '',
        legalPerson: '',
        legalPersonPhone: '',
        linkName: '',
        linkPhone: '',
        list: [],
        name: '',
        natureId: '',
        origin: '',
        password: '',
        product: '',
        registerDate: '',
        remark: '',
        type: '',
        labelId: '',
        // caterogyId: '',
        businessList: [], // 企业营业执照
        zzFileList: [], // 企业资质
        noticeFileList: [], // 企业资质
      },
      businessList: [], // 企业营业执照
      zzFileList: [], // 企业资质
      noticeFileList: [], // 企业资质
      canEdit: {
        name: false,
        creditCode: false,
        provinceId: false,
        cityId: false,
        areaId: false,
        legalPerson: false,
        businessList: false,
        // zzFileList: true,
        // noticeFileList: true,
      },
      nature: [],
      industry: [],
      cities: [],
      areas: [],
      scale: [],
      caterogy: [],
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        creditCode: [
          { required: true, validator: creditCode, trigger: 'blur' }
        ],
        address: [
          { required: true, validator: newRule, trigger: ['blur']}
        ],
        registerDate: [
          { required: true, message: '请选择成立日期', trigger: 'change' }
        ],
        natureId: [
          { required: true, message: '请选择企业性质', trigger: 'change' }
        ],
        industryId: [
          { required: true, message: '请选择所属行业', trigger: 'change' }
        ],
        labelId: [
          { required: true, message: '请选择企业规模', trigger: 'change' }
        ],
        // labelId: [
        //   { required: true, message: '请选择单位类别', trigger: 'change' }
        // ],
        legalPerson: [
          { required: true, validator: nameRule, trigger: 'blur' }
        ],
        legalPersonPhone: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ],
        linkName: [
          { required: true, validator: nameRule, trigger: 'blur' }
        ],
        linkPhone: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ],
        product: [
          { required: true, message: '请输入公司主营产品', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入简介', trigger: 'blur' }
        ],
        businessList: [
          { required: true, validator: picValue, message: '请上传', trigger: ['change', 'blur'] }
        ],
        
        
      },
      rules2: {
        name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        creditCode: [
          { required: true, validator: creditCode, trigger: 'blur' }
        ],
        address: [
          { required: true, validator: newRule, trigger: ['blur']}
        ],
        registerDate: [
          { required: true, message: '请选择成立日期', trigger: 'change' }
        ],
        natureId: [
          { required: true, message: '请选择企业性质', trigger: 'change' }
        ],
        industryId: [
          { required: true, message: '请选择所属行业', trigger: 'change' }
        ],
        // labelId: [
        //   { required: true, message: '请选择企业规模', trigger: 'change' }
        // ],
        labelId: [
          { required: true, message: '请选择单位类别', trigger: 'change' }
        ],
        legalPerson: [
          { required: true, validator: nameRule, trigger: 'blur' }
        ],
        legalPersonPhone: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ],
        linkName: [
          { required: true, validator: nameRule, trigger: 'blur' }
        ],
        linkPhone: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ],
        product: [
          { required: true, message: '请输入公司主营产品', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入公司简介', trigger: 'blur' }
        ],
        businessList: [
          { required: true, validator: picValue, message: '请上传营业执照', trigger: ['change', 'blur'] }
        ],
        // zzFileList: [
        //   { required: true, validator: picValue, message: '请上传企业资质', trigger: ['change', 'blur'] }
        // ],
        // noticeFileList: [
        //   { required: true, validator: picValue, message: '请上传中标通知书', trigger: ['change', 'blur'] }
        // ],
        
      }
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  inject: ['province'],
  created () {
    this.config({
      api: '/business/company',
      'field.id': 'id'
    })
    // 企业性质
    getTypes({
      page: 1,
      capacity: 9999,
      model: { type: 0 },
    })
      .then(res => {
        // console.log(res);
        this.nature = res.records
      })
    // 企业所属行业
    getTypes({
      page: 1,
      capacity: 9999,
      model: { type: 1 },
    })
      .then(res => {
        console.log(res);
        this.industry = res.records
      })
      .catch(e => {
        this.$message.error(e)
      })
    // 企业规模
    getTypes({
      page: 1,
      capacity: 9999,
      model: { type: 2 },
    })
      .then(res => {
        console.log(res);
        this.scale = res.records
      })
      .catch(e => {
        this.$message.error(e)
      })
    // 企业规模
    getTypes({
      page: 1,
      capacity: 9999,
      model: { type: 3 },
    })
      .then(res => {
        console.log(res);
        this.caterogy = res.records
      })
      .catch(e => {
        this.$message.error(e)
      })
    
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      
      // debugger
      // 编辑
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
        this.$refs.form.resetFields()
        for (const key in this.form) {
          this.form[key] = target[key]
          if (key in this.canEdit) {
            this.canEdit[key] = !!this.form[key]
          }
        }
        this.form.list = JSON.parse(JSON.stringify(this.form.list))
        console.log(this.form)
        this.form.fileList = []
        // this.form.changeFileList = []
        if (!this.form.businessList) {
          this.form.businessList = []
          this.businessList = []
        }
        this.form.businessList = JSON.parse(JSON.stringify(this.form.businessList))
        this.form.zzFileList = JSON.parse(JSON.stringify(this.form.zzFileList))
        this.form.noticeFileList = JSON.parse(JSON.stringify(this.form.noticeFileList))
        if (!this.form.zzFileList) {
          this.form.zzFileList = []
          this.zzFileList = []
        } else {
          // this.zzFileList = this.form.zzFileList
          this.form.zzFileList.map(item => {
            let response = {
              data: {
                imgaddr: item.fileurl
              }
            }
            item.response = response
            item.url = item.fileurlfull
            return item
          })
        }
        if (!this.form.noticeFileList) {
          this.form.noticeFileList = []
          this.noticeFileList = []
        } else {
          this.form.noticeFileList.map(item => {
            let response = {
              data: {
                imgaddr: item.fileurl
              }
            }
            item.response = response
            item.url = item.fileurlfull
            return item
          })
        }
        console.log(this.form.noticeFileList);
        // debugger
        this.selectProvince(this.form.provinceId, true)
      })
    },
    inputAction(item, key) {
      
      item[key] = item[key]
                  // .replace(/^-?\d+(\.\d{1,2})?$|^$/, '')
                  .replace(/[^\d.-]/g, '')
                  // .replace(/\-{2,}/g, ".")
                  .replace(/\.{2,}/g, '.')
                  .replace('.', '$#$')
                  .replace(/\./g, '')
                  .replace('$#$', '.')
                  .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
                  .replace(/^\./g, '')
      // console.log(item[key]); 
    },
    inputZAction(item, key) {
      item[key] = item[key].replace(/[^\d.]/g, '')
                      .replace(/\.{2,}/g, '.')
                      .replace('.', '$#$')
                      .replace(/\./g, '')
                      .replace('$#$', '.')
                      .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
                      .replace(/^\./g, '')
    },
    numInput(item, key) {
      // =""
      item[key]=item[key].replace(/^|[^\d]+/g,'')
    },
    begin() {
      this.isUploading=true
    },
    end() {
      this.isUploading=false
    },
    // 选择省份
    selectProvince(val, isInit) {
      if (!isInit) {
        this.cities = []
        this.areas = []
        this.form.cityId = ''
        this.form.areaId = ''
      }
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
          if (isInit) {
            this.selectCity(this.form.cityId, isInit)
          }
        })
      // const tempProvince = this.province().find(item => item.id === val)
      // if (tempProvince) {
      //   this.cities = tempProvince.childList
      //   if (isInit) {
      //     this.selectCity(this.form.cityId, isInit)
      //   }
      // }
    },
    // 选择城市
    selectCity(val, isInit) {
      if (!isInit) {
        this.areas = []
        this.form.areaId = ''
      }
      treeList({ type: 2, parentId: val })
        .then(res => {
          this.areas = res
          
        })
      // const tempCity = this.cities.find(item => item.id === val)
      // if (tempCity) {
      //   this.areas = tempCity.childList
      // }
    },
    __confirmEdit () {
      console.log(this.form)
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        
        // 调用新建接口
        this.isWorking = true
        /**
         * canEdit: {
            name: false,
            creditCode: false,
            provinceId: false,
            cityId: false,
            areaId: false,
            businessList: false,
          },
         */
        let param = {}
        for (const key in this.form) {
          if (key in this.canEdit) {
            if (!this.canEdit[key]) {
              param[key] = this.form[key]
            }
          } else {
            param[key] = this.form[key]
          }
        }
        
        // if (this.form.list) {
        //   for (const item of this.form.list) {
        //     if (item.income<0 || item.profit<0) {
        //       this.$message.error('必须填写营业收入和利润总额')
        //       return
        //     }
        //   }
        // }
        this.api.updateById(param)
          .then(() => {
            this.visible = false
            this.$message.success('修改成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$message.error(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.tip-style {
  height: 17px;
  color: #EE3821;
  display: flex;
  margin-bottom: 20px;
  line-height: 17px;
  vertical-align: middle;
  font-size: 14px;
  .tip-icon {
    vertical-align: middle;
    border: 1px #EE3821 solid;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    margin-right: 6px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
  }
}
.first-line {
  display: flex;
  .enterprise-name {
    ::v-deep .el-form-item__content {
      width: 448px;
    }
    margin-right: 20px;
  }
  .credit-code {
    ::v-deep .el-form-item__content {
      width: 266px;
    }
  }
}
.label-style {
  display: inline-block;
  vertical-align: top;
  .sub-label {
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
  }
}
.address {
  display: inline-block;
  ::v-deep .el-input__inner {
      width: 276px;
    }
}
.line {
  display: inline-block;
  width: 20px;
}
.parting-line {
  height: 1px;
  background-color: #DFE2E8;
  margin-top: 10px;
}
.legal-person {
  display: flex;
  ::v-deep .el-form-item__label{
    width: 130px;
  }
}
.product {
  ::v-deep .el-input__inner {
    width: 850px;
  }
  ::v-deep .el-textarea__inner {
    width: 850px;
  }
}

</style>
