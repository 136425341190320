<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" label-suffix="：" :rules="rules" inline>
      <div>
        <el-form-item label="服务机构" prop="soId">
          <el-select v-model="form.soId" filterable placeholder="请选择服务机构">
            <el-option
              v-for="item in this.serviceList"
              :key="item.id"
              :value="item.companyId"
              :label="item.companyName"
              
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { distributionServiceSave } from '@/api/business/declares'
import { fetchList as projectSo } from '@/api/business/projectSo'
export default {
  name: 'OperaDistributeServeWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        projectId: null,
        id: null,
        soId: null
      },
      serviceList: [],
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/areas',
      'field.id': 'id'
    })
    
  },
  methods: {
    open (title, target) {
      // debugger
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.getServiceList()
      })
    },
    getServiceList() {
      // debugger
      projectSo({
        capacity: 9999,
        model:{ projectId: this.form.projectId, type: 0 }
      })
        .then(res => {
          this.serviceList = res.records
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
    confirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        distributionServiceSave(this.form)
          .then(() => {
            this.visible = false
            this.$message.success('新建成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$message.error(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
::v-deep .el-select {
    width: 100%;
    .el-input__inner {
      width: 100%;
    }
  }
</style>
