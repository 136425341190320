import { MessageBox } from 'element-ui'

export default {
  ...MessageBox,
  // 删除二次确认
  deleteConfirm (message) {
    return MessageBox.confirm(message, '删除提醒', {
      confirmButtonText: '确认删除',
      cancelButtonText: '取消',
      type: 'warning'
    })
  },
  resetConfirm (message) {
    return MessageBox.confirm(message, '重置提醒', {
      confirmButtonText: '确认重置',
      cancelButtonText: '取消',
      type: 'warning'
    })
  },
  // 禁用二次确认
  disableConfirm (message) {
    return MessageBox.confirm(message, '禁用提醒', {
      confirmButtonText: '确认禁用',
      cancelButtonText: '取消',
      type: 'warning'
    })
  },
  // 导出二次确认
  exportConfirm (message) {
    return MessageBox.confirm(message, '导出提醒', {
      confirmButtonText: '确认导出',
      cancelButtonText: '取消',
      type: 'warning'
    })
  },
  // 完善提醒
  messageComplete (message) {
    return MessageBox.confirm(message, '资料提醒', {
      confirmButtonText: '去完善',
      cancelButtonText: '取消',
      type: 'warning'
    })
  },
  // 问卷确实提醒
  questionnaireMiss (message) {
    return MessageBox.confirm(message, '提醒', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
  },
  // 确认是否服务该企业
  confirmServe (message) {
    return MessageBox.confirm(message, '提示', {
      confirmButtonText: '确认服务',
      cancelButtonText: '拒绝服务',
      type: 'warning'
    })
  }
}
