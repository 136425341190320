<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
   
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="用户名称" prop="soUserId">
        <el-select v-model="form.soUserId" placeholder="请选择用户">
          <el-option
            v-for="item in so"
            :key="item.id"
            :value="item.id"
            :label="item.username"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { fetchList as childList } from '@/api/business/childrenUser'
import { distributionChildAccount } from '@/api/business/declares'
export default {
  name: 'ServiceDistributionChild',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {
    return {
      // 表单数据
      form: {
        projectId: '',
        ids: '',
        soUserId: ''
        
      },
      so: [
      ],
      
      // 验证规则
      rules: {
      }
    }
  },
  created () {
    this.config({
      api: '/business/account',
      'field.id': 'id'
    })
    
  },
  methods: {
    open(title, target) {
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        this.initData()
      })
    },
    initData() {
      childList({
        capacity: 9999,
        model: {}
      })
        .then(res => {
          this.so = res.records
        })
        .catch(e => {
          this.$message.error(e)
        })
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        distributionChildAccount(this.form)
          .then(() => {
            this.visible = false
            this.$message.success('分配成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$message.error(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/alertstyle.scss";
::v-deep .el-select {
    width: 100%;
    .el-input__inner {
      width: 100%;
    }
  }
</style>
