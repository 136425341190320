<template>
  <div>
    <el-upload
      class="avatar-uploader"
      :action="uploadImgUrl"
      :data="uploadData"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :on-error="uploadError"
      :before-upload="beforeAvatarUpload">
      <img v-if="file.imgurlfull" :src="file.imgurlfull" class="avatar">
      <div v-else>
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <div class="tips-style">{{ tipsLabel }}</div>
      </div>
    </el-upload>
  </div>
  
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    uploadData: Object,
    tipsLabel: ''
  },
  data() {
    return {
      uploadImgUrl: process.env.VUE_APP_API_PREFIX + '/public/uploadPicture',
      // uploadData: {
      //   folder: 'upload',
      //   type: 'image'
      // },
      imgurlfull: ''
    }
  },
  
  methods: {
    // 上传图片
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        this.file.imgurl = res.data.imgaddr;
        this.file.imgurlfull = res.data.url;
        this.$message.success('上传成功')
        this.$emit('uploadSuccess', { imgurl: res.data.imgaddr, imgurlfull: res.data.url })
      } else {
        this.$message.error('上传失败')
      }
      this.$emit('uploadEnd')
    },
    uploadError() {
      this.$message.error('上传失败')
      this.$emit('endUpload')
    },
    // // 拦截
    beforeAvatarUpload(file) {
      this.$emit('uploadBegin')
      return true;
    },
  },
}
</script>

<style lang="scss" scoped>
$image-width: 100px;
.avatar-uploader {
  width: 140px;
  height: 90px;
}
::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 140px;
  height: 90px;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.avatar {
  width: 140px;
  height: 90px;
  display: block;
}
.tips-style {
  height: 13px;
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  line-height: 13px;
}
</style>

