<template>
  <GlobalAlertWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    width="500px"
  >
    <el-form :model="form" ref="form" label-width="100px" label-suffix="：" :rules="rules">
      <el-form-item label="项目名称" prop="projectId">
        <el-select v-model="form.projectId" placeholder="请选择项目">
          <el-option
            v-for="item in projects"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="子账号" prop="objId">
        <el-select v-model="form.objId" filterable placeholder="请选择服务机构">
          <el-option
            v-for="item in children"
            :key="item.id"
            :value="item.id"
            :label="item.username"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </GlobalAlertWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalAlertWindow from '@/components/common/GlobalAlertWindow'
import { checkMobile, nameRule } from '@/utils/form'
import { findSJProjectList, distributeSjChild } from '@/api/business/sjProjectDeclares'
import { fetchList as userList } from '@/api/business/sjChildrenUser'
export default {
  name: 'OperaSJChildrenUserWindow',
  extends: BaseOpera,
  components: { GlobalAlertWindow },
  data () {

    return {
      // 表单数据
      form: {
        projectId: null,
        objId: '',
      },
      projects: [],
      children: [],
      // 验证规则
      rules: {
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        realname: [
          { required: false, validator: nameRule, trigger: 'blur'}
        ],
        mobile: [
          { required: false, validator: checkMobile, trigger: 'blur'}
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/business/sjChildrenUser',
      'field.id': 'id'
    })
    findSJProjectList()
      .then(res => {
        this.projects = res
      })
    userList({
      capacity:999,
      model: {}
    })
      .then(res => {
        this.children = res.records
      })
  },
  methods: {
    confirm() {
      distributeSjChild(this.form)
        .then(res => {
          this.$message.success('分配成功')
          this.$emit('success')
          this.visible = false
        })
        .catch(e => {
          this.$message.error(e)
        })
    }

  },
}
</script>

<style lang="scss" scoped>
.input-style {
  ::v-deep .el-input__inner {
    width: 200px;
  }
}
</style>


