import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/declareService/page', data, {
    trim: true
  })
}
// 查询
export function finishServeInfo (data) {
  return request.post('/business/declareService/findByDeclareId', data, {
    trim: true
  })
}
// 查询
export function doneServiceList (data) {
  return request.post('/business/declareService/doneServiceList', data, {
    trim: true
  })
}
// 查询
export function serviceDetail (data) {
  return request.post('/business/declareService/serviceDetail', data)
}

// 导出Excel
export function exportExcel (data) {
  return request.post('/business/declareService/exportExcel', data, {
    trim: true,
    download: true
  })
}

// 创建
export function create (data) {
  return request.post('/business/declareService/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/business/declareService/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/business/declareService/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/business/declareService/delete/batch', {
    params: {
      ids
    }
  })
}

// 上传核查资料-上传服务资料
export function uploadHCData (data) {
  return request.post('/business/declareService/uploadHCData', data)
}
export function editHCData (data) {
  return request.post('/business/declareService/editHCData', data)
}

// 完成服务
export function finishHCData (data) {
  return request.post('/business/declareService/finishHCData', data)
}
