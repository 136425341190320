<template>
  <div
    class="register"
    v-loading="isUploading"
    element-loading-text="文件上传中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="register-content">
      <div class="title">智能制造诊断综合服务平台</div>
      <!-- <div class="title">合肥市智改数转诊断服务平台</div> -->
      <div class="sub-title">
        <div class="flag"></div>
        <div>{{ type==2?'服务机构注册':'综合服务单位注册' }}</div>
        <div class="tips">请按要求填写下列内容，确保内容真实可查。企业名称、省市区、统一社会信息代码注册完成后，不可随意修改</div>
      </div>
      <div class="form">
        <el-form :model="form" ref="form" :rules="rules" label-width="150px" inline label-suffix="：">
          <div class="item-title">账号信息：</div>
          <div class="password">
            <el-form-item label="登录账号" prop="userName">
              <el-input v-model="form.userName" placeholder="请输入8-16位密码，包含大小写字母" />
            </el-form-item>
          </div>
          <div class="password">
            <el-form-item label="登录密码" prop="password">
              <el-input v-model="form.password" show-password placeholder="请输入8-16位密码，包含大小写字母" />
            </el-form-item>
          </div>
          <div class="password">
            <el-form-item label="密码确认" prop="passwordCheck">
              <el-input v-model="form.passwordCheck" show-password placeholder="请输入8-16位密码，包含大小写字母" />
            </el-form-item>
          </div>
          <div class="parting-line"></div>
          <div style="height:10px"></div>
          <div class="item-title">企业/单位信息：</div>
          <div style="height:10px"></div>
          <div class="first-line">
            <div class="enterprise-name">
              <el-form-item label="企业/单位名称" prop="companyName">
                <el-input v-model="form.companyName" placeholder="请输入企业名称"/>
              </el-form-item>
            </div>
            <div class="credit-code">
              <el-form-item label="统一社会信用代码" prop="creditCode">
                <el-input v-model="form.creditCode" placeholder="请输入社会信用代码" />
              </el-form-item>
            </div>
          </div>
            <!-- <div class="password">
              <el-form-item label="企业/单位名称" prop="companyName">
                <el-input v-model="form.companyName" placeholder="请输入企业名称"/>
              </el-form-item>
            </div>
            <div class="credit-code">
              <el-form-item label="统一社会信用代码" prop="creditCode">
                <el-input v-model="form.creditCode" placeholder="请输入社会信用代码" />
              </el-form-item>
            </div> -->
          <div class="label-id">
            <el-form-item label="单位类别" prop="labelId">
              <el-checkbox-group 
                v-model="form.labelId"
                @change="labelChange"
              >
                <el-checkbox v-for="item in labels" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
              </el-checkbox-group>
              <!-- <el-radio-group v-model="form.labelId">
                <el-radio  v-for="item in labels" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
              </el-radio-group> -->
            </el-form-item>
          </div>
          <div class="address">
            <el-form-item label="单位地址" prop="address">
              <el-select v-model="form.provinceId" placeholder="请选择省份" @change="selectProvince">
                <el-option
                  v-for="item in province"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
              <div class="line"></div>
              <el-select v-model="form.cityId" placeholder="请选择城市" @change="selectCity">
                <el-option
                  v-for="item in cities"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
              <div class="line"></div>
              <el-select v-model="form.areaId" placeholder="请选择地区">
                <el-option
                  v-for="item in areas"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
              <div class="line"></div>
              <div class="address-input">
                <el-input v-model="form.address" placeholder="请输入详细地址" />
              </div>
            </el-form-item>
          </div>
          <!-- <div class="first-line">
            <el-form-item label="成立时间" prop="registerDate">
              <el-date-picker
                v-model="form.registerDate"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <div class="line"></div>
            
            <div class="line"></div>
            <el-form-item label="企业所属行业" prop="industryId">
              <el-select v-model="form.industryId" placeholder="请选择企业所属行业">
                <el-option v-for="item in industry" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </div> -->
          <div class="parting-line"></div>
          <div style="height:10px"></div>
          <div class="legal-person">
            <el-form-item label="法定代表人" prop="legalPerson">
              <el-input v-model="form.legalPerson" placeholder="请输入企业法定代表人" />
            </el-form-item>
            <div class="line"></div>
            <el-form-item label="手机/电话" prop="legalPersonPhone">
              <el-input v-model="form.legalPersonPhone" placeholder="请输入联系方式" />
            </el-form-item>
          </div>
          <div class="legal-person">
            <el-form-item label="联系人" prop="linkName">
              <el-input v-model="form.linkName" placeholder="请输入联系人姓名" />
            </el-form-item>
            <div class="line"></div>
            <el-form-item label="手机/电话" prop="linkPhone">
              <el-input v-model="form.linkPhone" placeholder="请输入联系方式" />
            </el-form-item>
          </div>
          <div class="legal-person product">
            <el-form-item label="主营产品" prop="product">
              <el-input
                placeholder="请输入(100字以内)"
                v-model="form.product"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 12}"
                :maxlength="100"
                show-word-limit
              />
            </el-form-item>
          </div>
          <div class="legal-person product">
            <el-form-item label="" prop="content">
              <div slot="label" class="label-style">
                <div>企业/单位简介：</div>
                <div class="sub-label">(500字以内) </div>
              </div>
              <el-input
                placeholder="请输入"
                v-model="form.content"
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 12}"
                :maxlength="500"
                show-word-limit
              />
            </el-form-item>
          </div>
          <div class="legal-person">
            <el-form-item label="营业执照/登记证" prop="fileList">
              <UploadImage
                :fileList="form.fileList"
                @beginUpload="begin"
                @endUpload="end"
              />
            </el-form-item>
          </div>
          
          <div class="apply-btn" @click="registerAction">申请注册</div>
          <div class="argument">
            <img
              :src="isAgree?`${require('../assets/images/zhuce_selected@2x.png')}`:`${require('../assets/images/zhuce_select@2x.png')}`" 
              style="cursor: pointer;"
              @click="isAgree = !isAgree"
            >
            <div>选中表示同意<span @click="showProtocol">《用户服务协议》</span></div>
          </div>
        </el-form>
      </div>
      <div class="copyright">Copyright © 2022 - 2023 智能制造诊断综合服务平台. All Rights Reserved.  版权所有</div>
      <!-- <div class="copyright">Copyright © 2022 - 2023 合肥市智改数转诊断服务平台. All Rights Reserved.  版权所有</div> -->
    </div>
  </div>
</template>

<script>
import { treeList } from '@/api/business/areas'
import { fetchList as getTypes } from '@/api/business/labels'
import { registerServiceCompany, registerZFServiceCompany } from '@/api/business/company' 
import { checkMobile, nameRule, creditCode } from '@/utils/form'
import UploadImage from '../components/common/UploadImage.vue'

export default {
  components: {
    UploadImage
  },
  data() {
    let newRule = (rule, value, callback) => {
      if (!this.form.provinceId) {
        callback(new Error('请先选择省份'))
      } else if (!this.form.cityId) {
        callback(new Error('请先选择城市'))
      } else if (!this.form.areaId) {
        callback(new Error('请先选择地区'))
      } else {
        callback()
      }
    }
    let picValue = (rule, value, callback) => {
      console.log(value);
      if (value.length<=0) {
        callback(new Error('请上传营业执照'))
      } else {
        callback()
      }
    }
    let passwordRule = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入登录密码'))
        return
      } 
      let re =/^(?=.*[a-z])(?=.*\d)[^]{8,16}$/;
      let result =  re.test(value);
      if (!result) {
      　callback(new Error('密码为8-16位，包含大小写字母'))
      } else {
        callback()
      }
    }
    let passwordCheckRule = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请再次输入登录密码'))
        return
      } 
      if (value != this.form.password) {
      　callback(new Error('2次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      uploadImgUrl: process.env.VUE_APP_API_PREFIX + '/public/uploadPicture',
      uploadData: {
        folder: 'znzz/company_file',
        type: 'image'
      },
      isUploading: false,
      form: {
        companyName: '',
        creditCode: '',
        provinceId: '',
        cityId: '',
        areaId: '',
        address: '',
        registerDate: '',
        labelId: [],
        industryId: '',
        legalPerson: '',
        legalPersonPhone: '',
        linkName: '',
        linkPhone: '',
        product: '',
        content: '',
        password: '',
        fileList: [],
        userName: '',
        passwordCheck: ''
      },
      type: 0,
      isAgree: true,
      province: [],
      cities: [],
      areas: [],
      labels: [],
      industry: [],
      // 验证规则
      rules: {
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        creditCode: [
          { required: true, validator: creditCode, trigger: 'blur' }
        ],
        address: [
          { required: true, validator: newRule, trigger: 'change'}
        ],
        registerDate: [
          { required: true, message: '请选择成立日期', trigger: 'change' }
        ],
        labelId: [
          { required: true, message: '请选择单位类别', trigger: 'change' }
        ],
       
        legalPerson: [
          { required: true, message: '请输入法定代表人姓名', trigger: 'blur' }
        ],
        legalPersonPhone: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ],
        linkName: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        linkPhone: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ],
        product: [
          { required: true, message: '请输入公司主营产品', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入公司简介', trigger: 'blur' }
        ],
        fileList: [
          { required: true, validator: picValue, trigger: 'change' }
        ],
        userName: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        password: [
          { required: true, validator: passwordRule, trigger: 'blur' }
        ],
        passwordCheck: [
          { required: true, validator: passwordCheckRule, trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    console.log('fujg',this.$route.params);
    this.type = this.$route.params.type
    // 地区
    treeList({ type: 0 })
      .then(res => {
        // console.log(res)
        this.province = res
      })
      .catch(e => {
        this.$message.error(e)
      })
    // 企业性质
    getTypes({
      page: 1,
      capacity: 9999,
      model: { type: 3 },
    })
      .then(res => {
        // console.log(res);
        this.labels = res.records
      })
      .catch(e => {
        this.$message.error(e)
      })
    // 企业所属行业
    // getTypes({
    //   page: 1,
    //   capacity: 9999,
    //   model: { type: 1 },
    // })
    //   .then(res => {
    //     console.log(res);
    //     this.industry = res.records
    //   })
    //   .catch(e => {
    //     this.$message.error(e)
    //   })
  },
  methods: {
    begin() {
      this.isUploading=true
    },
    end() {
      this.$refs.form.clearValidate('fileList')
      this.isUploading=false
    },
    // 选择省份
    selectProvince(val) {
      this.cities = []
      this.areas = []
      this.form.cityId = ''
      this.form.areaId = ''
      treeList({ type: 1, parentId: val })
        .then(res => {
          this.cities = res
        })
    },
    // 选择城市
    selectCity(val) {
      this.areas = []
      this.form.areaId = ''
      treeList({ type: 2, parentId: val })
        .then(res => {
          this.areas = res
        })
    },
    labelChange(value) {
      this.form.labelId = [value[value.length-1]]
    },
    showProtocol() {
      // 用户协议
      // console.log('用户协议');
      this.$refs.protocol.open('用户协议')
    },
    registerAction() {
      // console.log(this.form);
      if (!this.isAgree) {
        this.$message.warning('请先同意用户协议')
        return
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        let register = this.type==2? registerServiceCompany : registerZFServiceCompany
        register({
          ...this.form,
          labelId: this.form.labelId[0]
        })
          .then(res => {
            this.$message.success('恭喜！注册成功，3秒后自动返回登录页...')
            setTimeout(()=>{
              this.$router.go(-2)
            }, 3000)
          })
          .catch(e => {
            this.$message.error(e)
          })
      })
    },
    
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
* {
  ::-webkit-scrollbar{
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.register {
  height: 100%;
  width: 100%;
  // overflow-y: scroll;
  background-image: url('../assets/images/register_bg.png');
  background-origin: center;
  background-size: cover;
  background-repeat: no-repeat;
  .register-content {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar{
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
    .item-title {
      height: 16px;
      font-size: 16px;
      font-weight: 600;
      color: #222222;
      line-height: 16px;
    }
    width: 1100px;
    margin: 0 auto;
    background-color: #fff;
    box-sizing: border-box;
    padding: 60px;
    position: relative;
    .title {
      height: 30px;
      font-size: 30px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #222222;
      line-height: 30px;
      text-align: center;
    }
    .sub-title {
      position: absolute;
      height: 26px;
      width: 100%;
      display: flex;
      left: 42px;
      margin-top: 40px;
      font-weight: 600;
      color: #222222;
      font-size: 26px;
      line-height: 26px;
      .flag {
        width: 6px;
        height: 24px;
        background-color: $primary-color;
        margin-right: 12px;
      }
      .tips {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        color: #EE3821;
        margin-left: 20px;
      }
    }
    .form {
      margin-top: 96px;
      .first-line {
        display: flex;
        .enterprise-name {
          ::v-deep .el-form-item__content {
            width: 400px;
          }
          margin-right: 10px;
        }
        .credit-code {
          ::v-deep .el-form-item__label{
            width: 150px !important;
          }
          ::v-deep .el-form-item__content {
            width: 236px;
          }
        }
      }
      .address {
        ::v-deep .el-form-item {
          display: flex;
        }
        ::v-deep  .el-form-item__content {
          flex: 1;
          display: flex;
        }
        ::v-deep .el-select {
          width: 150px;
          .el-input__inner {
            width: 100%;
          }
        }
        
        .address-input {
          // display: inline-block;
          flex: 1;
          ::v-deep .el-input__inner {
            // width: 180px;
            // flex: 1;
            width: 100%;
          }
        }
      }
      .line {
        display: inline-block;
        width: 20px;
      }
      .parting-line {
        height: 1px;
        background-color: #DFE2E8;
        margin: 10px 0;
      }
      .legal-person {
        display: flex;
        ::v-deep .el-form-item__label{
          width: 130px;
        }
      }
      .product {
        ::v-deep .el-input__inner {
          width: 800px;
        }
        ::v-deep .el-textarea__inner {
          width: 800px;
        }
      }
      .password {
        margin-top: 10px;
        ::v-deep .el-input__inner {
          width: 470px;
        }
        .credit-code {
          ::v-deep .el-form-item__content {
            width: 266px;
          }
        }
      }
    }
  }
}

::v-deep .el-form-item__label {
  display: inline-block;
}
::v-deep .el-form-item__content {
   display: inline-block;
}
::v-deep .el-form-item__label {
  color: #333333;
}



.argument {
  margin: 16px auto;
  width: 220px;
  display: flex;
  height: 16px;
  line-height: 16px;
  vertical-align: middle;
  font-size: 14px;
  color: #666666;
  span {
    color: #216EEE;
    cursor: pointer;
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    vertical-align: middle;
  }
}

.apply-btn {
  margin: 50px auto 0;
  width: 240px;
  color: #fff;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  background: #216EEE;
  box-shadow: 0px 3px 6px 0px rgba(33,110,238,0.14);
  border-radius: 2px;
  cursor: pointer;
}
.copyright {
  text-align: center;
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #828D9E;
  line-height: 12px;
  margin-top: 38px;
}
.label-style {
  display: inline-block;
  vertical-align: top;
  .sub-label {
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
  }
}
.label-id {
  ::v-deep .el-form-item {
    display: flex;
  }
  ::v-deep .el-checkbox-group {
    // flex: 1;
    width: 820px;
    display: flex;
    flex-wrap: wrap;
  }
}

</style>
