<template>
  <div>
    <el-upload
      class="avatar-uploader"
      :action="uploadImgUrl"
      :data="uploadData"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :on-error="uploadError"
      :before-upload="beforeAvatarUpload">
      <img v-if="file.imgurlfull" :src="file.imgurlfull" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
  
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    uploadData: Object
  },
  data() {
    return {
      uploadImgUrl: process.env.VUE_APP_API_PREFIX + '/public/uploadPicture',
      // uploadData: {
      //   folder: 'upload',
      //   type: 'image'
      // },
      imgurlfull: ''
    }
  },
  
  methods: {
    // 上传图片
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        this.file.imgurl = res.data.imgaddr;
        this.file.imgurlfull = res.data.url;
        this.$tip.apiSuccess('上传成功')
        this.$emit('uploadSuccess', { imgurl: res.data.imgaddr, imgurlfull: res.data.url })
      } else {
        this.$tip.apiFailed('上传失败')
      }
      this.$emit('uploadEnd')
    },
    uploadError() {
      this.$tip.apiFailed('上传失败')
      this.$emit('endUpload')
    },
    // // 拦截
    beforeAvatarUpload(file) {
      this.$emit('uploadBegin')
      return true;
    },
  },
}
</script>

<style lang="scss" scoped>
$image-width: 100px;
.avatar-uploader {
  width: $image-width;
  height: $image-width;
}
::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: $image-width;
  height: $image-width;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $image-width;
  height: $image-width;
  line-height: $image-width;
  text-align: center;
}
.avatar {
  width: $image-width;
  height: $image-width;
  display: block;
}
</style>

