import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/business/declareExpert/page', data, {
    trim: true
  })
}

// 导出Excel
export function exportExcel (data) {
  return request.post('/business/declareExpert/exportExcel', data, {
    trim: true,
    download: true
  })
}

// 创建
export function create (data) {
  return request.post('/business/declareExpert/create', data)
}

// 分配专家
export function assignExperts (data) {
  return request.post('/business/declareExpert/assignExperts', data)
}

// 修改
export function updateById (data) {
  return request.post('/business/declareExpert/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/business/declareExpert/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/business/declareExpert/delete/batch', {
    params: {
      ids
    }
  })
}
